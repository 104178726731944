'use strict';
angular.module('gmpApp')
  .controller('SettingsShopAccountListCtrl', function ($rootScope, $scope, $state, $stateParams, shopAccountModel, commonSVC, userInfo, $timeout, workSVC, settings, localStorageService) {
    // 자동 설정 쇼핑몰 리스트 : 옥션, 지마켓, 11번가, 인터파크, 스마트스토어, 쿠팡, 카카오톡 스토어 + 위메프
    $scope.autoSettingShopList = ['A001', 'A006', 'A112', 'A027', 'A077', 'B378', 'B688', 'B719'];

    $scope.channelDetailOpen = false;  // 추가,수정화면 노출여부
    $scope.searchText = '';
    $scope.engines = [];
    $scope.use_engines = [];
    $scope.tap_active = 0;
    $scope.filteredShopLen = 0; // 무료쇼핑몰(이마트몰,신세계몰) 제외한 쇼핑몰 수
    $scope.today = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
    $scope.shopNameSearchKey = '';
    $scope.tab = 0;  // 탭 인덱스
    $scope.searchData = {   // input ng-model 에 바로 $scope.data 같은 형식으로 하면 적용되지 않는 문제 수정
      shop_name: ''
    };
    $scope.shop_name = '';

    $scope.isFirstShopRegist = $stateParams.pageFrom === 'firstModal'; // 최초접속자 쇼핑몰 등록 모달에서 넘어온 경우
    $scope.showAddShopBtn = false;

    // 쇼핑몰 계정 상세가 쇼핑몰 리스트 하위에 열리도록 되어있는데 이로 인해 페이지 새로고침시에 하위페이지도 쇼핑몰 탭으로 인식되어
    // 탭이 2개 열리는 문제가 있음.
    // 쇼핑몰계정 페이지 로딩시 상세탭은 닫도록 해당 코드 추가.
    $rootScope.$emit('$closeTab', 'tab_settings_shop_account.channelSub');

    /**
     * 검색 필터
     */
    $scope.filterFunction = function(element) {
      if ($scope.searchText == '') { return true; }

      return !!JSON.stringify(element).match(new RegExp(`(${$scope.searchText})`, 'i'));
    };

    /**
     * 간편입점 홈페이지 이동
     */
    $scope.openHomePageEntry = function(page) {
      const sendInfo = localStorageService.get('sendInfo');
      const info = atob(decodeURIComponent(sendInfo));

      let url = `${settings.wwwUrl}/member/AdminLogin/?info=${encodeURIComponent(info)}`;

      if (page === 'result') {
        url += '&ret_url=entry/EntryResult';
      } else {
        url += '&ret_url=entry/EntryStore';
      }

      window.open(url);
    };

    /**
     * 사용중인 쇼핑몰 수 계산
     * (신세계몰/이마트몰 카운트 제외용)
     */
    $scope.calcShopLength = () => {
      $scope.filteredShopLen = $scope.use_engines.filter(mall =>
        (!$scope.searchText || !!JSON.stringify(mall).match(new RegExp(`(${$scope.searchText})`, 'i'))) &&
        (!['A083', 'A084', 'A012', '1009', '2009', '3009', 'B009', 'A178', 'B719', 'B851', 'B956', 'A175'].includes(mall.shop_cd) &&
        !(mall.pa_shop_cd.startsWith('X') && (mall.shop_cd !== mall.pa_shop_cd)))).length;
    };

    $scope.addMallInit = function () {
      $scope.channelDetailOpen = false;
    };

    $scope.sortableOptions = {
      update: function (e, ui) {
        $timeout(function() {
          updateSeq(ui.item.attr('id'));
        }, 100);
      }
    };

    const tooltip = document.getElementById('dragTooltip');

    const oriX = tooltip.getBoundingClientRect().left;
    const oriY = tooltip.getBoundingClientRect().left;

    $scope.dragCursorTooltip = (event) => {
      tooltip.style.opacity = 1;
      tooltip.style.position = 'absolute';
      tooltip.style.zIndex = 10000;

      drag(event.pageX, event.pageY);

      // 드래그 이동 이벤트
      document.addEventListener('mousemove', onMouseMove);
      // 드랍 이벤트
      document.addEventListener('mouseup', onMouseUp);

      function drag(pageX, pageY) {
        tooltip.style.left = pageX - oriX + 20 + 'px';
        tooltip.style.top = pageY - oriY + 'px';
      }

      function onMouseMove(event) {
        drag(event.pageX, event.pageY);
      }

      function onMouseUp() {
        tooltip.style.opacity = 0;
        document.removeEventListener('mousemove', onMouseMove);
        tooltip.onmouseup = null;
      }
    };

    /**
     * 쇼핑몰 리스트 순서 변경시 index update
     */
    function updateSeq(id) {
      const lastIdx = angular.copy($scope.use_engines).reverse().find((shop, idx) => shop.no !== idx)?.no;

      if (!lastIdx) { return; }

      const targetShop = id.split('(')[0];
      const targetId = id.substr(0, id.length - 1).split('(')[1];

      $scope.use_engines.forEach((shop, idx) => {
        // 변경된 순서가 가장 낮은 쇼핑몰 기준으로 상위의 쇼핑몰도 전부 new_seq 넣어줌
        if (idx < lastIdx) {
          shop.new_seq = idx + 1;
        } else if (shop.shop_id === targetId && shop.shop_name === targetShop) {
          // 마지막 인덱스로 변경시 인덱스 100으로 설정
          shop.new_seq = idx + 1 === $scope.use_engines.length ? 100 : shop.seq;
        }
      });

      shopAccountModel.editShopListSeq({ target: id, shop_list: $scope.use_engines.filter(shop => shop.new_seq) }, function(state, data) {
        if (state == 'success') {
          // 순서변경 후에도 $rootScope.use_channel_list 갱신
          $rootScope.$broadcast('channel_update', { data });
        }
      });
    }

    /**
     * 쇼핑몰 계정 추가
     */
    $scope.addMall = function(shop, shop_cd) {
      // 최초접속자 쇼핑몰계정 모달로 넘어온 경우 shop데이터 없어서 찾아서 넣어줌
      if (!shop && shop_cd) {
        shop = $scope.add_list.find(({ shop_cd: _shop_cd }) => _shop_cd === shop_cd);
      }

      $scope.showAddShopBtn = false;
      // 직접입력 쇼핑몰
      if (shop === 'custom' || shop?.pa_shop_cd.startsWith('U')) {
        shop = {
          pa_shop_cd: 'U000',
          shop_cd: shop === 'custom' ? 'U000' : shop.shop_cd,
          shop_name: shop === 'custom' ? '' : shop.shop_name,
          shop_id: '',
          ctry_cd: 'KR',
          domain: ''
        };
      }

      if (!shop) {
        $scope.channelDetailOpen = false;
        $scope.addMallModal = true;
      } else {
        $scope.params = shop;
        $scope.params.shop_type = shop.shop_type;
        $scope.params.mode = 'add';
        $scope.channelDetailOpen = true;
        $scope.toggleAccordion('channelAllList', 'close');
        $scope.toggleAccordion('channelDetail', 'open');

        // 최초접속자 쇼핑몰 등록 모달에서 넘어온 경우
        if ($scope.isFirstShopRegist) {
          $scope.params.pageFrom = 'firstModal';
        }

        $state.go('main.settings_shop_account.channelSub', $scope.params);
        $scope.isModalOpen = false;
      }

      $(window).scrollTop(0);
    };

    /**
     * 아코디언 오픈/클로즈
     */
    $scope.toggleAccordion = (target, forceAction) => {
      if (target === 'channelDetail' && !$scope.params) {
        return;
      }

      const targetEl = $(`.${target}`);

      if (forceAction) {
        if (forceAction === 'open') {
          targetEl.addClass('active');
        } else {
          targetEl.removeClass('active');
        }
      } else if (!targetEl.hasClass('active')) {
        targetEl.addClass('active');
      } else {
        targetEl.removeClass('active');
      }
    };

    /**
     * 쇼핑몰등록 최초화면으로 이동
     */
    $scope.resetForm = () => {
      $scope.toggleAccordion('channelDetail');
      $scope.toggleAccordion('channelAllList', 'open');
      $scope.params = null;

      $(window).scrollTop(0);
    };

    /**
     * 쇼핑몰 계정 수정
     */
    $scope.editMall = function(mall, shopInfo) {
      if ($rootScope.cantChangeTab) {
        commonSVC.showConfirmCustom({
          title: '쇼핑몰 계정 등록 안내',
          text: '<p class="no-padding help-text text-danger-600">쇼핑몰 계정 등록 후 솔루션 이용이 가능합니다.</p>',
          confirmButtonText: '쇼핑몰 계정 등록',
          isHtml: true,
          showCancelButton: false
        });

        return false;
      }

      $scope.showAddShopBtn = true;

      if (!mall && shopInfo) {
        mall = $scope.use_engines.find(({ shop_id, shop_cd }) => shop_cd === shopInfo.shop_cd && shop_id === shopInfo.shop_id);

        if (!mall) {
          return false;
        }
      }

      $scope.channelDetailOpen = true;
      mall.mode = 'edit';

      if (mall.shop_cd.startsWith('U')) { // 직접입력 쇼핑몰
        mall.pa_shop_cd = 'U000';
      }

      $scope.params = mall;
      $scope.toggleAccordion('channelAllList', 'close');
      $scope.toggleAccordion('channelDetail', !shopInfo ? 'open' : 'close');
      $scope.toggleAccordion('shipInfo', 'open');

      $state.go('main.settings_shop_account.channelSub', mall);
      $(window).scrollTop(0);
    };

    /**
     * 쇼핑몰계정 신규추가 쇼핑몰리스트 불러오기
     */
    $scope.shopAccountNewAddList = async () => {
      const data = await shopAccountModel.shopAccountNewAddList();
      const results = data.data.results;
      const simple_entry_shops = ['A032', 'A011', 'P016'];
      // 베타 쇼핑몰 flag
      results.forEach(v => {
        if (v.beta_limit) {
          v.beta_flag = moment(v.beta_limit) > moment();
        } else {
          v.beta_flag = false;
        }
        v.entry_flag = simple_entry_shops.includes(v.pa_shop_cd);
      });

      $scope.add_list = results.filter(shop => (
        $rootScope.accessGlobalYN
          ? !shop.pa_shop_cd.startsWith('Z') && !['X097', 'X098'].includes(shop.pa_shop_cd) && shop.shop_cd !== 'X095'
          : shop.ctry_cd === 'KR' && !['X', 'Z'].includes(shop.pa_shop_cd[0]) 
      ));

      if ($scope.affName === '에픽카') {
        $scope.add_list.push(results.find(shop => shop.shop_cd === 'P051'));
      }

      $timeout(() => {});
    };

    /**
     * 초기화
     */
    async function init() {
      try {
        await $scope.reRoadUseChannel('init');

        // 쇼핑몰계정 신규추가 쇼핑몰리스트 불러오기
        await $scope.shopAccountNewAddList();

        // 최초접속자 쇼핑몰 등록 모달에서 넘어온 경우
        if ($scope.isFirstShopRegist) {
          const targetShopCd = userInfo.user.first_add_shop_cds.split(',')
            .filter(Boolean)
            .find(shop_cd => $scope.use_engines.every(({ pa_shop_cd }) => pa_shop_cd !== shop_cd));

          $scope.addMall(null, targetShopCd);
        }
      } catch (err) {
        commonSVC.showToaster('error', '', '쇼핑몰 계정 리스트 로드 실패');
      }
    }

    /**
     * 사용중인 쇼핑몰 탭 활성화 & 새로고침
     */
    $scope.reRoadUseChannel = async (mode) => {
      if (mode == 'delete' || mode == 'init') {
        $scope.channelDetailOpen = false;
      } else {
        $scope.channelDetailOpen = true;
      }

      try {
        const re = await shopAccountModel.shopAccountUseList({ init_channel: true });

        // 판매자센터 접속 채널 리스트에 공유하기 위해서는 rootScope 에 값을 넣어주어야 함. 2018-04-16 rony
        $rootScope.$broadcast('channel_update', { data: re.data, mode });

        $scope.use_engines = re.data.results.filter(({ shop_cd, pa_shop_cd }) => !(!pa_shop_cd === 'X095' && pa_shop_cd.startsWith('X') && (shop_cd !== pa_shop_cd))) || [];
        $scope.use_engines.forEach((shop, idx) => { shop.no = idx; });

        $scope.calcShopLength();
      } catch (err) {
        commonSVC.showToaster('error', '', '쇼핑몰 계정 리스트 로드 실패');
      }
    };

    /**
     * 채널검색
     * 2018-12-05 Jacob
     */
    $scope.channelSearch = function (e) {
      if ((e && (e.keyCode === 13 || e.which === 13)) || !e) { // 엔터키 검색 가능하도록 수정
        $scope.shop_name = $scope.searchData.shop_name;
      }
    };

    $scope.shopNameFilter = shop => {
      return shop.shop_name.toUpperCase().includes($scope.shop_name.toUpperCase()) || shop.shop_name_kr.includes($scope.shop_name);
    };

    init();

    /**
    * 검색내용 초기화
    */
    $scope.resetDo = function() {
      $scope.searchText = '';
    };

    $scope.tabChange = function (idx) { // 탭 변경
      $scope.tab = idx;
    };

    $scope.resetDo2 = function () {
      $scope.tab = 0;               // 초기화시 첫 탭으로 이동
      $scope.searchData.shop_name = ''; // 검색값 초기화
      $scope.shop_name = '';
    };

    /**
     * 레프트 영역 자동 높이 조절
     */
    function setLeftHeight() {
      $timeout(function () {
        if ($('#listChannelSidebar').offset()) {
          const sideHeight = $(window).scrollTop() + $(window).height() - $('#listChannelSidebar').offset().top;

          const contentsHeight = $('#account-content-wrap').height();

          $('#listChannelSidebar').css({ 'min-height': sideHeight, 'max-height': contentsHeight });
        }
      });
    }

    $scope.$on('$viewContentLoaded', function() { setLeftHeight(); });

    $(window).on('resize', function () { setLeftHeight(); });

    $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
      if (toState.name == 'main.settings_shop_account') {
        window.dispatchEvent(new Event('resize'));
      }
    });

    $scope.$on('$locationChangeSuccessGlobal', async function (_, __, newUrl) {
      // 뒤로가기로 url 변경되는 경우 변경된 쇼핑몰 url에서 추출해서 $scope.params 업데이트
      const match = newUrl.match(/http(s)?:\/\/.*\/#!\/settings\/shop_account\/(add|edit)\/([^/]*)\/([^/]*)\/([^/]*)\/.*$/);

      if (match) {
        const [mode, shop_cd, pa_shop_cd, shop_id] = match.slice(2);
        const channelList = mode === 'add' ? $scope.add_list : $scope.use_engines;

        const shop = channelList.find(ch => {
          if (mode === 'add') {
            if (shop_cd === 'P059') {
              return ch.shop_cd === shop_cd;
            } else {
              return ch.shop_cd === pa_shop_cd;
            }
          } else {
            return ch.shop_cd === shop_cd && ch.shop_id === shop_id;
          }
        });

        if (shop) {
          $scope.params = { ...$scope.params, ...shop };
        }
      }
    });
  });