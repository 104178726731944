'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentPaymentListCtrl',
    function (// common
      $state, $rootScope, $scope, $compile, $timeout, $filter, gettextCatalog, $q, userInfo,
      // info
      settings,
      // SVC
      workSVC, commonSVC, shipmentSVC, onlineProductSVC, columnSVC,
      // Model
      systemModel, shipmentModel, commonModel,
      // List
      warehouseList, systemList, gridInfo
    ) {
      $scope.test = window.location.host.includes('localhost') || window.location.host.includes('staging');

      // 컬럼 설정 정보
      const menu_data = gridInfo.menu_data ? gridInfo.menu_data : false;

      $scope.loadDataTable = gridInfo;

      const channelList = angular.copy($rootScope.use_channel_list);

      // 별칭정보 담기
      const seller_nick_info = [];

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      // 직접 입력 추가
      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      let prodList = [];    // SKU상품 정보
      let addProdList = []; // 추가구매옵션정보
      let orderList = {};

      $scope.countList = { total: 0, delay: 0, collect: 0 }; // 우측 아이콘 카운트
      $scope.selectCount = 'total';

      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.payment);

      if (!$rootScope.user_profile.sol_stock) {
        $scope.excelFieldList = _.filter($scope.excelFieldList, function (f) {
          return f.header !== 'SKU코드';
        });
      }

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: '',
          search_word: '',
          search_type: 'partial',				//검색 기본은 부분일치로
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          site_id: '',
          site_code: '',
          status: ['결제완료'],
          ord_status_stat: ['결제완료'],
          page: 'payment',
          delivery_vendor: '', //배송처
          // to_country_code: '', //수령자 국가
          delay_status: '', //배송지연
          map_yn: '', //매칭여부
          multi_search_word: '', //멀티서치워드
          multi_type: 'shop_sale_no', //멀티서치 타입
          // excel_down_yn: '',
          memo_yn: '',
          multi_shop_id: ''
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          totalCount: 0,       // 검색전체 건수
          selectCount: 0,
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: '' },
            { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('세트코드'), value: 'set_cd' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('옵션명, 추가구매옵션'), value: 'shop_opt_name,shop_add_opt_name' },
            { label: gettextCatalog.getString('주문자명, 수령자명'), value: 'order_name,order_id,to_name' },
            { label: gettextCatalog.getString('주소'), value: 'to_addr' },
            { label: gettextCatalog.getString('주문자, 수령자 연락처'), value: 'tel' },
            { label: gettextCatalog.getString('메모'), value: 'memo' },
            { label: gettextCatalog.getString('사은품'), value: 'gift_name' },
            { label: gettextCatalog.getString('배송메시지'), value: 'ship_msg' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('주문 수집일'), value: 'wdate' },
            { label: gettextCatalog.getString('주문일'), value: 'ord_time' },
            { label: gettextCatalog.getString('결제 완료일'), value: 'pay_time' },
            { label: gettextCatalog.getString('상태 변경일'), value: 'ord_status_mdate' },
            { label: gettextCatalog.getString('발송 예정일'), value: 'ship_plan_date' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('SKU코드'), value: 'sku_cd' },
            { label: gettextCatalog.getString('쇼핑몰 주문번호'), value: 'shop_ord_no' },
            { label: gettextCatalog.getString('쇼핑몰(ID)'), value: 'shop' }
          ]
        },
        searchDetail: [
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd', // 2018-09-09 Jacob 상세검색에서 쇼핑몰 선택시 정상적으로 리로드 되지 않는 문제 수정
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return !option.pa_shop_cd?.startsWith('X');
            },
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              // 2017-06-16 chris 상세검색 선택한 채널의 계정만 필터
              return option.shop_cd == $scope.searchForm.shop_cd && option.shop_id;
            }
          },
          {
            // 배송처 선택
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: ''
          },
          // {
          //   // 국가
          //   title: gettextCatalog.getString('국가 선택'),
          //   search_name: 'to_country_code',
          //   item_list: countryList.data || [],
          //   item_key: 'ctry_name',
          //   item_value: 'ctry_cd',
          //   select_value: ''
          // },
          {
            // 배송지연여부
            title: gettextCatalog.getString('배송지연여부'),
            search_name: 'delay_status',
            item_list: [{ key: 1, value: '배송지연 O' }, { key: 0, value: '배송지연 X' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          },
          // {
          //   // 엑셀다운 O/X
          //   title: gettextCatalog.getString('엑셀다운 O/X'),
          //   search_name: 'excel_down_yn',
          //   item_list: [{ key: 1, value: '엑셀다운 O' }, { key: 0, value: '엑셀다운 X' }],
          //   item_key: 'value',
          //   item_value: 'key',
          //   select_value: ''
          // }
          {
            // 메모여부
            title: gettextCatalog.getString('메모여부'),
            search_name: 'memo_yn',
            item_list: [{ key: 1, value: '메모 있음' }, { key: 0, value: '메모 없음' }],
            item_key: 'value',
            item_value: 'key',
            select_value: ''
          }
        ],
        searchDetailMulti: [
          {
            title: gettextCatalog.getString('쇼핑몰(ID) 선택'),
            search_name: 'multi_shop_id',
            item_list: angular.copy(channelList).sort(function(prev, curr) {
              return `{${curr.pa_shop_cd} ${curr.shop_name}` < `{${prev.pa_shop_cd} ${prev.shop_name}` ? 1 : -1;
            }),
            item_key: 'shop_id',
            item_value: 'shop_id',
            search_word: ''
          },
        ]
      };

      $scope.searchData = angular.copy(search.searchData);
      $scope.searchDetailMulti = angular.copy(search.searchDetailMulti);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchBtn = {
        actions_right: [
          {
            label: `<i class="picon-get"></i><span> ${gettextCatalog.getString('주문 수집')}</span>`,
            small_label: '주문수집',
            test_id: 'btn-order-collect',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-6',
            perm_only: ['order.payment+write'],
            action: function() {
              $scope.scrapOrderConfirmList();
            }
          },
          {
            label: `<i class="icon-sync worksvc-spinner-orderSync order"></i><span> ${gettextCatalog.getString('주문 동기화')}</span>`,
            small_label: '주문동기화',
            test_id: 'btn-order-async',
            sub_label: '* 건 * 시간전 수집',
            btn_type: 'button',
            add_class: 'col-xs-6',
            perm_only: ['order.payment+write'],
            action: function() {
              $scope.orderSync();
            }
          }
        ],
        table_actions_top: [
          {
            label: '주문확인',
            test_id: 'btn-order-check',
            icon_class: 'pui-check',
            action: function () {
              $scope.sendOrder();
            },
            perm_only: ['order.payment+write']
          },
          {
            label: '배송지연전송',
            test_id: 'btn-post-delay',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-schedule',
            action: function () {
              $scope.delayedOrder();
            },
            perm_only: ['order.payment+write']
          },
          {
            label: '중복의심 주문',
            test_id: 'btn-suspicious-order',
            add_class: 'pui-order-btn-lg',
            icon_class: 'pui-warning',
            action: function () {
              $scope.openSuspiciousOrderSetting(false);
            },
            ngIfFunc: () => {
              return !$rootScope.osse_sol ? 'y' : 'n';
            },
            perm_only: ['order.payment+write']
          }
        ],
        table_states_top: [
          {
            label: '결제완료',
            test_id: 'btn-state-total',
            icon_class: 'pui-credit_score',
            count_name: 'total'
          },
          {
            label: '오늘수집',
            test_id: 'btn-state-collect',
            icon_class: 'pui-calendar_today',
            count_name: 'collect'
          }
        ],
        table_actions: [
          // {
          //   label: '상태변경',
          //   btn_type: 'dropdown',
          //   add_class: 'btn-primary',
          //   item_list: []
          // },
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            test_id: 'drop-work',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('주문삭제'),
                test_id: 'btn-order-del',
                action: function () {
                  $scope.deleteOrder();
                },
                perm_only: ['order.payment+admin']
              }
            ]
          }, {
            label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
            test_id: 'drop-excel',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: `${gettextCatalog.getString('통합 엑셀 다운')}<i class="icon-help menu-tooltip fl-r" uib-tooltip="원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다.
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다." tooltip-placement="right">`,
                menu_tooltip: `원하는 엑셀양식을 직접 생성하셔서 엑셀 다운로드 하는 기능입니다. 
주문의 모든 메뉴에서 동일한 양식으로 엑셀 다운로드 가능합니다.`,
                test_id: 'btn-excel-intergrate',
                action: function () {
                  $scope.downloadIntegratedExcel();
                },
                perm_only: ['order.unstoring+write']
              }
            ]
          }
        ]
      };
      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
        getOrderList: function () {
          // 주문 요약정보 매칭 정보
          _.forEach(orderList, function (order) {
            if (prodList[order.uniq]) {
              order.sku_pack = prodList[order.uniq][0].sku_cd;
              order.prod_name_pack = prodList[order.uniq][0].prod_name;
            }
          });

          return orderList;
        }
      };

      $scope.selectRowUniqList = [];

      $scope.isMemoDisabled = (uniq) => $scope.selectRowUniqList.length && !$scope.selectRowUniqList.includes(uniq);

      // 데이터 테이블에서 선택한 rowList 정보 받아옴
      $scope.$parent.$on('OnSelectedAllRow', _.debounce((event, data) => {
        $scope.selectRowUniqList = data.map(({ uniq }) => uniq);
        $scope.$apply();
      }, 200));

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        defaultSortingColumns: ['wdate'],
        notMovingColumns: [],
        notVisibleColumns: ['notice_msg', 'ord_status', 'ord_time', 'pay_time', 'ord_status_mdate', 'gprivate_no', 'order_tel', 'order_htel', 'to_tel', 'to_htel', 'c_sale_cd', 'gift_name', 'ship_method', 'last_exceldown_time', 'shop_ord_no_real'],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/list`,
          requestWillAction: function (data) {
            if ($rootScope.order_search.page === 'payment' && $rootScope.order_search.search) {
              $scope.searchForm.search_word = $rootScope.order_search.input;
              $scope.searchForm.search_key = $rootScope.order_search.key;
              $scope.searchData.search_key_name = $rootScope.order_search.name;
              $rootScope.order_search.search = false;
            }

            if ($rootScope.side_search.page === 'payment' && $rootScope.side_search.search) {
              $scope.searchForm.date_type = $rootScope.side_search.date_type;
              $scope.searchForm.sdate = $rootScope.side_search.sdate;
              $scope.searchForm.edate = $rootScope.side_search.edate;
              $scope.searchForm.status = $rootScope.side_search.status;
              $scope.selectCount = $rootScope.side_search.selectCount;
              $scope.excel_down_yn = $rootScope.side_search.excel_down_yn;
              $rootScope.side_search.search = false;
            }

            data = angular.merge({}, data, $scope.searchForm);

            //엑셀 모달용 데이터
            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function (result) {
            $scope.searchData.totalCount = result.recordsTotal;
            orderList = result.results;

            // SKU상품 정보 처리
            prodList = [];
            addProdList = [];
            result.results_prod.forEach(function(subRow) {
              if (subRow.add_opt_yn == 1) {
                if (!addProdList[subRow.uniq]) {
                  addProdList[subRow.uniq] = [];
                }

                addProdList[subRow.uniq].push(subRow);

              } else {
                if (!prodList[subRow.uniq]) {
                  prodList[subRow.uniq] = [];
                }

                prodList[subRow.uniq].push(subRow);
              }
            });

            init(result.statusCount);

            $scope.suspiciousOrders = result.suspiciousOrders;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 100,
            template: function (row) {
              return `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>
                <button ng-disabled="grid.appScope.isMemoDisabled('${row.uniq}')" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.leftTabAction(2)">메모</button>`;
            }
          },
          {
            key: 'shop_name',
            title: '쇼핑몰(계정)',
            width: 150,
            template: function(row) {
              let img = '직접입력';

              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right"><img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">${shop_info[1]}</span>`;
              }

              return img;
            }
          },
          {
            key: 'shop_ord_no',
            title: '쇼핑몰 주문번호',
            width: 190
          },
          {
            key: 'shop_ord_no_real',
            title: '원주문번호',
            width: 190
          },
          {
            key: 'wdate',
            title: '주문수집일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰 상품코드',
            width: 140,
            template: function(row) {
              if (row.shop_cd === 'A011') {
                row.shop_sale_no = row.misc16;
              }

              let sale_no = row.shop_sale_no || '';

              if (row.shop_cd === 'B378') { // 쿠팡
                sale_no = row.misc5;
                if (row.misc9) {
                  sale_no += `?vendorItemId=${row.misc9}`;
                }
              } else if (row.shop_cd === 'A524' && row.misc17) { // 롯데온
                sale_no += `?sitmNo=${row.misc17}`;
              }

              if (sale_no && !onlineProductSVC.impossibeDetailView.some(shopCd => row.shop_cd.includes(shopCd))) {
                return `<span>
                  <a ng-click="grid.appScope.goOlProdList('${row.shop_sale_no}')">${row.shop_sale_no}</a>
                  <i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${row.shop_cd}','${row.shop_id}','${sale_no}')"></i></span>`;
              } else {
                return row.shop_sale_no;
              }
            }
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 300,
            notCompile: true,
            template: function(row) {
              //이미지+상품명
              const exchangeSpan =
                row.exchange_ord_yn == 1 || row.exchange_yn == 1
                  ? '<span class="label label-default bg-blue">교환주문</span> '
                  : '';
              let giftSpan = '';
              let arrivalGuaranteed = '';

              // 스마트스토어는 선물하기 주문일 시 선물하기 플래그 추가 및 도착보장 플래그 추가
              if (['A077', 'A000'].includes(row.pa_shop_cd)) {
                giftSpan = row.misc20 === '1' ? '<span class="label label-default bg-green">선물하기</span> ' : '';
                arrivalGuaranteed = ['true', 'is_arrival_guaranteed'].includes(row.misc17) ? '<span><img ng-src="/assets/images/naver_flagX2.png" width="75" height="25" class="mr-5" alt="" src="/assets/images/naver_flagX2.png"></span>' : '';
              }
              const filteredShopSaleName = $filter('whiteSpace')(row.shop_sale_name);
              const suspiciousOrderSpan = !$rootScope.osse_sol && row.dupl_doubt_yn ? '<span class="label label-default bg-red">중복의심</span> ' : '';
              if (row.sale_img) {
                const img = row.sale_img || '/assets/images/upload.png';

                return (
                  `<span>${suspiciousOrderSpan}${
                    exchangeSpan
                  }${arrivalGuaranteed}${giftSpan}<img src='${
                    img
                  }' width='25' height='25' class='mr-10' onerror='this.src="/assets/images/noimage.png"'>${
                    filteredShopSaleName
                  }</span>`
                );
              } else {
                return `${suspiciousOrderSpan}${exchangeSpan}${arrivalGuaranteed}${giftSpan}${filteredShopSaleName}`;
              }
            }
          },
          {
            key: 'shop_opt_name',
            title: '옵션',
            width: 220,
            template: function(row) {
              return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
            }
          },
          {
            key: 'sale_cnt',
            title: '주문수량',
            width: 60,
            template: (row) => {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : row.sale_cnt;
            }
          },
          {
            key: 'shop_add_opt_name',
            title: '추가구매옵션',
            width: 150,
            notCompile: true,
            template: function(row) {
              // "추가구매옵션1=2;추가구매옵션2=3"
              let return_value = '';
              const retVals = [];

              if (addProdList[row.uniq] && addProdList[row.uniq].ord_opt_name != '') {

                _(addProdList[row.uniq])
                  .groupBy('ord_opt_seq')
                  .map(arr => {
                    retVals.push(`${arr[0].ord_opt_name}-${arr[0].opt_sale_cnt}개`);
                  })
                  .value();

                return_value = retVals.join(', ');
              }

              return return_value;
            }
          },
          {
            key: 'order_name',
            title: '주문자(ID)',
            width: 150,
            notCompile: true,
            template: function(row) { //속성
              let return_value = row.order_name;

              if (row.order_id) {
                return_value += `(${row.order_id})`;
              }

              return return_value;
            }
          },
          {
            key: 'to_name',
            title: '수령자명',
            width: 100
          },
          {
            key: 'ship_msg',
            title: '배송메세지',
            width: 200
          },
          {
            key: 'notice_msg',
            title: '기타메세지',
            width: 200
          },
          {
            key: 'to_addr1',
            title: '주소',
            width: 300,
            notCompile: true,
            template: function(row) { //속성
              let return_value = row.to_addr1;

              if (return_value && return_value.charAt(0) != '[') {
                return_value = `[${row.to_zipcd}]${return_value}`;
              }
              if (row.to_addr2) {
                return_value += row.to_addr2;
              }

              return return_value;
            }
          },
          {
            key: 'sales',
            title: '금액',
            width: 100,
            notCompile: true,
            template: function (row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.sales, '', 0);
            }
          },
          {
            key: 'ship_cost',
            title: '배송비',
            width: 100,
            notCompile: true,
            template: function (row) {
              return ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf(
                row.ord_status
              ) > -1 && !$rootScope.osse_sol
                ? 0
                : $filter('currency')(row.ship_cost, '', 0);
            }
          },
          {
            key: 'ord_status',
            title: '주문상태',
            width: 100,
            notCompile: true,
            template: function(row) {
              return $filter('statusColor')(row.ord_status);
            }
          },
          {
            key: 'ord_time',
            title: '주문일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'pay_time',
            title: '결제완료일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'ord_status_mdate',
            title: '상태변경일',
            width: 130,
            filter: 'dateValid'
          },
          {
            key: 'gprivate_no',
            title: '개인통관번호',
            width: 60
          },
          {
            key: 'order_tel',
            title: '주문자 전화번호',
            width: 150
          },
          {
            key: 'order_htel',
            title: '주문자 휴대폰번호',
            width: 150
          },
          {
            key: 'to_tel',
            title: '수령자 전화번호',
            width: 150
          },
          {
            key: 'to_htel',
            title: '수령자 휴대폰번호',
            width: 150
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 180
          },
          {
            key: 'gift_name',
            title: '사은품',
            width: 100
          },
          {
            key: 'ship_method',
            title: '배송방법',
            width: 120
          },
          {
            key: 'last_exceldown_time',
            title: '엑셀 다운일시',
            width: 130,
            filter: 'dateValid'
          }
        ]
      };

      // LG 대리점계정인 경우 커스텀컬럼 세팅
      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') && $rootScope.user_profile.pa_sol_no) {
        addColumnSet();
      }

      /**
       * 커스텀컬럼 세팅
       */
      async function addColumnSet() {
        const addColReadonly = $rootScope.user_profile.auth_type === '배송처';

        const params = {
          gridOption: $scope.grid.options,
          readOnly: addColReadonly,
          addcolType: 'all',
          search_key_items: $scope.searchData.search_key_items
        };

        // LG 대리점 사용계정인 경우 추가처리
        if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
          // 읽기만 가능한 컬럼으로 추가
          params.readOnly = true;
          // enum의 첫번째 값 디폴트로 사용
          params.useEnumFirstValDefault = true;
        }

        await columnSVC.columnsSet(params);
      }

      /**
       * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
       */
      $scope.$on('OnColumnChange', function(event, data) {
        _.each(data, function(row) {
          $compile(row)($scope);
        });
      });

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {

        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = 'total';
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };

      /**
       * 상세페이지 보여주기
       */
      $scope.showDetail = function(uniq) {
        //fgeklwhgleghwleghnlwknglkewgnklwengklewlwgkenlkwngk
        const resolve = {
          data: {
            fromPage: search.searchForm.page,
            uniq: uniq,
            warehouseList: warehouseList.data.result || [],
            systemList: systemList.data || []
          }
        };
        const modal = commonSVC.openModal('full', resolve, 'OrderShipmentDetailCtrl', 'views/order/shipment/detail.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            //$scope.searchDo(false);
          }
        });
        // 2017-09-21 ally 모달로 변경
        // $scope.isDetailShow = show;
        // var selectRow = $scope.isDetailShow && rowIndex > -1 ? rowIndex : null; // 상세보기 누른 row index
        // $scope.grid.methods.ChangeColMode(show, [ 0, 2, 4, 10 ], selectRow);
        // if(show){
        //   $state.go("main.order_shipment_payment_list.detail", {fromPage: 'payment', rowIndex: rowIndex , uniq: uniq, ord_date: ord_date});
        //   $scope.grid.methods.autoSelect(true);
        // }else{
        //   $scope.grid.methods.autoSelect(false);
        // }
      };

      // btn event..
      /**
       * 카운팅에 맞는 검색 결과
       */
      $scope.countSearch = function(type) {
        // 카운트 검색시 검색값 초기화 되지않도록 해당 값 주석 2018-11-08 rony
        // $scope.searchForm = angular.copy(search.searchForm);
        // $scope.searchDetail = angular.copy(search.searchDetail);
        switch (type) {
          case 'total':
            $scope.searchForm.date_type = 'wdate';
            $scope.searchForm.selectDate = '';
            $scope.searchForm.sdate = moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD');
            break;
          case 'collect':
            $scope.searchForm.date_type = 'wdate';
            $scope.searchForm.selectDate = '';
            $scope.searchForm.sdate = moment().format('YYYY-MM-DD');
            $scope.searchForm.edate = moment().format('YYYY-MM-DD');
            break;
        }
        $scope.searchDo(true, true);
        $scope.selectCount = type;
      };

      /**
       * 주문확인
       * */
      $scope.sendOrder = function () {
        let seleted = $scope.grid.methods.selectedData('uniq');
        let select_count = seleted.length;
        let params = {};
        if ((seleted.length && seleted.some(s => $scope.suspiciousOrders.map(ord => ord.uniq).includes(s))) || (!seleted.length && orderList.some(s => $scope.suspiciousOrders.map(ord => ord.uniq).includes(s.uniq))) && !$rootScope.osse_sol) {
          const modal = $scope.openSuspiciousOrderSetting(true, seleted.length ? seleted.filter(s => $scope.suspiciousOrders.map(ord => ord.uniq).includes(s)) : orderList.filter(s => $scope.suspiciousOrders.map(ord => ord.uniq).includes(s.uniq)).map(ord => ord.uniq));

          modal.result.then((re) => {
            if (re.results === 'success') {
              $scope.searchDo(true);

              // 선택 된 주문이 없으면 전체주문 주문확인
              if (seleted.length === 0) {
                select_count = $scope.countList.total;
              }

              if (select_count - re.suspiciousOrder.length === 0) {
                commonSVC.showMessage('주문확인 처리 가능한 주문이 없습니다.');

                return;
              }
              const map_info = $rootScope.user_profile.sol_stock ? '<li>설정 > 환경설정에서 [SKU상품 자동 매칭] 항목을 "설정"으로 선택하시면 솔루션에서 등록한 온라인상품의 주문이 발생한 경우, 신규주문 수집 시 출고할 SKU상품을 자동매칭 할 수 있습니다. </li>' : '';

              commonSVC.showConfirmHtml('주문확인', `<b class="text-primary">선택한 ${select_count - re.suspiciousOrder.length}건 주문을 주문확인 처리 합니다</b> ` +

        '<ul class="popup-ul">' +
        '<li>처리한 주문은 쇼핑몰에서도 주문확인(배송준비중)상태로 변경됩니다.</li>' +
        `<li>주문의 정확한 데이터 수집을 위해 주문확인 후 신규 주문 수집을 자동으로 진행합니다. (해당 쇼핑몰에서 새로 발생한 주문도 함께 수집 되며, 주문확인 한 주문은 신규주문 메뉴에서 확인 가능합니다.)</li>${
          map_info
        }</ul>` +
        '<div class=\'col-xs-12 text-left mt-10\' style=\'font-size : 11px;\'>' +
        '<ul class=\'text-box text-box-list_c\'>' +
        '<li>특정 쇼핑몰의 경우, 쇼핑몰의 정책에 따라 해당 기능을 지원하지 않을 수 있으니 ' +
        '<a href=\'https://playapi.api.plto.com/restApi/plto/support-shop-excel?type=gmp\'>쇼핑몰별 지원 기능 정보</a>를<br>꼭 확인해 주세요.</li>' +
        '</ul></div>', function () {
                // 작업 추가

                $q.resolve(true)
                  .then(function() {
                    // 선택 된 주문이 없으면 전체주문 주문확인
                    if (seleted.length === 0) {
                      const t = angular.copy($scope.pageData);

                      delete t.start;
                      t.length = 9999;

                      return shipmentModel.list(t)
                        .then(function(re) {
                          seleted = re.data.results.map(function(o) {
                            return o.uniq;
                          });

                          return true;
                        });

                    } else {
                      return true;
                    }
                  })
                  .then(function() {
                    params = {
                      numbers: seleted.filter(u => !$scope.suspiciousOrders.map(ord => ord.uniq).includes(u))
                    };
                    workSVC.addWorkSelect('CONFIRM_ORDER', params);
                  });
              });
            } else {
              return;
            }
          });
        } else {
          // 선택 된 주문이 없으면 전체주문 주문확인
          if (seleted.length === 0) {
            select_count = $scope.countList.total;
          }
          if (select_count === 0) {
            commonSVC.showMessage('주문확인 처리 가능한 주문이 없습니다.');

            return;
          }
          const map_info = $rootScope.user_profile.sol_stock ? '<li>설정 > 환경설정에서 [SKU상품 자동 매칭] 항목을 "설정"으로 선택하시면 솔루션에서 등록한 온라인상품의 주문이 발생한 경우, 신규주문 수집 시 출고할 SKU상품을 자동매칭 할 수 있습니다. </li>' : '';

          commonSVC.showConfirmHtml('주문확인', `<b class="text-primary">선택한 ${select_count}건 주문을 주문확인 처리 합니다</b> ` +

        '<ul class="popup-ul">' +
        '<li>처리한 주문은 쇼핑몰에서도 주문확인(배송준비중)상태로 변경됩니다.</li>' +
        `<li>주문의 정확한 데이터 수집을 위해 주문확인 후 신규 주문 수집을 자동으로 진행합니다. (해당 쇼핑몰에서 새로 발생한 주문도 함께 수집 되며, 주문확인 한 주문은 신규주문 메뉴에서 확인 가능합니다.)</li>${
          map_info
        }</ul>` +
        '<div class=\'col-xs-12 text-left mt-10\' style=\'font-size : 11px;\'>' +
        '<ul class=\'text-box text-box-list_c\'>' +
        '<li>특정 쇼핑몰의 경우, 쇼핑몰의 정책에 따라 해당 기능을 지원하지 않을 수 있으니 ' +
        '<a href=\'https://playapi.api.plto.com/restApi/plto/support-shop-excel?type=gmp\'>쇼핑몰별 지원 기능 정보</a>를<br>꼭 확인해 주세요.</li>' +
        '</ul></div>', function () {
            // 작업 추가

            $q.resolve(true)
              .then(function() {
                // 선택 된 주문이 없으면 전체주문 주문확인
                if (seleted.length === 0) {
                  const t = angular.copy($scope.pageData);

                  delete t.start;
                  t.length = 9999;

                  return shipmentModel.list(t)
                    .then(function(re) {
                      seleted = re.data.results.map(function(o) {
                        return o.uniq;
                      });

                      return true;
                    });

                } else {
                  return true;
                }
              })
              .then(function() {
                params = {
                  numbers: seleted
                };
                workSVC.addWorkSelect('CONFIRM_ORDER', params);
              });
          });

        }
      };

      /**
       * 배송지연
      * */
      $scope.delayedOrder = function () {
        let selected = $scope.grid.methods.selectedData('all');
        const dataNone = selected.reduce((aur, d) => {
          if (d.ship_delay_yn || !shipmentSVC.canDeliDelayShop.includes(d.pa_shop_cd) || d.shop_ord_no.indexOf('_copy') > -1) {
            aur.push(d);
          }

          return aur;
        }, []);

        if (selected.length === 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        // 결제완료 상태일 때 배송 지연 비활성화
        const cantDelayShop = dataNone.filter(data => shipmentSVC.cantDelayBeforeConfirm.includes(data.pa_shop_cd)).map(shop => shop.shop_name);

        if (dataNone.length > 0 && selected.length !== dataNone.length) {
          commonSVC.showConfirmCustom({
            title: gettextCatalog.getString('배송지연 전송'),
            text: `${shipmentSVC.delayDeliveryMessage.info}${cantDelayShop.length ? `<br />- 발주확인 전 주문의 배송지연처리를 지원하지 않는 쇼핑몰<br/>
            <span style="font-size:0.9em;margin-left:10px">(아래 쇼핑몰의 배송지연처리가 필요한 경우, 발주확인 이후 처리해 주시기 바랍니다.)</span>
            <span style="font-size:0.9em;margin-left:10px">[${cantDelayShop}]</span>` : ''}`,
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: shipmentSVC.delayDeliveryMessage.confirm,
            cancelButtonText: gettextCatalog.getString('취소'),
            animation: false,
          }, function () {

            // 필터링 적용시켜 원하는 자료만 선택시켜두게 함
            // 2017-03-13 MatthewKim
            const reselected_indexes = $scope.grid.methods.doSelectByFilter(function (r) {
              return !r.ship_delay_yn && shipmentSVC.canDeliDelayShop.includes(r.pa_shop_cd) && !shipmentSVC.cantDelayBeforeConfirm.includes(r.pa_shop_cd);
            }, true);

            if (reselected_indexes.length == 0) {
              commonSVC.showToaster('error', '자동 선택 불가', shipmentSVC.delayDeliveryMessage.fail);
            }
          });

          return false;
        } else if (dataNone.length > 0 && selected.length === dataNone.length) {
          commonSVC.showMessageHtml(shipmentSVC.delayDeliveryMessage.fail, `${shipmentSVC.delayDeliveryMessage.info}${cantDelayShop.length ? `<br />- 발주확인 전 주문의 배송지연처리를 지원하지 않는 쇼핑몰<br/>
          <span style="font-size:0.9em;margin-left:10px">(아래 쇼핑몰의 배송지연처리가 필요한 경우, 발주확인 이후 처리해 주시기 바랍니다.)</span>
          <span style="font-size:0.9em;margin-left:10px">[${cantDelayShop}]</span>` : ''}`);

          return false;
        }

        if (selected.some(u => $scope.suspiciousOrders.map(ord => ord.uniq).includes(u.uniq)) && !$rootScope.osse_sol) {
          const modal = $scope.openSuspiciousOrderSetting(true, selected.filter(u => $scope.suspiciousOrders.map(ord => ord.uniq).includes(u.uniq)).map(ord => ord.uniq));

          modal.result.then((re) => {
            if (re.results === 'success') {
              selected = selected.filter(s => !re.suspiciousOrder.includes(s.uniq));

              if (selected.length === 0) {
                commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

                return false;
              }

              const resolve = {
                systemList: function () {
                  return systemModel.load();
                }
              };

              resolve.data = {
                fromPage: search.searchForm.page,
                selectList: selected
              };

              commonSVC.openModal('', resolve, 'delayDeliveryCtrl', 'views/order/shipment/modals/delay_delivery.html');
            } else {
              return false;
            }
          });
        } else {
          const resolve = {
            systemList: function () {
              return systemModel.load();
            }
          };

          resolve.data = {
            fromPage: search.searchForm.page,
            selectList: selected
          };

          commonSVC.openModal('', resolve, 'delayDeliveryCtrl', 'views/order/shipment/modals/delay_delivery.html');
        }

      };

      /**
       * 주문삭제
       * */
      $scope.deleteOrder = function(uniq) {
        // 삭제권한 확인. 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.deleteOrder', userInfo.permission) === false) {
          return false;
        }

        let param = [];

        if (uniq) {
          param = [uniq];
        } else {
          param = $scope.grid.methods.selectedData('uniq');
        }

        if (param.length == 0) {
          commonSVC.showMessage(gettextCatalog.getString('선택된 주문이 없습니다.'));

          return false;
        }

        const params = {
          data: {
            uniqList: param,
            fromPage: 'payment'
          }
        };

        const modal = commonSVC.openModal('', params, 'OrderShipmentOrderDelete', 'views/order/shipment/modals/order_delete.html');

        modal.result.then(function (re) {
          if (re === 'success') {
            $scope.searchDo(true);
          }
        });
      };

      /**
       * 주문수집
       */
      $scope.scrapOrderConfirmList = function() {
        // 주문수집 권한 체크 2019-01-03 rony
        if (commonSVC.checkPermission('order.roles.scrapOrder', userInfo.permission)) {
          commonSVC.openModal('md', { data: { work_type: 'scrap' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html', false, true, false);
        }
      };

      /**
       * 주문 동기화
       */
      $scope.orderSync = function() {
      //  sync2: GMP (sync: EBAY)
        commonSVC.openModal('md', { data: { work_type: 'sync2' } }, 'OrderShipmentGetOrderCtrl', 'views/order/shipment/modals/get_order.html');
      };
      /**
       * 통합 엑셀 다운로드
       */
      $scope.downloadIntegratedExcel = function () {
        if (commonSVC.checkPermission('order.roles.excelOrder', userInfo.permission) === false) {
          return false;
        }

        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터
        const resolve = {};

        resolve.data = {
          excelFieldList: $scope.excelFieldList,
          title: '결제 완료 주문',
          url: '/app/order/excel/downIntegrated',
          searchForm: $scope.pageData,
          page: 'payment',
          visibleTable: visibleTable,
          isGlobal: false
        };

        const selected = $scope.grid.methods.selectedData('all');

        if (!selected.length) {
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;
          resolve.data.type = 'all';

          // 전체 다운로드 시 중복 의심 건에 대한 묶음번호를 인자로 보내 백엔드에서 해당 건 제외 후 다운로드
          if ($scope.suspiciousOrders.length && !$rootScope.osse_sol) {
            const modal = $scope.openSuspiciousOrderSetting(true, $scope.suspiciousOrders.map(o => o.uniq));

            modal.result.then((re) => {
              if (re.results === 'success') {
                const excludeBundleNos = $scope.suspiciousOrders.filter(ord => re.suspiciousOrder.includes(ord.uniq));

                resolve.data.type = 'all';
                resolve.data.count = $scope.searchData.totalCount - excludeBundleNos.length;
                resolve.data.isAll = true;
                resolve.data.excludeBundleNos = excludeBundleNos;

                commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
              } else {
                commonSVC.showToaster('error', '실패', '엑셀 다운로드에 실패하였습니다.');

                return false;
              }

            });
          } else {
            commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
          }
        } else {
          const uniq = selected.map(o => o.uniq);

          if (uniq.some(u => $scope.suspiciousOrders.map(ord => ord.uniq).includes(u)) && !$rootScope.osse_sol) {
            const modal = $scope.openSuspiciousOrderSetting(true, uniq.filter(u => $scope.suspiciousOrders.map(ord => ord.uniq).includes(u)));

            modal.result.then((re) => {
              if (re.results === 'success') {
                resolve.data.type = 'select';
                resolve.data.uniq = uniq.filter(u => !re.suspiciousOrder.includes(u));
                resolve.data.count = uniq.length - re.suspiciousOrder.length;
                resolve.data.isAll = false;

                commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
              } else {
                return false;
              }
            });
          } else {
            resolve.data.type = 'select';
            resolve.data.uniq = uniq;
            resolve.data.count = uniq.length;
            resolve.data.isAll = false;

            commonSVC.openModal('lg', resolve, 'ExcelDownIntegratedCtrl', 'views/order/shipment/modals/excel_down_integrated.html');
          }
        }

      };

      /**
       * 상품코드 클릭시 쇼핑몰 상품 관리로 이동
       */
      $scope.goOlProdList = (shop_sale_no) => {
        $rootScope.order_search.page = 'online';
        $rootScope.order_search = {
          page: 'online',
          search: true,
          search_key: 'shop_sale_no',
          search_word: shop_sale_no
        };

        $state.go('main.online_product_list');
        $rootScope.$broadcast('ordListSearch');
      };

      /**
       * 테이블에서 선택 변경 시
       */
      // $scope.$on('OnSelectChange', function(event, data) {
      //   if($scope.isDetailShow){
      //     $state.go("main.order_shipment_payment_list.detail", {fromPage: 'payment', rowIndex: data.selectIndex ,  uniq: data.selectData.uniq, number: data.selectData.number});
      //   }
      // });

      /**
       * 상세보기 닫기 눌렀을 시에
       */
      // $scope.$on('OnCloseDetail', function(event, data) {
      //   $scope.showDetail(null, false);
      //   // 2017-03-07 MatthewKim $state.go 옵션을 추가해서 파라미터 상속 false, 알림 false 로 이동하여 화면 이동을 시키게 함
      //   $state.go("main.order_shipment_payment_list", null, { inherit: false, notify: false } );
      // });

      /**
       * 주문 전체보기에서 주문코드 눌렀을 때
       */
      $scope.$on('integratedSearch_payment', function() {
        $scope.searchData.search_key_name = '주문번호';
        $scope.searchDo(true, true);
      });

      /**
       * 사이드바 집계 클릭시
       */
      $scope.$on('sidebarSearch_payment', function() {
        $scope.searchDo(true, true);
      });

      /**
       * 주문 요약정보 열기
       */
      // $scope.$on('openOrderInfoDetail', function(event, vdata) {

      //   console.log($scope.orderList);
      //   console.log(prodList);

      //   // 주문 요약정보 매칭 정보
      //   _.forEach($scope.orderList, function (order) {
      //     if (prodList[order.uniq]) {
      //       order.sku_pack = prodList[order.uniq][0].sku_cd;
      //       order.prod_name_pack = prodList[order.uniq][0].prod_name;
      //     }
      //   });

      //   vdata.row.child($compile('<detail-preview number="' + vdata.row[0][0] + '" type="order"></detail-preview>')($scope)).show();
      // });

      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_payment_list') {
          const $wrapper = $('#payment_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      /**
       * 2018-02-21 Daniel
       * 작업 완료시 새로고침
       * [주문동기화, 발주전 주문수집, 발주확인, 배송지연]
       */
      // _.each(["SyncOrderState", "ScrapOrderConfirmList", "ScrapOrderConfirmDo", "DelayedOrderDo", "DelayedOrderPaymentDo"], function(value){
      //   $scope.$on(value, function () {
      //     $scope.searchDo(true);
      //   });
      // });

      // 리스트와 별개로 초기에 세팅되어야하는 값
      function init(data) {
        $scope.selectRowUniqList = [];
        $scope.countList = {
          total: data['결제완료'] ? data['결제완료'][0].cnt : 0,
          collect: data['결제완료_오늘수집'] ? data['결제완료_오늘수집'][0].cnt : 0
        }; // 우측 아이콘 카운트
        // shipmentModel.shipmentCount($scope.searchForm,function(state,data){
        //   if(state === 'success'){
        //     $scope.countList = {total : data.results.total_cnt , delay : data.results.deli_delay_cnt , collect : data.results.today_collect_cnt}; // 우측 아이콘 카운트
        //   }
        // });
      }

      $scope.setMemoBtnActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      // 메모 전부완료시 메뉴 버튼 색상 변경
      $scope.setMemoCompleteActive = function (key, value, off) {
        const index = _.findIndex(orderList, (obj) => {
          return obj[key] == value;
        });

        orderList[index].memo_complete_yn = off ? 0 : 1;
        $scope.grid.methods.reDraw();
      };

      /**
       * 중복의심 주문 모달
       */
      $scope.openSuspiciousOrderSetting = (isWork, uniqs) => {
        if (!$scope.suspiciousOrders.length) {
          return commonSVC.showMessage('중복의심 주문이 없습니다.');
        }

        const modal = commonSVC.openModal('x3g', { data: { suspiciousOrder: uniqs || $scope.suspiciousOrders?.map(ord => ord.uniq) || [], page: search.searchForm.page, useSystemList: systemList, warehouseList, isWork } }, 'SuspiciousOrderCtrl', 'views/order/suspicious/suspicious_order.html');

        modal.result.then(() => {
          $scope.searchDo(true);
        });

        return modal;
      };

      // 2018-04-06 Daniel
      // 재고관리제외버전 추가
      // stockExceptionSVC.scopeExcept(_.last($state.current.url.split('/')), $scope);
    });
