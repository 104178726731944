'use strict';

angular.module('gmpApp')

  /**
   * 템플릿 설정 모달 컨트롤러
   */
  .controller('SetTemplateCtrl', function ($scope, $state, $rootScope, data, $uibModalInstance, onlineProductSVC, templateModel, systemModel, templateGroupList, commonSVC) {
    $scope.templateListModal = false;
    $scope.templateGroupList = templateGroupList.data.results || [];
    $scope.prodCount = data.selectedData.length;
    $scope.site_list = _(data.selectedData)
      .groupBy(function (prod) {
        return `${prod.shop_name},${prod.shop_id}`;
      })
      .map(function (prod_list) {
        return {
          shop_cd: prod_list[0].shop_cd,
          shop_id: prod_list[0].shop_id,
          shop_name: prod_list[0].shop_name,
          site_prod_count: `${prod_list[0].shop_name}(${prod_list[0].shop_id})` + ` ${prod_list.length}건`,
          prod_list: prod_list
        };
      })
      .value();
    $scope.selected_prod = _.map($scope.site_list, 'site_prod_count').join(', ');

    /**
     * 온라인 템플릿 리스트 가져오기
     */
    $scope.onlineTemplate = function (index, openFlag) {
      $scope.site = $scope.site_list[index];

      $scope.site.search = '';

      $scope.templateList = [];
      $scope.templateList2 = [];

      //템플릿 수정후 펼침상태 유지
      if (openFlag == undefined) {
        $scope.templateListModal = !$scope.templateListModal;
      }

      // 2017-08-29 chris 템플릿 조회 오픈시에만 템플릿 리스트 가져오게 수정
      if (!$scope.templateListModal) {
        return;
      }

      $scope.openTemplate = index;

      //선택된 템플릿 매칭해주기위한 데이터
      $scope.shop_params = { shop_cd: $scope.site.shop_cd, shop_id: $scope.site.shop_id };

      const param = {
        shop_cd: $scope.site.shop_cd,
        shop_id: $scope.site.shop_id,
        bookmark: 'all'
      };

      templateModel.templateOnlineProduct(param)
        .then(function (res) {

          const allTemplate = [];
          const bookmarkGroup = _.groupBy(res.data.results, 'bookmark_yn');

          _.each(bookmarkGroup, function (list, yn) {
            if (parseInt(yn)) {
              $scope.templateList = list;
            }

            // 전체 템플릿 리스트 노출위해 값 담음 2018-07-09 rony
            _.forEach(list, function(v) {
              allTemplate.push(v);
            });
          });
          $scope.templateList2 = allTemplate;

          if (!$scope.templateList.length) {
            // 없을경우 전체탭으로이동
            $(`#category${index}-1`).removeClass('active');
            $(`#category${index}-2`).addClass('active');

            $(`#bookmarkTab${index}`).removeClass('active');
            $(`#allTab${index}`).addClass('active');
          }
        })
        .catch(function (err) {
          commonSVC.showToaster('error', '', err);
        });

    };

    /**
     * 템플릿 선택
     */
    $scope.templateNameAdd = function (site, data) {
      if (data) {
        site.template_name = data.template_name;
        site.template_no = data.template_no;
        $scope.templateListModal = false;
      }
    };

    /**
 *  템플릿 리스트 닫기
 */
    $scope.listModal = function() {
      $scope.templateListModal = false;
    };

    /**
 *  템플릿 등록으로 이동
 */
    $scope.template_add = function(index) {
      const param = {
        type1: 'ol'
      };

      $state.go('main.setForm_template_add', param);

      //템플릿 등록시 발생 이벤트
      $scope.$on('templateRefreshGlobal', function() {
        //템플릿리스트 새로고침
        $scope.onlineTemplate(index, 'true');
      });
    },

    // 템플릿 그룹을 드롭다운에서 선택하면 템플릿 그룹에 저장되어있는 템플릿을 자동 설정해줌
    $scope.setTemplateGroup = function (template_group_no) {
      if (template_group_no) {
        templateModel.loadGroup({ template_group_no: template_group_no })
          .then(function (results) {
            const templateGroup = results.data.results;

            _.forEach($scope.site_list, function (site) {
              $scope.templateNameAdd(site, _.find(templateGroup, { shop_cd: site.shop_cd, shop_id: site.shop_id }));
            });
          });
      }
    };

    // 템플릿 그룹 번호가 바뀔 때 템플릿 설정 함수 호출
    $scope.$watch('template_group_no', $scope.setTemplateGroup);

    // 템플릿 그룹 관리 모달
    $scope.manageTemplateGroup = function () {
      let template_group_no;
      const resolve = {
        data: {},
        systemList: function () {
          return systemModel.load();
        }
      };
      const modal = commonSVC.openModal('lg', resolve, 'ManageTemplateGroupCtrl', 'views/online/product/modals/manage_template_group.html');

      modal.result
        .then(function (re) {
          template_group_no = re[0];

          return templateModel.listGroup();
        })
        .then(function (re) {
          $scope.templateGroupList = re.data.results;
          $scope.template_group_no = `${template_group_no}`;
        });
    };

    /**
     * 온라인 템플릿 상세모달
     */
    $scope.onlineTemplateDetail = function (template_no, siteCode, siteId, shopName) {
      onlineProductSVC.onlineTemplateDetail(template_no, siteCode, siteId, shopName, $scope.openTemplate, $scope.onlineTemplate);
    };

    // 템플릿 설정 버튼
    $scope.ok = function () {
      if (!$scope.form1.$valid) {
        commonSVC.showMessage('템플릿을 모두 설정해주시기 바랍니다.');

        return false;
      }

      const ol_shop_no_list = [],
            template_no_list = [],
            log_msg_list = [];

      _.forEach($scope.site_list, function (site) {
        _.forEach(site.prod_list, function (prod) {
          ol_shop_no_list.push(prod.ol_shop_no);
          template_no_list.push(site.template_no);
          log_msg_list.push(`적용템플릿:${prod.template_no || '없음'} -> ${site.template_no}`);
        });
      });

      const param = {
        ol_shop_no_list: ol_shop_no_list,
        template_no_list: template_no_list,
        log_msg_list: log_msg_list
      };

      templateModel.set(param, function (status) {
        if (status === 'success') {
          commonSVC.showToaster('success', '성공', '템플릿 설정 성공');
          $uibModalInstance.close('success');
        } else {
          commonSVC.showMessage('실패', '템플릿 설정에 실패했습니다.');
        }
      });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 템플릿 검색 필터
    $scope.filterFn = (data) => {
      return data.template_name.includes($scope.site.search) || data.template_no.toString().includes($scope.site.search);
    };
  })

  // 템플릿 그룹 수정 / 생성 컨트롤러
  .controller('EditTemplateGroupCtrl', function ($scope, $state, $rootScope, $timeout, data, $uibModalInstance, templateModel, templateGroupDetail, commonSVC, onlineProductSVC) {
    $scope.templateListModal = false;
    $scope.template_group_name = data.template_group_name || '';
    $scope.site_list = templateGroupDetail ? templateGroupDetail.data.results : []; // 템플릿 그룹 수정의 경우 템플릿 그룹에 저장된 정보를 로드함
    $scope.use_engines = $rootScope.useChannelList({ site_action: 'SyncProdState' });           // 사용중인 채널 리스트
    $scope.type = data.create ? '생성' : '수정';
    $scope.channelSelectAll = true;

    // 기본 템플릿 그룹인지 아닌지 판별하는 변수
    $scope.notDefaultTempGroup = !data.template_group_no || (data.template_group_no && data.default_template_group_no !== data.template_group_no);

    // 쇼핑몰 계정 선택에 자동으로 넣어주기 위한 작업
    const pushSiteIdx = _.map($scope.site_list, function (site) {
      return _.findIndex($scope.use_engines, function(o) {
        return o.shop_id === site.shop_id && o.shop_cd === site.shop_cd;
      });
    });

    // 아래 select2로 변하고 나서(view가 모두 load된 후 : $viewContentLoaded event 발생 후)
    // 이미 설정된 템플릿을 넣어줘야하기 때문에(pushSiteIdx) timeout걸어줌
    $timeout(function () {
      $('#shopList').val(pushSiteIdx).trigger('change');
    }, 100);

    // 등록 쇼핑몰 선택시 해줘야하는 작업
    $scope.$watch('$viewContentLoaded', function () {
      $('.select-fixed-multiple').select2({
        minimumResultsForSearch: Infinity,
        width: '100%',
        placeholder: '쇼핑몰(계정)선택',
        closeOnSelect: false
      });

      // 쇼핑몰 선택 시 액션
      $('#shopList').on('change.select2', function () {
        let va = $(this).val() || [];
        const site_list = [];

        if (va[0] == 'all') {
          $scope.channelSelectAll = false;
          va = [];
          for (let i = 0; i < $scope.use_engines.length; i++) {
            va.push(i);
          }
          $(this).val(va).trigger('change');
          $(this).select2('close');

          return;
        }

        if (va[0] == 'del') {
          $scope.channelSelectAll = true;
          $scope.site_list = [];
          $('.reg-channel-select').val([]).trigger('change');
          $(this).select2('close');

          return;
        }

        _.forEach(va, function (v) {
          const idx = parseInt(v);
          const row = $scope.use_engines[idx];
          const siteIndex = _.findIndex($scope.site_list, { shop_cd: row.shop_cd, shop_id: row.shop_id });

          if (siteIndex < 0) {
            site_list.push({ shop_cd: row.shop_cd, shop_id: row.shop_id, shop_name: row.shop_name });
          } else {
            site_list.push($scope.site_list[siteIndex]);
          }
        });

        $scope.site_list = site_list;
        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
      });
    });

    /**
     * 온라인 템플릿 리스트 가져오기
     */
    $scope.onlineTemplate = function (index, openFlag) {
      const site = $scope.site_list[index];

      site.search = '';

      $scope.templateList = [];
      $scope.templateList2 = [];

      //템플릿 수정후 펼침상태 유지
      if (openFlag == undefined) {
        $scope.templateListModal = !$scope.templateListModal;
      }

      // 2017-08-29 chris 템플릿 조회 오픈시에만 템플릿 리스트 가져오게 수정
      if (!$scope.templateListModal) {
        return;
      }

      $scope.openTemplate = index;

      //선택된 템플릿 매칭해주기위한 데이터
      $scope.shop_params = { shop_cd: site.shop_cd, shop_id: site.shop_id };

      const param = {
        shop_cd: site.shop_cd,
        shop_id: site.shop_id,
        bookmark: 'all'
      };

      templateModel.templateOnlineProduct(param)
        .then(function (res) {

          const allTemplate = [];
          const bookmarkGroup = _.groupBy(res.data.results, 'bookmark_yn');

          _.each(bookmarkGroup, function (list, yn) {
            if (parseInt(yn)) {
              $scope.templateList = list;
            }

            // 전체 템플릿 리스트 노출위해 값 담음 2018-07-09 rony
            _.forEach(list, function(v) {
              allTemplate.push(v);
            });
          });
          $scope.templateList2 = allTemplate;

          if (!$scope.templateList.length) {
            // 없을경우 전체탭으로이동
            $(`#category${index}-1`).removeClass('active');
            $(`#category${index}-2`).addClass('active');

            $(`#bookmarkTab${index}`).removeClass('active');
            $(`#allTab${index}`).addClass('active');
          }
        })
        .catch(function (err) {
          commonSVC.showToaster('error', '', err);
        });

    };

    /**
     * 템플릿 선택
     */
    $scope.templateNameAdd = function (site, data) {
      site.template_name = data.template_name;
      site.template_no = data.template_no;
      $scope.templateListModal = false;
    };

    /**
 *  템플릿 리스트 닫기
 */
    $scope.listModal = function() {
      $scope.templateListModal = false;
    };

    /**
 *  템플릿 등록으로 이동
 */
    $scope.template_add = function(index) {
      const param = {
        type1: 'ol'
      };

      $state.go('main.setForm_template_add', param);

      //템플릿 등록시 발생 이벤트
      $scope.$on('templateRefreshGlobal', function() {
        //템플릿리스트 새로고침
        $scope.onlineTemplate(index, 'true');
      });
    };

    /**
     * 온라인 템플릿 상세모달
     */
    $scope.onlineTemplateDetail = function (template_no, siteCode, siteId, shopName) {
      onlineProductSVC.onlineTemplateDetail(template_no, siteCode, siteId, shopName, $scope.openTemplate, $scope.onlineTemplate);
    };

    $scope.ok = function () {
      // 필수값 검사와 템플릿 그룹 이름이 기본 템플릿 그룹이 아닌 경우 쇼핑몰 및 템플릿 설정 필수
      if (!$scope.form1.$valid || $scope.notDefaultTempGroup && !$scope.site_list.length) {
        commonSVC.showMessage('등록 실패', '필수값을 모두 입력, 선택 해주세요.');

        return false;
      }

      const update_data = _.map($scope.site_list, function (site) {
        return [site.template_no, site.shop_cd, site.shop_id];
      });

      const param = {
        template_group_name: $scope.template_group_name,
        ...(data.template_group_no && { template_group_no: data.template_group_no }),
        update_data: update_data
      };

      templateModel.editGroup(param, function (status) {
        if (status === 'success') {
          commonSVC.showToaster('success', '성공', `템플릿 그룹 ${$scope.type} 성공`);
          $uibModalInstance.close('success');
        } else {
          commonSVC.showMessage('실패', `템플릿 그룹 ${$scope.type}에 실패했습니다.`);
        }
      });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  // 템플릿 그룹 관리 모달 컨트롤러
  .controller('ManageTemplateGroupCtrl', function ($scope, $rootScope, $uibModalInstance, $compile, $timeout, $filter, data, settings, commonSVC, systemList, templateModel) {
    $scope.default_template_group_no = systemList.data.default_template_group_no;
    $scope.template_list = data.template_list; // 템플릿 관리에서 모달 연 경우 적용하기 버튼 숨김
    $scope.searchData = {
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수 <- 작업예정
    };
    $scope.searchForm = {
      search_key: 'template_group_name',
      search_word: '',
      orderby: ''
    };
    /**
     *  템플릿 그룹 데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: [
        'widget', 'template_group_no', 'template_group_name', 'name', 'wdate', 'mdate'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['mdate'],
      notSortingColumns: ['widget'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        multiSelect: false,
        checkbox: !data.template_list
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/template-group/list`,
        requestWillAction: function (data) {
          ($scope.searchForm.orderby || $scope.searchForm.orderby === '') && delete $scope.searchForm.orderby;

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          width: 120,
          title: '도구',
          template: function (row) {
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.showDetail(${row.template_group_no}, '${row.template_group_name}')">상세</button>
                <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.copyTemplateGroup(${row.template_group_no})">
                복사
              </button>
              <button
                class="btn btn-default btn-xxs"
                ng-disabled="${row.template_group_no} === ${$scope.default_template_group_no}"
                ng-click="grid.appScope.deleteTemplateGroup(${row.template_group_no})">
                삭제
              </button>`;
          }
        },
        {
          key: 'template_group_no',
          title: '템플릿 그룹번호',
          width: 80
        },
        {
          key: 'template_group_name',
          title: '템플릿 그룹명',
          width: 200
        },
        {
          key: 'name',
          title: '작성자',
          width: 80,
          template: function (row) {
            return $scope.default_template_group_no === row.template_group_no ? 'SYSTEM' : row.name;
          }
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 100
        },
        {
          key: 'mdate',
          title: '수정일',
          width: 100
        }
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay) {
      $scope.grid.methods.reloadData(function() { }, refresh, noDelay);
    };

    /**
     * 검색초기화
     */
    $scope.resetDo = function() {
      $scope.searchForm = {
        search_key: 'template_group_name',
        search_word: '',
        orderby: 'wdate desc'
      };
      $scope.searchDo(true, true);
    };

    /**
     * 테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 템플릿 그룹 상세 모달
     */
    $scope.showDetail = function (template_group_no, template_group_name) {
      const resolve = {
        data: {
          create: false,
          template_group_name: template_group_name,
          template_group_no: template_group_no,
          default_template_group_no: $scope.default_template_group_no
        },
        templateGroupDetail: function () {
          return templateModel.loadGroup({ template_group_no: template_group_no });
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'EditTemplateGroupCtrl', 'views/online/product/modals/edit_template_group.html');

      modal.result.then(function() {
        $scope.searchDo();
      });
    };

    // 템플릿 그룹 생성
    $scope.createTemplateGroup = function () {
      const resolve = {
        data: {
          create: true,
          template_group_name: ''
        },
        templateGroupDetail: false
      };

      const modal = commonSVC.openModal('lg', resolve, 'EditTemplateGroupCtrl', 'views/online/product/modals/edit_template_group.html');

      modal.result
        .then(function (re) {
          if (re == 'success') {
            $scope.searchDo();
          }
        });
    };

    // 템플릿 그룹 삭제
    $scope.deleteTemplateGroup = function (template_group_no) {
      commonSVC.showConfirm('템플릿 그룹 삭제', '선택한 템플릿 그룹을 삭제하시겠습니까?', function (confirm) {
        if (confirm) {
          templateModel.deleteGroup({ template_group_no: template_group_no })
            .then(function (re) {
              if (re.data.result === 'success') {
                commonSVC.showToaster('success', '성공', '템플릿 그룹이 삭제되었습니다.');
                $scope.searchDo();
              }
            });
        }
      });
    };

    // 템플릿 그룹 복사
    $scope.copyTemplateGroup = function (template_group_no) {
      templateModel.copyGroup({ template_group_no: template_group_no })
        .then(function (re) {
          if (re.data.result === 'success') {
            commonSVC.showToaster('success', '성공', '템플릿 그룹이 복사되었습니다.');
            $scope.searchDo();
          }
        });
    };

    // 선택된 템플릿 그룹에 설정된 템플릿으로 온라인 상품에 적용
    $scope.apply = function() {
      $uibModalInstance.close($scope.grid.methods.selectedData('template_group_no'));
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 상품전송 모달 컨트롤러 (이제는 상품/수정 전송 모달 컨트롤러임)
   * 상품 + 수정 전송 모달 컨트롤러 2018-05-24 alvin
   */
  .controller('OnlineProductListSendCtrl', function ($scope, data, $uibModalInstance, siteInfo, templateModel, commonSVC, commonModel, workSVC, errorSVC, $q, $state) {

    /*************
     veiw 1) 여러 쇼핑몰 일괄 전송
     veiw 2) 여러 쇼핑몰 중 템플릿 사용이 'Y' 면서 적용 템플릿 없는 경우
     view 3) 템플릿 사용이 'N' 인 한개의 채널
     view 4) 템플릿 사용이 'Y' 인 한개의 채널
     view 5) 템플릿 사용이 'Y' 인 한개의 채널 중 적용 템플릿이 적용되지 않은 상품이 포함된 경우
     *************/

    $scope.impossible_count = data.impossible_count;
    $scope.view = 0;                         // view Type
    $scope.possible_count = data.result.length;   // 선택한 상품수
    $scope.total_count = $scope.impossible_count + $scope.possible_count;
    $scope.none_temp_total = 0;               // 템플릿 미적용 상품 수
    $scope.siteName = '';                     // 채널 이름
    $scope.templateList = [];                 // 선택가능한 템플릿 리스트
    $scope.select_temp = {};                  // 선택한 템플릿
    const channelUseList = data.channelUseList;

    if (data.siteInfo.code === '') {
      // 여러 쇼핑몰 선택시
      $scope.view = 1;
      angular.forEach(data.result, function (val) {
        if (siteInfo[val.shop_cd].template_yn === 1 && (val.templete_add === '' && val.templete_deliv === '')) {
          $scope.view = 2;
        }
      });
    } else {
      $scope.siteName = siteInfo[data.siteInfo.code].name;
      // 한개의 채널이면서 템플릿 사용유무
      if (siteInfo[data.siteInfo.code].template_yn === 1) {
        $scope.view = 4;

        // 적용템플릿 없는 상품이 있을 경우
        angular.forEach(data.result, function (val) {
          if (val.templete_add === '' && val.templete_deliv === '') {
            $scope.none_temp_total++;
            $scope.view = 5;
          }
        });

        if ($scope.view == 5) {
          // 해당 채널에 등록된 템플릿 불러오기
          const param = { site_code: data.siteInfo.code, site_name: siteInfo[data.siteInfo.code].name, site_id: data.siteInfo.id };

          templateModel.list(param, function (state, data) {
            if (state == 'success') {
              $scope.templateList = data.results;
            } else {
              commonSVC.showMessage('실패', '템플릿 정보를 불러오지 못하였습니다.');
            }
          });
        }

      } else {
        $scope.view = 3;
      }
    }

    $scope.ok = _.debounce(function() {

      if ($scope.view == '1' || $scope.view == '3' || $scope.view == '4') {

        // 템플릿 선택 여부 확인
        // if ($scope.view == '4') {

        // }

        const checkCnt = data.result.filter(d => siteInfo[d.shop_cd].sol_type !== 'DEV' && !(siteInfo[d.shop_cd].beta_edate && (moment() < moment(siteInfo[d.shop_cd].beta_edate)))).length;

        workSVC.checkProductCharge(checkCnt)
          .then(function() {
            const re = {};

            const olList = angular.copy(data.result);

            if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
              Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
                if (prodList[0].ebay_shop_mas_no) {
                  const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));
                  const jobTypeStatus = prodList.some(prod => prod.sale_status !== '판매대기');

                  prodList.forEach(ol => {
                    ol.pa_shop_cd = 'ESMM';
                    ol.shop_cd = 'ESMM';
                    ol.shop_id = matchShops[0].etc7;
                    ol.sale_status = jobTypeStatus ? '수정대기' : '판매대기'; // 수정대기 이후 상태가 같이 묶여있는 경우 판매대기인 상품도 수정작업으로 등록되어야 함
                  });
                }
              });
            }

            angular.forEach(olList, function(v) {
              if (!re[`${v.shop_cd}||${v.shop_id}`]) {
                re[`${v.shop_cd}||${v.shop_id}`] = {};
                re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no = [];
                re[`${v.shop_cd}||${v.shop_id}`].sale_no = [];
                re[`${v.shop_cd}||${v.shop_id}`].sale_status = [];
                re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no = [];

              }

              // work_log 에 sale_no 를 기준으로 insert 되는데 ol_shop_no 가 넘어가고 있어 작업로그가 2개씩 생기는 문제가 발생되고있었음.  2018-02-07 rony
              re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no.push(v.ol_shop_no);
              re[`${v.shop_cd}||${v.shop_id}`].sale_no.push(v.sale_no);
              re[`${v.shop_cd}||${v.shop_id}`].sale_status.push(v.sale_status);
              re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no.push(v.ebay_shop_mas_no);
            });

            const reCnt = {
              success: 0,
              error: 0,
              errSite: [],
              errReason: []
            };

            if (re != {}) {
              const promiseArr = [];
              let setActions = '';
              const actionType = ['RegistProd', 'EditProdAll'];
              let prodList = [];
              let saleNoList = [];
              let esmMasNoList = [];

              angular.forEach(re, function(v, k) {
                const site = k.split('||');

                // 상품전송, 수정전송 따로 액션처리
                angular.forEach(actionType, function(v2) {

                  setActions = v2 == 'RegistProd' ? '판매대기' : '수정대기';
                  prodList = [];
                  saleNoList = [];
                  esmMasNoList = [];

                  for (let i = 0; i < v.sale_status.length; i++) {
                    if (v.sale_status[i] == setActions) {
                      prodList.push(v.ol_shop_no[i]);
                      saleNoList.push(v.sale_no[i]);
                      esmMasNoList.push(v.ebay_shop_mas_no[i] || '');
                    }
                  }

                  // 액션에 따라 상품이 있을 때
                  if (prodList.length > 0) {
                    // 작업 추가
                    const params = {
                      site_code: site[0],
                      site_id: site[1],
                      prod_list: prodList,
                      saleNo_list: saleNoList,
                      esmMasNo_list: esmMasNoList,
                      isSingleProd: data.isSingleProd || 0,
                      isSmileProd: data.isSmileProd || false
                    };
                    // 액션을 상품 전송일 때 한번 수정전송일 때 한번
                    const anHttpPromise = workSVC.addWork(v2, params)
                      .then(function(result) {
                        if (result.status == 200) {
                          reCnt.success++;
                        } else {
                          reCnt.error++;
                          reCnt.errSite.push(`${site[0]}(${site[1]})`);
                          reCnt.errReason.push(result.data.error || '');
                        }

                        return result;
                      })
                      .catch(function(err) {
                        reCnt.error++;
                        reCnt.errSite.push(`${site[0]}(${site[1]})`);
                        reCnt.errReason.push('');

                        return err;
                      });

                    promiseArr.push(anHttpPromise);
                  }
                });
              });

              $q.all(promiseArr)
                .then(async re => {
                  if (!reCnt.success) {
                    throw re;
                  } else {
                    const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');

                    commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
                    $uibModalInstance.close();
                  }
                })
                .catch(function(err) {
                  const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
                  commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
                  $uibModalInstance.close();

                });
            }
          })
          .catch(function(err) {
            if (err.data.error.includes('check_charge')) {
              commonSVC.showConfirm('작업 카운트가 부족하여 작업 등록이 실패되었습니다.', '결제 > 부가서비스 에서 상품 또는 주문의 카운트를 구매 하시거나 버전을 업그레이드 하여 이용해주시기 바랍니다.', function (confirm) {
                if (confirm) {
                  $state.go('main.pay_list');
                  $state.reload();
                }
              });
            } else {
              commonSVC.showMessage('상품 잔여 전송 수량 조회에 실패하였습니다.');
            }

            $uibModalInstance.close();
          });
      }

    }, 1000);

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
    // if(data.siteInfo.code != ""){
    //   // 템플릿 사용 쇼핑몰인지 확인
    //   if(siteInfo[data.siteInfo.code].template_yn == "1"){
    //     // 템플릿 설정 확인
    //     for(var i in data.result){
    //       var re = data.result[i];
    //       if(re.templete_add == "" || re.templete_deliv == ""){
    //         $scope.none_temp_total++;
    //         $scope.view = "4";  // 템플릿 선택 출력
    //         break;
    //       }
    //     }
    //   }
    //
    // }
    // // 전체 쇼핑몰을 선택한 경우
    // else{
    //
    //   // 템플릿 설정 확인
    //   for(var i in data.result){
    //     var re = data.result[i];
    //     if(siteInfo[re.site_code].template_yn == "1" && (re.templete_add == "" || re.templete_deliv == "")){
    //       $scope.view = "2";  // 템플릿 선택 에러 출력
    //       break;
    //     }
    //   }
    //
    // }
    // 판매대기 상태 확인
    // for(var i in data.result){
    //   var re = data.result[i];
    //   if(re.status != "판매대기"){
    //     $scope.view = "3";  // 판매대기 상태 에러 출력
    //     break;
    //   }
    // }

  })

  /**
   * 상품 수정 모달 컨트롤러 (이제는 부분수정만 이 컨트롤러 사용)
   * 부분 수정 모달 컨트롤러 2018-05-24 alvin
   */
  .controller('OnlineProductListAllEditCtrl', function ($rootScope, $scope, data, $uibModalInstance, siteInfo, workSVC, commonSVC, errorSVC, $q, $timeout, $state) {

    $scope.view = '1'; // 상품수정 컨펌 출력
    $scope.prod_total = data.result.length;
    $scope.none_temp_total = 0;

    const act = 'EditProd';
    const channelUseList = data.channelUseList;

    $scope.edit = {
      isEditName: false,
      isEditPrice: false,
      isEditOpt: false,
      isEditAddopt: false,
      isEditContent: false,
      isEditImage: false
    };

    // 대리점 계정일 경우 '내일 배송 가능 여부' 추가
    if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
      $scope.edit.isEditNextDelivery = false;
    }

    $scope.ok = _.debounce(function() {
      $timeout(function () { $scope.waitBtnDisableFlag = true; }, 100);

      // 2018-09-07 Jacob 부분수정 항목 체크없이 전송하기 눌렀을때 전송됐던 오류 수정
      if (Object.keys($scope.edit).every(key => $scope.edit[key] == false)) {
        $timeout(function () { $scope.waitBtnDisableFlag = false; }, 500);
        commonSVC.showMessage('부분수정할 항목을 선택해 주세요.');

        return false;
      }

      if ($scope.view == '1' || $scope.view == '4') {

        // 템플릿 선택 여부 확인
        // if ($scope.view == '4') {

        // }

        /**
         * 퀸잇 && 상품 부분 수정 시 이미지만 선택한 경우에는 카운트 미차감
         */
        let exceptCnt = 0;

        if (data.result.some(prod => 'P055' === (prod?.pa_shop_cd || prod?.shop_cd)) && $scope.edit?.isEditImage && Object.values($scope.edit).filter(Boolean).length === 1) {
          exceptCnt = data.result.filter(prod => 'P055' === (prod?.pa_shop_cd || prod?.shop_cd)).length;
        }
        const checkCnt = data.result.filter(d => siteInfo[d.shop_cd].sol_type !== 'DEV' && !(siteInfo[d.shop_cd].beta_edate && (moment() < moment(siteInfo[d.shop_cd].beta_edate)))).length - exceptCnt;

        workSVC.checkProductCharge(checkCnt)
          .then(function() {

            const re = {};

            const olList = angular.copy(data.result);

            if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
              Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
                if (prodList[0].ebay_shop_mas_no) {
                  const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

                  prodList.forEach(ol => {
                    ol.pa_shop_cd = 'ESMM';
                    ol.shop_cd = 'ESMM';
                    ol.shop_id = matchShops[0].etc7;
                  });
                }
              });
            }

            angular.forEach(olList, function(v) {
              if (!re[`${v.shop_cd}||${v.shop_id}`]) {
                re[`${v.shop_cd}||${v.shop_id}`] = {};
                re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no = [];
                re[`${v.shop_cd}||${v.shop_id}`].sale_no = [];
                re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no = [];
              }

              // work_log 에 sale_no 를 기준으로 insert 되는데 ol_shop_no 가 넘어가고 있어 작업로그가 2개씩 생기는 문제가 발생되고있었음.  2018-02-07 rony
              re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no.push(v.ol_shop_no);
              re[`${v.shop_cd}||${v.shop_id}`].sale_no.push(v.sale_no);
              re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no.push(v.ebay_shop_mas_no);
            });

            const reCnt = {
              success: 0,
              error: 0,
              errSite: [],
              errReason: []
            };

            if (re != {}) {
              const promiseArr = [];

              angular.forEach(re, function(v, k) {
                const site = k.split('||');
                // 작업 추가
                const params = {
                  site_code: site[0],
                  site_id: site[1],
                  prod_list: v.ol_shop_no,
                  edit_col: $scope.edit,
                  saleNo_list: v.sale_no,
                  esmMasNo_list: v.ebay_shop_mas_no,
                  isSingleProd: data.isSingleProd || 0,
                  isSmileProd: data.isSmileProd || false
                };

                // 부분 수정인 경우
                // if(data.mode == "prodEdit"){
                //   params.edit_col = $scope.edit;
                // }

                const anHttpPromise = workSVC.addWork(act, params)
                  .then(function(result) {
                    if (result.status == 200) {
                      reCnt.success++;
                    } else {
                      reCnt.error++;
                      reCnt.errSite.push(`${site[0]}(${site[1]})`);
                      reCnt.errReason.push(result.data.error || '');
                    }

                    return result;
                  })
                  .catch(function(err) {
                    reCnt.error++;
                    reCnt.errSite.push(`${v.shop_name}(${v.shop_id})`);
                    reCnt.errReason.push('');

                    return err;
                  });

                promiseArr.push(anHttpPromise);
              });

              $q.all(promiseArr)
                .then(async re => {
                  if (!reCnt.success) {
                    throw re;
                  } else {
                    const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
                    commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
                    $uibModalInstance.close();
                  }
                })
                .catch(function(err) {
                  const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
                  commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
                  $timeout(function () { $scope.waitBtnDisableFlag = false; });
                });
            }
          })
          .catch(function(err) {
            if (err.data.error == 'check_charge') {
              commonSVC.showConfirm('작업 카운트가 부족하여 작업 등록이 실패되었습니다.', '결제 > 부가서비스 에서 상품 또는 주문의 카운트를 구매 하시거나 버전을 업그레이드 하여 이용해주시기 바랍니다.', function (confirm) {
                if (confirm) {
                  $state.go('main.pay_list');
                  $state.reload();
                }
              });
            } else {
              commonSVC.showMessage('상품 잔여 전송 수량 조회에 실패하였습니다.');
            }

            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      }

    }, 1000);

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

  })

  /**
   * 마스터 상품 카테고리 수정 모달 컨트롤러
   */
  .controller('SetCategoryCtrl', function ($scope, data, $uibModalInstance, $q, $timeout, $compile, categoryList, selectedOnlineProd, categoryModel, onlineProductModel, commonSVC, categorySVC) {
    selectedOnlineProd = selectedOnlineProd.data.results;

    //카테고리 검색
    $scope.checkAdditionalInfo = categorySVC.checkAdditionalInfo;
    $scope.categorySearchKey = '';
    $scope.categoryName = '';
    $scope.categoryList = categoryList.data || [];
    $scope.master_prod_count = data.selectedData.length;
    $scope.siteList = [];
    $scope.matchingInfo = [];
    $scope.updateCateData = {};
    $scope.applyList = {};
    $scope.originData = {};
    $scope.scrapShopList = {};
    $scope.search = {};
    $scope.cateLoadYn = false;
    $scope.saveFlag = false;
    $scope.placeholder = '상단의 검색창에서 카테고리명을 검색해 보세요.';

    let openIdx = null;
    let originSimCateList = {};
    let searchWord = [];

    //l

    $scope.site_list = _.groupBy(selectedOnlineProd, function (prod) {
      return `${prod.shop_name}(${prod.shop_id})`;
    });

    $scope.selected_shop = _.map($scope.site_list, function (value) {
      return {
        pa_shop_cd: value[0].pa_shop_cd,
        shop_cd: value[0].shop_cd,
        shop_id: value[0].shop_id,
        shop_name: value[0].shop_name,
        prod_list: value,
        match_cate_name: '',
        scrap_cate_yn: value[0].scrap_cate_yn
      };
    });
    $scope.placeholder = '상단의 검색창에서 카테고리명을 검색해 보세요.';

    const setSaveFlag = () => {
      $scope.saveFlag = $scope.selected_shop.every(s => {
        const data = $scope.updateCateData[s.shop_cd][s.shop_id];

        return data && ((data.isOrigin && !data.isPre) || (data.isApply || (data.end_yn && !$scope.checkAdditionalInfo[s.pa_shop_cd])));
      });
    };

    /**
       * 새 템플릿 생성
       */
    $scope.createCategory = function () {
      $scope.sol_cate_no = 'new';

      $scope.selected_shop.forEach(shop => {
        if (!$scope.updateCateData[shop.shop_cd]) {
          $scope.updateCateData[shop.shop_cd] = {};
        }
        if (!$scope.applyList[shop.shop_cd]) {
          $scope.applyList[shop.shop_cd] = {};
        }
        if (!$scope.originData[shop.shop_cd]) {
          $scope.originData[shop.shop_cd] = {};
        }

        $scope.updateCateData[shop.shop_cd][shop.shop_id] = '';
        $scope.applyList[shop.shop_cd][shop.shop_id] = [];
        $scope.originData[shop.shop_cd][shop.shop_id] = '';

        shop.match_cate_name = '';
        shop.match_cate_code = '';
        shop.esm_match_cate_name = '';
        shop.esm_match_cate_codes = '';
        shop.key_selPrdTypCd = '';

        $scope.updateCateData[shop.shop_cd][shop.shop_id] = '';
        $scope.originData[shop.shop_cd][shop.shop_id] = '';
      });
    };

    /**
       * 카테고리 템플릿 선택
       */
    $scope.setCategory = async (cate) => {
      $scope.cateLoadYn = true;

      $scope.sol_cate_no = cate.id;
      const result = (await categoryModel.cateShopIdSelectList({ sol_cate_no: $scope.sol_cate_no })).data;

      // 마스터 상품에 매칭된 슬레이브 쇼핑몰만 표시
      $scope.shopList = result.filter(s => $scope.selected_shop.some(slave => s.shop_cd === slave.shop_cd && s.shop_id === slave.shop_id));

      $scope.selected_shop.forEach(shop => {
        const matchCate = $scope.shopList.find(value => value.shop_id === shop.shop_id && value.shop_cd === shop.shop_cd);

        $scope.updateCateData[shop.shop_cd] = $scope.updateCateData[shop.shop_cd] || {};
        $scope.applyList[shop.shop_cd] = $scope.applyList[shop.shop_cd] || {};
        $scope.originData[shop.shop_cd] = $scope.originData[shop.shop_cd] || {};

        if ($scope.updateCateData[shop.shop_cd][shop.shop_id]) {
          //
        } else {
          $scope.updateCateData[shop.shop_cd][shop.shop_id] = '';
          $scope.applyList[shop.shop_cd][shop.shop_id] = [];
          $scope.originData[shop.shop_cd][shop.shop_id] = '';
        }

        if (!matchCate) {
          // 매칭되는 카테고리가 없으면 selected_shop 데이터 초기화 - 2020-01-07 Alvin
          shop.match_cate_name = '';
          shop.match_cate_code = '';
          shop.esm_match_cate_name = '';
          shop.esm_match_cate_codes = '';
          shop.key_selPrdTypCd = '';

          $scope.updateCateData[shop.shop_cd][shop.shop_id] = '';
          $scope.originData[shop.shop_cd][shop.shop_id] = '';

          return;
        }

        const shop_cate_no_add_info = matchCate.sol_cate_shop_add_info;

        if (shop.shop_cd === 'A001' || shop.shop_cd === 'A006') {
          shop.esm_match_cate_name = shop_cate_no_add_info.catename_esm;
          shop.esm_match_cate_codes = shop_cate_no_add_info.catecode_esm;
        } else if (shop.shop_cd === 'A112') {
          shop.key_selPrdTypCd = shop_cate_no_add_info.key_selPrdTypCd;
        }

        shop.shop_cate_no_add_info = shop_cate_no_add_info;
        shop.match_cate_name = shop_cate_no_add_info.category_names;
        shop.match_cate_code = matchCate.shop_cate_cd;
        shop.sol_cate_no = matchCate.sol_cate_no;
        shop.shop_cate_no = matchCate.shop_cate_no;
        shop.pin_yn = matchCate.pin_yn;

        if (matchCate.sol_cate_shop_add_info) {
          const val = {
            category_names: matchCate.sol_cate_shop_add_info.category_names,
            category_code: matchCate.sol_cate_shop_add_info.category_code,
            detailData: matchCate.sol_cate_shop_add_info,
            isOrigin: true,
            pa_shop_cd: matchCate.pa_shop_cd
          };

          $scope.updateCateData[shop.shop_cd][shop.shop_id] = val;
          $scope.originData[shop.shop_cd][shop.shop_id] = val;
        }
      });

      $scope.resetCateSearch();
      $scope.search_yn = false;

      $scope.cateLoadYn = false;
      $scope.matchingInfo = $scope.shopList;

      setSaveFlag();

      $scope.categoryName = cate.name;
    };

    /**
       * 카테고리 검색
       */
    $scope.findCategory = async () => {
      if (!$scope.search.word || !$scope.search.word.replace(/[^a-zA-Z가-힣0-9]/g, '')) {
        commonSVC.showMessage('실패', '카테고리 검색어를 입력해주세요.');

        return false;
      }

      try {
        const res = await categoryModel.findCategory({ searchWord: $scope.search.word });

        await $timeout(() => {
          $scope.search_yn = true;
          $scope.placeholder = '카테고리 검색이 지원되지 않는 쇼핑몰입니다. 우측의 화살표를 클릭하여 카테고리를 설정해 보세요.';
          $scope.simCateList = res.data.result;

          originSimCateList = Object.assign(res.data.result);

          // 중복 키워드 확인
          if (!searchWord.includes($scope.search.word)) {
            searchWord.push($scope.search.word);
          }

          for (const { pa_shop_cd, shop_cd, shop_id } of $scope.selected_shop) {
            if (shop_cd !== 'Z000' &&
              (!$scope.updateCateData[shop_cd][shop_id] || !$scope.updateCateData[shop_cd][shop_id].isOrigin) &&
              $scope.simCateList[pa_shop_cd]) {
              $scope.updateCateData[shop_cd][shop_id] = $scope.originData[shop_cd][shop_id] || $scope.simCateList[pa_shop_cd][0];
            }
          }

          // 열려있을 때 변경 이벤트 처리
          if (typeof openIdx === 'number' && $scope.isOpen) {
            const { shop_cd, shop_id } = $scope.matchingInfo[openIdx];

            if (!$scope.updateCateData[shop_cd][shop_id]) {
              return false;
            }

            if (!$scope.updateCateData[shop_cd][shop_id].isOrigin && !$scope.updateCateData[shop_cd][shop_id].isApply) {
              $scope.changeUpdate(openIdx, 'category_selection', { shop_cd, shop_id });
            }
          }

          setSaveCnt();
        });
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.message || err.data.error);
      }
    };

    /**
       *
       */
    $scope.changeUpdate = async (index, type, shop) => {
      if ($(`#test_${index}`).css('display') !== 'none') {
        if ($scope.updateCateData[shop.shop_cd][shop.shop_id].isApply || $scope.updateCateData[shop.shop_cd][shop.shop_id].isOrigin) {
          $scope.$broadcast(type, { ...$scope.updateCateData[shop.shop_cd][shop.shop_id], site_code: shop.shop_cd, site_id: shop.shop_id });
        } else {
          const detailData = await categoryModel.getDetailData({ shop_cd: shop.pa_shop_cd, cateData: $scope.updateCateData[shop.shop_cd][shop.shop_id] });

          $scope.$broadcast(type, { ...detailData.data.result, site_code: shop.shop_cd, site_id: shop.shop_id });
        }
      }

      setSaveCnt();
    };

    /**
       * 카테고리 검색 초기화
       */
    $scope.resetCateSearch = async (init) => {
      await $timeout(() => {
        for (const shop in $scope.updateCateData) {
          for (const shop_id in $scope.updateCateData[shop]) {
            $scope.updateCateData[shop][shop_id] = $scope.originData[shop][shop_id];
          }
        }

        $scope.simCateList = init ? [] : Object.assign(originSimCateList);
        $scope.placeholder = '상단의 검색창에서 카테고리명을 검색해 보세요.';

        if (init) {
          $scope.search.word = '';
          $scope.search_yn = false;
        }

        setSaveCnt();
      });
    };

    /**
       * 카테고리 검색 필터링
       */
    $scope.categoryNameFilter = function(c) {
      const searchKey = new RegExp($scope.categorySearchKey, 'i');

      return searchKey.test(String(c.id)) || searchKey.test(c.name) || c.name === '';
    };

    /**
       * 쇼핑몰별 카테고리 매칭 설정
       **/
    $scope.infoOpen = async (index, data) => {
      //리스트 선택시 매칭정보상세 초기화
      $('.shopListShow').html('');
      $('.shopListShow').hide();

      //열고 닫기
      if ($(`#test_${index}`).css('display') == 'none') {
        $scope.isOpen = true;

        //열기 일 경우 쇼핑몰 매칭정보 html 새로 그리기
        const url = angular.element(`<div data-ng-include='"views/settings/category/shopDetail/${data.pa_shop_cd}.html"' id='matchingInfo_${index}' class='row blue-bg border-bottom border-bottom-grey-100 p-20 shopListShow'></div>`);

        angular.element(`#test_${index}`).html(url);
        $compile(url)($scope);
        $scope.matchingId = data.shop_id;

        $scope.matchingData = {
          categoryId: $scope.sol_cate_no,
          site_code: data.shop_cd,
          pa_shop_code: data.pa_shop_cd,
          site_id: data.shop_id,
          categoryNames: _.get(data, 'sol_cate_shop_add_info.category_names') || '매칭정보 없음',
          pin_yn: data.pin_yn,
          isOrigin: !!$scope.updateCateData[data.shop_cd][data.shop_id].isOrigin
        };

        if ($scope.updateCateData[data.shop_cd][data.shop_id] && ($scope.updateCateData[data.shop_cd][data.shop_id].isOrigin || $scope.updateCateData[data.shop_cd][data.shop_id].isApply)) {
          $scope.matchingData.sol_cate_shop_add_info = { ...$scope.updateCateData[data.shop_cd][data.shop_id].detailData };
        } else if ($scope.updateCateData[data.shop_cd][data.shop_id]) {
          const detailData = await categoryModel.getDetailData({ shop_cd: data.pa_shop_cd, cateData: $scope.updateCateData[data.shop_cd][data.shop_id] });

          $scope.matchingData.sol_cate_shop_add_info = detailData.data.result.detailData || null;
        } else {
          $scope.matchingData.sol_cate_shop_add_info = null;
        }

        //열기
        openIdx = index;
        $(`#test_${index}`).show();
      } else if ($(`#test_${index}`).css('display') == 'block') {
        $scope.isOpen = false;
        //닫기
        $(`#test_${index}`).hide();
      }

      $scope.shop_cd = data.shop_cd;
      $scope.shop_id = data.shop_id;
    };

    /**
       * 카테고리 적용
       */
    $scope.applyCate = (param) => {
      // 동일 쇼핑몰 적용처리.
      if (param.set_all_shop) {
        for (const cate of $scope.selected_shop.filter(cate => cate.pa_shop_cd === $scope.matchingData.pa_shop_code)) {
          // for (const site_id in $scope.updateCateData[param.site_code]) {
          $scope.updateCateData[cate.shop_cd][cate.shop_id] = param;
          $scope.applyList[cate.shop_cd][cate.shop_id][0] = param;
        }

      } else {
        $scope.updateCateData[param.site_code][param.site_id] = param;

        if (!$scope.applyList[param.site_code]) {
          $scope.applyList[param.site_code] = {};
        }

        if (!$scope.applyList[param.site_code][param.site_id]) {
          $scope.applyList[param.site_code][param.site_id] = [];
        }

        $scope.applyList[param.site_code][param.site_id][0] = param;
      }

      setSaveCnt();

      $scope.shopCateTmpSave(param.set_all_shop ? param.site_code : null);
    };

    /**
       * 쇼핑몰 카테고리 임시저장
       */
    $scope.shopCateTmpSave = async (site_code) => {
      let pa_shop_cd, shop_cd, shop_id;
      const oriIdx = openIdx === null ? 0 : openIdx;

      do {
        if (openIdx === null) {
          openIdx = 0;
        } else {
          openIdx += 1;
        }

        if ($scope.selected_shop[openIdx]) {
          shop_cd = $scope.selected_shop[openIdx].shop_cd;
          shop_id = $scope.selected_shop[openIdx].shop_id;
          pa_shop_cd = $scope.selected_shop[openIdx].pa_shop_cd;
        }
      }
      while ($scope.selected_shop[openIdx] && shop_cd !== 'Z000' &&
        ($scope.selected_shop[openIdx].pin_yn // 고정돼 있거나
          || (site_code ? $scope.selected_shop[openIdx].shop_cd === site_code : false) // 동일 쇼핑몰 적용이거나
          || ($scope.updateCateData[shop_cd][shop_id].end_yn && !$scope.checkAdditionalInfo[pa_shop_cd]) // 추가적용이 필요 없거나
          || $scope.updateCateData[shop_cd][shop_id].isOrigin // 변경사항이 없어서 설정이 필요 없는 경우
          || $scope.updateCateData[shop_cd][shop_id].isApply
        )); // 변경사항이 있는경우

      // 다음 카테고리가 없을 경우 현재 카테고리 창 닫음
      $timeout(() => {
        $scope.infoOpen(oriIdx, $scope.selected_shop[oriIdx]);
      });
    };

    /**
       * 일괄저장 카운팅
       */
    const setSaveCnt = () => {
      $scope.saveCnt = 0;

      for (const shop_cd in $scope.updateCateData) {
        for (const shop_id in $scope.updateCateData[shop_cd]) {
          // 필터링 되어 있는 경우 필터링 된 데이터만 카운트함.
          const data = $scope.updateCateData[shop_cd][shop_id];

          if (data) {
            // 뷰와 같은 함수를 써서 카운트를 맞춤
            if (['border-success', 'border-blue-600'].includes($scope.getSelectionCateClass(data))) {
              $scope.saveCnt++;
            }
          }
        }
      }

      setSaveFlag();
    };

    /**
       * 카테고리 인풋 클래스 설정
       */
    $scope.getSelectionCateClass = (data) => {
      let className = '';

      if (data?.detailData?.isProdScrap && data.isOrigin) { // 수집시 매칭된 카테고리인 경우
        if (data.detailData.isPlayautoCate) { // 표준카테고리 매칭
          className = 'border-blue-600';
        } else { // 유사카테고리 매칭
          className = 'border-success';
        }
      } else if (!data || (data.isOrigin && !data.isPre)) { // 기존 데이터이거나 저장되지 않은 경우 디폴트
        className = 'border-default';
      } else if (!data.isApply && (!data.end_yn || $scope.checkAdditionalInfo[data.pa_shop_cd || data.shop_cd])) {
        className = 'border-danger';
      } else {
        className = 'border-success';
      }

      return className;
    };

    const setUpdateList = async () => {
      const esmNoneShopIdList = [];
      const updateList = [];

      for (const shopIdx in $scope.selected_shop) {
        const shop = $scope.selected_shop[shopIdx];
        const data = $scope.updateCateData[shop.shop_cd] ? $scope.updateCateData[shop.shop_cd][shop.shop_id] : null;

        if ((!data
          || data.isOrigin // 원래 데이터
          || (!data.isApply && (!data.end_yn || $scope.checkAdditionalInfo[shop.pa_shop_cd])) // 원래데이터가 아니고 마지막 카테고리가 아니거나 추가 정보를 입력해야하는 경우
        ) && !(data?.isOrigin && data.detailData?.isProdScrap)) { // 상품수집시 저장된 카테 정보 수정여부
          continue;
        }

        data.index = shopIdx;

        let param = {};

        // 상세에서 카테고리 선택 적용한 경우
        if (data.isApply) {
          param = { ...data, pa_shop_cd: shop.pa_shop_cd, shop_cd: shop.shop_cd, shop_id: shop.shop_id, categoryId: $scope.sol_cate_no };
        } else {
          // 적용하지 않고 바로 저장하는 경우 (유사 카테고리 검색)
          const detailData = await categoryModel.getDetailData({ shop_cd: shop.pa_shop_cd, cateData: data });
          param = {
            pa_shop_cd: shop.pa_shop_cd,
            shop_cd: shop.shop_cd,
            shop_id: shop.shop_id,
            category_codes: 'test',
            category_names: data.category_names,
            categoryId: $scope.sol_cate_no,
            set_all_shop: false,
            country: 'kr',
            type: 0
          };
          Object.assign(param, detailData.data.result);
        }

        if (['A001', 'A006'].includes(param.shop_cd)) {

          param.cateData = data.detailData;
        }

        updateList.push(param);
      }

      return { updateList, esmNoneShopIdList };
    };

    const updateCateTemp = async (updateList) => {
      const timeout = (2 * 60 * 1000) + parseInt(updateList.length / 100) * 10; // 100개 이후 100개 마다 10초씩 추가
      await categoryModel.shopInfoDetailEditAll({ categoryData: updateList, searchWord, timeout });

      // 검색어 초기화
      searchWord = [];

      $scope.applyList = { ...$scope.applyList };

      commonSVC.showToaster('success', '성공', '카테고리 템플릿 저장이 완료되었습니다.');
    };

    /**
       * 카테고리 템플릿 저장
       */
    // 일괄저장
    $scope.saveCateTemplate = async () => {
      try {
        const { updateList, esmNoneShopIdList } = await setUpdateList();

        if (!updateList.length) {
          commonSVC.showMessage('실패', '일괄 저장 가능한 쇼핑몰이 없습니다.');

          return;
        } else if (esmNoneShopIdList.length) {
          commonSVC.showMessage('실패', `해당 쇼핑몰계정의 ESM카테고리를 등록해주세요\n${esmNoneShopIdList.join('\n')}`);

          return;
        }

        if ($scope.sol_cate_no === 'new') {
          const parentList = (await categoryModel.categoryListTree({ search_keyword: '' })).data.results;
          const data = {
            list: parentList,
            cateData: {
              categoryData: updateList,
              searchWord,
              timeout: (2 * 60 * 1000) + parseInt(updateList.length / 100) * 10
            }
          };

          const modal = await commonSVC.openModal('lg', { data }, 'CategoryAddCtrl', 'views/settings/category/modals/categoryAdd.html').result;

          if (modal.state === 'success') {
            $scope.sol_cate_no = modal.code;
            commonSVC.showToaster('success', '성공', '카테고리 템플릿 저장이 완료되었습니다.');
          }
        } else {
          updateCateTemp(updateList);
        }

        updateList.forEach(({ shop_cd, shop_id }) => $scope.updateCateData[shop_cd][shop_id].isOrigin = true);
        setSaveCnt();
      } catch (err) {
        commonSVC.showToaster('error', '실패', err.message || err.data.error);
      } finally {
        $scope.loading = false;
      }
    };

    /**
       * 수정 카테고리 반영
       */
    $scope.ok = async () => {
      const cate_edit_params = {
        ol_shop_no_list: [],
        shop_cate_no_add_info_list: [],
        shop_cate_no_list: []
      };

      if ($scope.saveCnt) {
        const msg = `<div>
        아니오 선택 시,<br />
        해당 상품에만 변경한 정보를 반영하고 솔루션 카테고리에는 저장하지 않습니다.<br /><br />
        예 선택 시,<br />
        해당 상품 및 솔루션 카테고리와 <br />
        해당 카테고리가 연동된 쇼핑몰 상품의 카테고리를 일괄 수정합니다.<br />
        (추천 옵션이 지원되는 쇼핑몰이나 온라인 상품의 [상세] ><br />
        개별 카테고리가 지정된 상품은 제외,<br />
        저장되어 있는 옵션은 그대로 유지됩니다.)<br /><br />
        *쇼핑몰에 이미 등록된 상품은 수정대기 -> 상품전송 시<br />
        카테고리는 수정하지 않습니다.
        </div>`;

        const confirm = await commonSVC.showConfirmHtml('설정하신 정보를 카테고리 템플릿으로 저장하시겠습니까?', msg, '', { confirmButtonText: '예', cancelButtonText: '아니오' });

        const { updateList, esmNoneShopIdList } = await setUpdateList();

        if (confirm) {
          if (!updateList.length) {
            commonSVC.showMessage('실패', '일괄 저장 가능한 쇼핑몰이 없습니다.');

            return;
          } else if (esmNoneShopIdList.length) {
            commonSVC.showMessage('실패', `해당 쇼핑몰계정의 ESM카테고리를 등록해주세요\n${esmNoneShopIdList.join('\n')}`);

            return;
          }

          updateCateTemp(updateList);

          updateList.forEach(({ shop_cd, shop_id }) => $scope.updateCateData[shop_cd][shop_id].isOrigin = true);
          setSaveCnt();
        } else {
          for (const shop of updateList) {
            const sData = {
              pa_shop_cd: shop.pa_shop_cd,
              shop_cd: shop.shop_cd,
              shop_id: shop.shop_id,
              sol_cate_no: $scope.sol_cate_no,
              cate_code: shop.category_code,
              cate_name: shop.category_names
            };

            const shop_cate_no = (await categoryModel.shopCategoryAdd(sData)).data.result;
            const data = $scope.selected_shop.find(s => s.shop_cd === shop.shop_cd && s.shop_id === shop.shop_id);

            data.prod_list.forEach(prod => {
              cate_edit_params.ol_shop_no_list.push(prod.ol_shop_no);
              cate_edit_params.shop_cate_no_add_info_list.push(JSON.stringify(shop.detailData));
              cate_edit_params.shop_cate_no_list.push(shop_cate_no);
            });
          }
        }
      }

      const params = {
        sale_nos: data.selectedData.map(data => data.sale_no).join(','),
        sol_cate_no: $scope.sol_cate_no,
        ...(cate_edit_params.ol_shop_no_list.length && { excpt_list: cate_edit_params.ol_shop_no_list })
      };

      // 템플릿 수정사항 저장하지 않은 경우엔 마스터 상품 카테고리 업데이트 하지 않음
      await onlineProductModel.setSolCate(params);

      // 템플릿에 저장하지 않은 수정사항이 남아 있는 경우 쇼핑몰 개별 카테고리로 반영
      if ($scope.saveCnt && cate_edit_params.ol_shop_no_list.length) {
        await onlineProductModel.setShopCategory(cate_edit_params);
      }

      commonSVC.showToaster('success', '성공', '마스터 상품 카테고리 수정에 성공하셨습니다');
      $uibModalInstance.close('success');
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 판매중지 모달 컨트롤러
   */
  .controller('OnlineProductListCancelCtrl', function ($scope, data, $uibModalInstance, commonSVC, workSVC, errorSVC, $q) {

    const channelUseList = data.channelUseList;

    $scope.prod_total = data.result.length;
    $scope.none_temp_total = 0;
    $scope.cancel_type = 'CancelProd';
    $scope.isStop = false;

    $scope.ok = function() {
      work();
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    function work() {
      const re = {};

      const olList = angular.copy(data.result);

      if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
        Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
          if (prodList[0].ebay_shop_mas_no) {
            const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

            prodList.forEach(ol => {
              ol.pa_shop_cd = 'ESMM';
              ol.shop_cd = 'ESMM';
              ol.shop_id = matchShops[0].etc7;
            });
          }
        });
      }

      angular.forEach(olList, function(v) {
        if (!re[`${v.shop_cd}||${v.shop_id}`]) {
          re[`${v.shop_cd}||${v.shop_id}`] = {};
          re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no = [];
          re[`${v.shop_cd}||${v.shop_id}`].sale_no = [];
          re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no = [];
        }

        // work_log 에 sale_no 를 기준으로 insert 되는데 ol_shop_no 가 넘어가고 있어 작업로그가 2개씩 생기는 문제가 발생되고있었음.  2018-02-07 rony
        re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no.push(v.ol_shop_no);
        re[`${v.shop_cd}||${v.shop_id}`].sale_no.push(v.sale_no);
        re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no.push(v.ebay_shop_mas_no);
      });

      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };

      if (re != {}) {
        const promiseArr = [];

        angular.forEach(re, function(v, k) {
          const site = k.split('||');
          // 작업 추가
          const params = {
            site_code: site[0],
            site_id: site[1],
            prod_list: v.ol_shop_no,
            saleNo_list: v.sale_no,
            esmMasNo_list: v.ebay_shop_mas_no,
            isSingleProd: data.isSingleProd || 0,
            isSmileProd: data.isSmileProd || false
          };
          const anHttpPromise = workSVC.addWork($scope.cancel_type, params)
            .then(function(result) {
              if (result.status == 200) {
                reCnt.success++;
              } else {
                reCnt.error++;
                reCnt.errSite.push(`${site[0]}(${site[1]})`);
                reCnt.errReason.push(result.data.error || '');
              }

              return result;
            })
            .catch(function(err) {
              reCnt.error++;
              reCnt.errSite.push(`${site[0]}(${site[1]})`);
              reCnt.errReason.push('');

              return err;
            });

          promiseArr.push(anHttpPromise);
        });

        $q.all(promiseArr)
          .then(async re => {
            if (!reCnt.success)
            {
              throw re;
            } else {
              const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');

              commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
            }
          })
          .catch(function(err) {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
            commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
          })
          .finally(function() {
            $uibModalInstance.close();
          });
      }
    }
  })

  /**
   * 일시품절 모달 컨트롤러
   */
  .controller('OnlineProductListStopCtrl', function ($scope, $rootScope, data, $uibModalInstance, commonSVC, workSVC, errorSVC, $q) {
    $scope.prod_total = data.result.length;
    $scope.none_temp_total = 0;
    $scope.cancel_type = 'StopProd';
    $scope.isStop = false;
    const channelUseList = data.channelUseList;

    $scope.ok = function() {
      work();
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    function work() {
      const re = {};
      const olList = angular.copy(data.result);

      if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
        Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
          if (prodList[0].ebay_shop_mas_no) {
            const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

            prodList.forEach(ol => {
              ol.pa_shop_cd = 'ESMM';
              ol.shop_cd = 'ESMM';
              ol.shop_id = matchShops[0].etc7;
            });
          }
        });
      }

      angular.forEach(olList, function(v) {
        if (!re[`${v.shop_cd}||${v.shop_id}`]) {
          re[`${v.shop_cd}||${v.shop_id}`] = {};
          re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no = [];
          re[`${v.shop_cd}||${v.shop_id}`].sale_no = [];
          re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no = [];
        }

        // work_log 에 sale_no 를 기준으로 insert 되는데 ol_shop_no 가 넘어가고 있어 작업로그가 2개씩 생기는 문제가 발생되고있었음.  2018-02-07 rony
        re[`${v.shop_cd}||${v.shop_id}`].ol_shop_no.push(v.ol_shop_no);
        re[`${v.shop_cd}||${v.shop_id}`].sale_no.push(v.sale_no);
        re[`${v.shop_cd}||${v.shop_id}`].ebay_shop_mas_no.push(v.ebay_shop_mas_no);
      });

      const reCnt = {
        success: 0,
        error: 0,
        errSite: [],
        errReason: []
      };

      if (re != {}) {
        const promiseArr = [];

        angular.forEach(re, function(v, k) {
          const site = k.split('||');
          // 작업 추가
          const params = {
            site_code: site[0],
            site_id: site[1],
            prod_list: v.ol_shop_no,
            saleNo_list: v.sale_no,
            esmMasNo_list: v.ebay_shop_mas_no,
            isSingleProd: data.isSingleProd || 0,
            isSmileProd: data.isSmileProd || false
          };

          const anHttpPromise = workSVC.addWork($scope.cancel_type, params)
            .then(function(result) {
              if (result.status == 200) {
                reCnt.success++;
              } else {
                reCnt.error++;
                reCnt.errSite.push(`${site[0]}(${site[1]})`);
                reCnt.errReason.push(result.data.error || '');
              }

              return result;
            })
            .catch(function(err) {
              reCnt.error++;
              reCnt.errSite.push(`${site[0]}(${site[1]})`);
              reCnt.errReason.push('');

              return err;
            });

          promiseArr.push(anHttpPromise);
        });

        $q.all(promiseArr)
          .then(async re => {
            if (!reCnt.success) {
              throw re;
            } else {
              const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');

              commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
              $uibModalInstance.close();
            }
          })
          .catch(function(err) {
            const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
            commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);
            $uibModalInstance.close();

            return err;
          });
      }
    }
  })

  /**
   * 판매중지 해제 모달 컨트롤러
   */
  .controller('OnlineProductListCancelStopCtrl', function ($scope, $q, $uibModalInstance, data, workSVC, commonSVC, errorSVC, siteInfo, $state) {
    $scope.prod_total = data.result.length;
    $scope.cancel_type = 'RegistProdRe';
    $scope.isAllSoldOut = _.filter(data.result, { sale_status: '일시품절' }).length === data.result.length;
    $scope.checkOnlyEsm = data.result.filter(ol => ol.sale_status === '판매중지' && ol.ebay_shop_mas_no).length === data.result.length;
    const channelUseList = data.channelUseList;

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = async function() {

      // 상품재등록(쇼핑몰 상품코드 유지)미지원 쇼핑몰 검색
      if ($scope.cancel_type === 'RegistProdRe' && !$scope.isAllSoldOut) {
        const no_shops = ['B394', 'A524', 'P016', 'P018', 'A076', 'P037'];
        const no_list = [];

        data.result.forEach((s) => {
          if (s.sale_status === '판매중지' && no_shops.some((s2) => s2 === s.shop_cd)) {
            no_list.push(s.shop_name);
          }
        });

        if (no_list.length > 0) {
          await commonSVC.showMessage(`${_.uniq(no_list).join(', ')} 쇼핑몰 상품은 '상품코드 유지 재판매'를 지원하지 않아 해당 쇼핑몰 작업이 실패합니다.`, '[새 상품으로 등록]에 체크 하신 후 작업을 진행해 주세요.');

          $scope.waitBtnDisableFlag = false;
          $scope.$apply();

          return false;
        }
      }

      // 상품재등록(새상품으로 등록) 미지원 쇼핑몰 검색
      if ($scope.cancel_type === 'RegistProd' && !$scope.isAllSoldOut) {
        const no_new_shops = ['P044'];
        const no_new_list = data.result.filter(o => no_new_shops.includes(o.shop_cd)).map(o => o.shop_name);

        if (no_new_list.length) {
          await commonSVC.showMessage(`${_.uniq(no_new_list).join(', ')} 쇼핑몰 상품은 '새 상품으로 등록'를 지원하지 않아 해당 쇼핑몰 작업이 실패합니다.`, '[상품코드 유지 재판매]에 체크 하신 후 작업을 진행해 주세요.');

          $scope.waitBtnDisableFlag = false;
          $scope.$apply();

          return false;
        }

        if (data.result.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no && ol.sale_status === '판매중지')) {
          const confirm = await commonSVC.showConfirmCustom({
            title: '새 상품으로 등록 불가한 상품이 포함되어 있습니다.',
            text: '· ESM마스터상품은 새 상품으로 등록이 불가합니다.',
            confirmButtonText: '가능한 상품만 작업',
            cancelButtonText: '닫기',
          });

          if (!confirm) {
            $scope.waitBtnDisableFlag = false;
            $scope.$apply();

            return false;
          }

          data.result = data.result.filter(ol => !(['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no && ol.sale_status === '판매중지'));

          if (!data.result) {
            commonSVC.showMessage('선택된 상품이 없습니다.');

            $scope.waitBtnDisableFlag = false;
            $scope.$apply();

            return;
          }
        }
      }

      const checkCnt = data.result.filter(d => siteInfo[d.shop_cd].sol_type !== 'DEV' && !(siteInfo[d.shop_cd].beta_edate && (moment() < moment(siteInfo[d.shop_cd].beta_edate)))).length;

      workSVC.checkProductCharge(checkCnt)
        .then(function() {
          const re = {};
          const esmMasNo = {};

          const olList = angular.copy(data.result);

          if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
            Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
              if (prodList[0].ebay_shop_mas_no) {
                const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

                prodList.forEach(ol => {
                  ol.pa_shop_cd = 'ESMM';
                  ol.shop_cd = 'ESMM';
                  ol.shop_id = matchShops[0].etc7;
                });
              }
            });
          }

          angular.forEach(olList, function(v) {
            if (!re[`${v.shop_cd}||${v.shop_id}||${v.sale_status}`]) {
              re[`${v.shop_cd}||${v.shop_id}||${v.sale_status}`] = [];
              esmMasNo[`${v.shop_cd}||${v.shop_id}||${v.sale_status}`] = [];
            }
            re[`${v.shop_cd}||${v.shop_id}||${v.sale_status}`].push(v.ol_shop_no);
            esmMasNo[`${v.shop_cd}||${v.shop_id}||${v.sale_status}`].push(v.ebay_shop_mas_no);
          });

          const reCnt = {
            success: 0,
            error: 0,
            errSite: [],
            errReason: []
          };

          if (re) {
            const promiseArr = [];

            angular.forEach(re, function(v, k) {
              let matchEsmKey = '';
              const site = k.split('||');

              if (site[0] === 'ESMM') {
                matchEsmKey = Object.keys(esmMasNo).find(key => key === k);
              }

              // 작업 추가
              const params = {
                site_code: site[0],
                site_id: site[1],
                prod_list: v,
                isSingleProd: data.isSingleProd || 0,
                isSmileProd: data.isSmileProd || false,
                ...(matchEsmKey && { esmMasNo_list: esmMasNo[matchEsmKey] })
              };
              const action = site[2] === '일시품절' ? 'RestoreProd' : $scope.cancel_type;

              const anHttpPromise = workSVC.addWork(action, params)
                .then(function(result) {
                  if (result.status == 200) {
                    reCnt.success++;
                  } else {
                    reCnt.error++;
                    reCnt.errSite.push(`${site[0]}(${site[1]})`);
                    reCnt.errReason.push(result.data.error || '');
                  }

                  return result;
                })
                .catch(function(err) {
                  reCnt.error++;

                  return err;
                });

              promiseArr.push(anHttpPromise);
            });

            $q.all(promiseArr)
              .then(async re => {
                if (!reCnt.success)
                {
                  throw re;
                } else {
                  const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
                  commonSVC.showToaster('success', '작업등록 완료', `성공 : ${reCnt.success}, 실패 : ${reCnt.error} ${reCnt.error ? errMsg : ''}\n\n작업 결과는 좌측 [작업] 탭에서 확인해 주세요.`);
                  // 모달이 정상적으로 닫히지 않아 수정. 2018-01-18 rony
                  // $uibModalInstance.close();
                  // $scope.close();
                }
              })
              .catch(function(err) {
                const errMsg = errorSVC.getErrorMsg(reCnt.errSite, reCnt.errReason, 'work');
                commonSVC.showToaster('error', '작업등록 실패', `${reCnt.error ? errMsg : err}`);

                return err;
              })
              .finally(function() {
                $uibModalInstance.close();
              });
          }
        }).catch(function (err) {
          if (err.data.error === 'check_charge') {
            commonSVC.showConfirm('작업 카운트가 부족하여 작업 등록이 실패되었습니다.', '결제 > 부가서비스 에서 상품 또는 주문의 카운트를 구매 하시거나 버전을 업그레이드 하여 이용해주시기 바랍니다.', function (confirm) {
              if (confirm) {
                $state.go('main.pay_list');
                $state.reload();
              }
            });
          } else {
            commonSVC.showMessage('상품 잔여 전송 수량 조회에 실패하였습니다.');
          }

          $uibModalInstance.close();
        });
    };
  })

  /**
   * 판매기간 연장 모달 컨트롤러
   */
  .controller('OnlineProductListExtensionCtrl', function ($scope, data, $uibModalInstance, workSVC, commonSVC, $state, siteInfo) {

    $scope.prod_total = data.result.length;                  // 선택한 전송 대상 건수
    //$scope.extensionDate = '3';                              // 선택한 판매연장 기간

    const numbers = [];

    const channelUseList = data.channelUseList;
    const olList = angular.copy(data.result);

    if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
      Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
        if (prodList[0].ebay_shop_mas_no) {
          const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

          prodList.forEach(ol => {
            ol.pa_shop_cd = 'ESMM';
            ol.shop_cd = 'ESMM';
            ol.shop_id = matchShops[0].etc7;
          });
        }
      });
    }

    angular.forEach(olList, function(v) {
      numbers.push(`${v.ol_shop_no}||${v.shop_cd}||${v.shop_id}`);
    });

    //
    $scope.extensionDate = {
      A001: '15',
      A006: '15',
      ESMM: '15',
      A077: '3',
      A112: '3',
      A027: '1',
      A027_use_yn: 'Y',
      A112_input: '', // 직접입력한 11번가 연장기간 2020-04-21 Tim
      B700: '7',
      B720: '3'
    };

    // 판매기간 연장
    // 선택된 부분에 따라 기간설정을 다르게 할 수 있도록 구성함.
    // 샵 이름에 따라 boolean 설정
    // 2017. 10. 25 박현도
    $scope.checkStores = {
      storefarm: [
        { title: '설정안함', checked: false },
        { title: '설정함', checked: false },
      ],
    };

    $scope.checkBool = {
      storefarm: false,
      iist: false
    };

    $scope.updateSelection = function(position, items, title, whichStore, sol_code) {
      // 최소, 최대 연장 기간
      let min_extensionDate;
      let max_extensionDate;
      switch (sol_code) {
        // 스마트 스토어
        case 'A077':
          min_extensionDate = '3';
          max_extensionDate = '9999';
          break;
        // 옥션, 지마켓, esm 마스터
        case 'A001':
        case 'A006':
        case 'ESMM':
          min_extensionDate = '15';
          max_extensionDate = '-1';
          break;
      }

      angular.forEach(items, function(subscription, index) {
        $scope.checkBool.storefarm = true;
        $scope.extensionDate[sol_code] = min_extensionDate;

        if (position != index) {
          subscription.checked = false;
          $scope.checkBool.storefarm = false;
          $scope.extensionDate[sol_code] = max_extensionDate;
        }
      });
    };

    $scope.showShopName = {
      auction: false,
      gmarket: false,
      esmMaster: false,
      storefarm: false,
      iist: false,
      interpark: false,
      kakaopresent: false,
      sportsmania: false,
      tmonWorld: false,
      interparkshopping: false,
      wemakepriceplus: false,
    };

    $scope.checkShopName = function() {
      for (let i = 0; i < data.result.length; i++) {
        if (data.result[i].shop_name === '옥션') {
          if (data.result[i].ebay_shop_mas_no) {
            $scope.showShopName.esmMaster = true;
          } else {
            $scope.showShopName.auction = true;
          }
        }
        else if (data.result[i].shop_name === '지마켓') {
          if (data.result[i].ebay_shop_mas_no) {
            $scope.showShopName.esmMaster = true;
          } else {
            $scope.showShopName.gmarket = true;
          }
        }
        else if (data.result[i].shop_name === '스마트스토어') {
          $scope.showShopName.storefarm = true;
        }
        else if (data.result[i].shop_name === '11번가') {
          $scope.showShopName.iist = true;
        }
        else if (data.result[i].shop_name === '인터파크') {
          $scope.showShopName.interpark = true;
        }
        else if (data.result[i].shop_name === '카카오 선물하기') {
          $scope.showShopName.kakaopresent = true;
        }
        else if (data.result[i].shop_name === '스포츠매니아') {
          $scope.showShopName.sportsmania = true;
        }
        else if (data.result[i].shop_name === '티몬월드') {
          $scope.showShopName.tmonWorld = true;
        }
        else if (data.result[i].shop_name === '인팍쇼핑') {
          $scope.showShopName.interparkshopping = true;
        }
        else if (data.result[i].shop_name === '위메프플러스') {
          $scope.showShopName.wemakepriceplus = true;
        }
        else if (data.result[i].shop_name === '도매꾹(신)') {
          $scope.showShopName.domeggook = true;
        }
      }
    };

    $scope.changeDate = () => {
      const ctrl = parseInt($scope.extensionDate.A027_ctrl);

      if (ctrl !== -1) {
        $scope.extensionDate.A027_edate = moment().add(ctrl, 'day').format('YYYY-MM-DD');
      }
    };

    $scope.changeRdo = (rdo) => {
      if (rdo === 'N') {
        $scope.extensionDate.A027_edate = '9999-12-31';
      } else {
        $scope.extensionDate.A027_edate = moment().format('YYYY-MM-DD');
      }
    };

    $scope.ok = _.throttle(() => {
      if ($scope.extensionDate.A112 === '9999') {
        if ($scope.extensionDate.A112_input === '' || $scope.extensionDate.A112_input === '0') {
          commonSVC.showMessage('연장할 기간을 입력해 주세요.');

          return false;
        }
      }

      // 작업 추가
      const params = {
        numbers: numbers,
        extension_date: $scope.extensionDate,
        isSingleProd: data.isSingleProd || 0
      };

      const checkCnt = data.result.filter(d => siteInfo[d.shop_cd].sol_type !== 'DEV' && !(siteInfo[d.shop_cd].beta_edate && (moment() < moment(siteInfo[d.shop_cd].beta_edate)))).length;

      workSVC.checkProductCharge(checkCnt)
        .then(function(re) {
          if (re.status == 200) {
            workSVC.addWorkSelect('EXTENT_PROD', params);
            $uibModalInstance.close();
          }
        })
        .catch(function(err) {
          if (err.data.error == 'check_charge') {
            commonSVC.showConfirm('작업 카운트가 부족하여 작업 등록이 실패되었습니다.', '결제 > 부가서비스 에서 상품 또는 주문의 카운트를 구매 하시거나 버전을 업그레이드 하여 이용해주시기 바랍니다.', function (confirm) {
              if (confirm) {
                $state.go('main.pay_list');
                $state.reload();
              }
            });
          } else {
            commonSVC.showMessage('상품 잔여 전송 수량 조회에 실패하였습니다.');
          }

          $uibModalInstance.close();
        });

    }, 2000, { trailing: false });

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 상품상태 동기화 모달 컨트롤러
   */
  .controller('OnlineProductListSyncCtrl', function ($rootScope, $scope, data, localStorageService, $uibModalInstance, workSVC, commonSVC) {
    $scope.syncDisabled = false;
    $scope.prod_total = data.result.length;                  // 선택한 전송 대상 건수
    $scope.sync = {                                         // 선택한 동기화 항목
      state: false, site_stock: false, reg_edate: false, site_sprice: false
    };

    // 대리점 계정일 경우 '내일 배송 가능 여부' 추가
    if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
      $scope.sync.next_delivery = false;
    }

    const configList = localStorageService.get('prodSync')?.split(',') || [];
    $scope.saveCheck = !!configList.length;
    configList.forEach(key => {
      $scope.sync[key] = true;
    });

    const channelUseList = data.channelUseList;

    const olList = angular.copy(data.result);

    if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
      Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
        if (prodList[0].ebay_shop_mas_no) {
          const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

          prodList.forEach(ol => {
            ol.pa_shop_cd = 'ESMM';
            ol.shop_cd = 'ESMM';
            ol.shop_id = matchShops[0].etc7;
          });
        }
      });
    }

    const numbers = [];

    angular.forEach(olList, function(v) {
      numbers.push(`${v.ol_shop_no}||${v.shop_cd}||${v.shop_id}`);
    });

    $scope.ok = function() {
      if (Object.keys($scope.sync).every(key => $scope.sync[key] == false)) {
        $scope.syncDisabled = false;
        commonSVC.showMessage('동기화 할 항목을 선택해 주세요.');

        return;
      }

      const sync_data = [];

      for (const key in $scope.sync) {
        if ($scope.sync[key]) {
          sync_data.push(key);
        }
      }

      // 작업 추가
      const params = {
        numbers: numbers,
        sync_data: sync_data,
        isSingleProd: data.isSingleProd || 0,
        isSmileProd: data.isSmileProd || false,
      };

      $scope.syncDisabled = true;
      workSVC.addWorkSelect('SYNC_PROD_STATE', params);

      if ($scope.saveCheck) {
        localStorageService.set('prodSync', Object.keys($scope.sync).filter(key => $scope.sync[key]).join());
      }
      $uibModalInstance.close();

    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

  })

  /**
   * 연동해제, 연동수정
   */
  .controller('OnlineProductListLinkageCtrl', function ($rootScope, $scope, $state, data, commonSVC, workSVC, $uibModalInstance, onlineProductModel, $timeout) {
    $scope.prod = {
      count: data.result.length,
      isSync: false,
      std_ol_yn: '0'
    };
    $scope.linkage_type = data.linkage_type;
    $scope.prodList = angular.copy(_.sortBy(data.result, 'ebay_shop_mas_no', 'shop_cd', 'shop_id', 'sale_status'));
    $scope.edit_mode = $scope.prod.count == 0 ? 'excel' : 'default';
    $scope.sync = { state: false, site_stock: false, reg_edate: false, site_sprice: false };

    // 대리점 계정일 경우 '내일 배송 가능 여부' 추가
    if ($rootScope.affName === 'LG전자' && $rootScope.user_profile.pa_sol_no) {
      $scope.sync.next_delivery = false;
    }

    // 인풋 초기값으로 돌리는 함수
    const resetInput = () => {
      $scope.fileName = '파일 검색 또는 끌어다놓기';
      $scope.fileData = '';
    };
    const channelUseList = data.channelUseList;

    resetInput();

    $scope.fileSelect = (files) => {
      // 노출용 파일명 세팅, 파일 개수 제한
      $scope.fileData = '';
      if (files.length >= 2) {
        commonSVC.showMessage('엑셀 파일은 한개만 업로드해주세요.');
      } else if (!['.xlsx', '.csv'].reduce((acc, cur) => acc ? true : files[0].name.endsWith(cur), false)) {
        commonSVC.showMessage('엑셀 파일 등록 실패', '.xlsx, csv 형식의 파일로 등록해 주세요.');
      } else if (/[+?#]/.test(files[0].name)) {
        commonSVC.showMessage('엑셀작업 등록실패', '파일명에 사용 불가능한 특수문자가 포함되어 있습니다.\n사용 불가 특수문자: + ? #');
      } else if (files.length === 1) {
        $scope.fileName = files[0].name;
        $scope.fileData = files[0];
      }
    };

    _.each($scope.prodList, function(row) {
      // chris 2018-11-09 원본 쇼핑몰 상품코드 추가 (로그용)
      row.ori_shop_sale_no = angular.copy(row.shop_sale_no);
    });

    //연동해제
    $scope.stop = function() {
      modify();
    };

    //연동수정
    $scope.edit = () => {
      if ($scope.form1.editProdForm.$valid) {
        const isDup = $scope.prodList.filter(({ shop_sale_no, shop_cd, shop_id }, index, iteratee) => iteratee.slice(index + 1, -1).some(({ shop_sale_no: a, shop_cd: b, shop_id: c }) => shop_sale_no === a && shop_cd === b && shop_id === c));

        if (isDup.length) {
          commonSVC.showMessage('중복된 쇼핑몰 코드가 있습니다.', `쇼핑몰 코드 ${Array.from(new Set(isDup.map(({ shop_sale_no }) => shop_sale_no))).join(',')} 를 확인해주세요`);

          return false;
        }

        if ($scope.prod.isSync && Object.keys($scope.sync).every(key => $scope.sync[key] == false)) {
          commonSVC.showMessage('동기화 할 항목을 선택해 주세요.');

          return false;
        }

        if ($scope.prod.isSync) {
          const title = '입력하신 정보로 연동정보를 수정합니다.';
          const contents = '입력하신 정보가 쇼핑몰과 다른 경우 상품 수정 시 실패될 수 있으니 작업에 유의해 주세요. \n 상품동기화 작업은 템플릿이 없는 상품 제외 후 작업관리에 등록되며, \n 동기화 작업 결과는 작업관리에서 확인할 수 있습니다.';
          const buttons = [
            {
              name: '작업관리로 이동',
              next: () => {
                modify('sync_go_work');
              }
            },
            {
              name: '확인',
              next: () => {
                modify('sync');
              }
            },
            {
              name: '취소',
              next: () => {
                return;
              }
            }
          ];

          commonSVC.showMultiButton(title, contents, buttons);
        } else {
          commonSVC.showConfirm('입력하신 정보로 연동정보를 수정합니다.', '입력하신 정보가 쇼핑몰과 다른 경우 상품 수정 시 실패될 수 있으니 \n 작업에 유의해 주세요.', function () {
            modify();
          });
        }
      } else {
        commonSVC.showMessage('쇼핑몰 상품코드에 빈 항목이 존재합니다.', '쇼핑몰 상품코드를 모두 입력해 주신 후 다시 시도해주세요.');
      }
    };

    $scope.upload = function () {
      if ($scope.prod.isSync && Object.keys($scope.sync).every(key => $scope.sync[key] == false)) {
        commonSVC.showMessage('동기화 할 항목을 선택해 주세요.');

        return false;
      }
      $scope.waitBtnDisableFlag = true;

      if ($scope.fileData) {
        const sync_data = [];
        for (const key in $scope.sync) {
          if ($scope.sync[key]) {
            sync_data.push(key);
          }
        }
        const params = {
          // 수정상품
          target: $scope.target,
          // 등록, 수정여부
          mode: 'edit',
          // 이후 상품동기화 여부
          sync: $scope.prod.isSync,
          sync_data: sync_data,
          // 일반,단일 상품 여부
          isSingleProd: $scope.prod.std_ol_yn,
          isSmileProd: parseInt($scope.prod.std_ol_yn) === 2
        };

        workSVC.addExcelWork('EditOnlineLinkageExcel', params, $scope.fileData)
          .finally(function () {
            $uibModalInstance.close();
            $timeout(function () { $scope.waitBtnDisableFlag = false; });
          });
      } else {
        commonSVC.showMessage('엑셀작업 등록실패', '파일을 선택해주세요.');
        $timeout(function () { $scope.waitBtnDisableFlag = false; });
      }
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    function modify(next = '') {
      const params = {
        type: $scope.linkage_type,
        sale_data: $scope.prodList.map(({ shop_cd, shop_id, shop_sale_no, ori_shop_sale_no, ol_shop_no, ebay_shop_mas_no }) => ({
          shop_cd
          , shop_id
          , shop_sale_no
          , ori_shop_sale_no
          , ol_shop_no
          , ebay_shop_mas_no
        }))
      };
      const txt = $scope.linkage_type === 'edit' ? '수정' : '해제';

      onlineProductModel.setShopLinkInfo(params, function(state, data) {

        if (data.results?.length) {
          if (data.results[0].duplicates) {
            $timeout(() => {
              commonSVC.showMessage('중복된 쇼핑몰 코드가 있습니다.', `중복된 쇼핑몰상품코드 : (${data.results[0].value.join(', ')})\n 다른 상품에 연동되어 있는 상품코드는 사용할 수 없습니다.\n 입력하신 쇼핑몰 상품코드를 다시 한번 확인해주세요.`);
            }, 100);
          } else {
            const result = {
              success: data.results[0].success ? data.results[0].success : 0,
              error: data.results[0].error ? data.results[0].error : 0
            };
            const msg = `연동상품 ${txt}에 성공${result.success}건 / 실패 ${result.error}건 하였습니다.`;

            commonSVC.showToaster('success', '성공', msg);

            if (next.includes('sync')) {

              next === 'sync' ? $uibModalInstance.close('refresh') : $uibModalInstance.close('success');

              const sync_data = [];

              for (const key in $scope.sync) {
                if ($scope.sync[key]) {
                  sync_data.push(key);
                }
              }
              const olList = angular.copy($scope.prodList);

              if (olList.some(ol => ['A001', 'A006'].includes(ol.pa_shop_cd) && ol.ebay_shop_mas_no)) {
                Object.values(_.groupBy(olList, 'ebay_shop_mas_no')).forEach(prodList => {
                  if (prodList[0].ebay_shop_mas_no) {
                    const matchShops = channelUseList.filter(ch => prodList.some(ol => ol.shop_cd === ch.shop_cd && ol.shop_id === ch.shop_id));

                    prodList.forEach(ol => {
                      ol.pa_shop_cd = 'ESMM';
                      ol.shop_cd = 'ESMM';
                      ol.shop_id = matchShops[0].etc7;
                    });
                  }
                });
              }

              const params = {
                numbers: _.map(olList, function (v) {
                  return `${v.ol_shop_no}||${v.shop_cd}||${v.shop_id}`;
                }),
                sync_data: sync_data,
                isSingleProd: $scope.prodList[0].std_ol_yn || 0
              };

              workSVC.addWorkSelect('SYNC_PROD_STATE', params);

              if (next === 'sync_go_work') {
                $state.go('main.work');
              }
            } else {
              $uibModalInstance.close('refresh');
            }
          }
        } else {
          commonSVC.showToaster('error', '실패', `연동상품 ${txt}에 실패 하였습니다.`);
        }
      });
    }

    // 테이블 리사이징 기능 추가
    if ($scope.linkage_type === 'edit' && $scope.prodList.length) {
      $timeout(function () {
        $(document).ready(function() {
          $('.draggable-col').each(function() {
            var thWidth = $(this).outerWidth();

            $(this).append('<div class="draggable-handle"></div>');
            $(this).find('.draggable-handle').css('left', thWidth - 6 + 'px');
          });

          $('.draggable-col').resizable({
            handles: 'e',
            minWidth: 50,
            resize: function(event, ui) {
              var columnIndex = $(this).index();
              var newWidth = ui.size.width + 'px';

              $('col:eq(' + columnIndex + ')').css('width', newWidth);
              $(this).find('.draggable-handle').css('left', ui.size.width - 6 + 'px');
            },
            containment: 'parent'
          });
        });
      }, 100);
    }
  })

  /**
   * 2019-04-03 lucas
   * 마스터 상품 정보 수정 시 공통으로 수정할 연동된 쇼핑몰 상품 선택 모달
   */
  .controller('EditMasterProdCtrl', function ($scope, $rootScope, $uibModalInstance, $timeout, commonSVC, data, slaveProdList, systemList) {
    $scope.master_only = true;                        // 마스터만 수정하는지
    $scope.shop_list = [];                            // 마스터 상품에 연동된 쇼핑몰 상품 리스트

    // ESM쇼핑몰 중 하나라도 조회 안될 시 권한이 없는 것으로 판단하고 슬레이브 리스트에서 제거
    if (slaveProdList.data.results.some(prod => prod.ebay_shop_mas_no)) {
      Object.values(_.groupBy(slaveProdList.data.results, 'ebay_shop_mas_no')).filter(list => {
        if (!list[0].ebay_shop_mas_no) {
          $scope.shop_list.push(...list);
        } else {
          if (list.length > 1) {
            $scope.shop_list.push(...list);
          }
        }
      });
    } else {
      $scope.shop_list = slaveProdList.data.results;    // 마스터 상품에 연동된 쇼핑몰 상품 리스트
    }

    $scope.selected_index;                            // 선택한 상품 인덱스
    $scope.edit_single_prod = data.edit_single_prod;  // 마스터 상품 하나만 수정하는지 여부 (마스터 상품 상세 / 정보 일괄 수정)
    $scope.channelSelectAll = true;
    $scope.shop_id_view_type = systemList.data.shop_id_view_type;  //쇼핑몰 계정 표기방식

    $scope.editShop = 'all'; // 수정 쇼핑몰 계정 (전체/선택)
    $scope.editType = 'all'; // 수정항목 타입 (전체/선택)
    // 수정항목 리스트
    $scope.editList = [
      {
        title: '옵션정보',
        tooltip: '마스터 상품 옵션에 매칭된 SKU상품의 정보도 함께 반영됩니다.\n쇼핑몰 정책에 따라 미지원되는 항목은 마스터 상품의 옵션, 추가구매옵션 정보로 반영되지 않을 수 있습니다.',
        itemList: [
          { title: '등록 상품 선택(옵션)', value: 'opt', selected: false },
          { title: '추가구매옵션', value: 'addOpt', selected: false },
        ]
      },
      {
        title: '가격정보',
        itemList: [
          { title: '판매가', value: 'sale_price', selected: false },
          { title: '공급가', value: 'supply_price', selected: false },
          { title: '원가', value: 'cost_price', selected: false },
          { title: '시중가', value: 'street_price', selected: false },
          { title: '배송비 종류', value: 'ship', selected: false },
        ]
      },
      {
        title: '기본정보',
        itemList: [
          { title: '마스터 상품명', value: 'shop_sale_name', selected: false },
          { title: '쇼핑몰 판매수량', value: 'sale_cnt_limit', selected: false },
          { title: '원산지', value: 'madein', selected: false },
          { title: '과세여부', value: 'tax_type', selected: false },
          { title: '모델명/브랜드/제조사', value: 'model_brand_maker', selected: false },
          { title: '키워드', value: 'keyword', selected: false },
          { title: '미성년자 구매', value: 'adult_yn', selected: false },
          { title: '상품정보제공고시', value: 'prod_noti_data', selected: false },
          { title: '제조일자', value: 'made_date', selected: false },
          { title: '유효일자', value: 'expire_date', selected: false },
          { title: 'UPC/EAN 코드', value: 'global_barcode', selected: false },
          { title: 'ISBN 코드', value: 'isbn', selected: false },
          { title: '바코드', value: 'barcode', selected: false },
          { title: '인증정보', value: 'cert_info', selected: false },
          { title: 'HS코드', value: 'hscd', selected: false },
          { title: '사은품', value: 'gift_name', selected: false },
          { title: '해외배송여부', value: 'foreign_ship_avail_yn', selected: false },
          ...($rootScope.user_profile.sol_stock < 1 ? [{ title: '배송처', value: 'depot_no', selected: false }] : []) // 재고미사용 버전인 경우 배송처 항목 출력
        ]
      },
      {
        title: '이미지 정보',
        itemList: [
          { title: '이미지', value: 'img', selected: false },
          { title: '상세설명', value: 'detail_desc', selected: false },
        ]
      },
    ];

    // 수정항목 5개씩 나눠서 저장 (테이블 노출이슈때문에)
    $scope.editList.forEach(list => {
      list.chunkItemList = _.chunk(list.itemList, 5);
    });

    /**
     * 수정항목 선택
     */
    $scope.editOptionSelected = option => {
      if ($scope.editType === 'select' && option) {
        option.selected = !option.selected;
      }
    };

    /**
     * 수정항목 타입 변경
     */
    $scope.editTypeChange = type => {
      // '전체항목' 선택시 수정항목들 선택해제
      if (type === 'all') {
        for (const { itemList } of $scope.editList) {
          for (const item of itemList) {
            item.selected = false;
          }
        }
      }
    };

    /**
     * 연동 수정 계정 항목 변경시 멀티셀렉트 초기화
     */
    $scope.editShopChange = (type) => {
      $('.reg-channel-select').val([]);
      $scope.editShop = type;
      $scope.selected_index = [];
      $scope.channelSelectAll = true;
      $('#shopList').next().find('ul.select2-selection__rendered li.select2-selection__choice').remove();
    };

    // $viewContentLoaded : Emitted every time the ngView content is reloaded.
    // select2 : (쇼핑몰 상품 등록 > 쇼핑몰 계정 선택) 형태의 특수한 select box
    // select2를 로딩이 끝난 뒤에 설정해줌

    $scope.$watch('$viewContentLoaded', function () {
      $timeout(function () {
        $('.select-fixed-multiple').select2({
          minimumResultsForSearch: Infinity,
          width: '100%',
          placeholder: '쇼핑몰(계정)선택',
          closeOnSelect: false
        });

        // 쇼핑몰 선택 시 액션
        $('.reg-channel-select').on('change.select2', function () {
          let va = $(this).val() || [];

          if (va[0] == 'all') {
            $scope.channelSelectAll = false;
            va = [];
            for (let i = 0; i < $scope.shop_list.length; i++) {
              va.push(i);
            }
            $(this).val(va).trigger('change');
            $(this).select2('close');

            return;
          }

          if (va[0] == 'del') {
            $scope.channelSelectAll = true;
            $scope.selected_index = [];
            $('.reg-channel-select').val([]).trigger('change');
            $(this).select2('close');

            return;
          }

          $scope.selected_index = va;
          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }
        });
      }, 0);
    });

    $scope.ok = function() {
      let return_data;

      if ($scope.master_only) {
        return_data = true;
      } else {
        if ($scope.editShop === 'all') {
          $scope.selected_index = [];
        }
        const selected_shop = $scope.edit_single_prod ? _.map($scope.editShop === 'all' ? [...$scope.shop_list.keys()] : $scope.selected_index, function (index) {
          return $scope.shop_list[Number(index)];
        }) : $scope.shop_list;

        // 연동상품 수정항목 선택 옵션
        const add_editOption = $scope.editType === 'all' ? [] : $scope.editList.reduce((prev, curr) => [...prev, ...curr.itemList], []).filter(({ selected }) => selected).map(({ value }) => value);

        if (!selected_shop.length) {
          commonSVC.showMessage('수정 실패', '상품정보를 수정할 쇼핑몰 계정을 선택하세요.');

          return;
        }

        if ($scope.editType === 'select' && !add_editOption.length) {
          commonSVC.showMessage('마스터 상품 수정 안내', '목록에서 수정할 항목이 선택되지 않았습니다.\n항목을 선택하신 후 다시 진행해주세요.');

          return;
        }

        return_data = {
          add_ol_shop_no: _.map(selected_shop, 'ol_shop_no'),
          add_shop_cd: _.map(selected_shop, 'shop_cd'),
          add_shop_id: _.map(selected_shop, 'shop_id'),
          add_std_ol_yn: _.map(selected_shop, 'std_ol_yn'),
          add_sale_no: _.map(selected_shop, 'sale_no'),
          add_sale_status: _.map(selected_shop, 'sale_status'),
          add_sale_price: _.map(selected_shop, 'sale_price'),
          add_supply_price: _.map(selected_shop, 'supply_price'),
          add_cost_price: _.map(selected_shop, 'cost_price'),
          add_street_price: _.map(selected_shop, 'street_price'),
          add_selected_shop: selected_shop.map(shop => {
            return {
              pa_shop_cd: shop.pa_shop_cd,
              shop_cd: shop.shop_cd,
              shop_id: shop.shop_id,
              ol_shop_no: shop.ol_shop_no,
              std_ol_yn: shop.std_ol_yn,
              shop_cate_cd: shop.shop_cate_cd,
              ebay_shop_mas_no: shop.ebay_shop_mas_no
            };
          }),
          add_editOption
        };
      }

      $uibModalInstance.close(return_data);
    };

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };
  })

  // 온라인 상품 임시저장 모달 컨트롤러
  .controller('SaveTemporaryOnlineCtrl', function ($scope, $uibModalInstance, commonSVC, onlineProductModel) {
    onlineProductModel.temporarySaveList().then(function(response) {
      if (response.status === 200) {
        _.forEach(response.data.results, function(item) {
          if (item.img === '""') {
            item.img = undefined;
          }
        });
        $scope.temporaryListData = response.data.results;
      }
    });

    // regExp로 문자열 양 끝의 "" 를 trimming
    $scope.htmlEscape = function (arg) {
      return arg.replace(/^"(.*)"$/, '$1');
    };

    // 임시 저장 삭제
    $scope.temporarySaveDelete = function (tmpsave_no) {
      commonSVC.showConfirm('임시저장 삭제', '선택한 임시저장 데이터를 삭제하시겠습니까?', function (confirm) {
        if (confirm) {
          onlineProductModel.temporarySaveDelete(tmpsave_no, function (re) {
            if (re === 'success') {
              commonSVC.showToaster('success', '성공', '임시저장 데이터가 삭제되었습니다.');
              _.forEach($scope.temporaryListData, function(item, idx) {
                if (item.tmpsave_no === tmpsave_no) {
                  $scope.temporaryListData.splice(idx, 1);
                }
              });
            }
          });
        }
      });
    };

    // 임시 저장 적용
    $scope.applyTemporarySave = function (tmpsave_no) {
      $uibModalInstance.close({ tmpsave_no: tmpsave_no });
    };

    // 임시 저장 모달 창 닫기
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  //추천 옵션 확인 모달
  .controller('esmOptCheck', function ($scope, data, $uibModalInstance) {
    $scope.data = _.cloneDeep(data.data);
    $scope.opt = [];
    init();

    function init() {
      _.forEach($scope.data, function(opt) {
        const temp = { name: '', attr: '' };

        temp.name = opt.name;
        temp.attr = _.map(opt.cateOpAttrList, 'ObjOptEssenName').join(', ');
        $scope.opt.push(temp);
      });
    }
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 추천 고정 옵션 모달
   */
  .controller('CategoryFixedOptionCTRL', function ($scope, data, $uibModalInstance, $timeout) {
    $scope.data = _.cloneDeep(data.data);
    $scope.opt_list = [];
    $scope.allCheck = false;
    $scope.search = {
      keyword: ''
    };
    $scope.selectOptList = [];
    $scope.searchResults = [];

    // 직접입력 옵션
    $scope.directOptions = {
      visible: $scope.data.enableDirectOptions,
      values: ''
    };

    $scope.checkAll = function () {
      $scope.selectOptList = [];

      $scope.opt_list.forEach((v) => {
        if (!v.initOption) {
          v.checked = false;
        }
      });

      _.forEach($scope.searchResults, function(row) {
        if (row.initOption === true) {
          return;
        }

        row.checked = $scope.allCheck;
        if ($scope.allCheck) {
          $scope.selectOptList.push(row.name);
        }
      });
    };

    $scope.selectOpt = (row) => {
      if (row.initOption === true) {
        return;
      }

      row.checked = !row.checked;

      if ($scope.allCheck === true && row.checked === false) {
        $scope.allCheck = false;
      }

      syncAllCheck();

      if (row.checked) {
        $scope.selectOptList.push(row.name);
      } else {
        $scope.selectOptList.splice(_.findIndex($scope.selectOptList, function(n) { return n == row.name; }), 1);
      }
    };

    $scope.ok = () => {
      // 직접입력 옵션이 있는경우 선택값에 더해줌.
      if ($scope.directOptions.visible && $scope.directOptions.values) {
        $scope.selectOptList = [...new Set($scope.selectOptList.concat($scope.directOptions.values.split(',')))];
      }

      $uibModalInstance.close({
        result: {
          attr: $scope.selectOptList.join(',')
        },
        checkeds: $scope.opt_list.filter((v) => v.checked === true)
      });
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.onChangeSearchKeyword = () => {
      $scope.searchResults = $scope.opt_list.filter((item) => item.name.includes($scope.search.keyword));

      syncAllCheck();
    };

    function syncAllCheck() {
      if (!$scope.searchResults.length) {
        $scope.allCheck = false;

        return;
      }

      const oldLength = $scope.searchResults.length;

      $scope.allCheck = $scope.searchResults.every((item) => item.checked === true);

      if ($scope.allCheck === true) {
        if ($scope.opt_list.length !== $scope.searchResults.length && oldLength !== $scope.searchResults.length) {
          $scope.allCheck = false;
        }
      }
    }

    const init = async () => {
      $scope.opt_list = _.cloneDeep(data.data.cateOpAttr.filter(o => o.ObjOptEssenName && !($scope.data.shop_cd === 'A092' && o.ObjOptEssenNo === '-1')));

      await $timeout(() => {
        $scope.searchResults = [ ...$scope.opt_list ];

        if ($scope.data.attr) {
          const tempList = $scope.data.attr.split(',');

          tempList.forEach(opt => {
            const obj = $scope.opt_list.find(s => s.name.trim() === opt.trim());

            if (obj) {
              $scope.selectOptList.push(opt);

              obj.checked = true;
            }
          });

          // 직접입력 옵션 셋팅
          $scope.directOptions.values = tempList.filter(opt => !$scope.opt_list.find(s => s.name.trim() === opt.trim())).join(',');
        }
      });
    };

    init();
  })

/**
   * 2018-01-04 Roy
   * 스타일 코드로 SKU상품 리스트 조회
   */
  .controller('styleProdList', function ($scope, $rootScope, settings, commonSVC, $uibModalInstance, data, $compile, $timeout, productSVC, productModel, supplierModel, warehouseModel, systemModel, $anchorScroll, $location, userInfo) {
    $scope.searchData = angular.copy(productSVC.searchData);
    $scope.searchData.showCount = 5;
    $scope.searchForm = angular.copy(productSVC.searchForm);
    $scope.searchForm.group_prod = true;

    $scope.data = data;

    $scope.options = [];
    $scope.preStyleSkuList;
    $scope.styleSkuList = [];
    $scope.style_cd = '';
    $scope.search_key = 'all';
    $scope.cateOpAttList = [];
    $scope.inValidAttr = [];
    $scope.applyValid = false;

    let changed = false;
    let pageData = {};

    /**
     *  데이터 테이블
     */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: [
        'widget', 'prod_img', 'style_cd', 'sku_cd', 'prod_name', 'attri_type',
        'attri', 'brand', 'model', 'sale_price', 'depot_name'
      ],
      alignRightColumns: [],
      defaultSortingColumns: ['mdate'],
      notSortingColumns: ['attri_type', 'widget'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/base/list`,

        requestWillAction: function (data) {
          data.modal_flag = 'ol_shop';

          ($scope.searchForm.orderby || $scope.searchForm.orderby === '') && delete $scope.searchForm.orderby;

          pageData = Object.assign(data, $scope.searchForm);

          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.prodList = result.results;

          return result.results;
        }
      },
      selectOptions: {
        multiSelect: false
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 60,
          template: function (row) {
            return `<button type="button" class="btn btn-default btn-xxs" ng-click="grid.appScope.showDetail(0, 0, '${row.sku_cd}', '${row.prod_no}')">상세</button>`;
          }
        },
        {
          key: 'prod_img',
          title: '이미지',
          width: 50,
          template: function (row) {
            return `<img src="${row.prod_img}" width="35px">`;
          }
        },
        {
          key: 'style_cd',
          title: '스타일 상품',
          width: 110
        },
        {
          key: 'sku_cd',
          title: 'SKU코드',
          width: 110
        },
        {
          key: 'prod_name',
          title: 'SKU상품명',
          width: 150
        },
        {
          key: 'attri_type',
          title: '속성명',
          width: 110
        },
        {
          key: 'attri',
          title: '속성',
          width: 110
        },
        {
          key: 'brand',
          title: '브랜드',
          width: 80
        },
        {
          key: 'model',
          title: '모델명',
          width: 80
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 80
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 110,
          template: function (row) {
            return `<p value="${row.depot_name}">${row.depot_name}</p>`;
          }
        }
      ]
    };

    /**
     * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
     */
    $scope.$on('OnColumnChange', function(event, data) {
      _.each(data, function(row) {
        $compile(row)($scope);
      });
    });

    $scope.searchDo = function () {
      if ($scope.search_key == 'all') { $scope.searchForm.search_key = 'all'; }
      else { $scope.searchForm.search_key = [$scope.search_key]; }
      $scope.grid.methods.reloadData(function() {}, true);
    };

    /**
     * 상세페이지 모달창
     */
    $scope.showDetail = function(rowIndex, show, sku_cd, prod_number) {

      // SKU상품수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          detailType: 'edit'
        }
      };

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, ebaydepot_yn: false });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');
    };

    $scope.$on('OnSelectChangeBefore', (event, row) => {
      if (row.selectDataSelected == true) {
        $scope.styleGroupList(row.selectData.style_cd, row.selectData.d_name, row.selectData.attri_type);
      }
    });

    //선택된 SKU상품과 동일한 SKU상품 리스트 가져오기
    $scope.styleGroupList = function(style_cd, d_name, attri_type) {
      const style_opt_name = attri_type && attri_type.split(',');

      if (attri_type && ($scope.data.currentOpt == 'esm_' && !$scope.data.esmDirectOptionEnable)) {
        const etc_opt = [];
        const tempProdOpt = [];

        _.forEach(style_opt_name, function(opt_name) {
          _.forEach($scope.data.opt, function(row, optIndex) {
            if (row.name == opt_name && style_opt_name.length > 1) {
              tempProdOpt.push($scope.data.opt[optIndex]);
            }
          });
          const find_opt = _.find($scope.data.opt, function(row) {
            return row.name == opt_name;
          });

          if (!find_opt) {
            etc_opt.push(opt_name);
          }
        });
        _.forEach($scope.data.opt, function (o) {
          if (_.findIndex(tempProdOpt, function (t) { return t.name == o.name; }) == -1) {
            tempProdOpt.push(o);
          }
        });

        if (etc_opt.length) {
          commonSVC.showMessage(`선택한 스타일상품의 속성이 지원하지 않는 옵션이므로 사용이 불가능합니다. (${etc_opt.join(', ')})`);

          return false;
        }
        $scope.data.opt = tempProdOpt;
      }

      if (!['', 'cpang_', 'wmp_'].includes($scope.data.currentOpt) && style_opt_name.length > 2 + Number($scope.data.currentOpt === 'std_')) {
        commonSVC.showMessage(`옵션은 ${2 + Number($scope.data.currentOpt === 'std_')}개까지 지원합니다`);

        return false;
      }

      $scope.style_cd = style_cd;
      const data = angular.copy(pageData);

      data.start = 0;
      data.search_key = ['style_cd'];
      data.search_word = style_cd.toString();
      data.search_type = 'exact';

      data.length = 999999;
      commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/stock/base/list`, data, function(state, data) {
        $scope.preStyleSkuList = _.sortBy(data.results, 'prod_no');
        $scope.inValidAttr = [];
        $scope.applyValid = false;
        changed = true;

        // 조회되는 SKU상품 리스트가 있는경우 스크롤 내림. 2018-03-07 rony
        if ($scope.preStyleSkuList.length > 0) {
          $location.hash('preStyleList');
          $anchorScroll();
        }
      });
    };

    $scope.apply = function() {
      let applyValid = true;

      if (!$scope.style_cd) {
        commonSVC.showMessage('매칭하실 SKU상품을 선택 해주세요.');

        return false;
      }

      if ($scope.styleForm.$valid) {
        // 매칭한 사이즈 옵션값 push

        const data = angular.copy(pageData);

        data.start = 0;
        data.search_key = ['style_cd'];
        data.search_word = $scope.style_cd.toString();
        data.search_type = 'exact';

        data.length = 999999;
        commonSVC.sendUrl('POST', `${settings.pa20ApiUrl}/app/stock/base/list`, data, function(state, data) {
          for (let i = 0; i < $scope.inValidAttr.length; i++) {
            $scope.options.push($scope.inValidAttr[i].validAttr);
          }

          if (changed) {
            $scope.styleSkuList = _.sortBy(data.results, 'prod_no');
          }
          if ($scope.data.currentOpt == 'esm_' && changed) {

            // 선택형이 있을 때
            const nType = $scope.data.opt.filter(opt => opt.cateOpAttr);

            if (nType.length) {
              const attri_type = $scope.styleSkuList[0].attri_type.split(',');

              for (const attrIdx in attri_type) {
                const attr = attri_type[attrIdx];

                for (const prod of $scope.styleSkuList) {
                  const attri = prod.attri.split(',');
                  const opt = nType.find(opt => opt.name === attr);

                  if (opt) {
                    const validOpt = opt.cateOpAttrList.find(cateOp => cateOp.ObjOptEssenName === attri[attrIdx]);

                    //유효한 옵션값
                    if (validOpt) {
                      prod.validAttr = attri[attrIdx];
                      $scope.options.push(attri[attrIdx]);
                    } else {
                      //유효하지 않은 옵션값
                      applyValid = false;
                      prod.inValidAttr = attri[attrIdx];

                      const attrs = {
                        attri: attri[attrIdx],
                        cateOpAttrList: opt.cateOpAttrList,
                        validAttr: '',
                      };

                      attrs[opt.name] = true;

                      $scope.inValidAttr.push(attrs);
                    }
                  }
                }
              }
            }

            // 스크롤 맨 아래로 이동 2018-03-13 rony
            $location.hash('preApply');
            $anchorScroll();
          }

          if (!applyValid) { $scope.inValidAttr = _.uniqBy($scope.inValidAttr, 'attri'); }
          $scope.applyValid = !applyValid;
          changed = false;

          if (applyValid) {
            _.forEach($scope.styleSkuList, function(skuRow) {
              _.forEach($scope.inValidAttr, function(row) {
                if (skuRow.inValidAttr == row.attri) {
                  skuRow.validAttr = row.validAttr;
                  skuRow.cateOpAttr = true;
                }
              });

              if ($scope.data.currentOpt == 'esm_' && !$scope.data.esmDirectOptionEnable) {
                const attr_types = $scope.styleSkuList[0].attri_type.split(',');

                _.forEach(attr_types, function(attr_type, i) {
                  const attr = skuRow.attri.split(',');

                  if (skuRow.validAttr && skuRow.cateOpAttr && skuRow[attr[i]]) {
                    skuRow[`attr${i + 1}`] = skuRow.validAttr;
                    skuRow.attri = skuRow.validAttr;
                  } else {
                    skuRow[`attr${i + 1}`] = attr[i];
                  }
                });
              }
            });

            const returnData = {
              styleSkuList: $scope.styleSkuList,
              options: $scope.options,
              opt: $scope.data.opt
            };

            returnData.opt.forEach((opt, idx) => {
              returnData.opt[idx].attr_list = [];

              // 선택 형인 경우
              if (opt.cateOpAttr && $scope.data.currentOpt == 'esm_' && !$scope.data.esmDirectOptionEnable) {
                returnData.styleSkuList.forEach(sku => {
                  sku.attri_type.split(',').forEach((val, idx) => {
                    // 값별로 재매칭(순서에 맞춰서 맞는값 넣는 작업)
                    if (val === opt.name) {
                      sku[`attr${idx + 1}`] = sku.validAttr;
                      returnData.opt[idx].attr_list.push(sku[`attr${idx + 1}`]);
                    }
                  });

                  sku.attr = `${sku.attr1},${sku.attr2}`;
                });

                returnData.opt[idx].attr = returnData.opt[idx].attr_list.join(',');
              }
            });

            $uibModalInstance.close(returnData);
          }
        });
      }
    };
    $scope.attrChange = function(idx) {
      const flag = _.filter($scope.inValidAttr, function(row) {
        return row.validAttr == $scope.inValidAttr[idx].validAttr;
      });

      if (flag.length > 1) {
        $scope.inValidAttr[idx].validAttr = '';
        commonSVC.showMessage('이미 매칭된 옵션값 입니다.');
      }

      // 원래 else 부분에서 $scope.esm_options.size.push($scope.inValidAttr[idx].validAttr); 처리 있었지만
      // 그러면 셀렉트 박스 선택할때마다 push 되서 $scope.apply 부분에서 push 하도록 수정함
      // 2018-07-27 Alvin

    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 옵션 불러오기 모달
   */
  .controller('importOpt', function ($scope, $rootScope, data, $uibModalInstance, settings, $timeout, shopAccountModel, onlineProductModel, productModel, commonSVC, categoryModel, systemModel) {
    $scope.data = angular.copy(data);
    $scope.detailSearchFlag = false;

    $scope.saveSameCSaleCdYn = $scope.data.detailType !== 'add' && JSON.parse(localStorage.getItem('saveSameCSaleCdYn')); // 동일한 판매자관리코드 조회 체크 적용

    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
        prod_type: 'all',
        opt_type: 'all',
        shop: 'all',
        shop_cd: '',
        shop_id: '',
        type: '',
        sdate: moment().subtract($scope.data.systemList.data.search_date, 'month').format('YYYY-MM-DD'),
        edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
        same_c_sale_cd: '', // 동일한 판매자관리코드 조회
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectType: false,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
        selectCount: 0,      // 검색전체 건수
        selectBundleCnt: 0,  // 선택 묶음건수
        totalCount: 0,       // 검색전체 건수
      },
    };

    // var warehouse_list = warehouseList.data.result || [];

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.use_engines = angular.copy($rootScope.use_channel_list);

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      selectOptions: false,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'prod_type', 'c_sale_cd', 'shop_sale_no', 'shop', 'opt_type', 'sale_status', 'opt_count'],
      alignRightColumns: ['sale_price'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'prod_type', 'shop', 'opt_count'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/online/list-of-avail-import-option`,
        requestWillAction: function(data) {

          if ($scope.searchForm.shop !== 'all') {
            $scope.searchForm.shop_cd = $scope.use_engines[$scope.searchForm.shop].shop_cd;
            $scope.searchForm.shop_id = $scope.use_engines[$scope.searchForm.shop].shop_id;
          }
          $scope.searchForm.type = $scope.data.type;

          // '동일한 판매자관리코드 조회' 설정시 검색조건에 추가해줌
          $scope.searchForm.same_c_sale_cd = $scope.saveSameCSaleCdYn ? $scope.data.c_sale_cd : '';

          data = angular.merge({}, data, $scope.searchForm);

          // 엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 50,
          template: function(row) {
            const location = 'KR';

            return (
              `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.onlineProductEdit('${row.shop_cd}','${row.shop_id}',${row.ol_shop_no},'${location}')">상세</button>`
            );
          }
        },
        {
          key: 'prod_type',
          title: '상품구분',
          width: 100,
          template: function(row) {
            let prod_type = '';

            if (row.shop_cd === 'Z000') {
              prod_type = '마스터상품';
            } else {
              if (row.std_ol_yn === 2) {
                prod_type = '물류상품';
              } else if (row.std_ol_yn === 1) {
                prod_type = '단일상품';
              } else {
                prod_type = '일반상품';
              }
            }

            return prod_type;
          }
        },
        {
          key: 'c_sale_cd',
          title: '판매자관리코드',
          width: 150,
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰상품코드',
          width: 140,

        },
        {
          key: 'shop',
          title: '쇼핑몰(계정)',
          width: 110,
          template: function (row) {
            let shop = row.shop_name;

            if (row.shop_id) {
              shop += `(${row.shop_id})`;
            }

            return shop;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 160
        },
        {
          key: 'opt_type',
          title: '옵션유형',
          width: 100,
        },
        {
          key: 'opt_count',
          title: '추가항목 수',
          width: 100
        },
        {
          key: 'sale_price',
          title: '판매가',
          width: 100
        },
        {
          key: 'sale_status',
          title: '상품상태',
          width: 100,
        }
      ]
    };

    if ($scope.data.type == '추가구매') {
      removeColumns(['opt_type']);
    } else {
      removeColumns(['opt_count']);
    }

    //해당 컬럼 삭제
    function removeColumns(columns) {
      columns.forEach(function (column) {
        const findIndex = $scope.grid.options.columns.findIndex(function (row) {
          return row.key == column;
        });

        if (findIndex > -1) {
          $scope.grid.options.columns.splice(findIndex, 1);
        }
      });
    }

    /**
       * 검색
       */
    $scope.searchDo = function (refresh, noDelay, callback) {
      if ($scope.saveSameCSaleCdYn && $scope.searchForm.search_key === 'c_sale_cd') {
        commonSVC.showMessage('[동일한 판매자관리코드 조회] 설정이 체크된 상태에서\n[판매자관리코드] 필드로 검색이 불가합니다.');

        return false;
      }

      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      $timeout(function () {
        $scope.searchDo(true, true);
      });
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 수정 모달열기
     */
    $scope.onlineProductEdit = function (shop_cd, shop_id, ol_shop_no, location) {
      const resolve = {};
      const data = {};

      data.shop_cd = shop_cd;
      data.shop_id = shop_id;
      data.ol_shop_no = ol_shop_no;
      data.country = location;
      data.warehouseList = $scope.data.warehouseList;

      resolve.data = data;

      resolve.prodDetail = function () {
        return onlineProductModel.detail(shop_cd, shop_id, ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = function () {
        return productModel.getMadein();
      };
      //환경설정 셋팃값
      resolve.systemList = function () {
        return systemModel.load();
      };

      const modal = commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo(false);
        }
      });
    };

    $scope.date_change = function(data, num) {
      if (data === 'DAYS') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - num), 'yyyy-MM-dd');
      } else if (data === 'MONTH') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - num), 'yyyy-MM-dd');
      } else if (data === 'ALL') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date('2001-01-01'), 'yyyy-MM-dd');
      }
      $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
    };

    $scope.apply = function() {
      const selectedData = $scope.grid.methods.selectedData()[0];

      if (!selectedData) {
        commonSVC.showMessage('상품을 선택하세요.');

        return false;
      }
      if ($scope.data.currentOpt == 'wmp_' && selectedData.opt_type != '옵션없음') {
        if (selectedData.opt_type == '독립형') {
          commonSVC.showMessage('선택하신 상품은 독립형 옵션으로 위메프2.0 상품에 적용이 불가합니다.');

          return false;
        }
      }
      if (['std_', 'cpang_'].includes($scope.data.currentOpt) && selectedData.opt_type === '독립형') {
        const shop = $scope.data.currentOpt === 'std_' ? '11번가 신규상품' : '쿠팡';
        if ((selectedData.opt_sort1 && selectedData.opt_sort2 && selectedData.opt_sort3) || selectedData.opt_type == '독립형') {
          commonSVC.showMessage(`${shop} 정책상 옵션없음 또는 조합형 옵션으로만 등록이 가능합니다.`);

          return false;
        }
      }

      commonSVC.showConfirm(`선택한 상품의 ${$scope.data.type}옵션을 등록/수정중인 상품에 적용 하시겠습니까?`)
        .then(async function (confirm) {
          if (confirm) {
            const param = {
              ol_shop_no: selectedData.ol_shop_no,
              deal_type: $scope.data.type || '기초상품'
            };
            try {
              const res = await onlineProductModel.getOption(param);

              selectedData.optData = res.data.results?.optData;

              //옵션명, 옵션값, 옵션타입 매치
              Object.assign(selectedData, res.data.results?.optInfo);

              // 동일 판매자관리코드 조회 체크 저장
              if ($scope.data.detailType !== 'add') {
                localStorage.setItem('saveSameCSaleCdYn', $scope.saveSameCSaleCdYn);
              }

              $uibModalInstance.close(selectedData);
            } catch (err) {
              commonSVC.showMessage('옵션정보 호출에 실패 했습니다.');
            }
          }
        });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 옵션 템플릿
   */
  .controller('addOptTemplate', function($scope, $uibModalInstance, $rootScope, settings, $timeout, commonSVC, onlineProductModel, onlineProductSVC) {
    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectType: false,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
        selectCount: 0,      // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
      },
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.searchForm = angular.copy(search.searchForm);

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      selectOptions: false,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'template_no', 'count', 'name', 'mdate'],
      alignRightColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: ['widget', 'count'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/add-option-templates/list`,
        requestWillAction: function(data) {

          data = angular.merge({}, data, $scope.searchForm);

          // 엑셀 모달용 데이터
          $scope.pageData = angular.copy(data);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 150,
          template: function(row) {
            return (
              `${'' +
              '<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addOptTemplateDetail('}${row.template_no}, 'edit')">상세</button>` +
              `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addoptionTemplateCopy(${row.template_no})">복사</button>` +
              `<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.addoptionTemplateDelete(${row.template_no})">삭제</button>`
            );
          }
        },
        {
          key: 'template_no',
          title: '추가구매옵션템플릿코드',
          width: 180,
        },
        {
          key: 'template_name',
          title: '추가구매옵션템플릿명',
          width: 280,
        },
        {
          key: 'count',
          title: '추가항목 수',
          width: 100,
        },
        {
          key: 'name',
          title: '작성자',
          width: 100,
        },
        {
          key: 'mdate',
          title: '최종 수정일',
          width: 200,
        }
      ]
    };

    /**
       * 검색
       */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);

      $timeout(function () {
        $scope.searchDo(true, true);
      });
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    // 옵션템플릿 상세
    $scope.addOptTemplateDetail = function (template_no, type) {
      const resolve = {
        data: {
          template_no: template_no || '',
          type: type || ''
        }
      };
      const redata = commonSVC.openModal('xxg', resolve, 'addOptTemplateDetail', 'views/online/product/option/add_opt_template_detail.html');

      redata.result.then(function (re) {
        if (re == 'success') {
          $scope.searchDo();
        }
      });
    };

    //옵션 템플릿 삭제
    $scope.addoptionTemplateDelete = function (template_no) {
      commonSVC.showConfirm('선택한 추가구매옵션 템플릿을 삭제 하시겠습니까?')
        .then(function (confirm) {
          if (confirm) {
            onlineProductModel.addoptionTemplateDelete({ template_no: template_no })
              .then(function (re) {
                if (re.status == 200 && !!re.data.result) {
                  commonSVC.showToaster('success', '성공', '정상적으로 삭제되었습니다.');
                  $scope.searchDo();
                }
              })
              .catch(function () {
                commonSVC.showToaster('error', '실패', '템플릿 삭제에 실패하였습니다.');
              });
          }
        });
    };

    //옵션 템플릿 복사
    $scope.addoptionTemplateCopy = function (template_no) {
      commonSVC.showConfirm('선택한 추가구매옵션 템플릿을 복사 하시겠습니까?')
        .then(function (confirm) {
          if (confirm) {
            onlineProductModel.addoptionTemplateCopy({ template_no: template_no })
              .then(function (re) {
                if (re.status == 200 && (($rootScope.backend_user && re.data.result == 'success') || re.data.new_template_no)) {
                  commonSVC.showToaster('success', '성공', '정상적으로 복사되었습니다.');
                  $scope.searchDo();
                }
              })
              .catch(function () {
                commonSVC.showToaster('error', '실패', '템플릿 복사에 실패하였습니다.');
              });
          }
        });
    };

    $scope.apply = function () {
      const template_no = $scope.grid.methods.selectedData('template_no')[0];

      commonSVC.showConfirm('선택한 템플릿의 옵션을 등록/수정중인 상품에 적용 하시겠습니까?')
        .then(function (confirm) {
          if (confirm) {
            onlineProductModel.addoptionTemplateDetail({ template_no: template_no })
              .then(function (re) {
                const return_data = {};

                const addOptData = onlineProductSVC.initAddOpt(re.data.result.addOptData);

                return_data.add_opt = addOptData.add_opt;
                return_data.add_opt_table = addOptData.add_opt_table;

                $uibModalInstance.close(return_data);
              });
          }
        });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

  })

  //추가구매옵션 템플릿 상세
  .controller('addOptTemplateDetail', function ($scope, data, $uibModalInstance, onlineProductModel, commonSVC, onlineProductSVC, $timeout, userInfo) {
    $scope.sol_stock_auth = userInfo.user.sol_stock > 0 && (userInfo.user.auth_type === '총괄관리자' || userInfo.permission?.stock?.use_yn);

    $scope.data = angular.copy(data);

    $scope.data.add_opt = [{}];          // 추가 구매 옵션
    $scope.data.add_opt_table = [];      // 추가 구매 옵션 테이블

    init();

    function init() {
      if ($scope.data.type == 'edit') {

        onlineProductModel.addoptionTemplateDetail({ template_no: $scope.data.template_no })
          .then(function (re) {
            $scope.data = angular.merge({}, $scope.data, angular.copy(re.data.result));

            const addOptData = onlineProductSVC.initAddOpt($scope.data.addOptData);

            $scope.data.add_opt = addOptData.add_opt;
            $scope.data.add_opt_table = addOptData.add_opt_table;      // 추가 구매 옵션 테이블
          });
      } else {
        $scope.data.add_opt = [{}];          // 추가 구매 옵션
        $scope.data.add_opt_table = [];      // 추가 구매 옵션 테이블
      }
    }

    $scope.apply = function () {
      commonSVC.showConfirm('저장하시겠습니까?')
        .then(function (confirm) {
          if (confirm) {
            if (!$scope.data.template_name) {
              throw '추가구매옵션 템플릿명을 확인하세요.';
            }
            if (!$scope.data.add_opt_table.length) {
              throw '추가구매옵션을 생성하세요.';
            }

            let checkValid = true;

            for (const i in $scope.data.add_opt_table) {
              const addProd = $scope.data.add_opt_table[i];

              // 추가금액의 경우 0원이 가능해서 따로 처리
              if (!addProd.name || !addProd.opName || (!addProd.opPrice && addProd.opPrice !== 0)) {
                checkValid = false;
              }
            }

            if (!checkValid) {
              throw '추가구매옵션 정보를 확인하세요.';
            }

            if ($scope.data.type == 'edit') {
              onlineProductModel.addoptionTemplateEdit($scope.data)
                .then(function (re) {
                  if (re.status == 200) {
                    commonSVC.showToaster('success', '성공', '추가구매옵션 템플릿이 수정되었습니다.');
                    $uibModalInstance.close('success');
                  }
                })
                .catch(function () {
                  commonSVC.showToaster('error', '실패', '추가구매옵션 템플릿 수정에 실패하였습니다.');
                  $uibModalInstance.dismiss('cancel');
                });
            } else {
              onlineProductModel.addoptionTemplateAdd($scope.data)
                .then(function (re) {
                  if (re.status == 200) {
                    commonSVC.showToaster('success', '성공', '추가구매옵션 템플릿이 등록되었습니다.');
                    $uibModalInstance.close('success');
                  }
                })
                .catch(function () {
                  commonSVC.showToaster('error', '실패', '추가구매옵션 템플릿 등록에 실패하였습니다.');
                  $uibModalInstance.dismiss('cancel');
                });
            }
          }
        })
        .catch((errMsgs) => {
          $timeout(() => {
            commonSVC.showMessage(errMsgs);
          }, 100);
        });
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 기존상품 추가항목 불러오기 모달
   */
  .controller('importSetInfoCtrl', function ($scope, $rootScope, data, systemList, $uibModalInstance, settings, $timeout, commonSVC) {
    $scope.data = angular.copy(data);

    const seller_nick_info = [];

    $rootScope.useChannelList({ func: shop => shop.shop_cd == $scope.data.shop_cd }).forEach(chRow => {
      seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
    });

    // disabled된 항목 있는지 여부
    const hasDisabled = data.hasDisabled;

    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectType: false,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
        selectCount: 0,      // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
      },
    };

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      emptyText: '불러올 추가항목이 없습니다.',
      emptyTextSub: '지금 작업중인 상품과 동일한 쇼핑몰, 동일한 카테고리 상품만 리스트에 노출됩니다.',
      selectOptions: false,
      pinningColumns: [],
      alignCenterColumns: ['c_sale_cd', 'sale_img1', 'shop'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: ['shop', 'sale_img1', 'shop_sale_no', 'shop_sale_name'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/set-info/list-of-avail-import-set-info`,
        requestWillAction: function(data) {
          data.shop_cate_no = $scope.data.shop_cate_no;
          data.ol_shop_no = $scope.data.ol_shop_no;
          data.std_ol_yn = $scope.data.std_ol_yn;

          data = angular.merge({}, data, $scope.searchForm);
          data.shop_cd = $scope.data.shop_cd;

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'shop',
          title: '쇼핑몰(계정)',
          width: 110,
          template: function (row) {
            const shop_info = commonSVC.getShopIdViewText(systemList.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

            return `<span uib-tooltip="${row.shop_name}(${shop_info[0]})" tooltip-append-to-body="true" tooltip-placement="right">
              <img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">
              ${shop_info[1]}
            </span>`;
          }
        },
        {
          key: 'sale_img1',
          title: '이미지',
          width: 50,
          important: true,
          notCompile: true,
          template: function(row) {
            let img = '';

            if (row.sale_img_url) {
              img += row.sale_img_url || '';
              img += row.sale_img1 || '';
            } else {
              img = '/assets/images/noimage.png';
            }

            return `<img src='${img}' width='20' height='20' onerror='this.src="/assets/images/noimage.png"'>`;
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰상품코드',
          width: 140,
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 200
        },
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);
      $timeout(function () {
        $scope.searchDo(true, true);
      });
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    $scope.date_change = function(data, num) {
      if (data === 'DAYS') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setDate(new Date().getDate() - num), 'yyyy-MM-dd');
      } else if (data === 'MONTH') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date().setMonth(new Date().getMonth() - num), 'yyyy-MM-dd');
      } else if (data === 'ALL') {
        $scope.searchForm.sdate = commonSVC.getDate(new Date('2001-01-01'), 'yyyy-MM-dd');
      }
      $scope.searchForm.edate = commonSVC.getDate(new Date(), 'yyyy-MM-dd');
    };

    /**
     * 적용
     */
    $scope.apply = function() {
      if (hasDisabled) {
        commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

        return false;
      }

      const selectedData = $scope.grid.methods.selectedData()[0];

      if (!selectedData) {
        commonSVC.showMessage('상품을 선택하세요.');

        return false;
      }

      $uibModalInstance.close(selectedData.set_info);
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 추가항목 템플릿
   */
  .controller('setInfoTemplateCtrl', function($scope, $rootScope, $uibModalInstance, data, systemList, settings, $timeout, commonSVC, onlineProductModel) {
    $scope.data = angular.copy(data);

    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.shop_id = data.shop_id;
    $scope.pa_shop_cd = data.pa_shop_cd;
    $scope.seller_nick = data.seller_nick;

    // disabled된 항목 있는지 여부
    const hasDisabled = data.hasDisabled;

    // shop_cate_no_add_info 혹은 sol_cate_shop_add_info 조회
    onlineProductModel.getCateAddInfo({ shop_cd: data.shop_cd, shop_id: data.shop_id, shop_cate_no: data.shop_cate_no, ol_shop_no: data.data.ol_shop_no })
      .then(re => {
        $scope.data.shop_data.match_cate_code = re.data.result.category_code;
      });

    const search = {
      searchForm: {
        search_key: 'all',
        search_word: '',
      },
      searchData: {
        all_checked: false,  // 전체선택 여부
        selectType: false,    // 셀렉트카운팅시 번들갯수 말고 row별로 처리 여부
        selectCount: 0,      // 검색전체 건수
        totalCount: 0,       // 검색전체 건수
      },
    };

    $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function(count) {
        $scope.changeCount(count);
      }
    };

    $scope.searchForm = angular.copy(search.searchForm);
    $scope.searchData = angular.copy(search.searchData);

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      emptyText: '불러올 추가항목 양식이 없습니다.',
      emptyTextSub: '지금 작업중인 상품과 동일한 쇼핑몰, 동일한 카테고리 상품만 리스트에 노출됩니다.',
      selectOptions: false,
      pinningColumns: ['widget'],
      alignCenterColumns: ['widget', 'default_yn', 'bookmark_yn'],
      alignRightColumns: [],
      defaultSortingColumns: [],
      notSortingColumns: ['widget', 'template_name', 'wdate', 'mdate', 'name', 'default_yn', 'bookmark_yn'],
      notResizingColumns: ['widget'],
      notMovingColumns: [],
      notVisibleColumns: [],
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/product/set-info/list`,
        requestWillAction: function(data) {
          data = angular.merge({}, data, $scope.searchForm);
          data.shop_cate_no = $scope.data.shop_cate_no;
          data.shop_cd = $scope.data.shop_cd;
          data.pa_shop_cd = $scope.data.pa_shop_cd;
          data.std_ol_yn = $scope.data.data.std_ol_yn;

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.recordsTotal;
          $scope.searchData.default_template_no = result.default_template_no;
          $scope.searchData.bookmarkTotal = result.bookmarkTotal;

          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '도구',
          width: 120,
          template: function(row) {
            return `
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.setInfoTemplateDetail(${row.template_no}, '${row.template_name}', '${row.shop_id}')">상세</button>
              <button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.setInfoTemplateDelete(${row.template_no}, ${row.default_yn})">삭제</button>
            `;
          }
        },
        {
          key: 'template_name',
          title: '추가항목 양식명',
          width: 280,
        },
        {
          key: 'wdate',
          title: '등록일',
          width: 140,
        },
        {
          key: 'mdate',
          title: '수정일',
          width: 140,
        },
        {
          key: 'name',
          title: '최종수정자',
          width: 120,
        },
        {
          key: 'default_yn',
          title: '기본',
          width: 90,
          template: function (row) {
            const isDefault = row.template_no === $scope.searchData.default_template_no;

            return `<input type="radio" name="default_template" value="${row.template_no}" ng-checked="${isDefault}" ng-click="grid.appScope.setDefault(${row.template_no})">`;
          }
        },
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 80,
          template: function (row) {
            row.bookmark_yn = !!row.bookmark_yn;

            return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.setBookmark(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    /**
     * 검색
     */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.grid.methods.reloadData(function () {
        if (callback) {
          callback();
        }
      }, refresh, noDelay);
    };

    /**
     * 검색 초기화
     */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(search.searchForm);

      $timeout(function () {
        $scope.searchDo(true, true);
      });
    };

    /**
     * 데이터테이블 pageLength 조절
     */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
     * 기본 양식 변경
     */
    $scope.setDefault = async template_no => {
      if ($scope.searchData.default_template_no) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 양식을 기본으로 설정하시겠습니까?',
          text: '이미 등록된 추가항목 기본 양식이 존재합니다.\n\'설정\' 클릭 시 상품의 추가항목 등록 시 현재 입력한 양식의 내용을 기본으로 불러옵니다.',
          confirmButtonText: '설정'
        });

        if (!confirm) {
          $(`input:radio[name="default_template"]:radio[value="${$scope.searchData.default_template_no}"]`).prop('checked', true);

          return false;
        }
      }

      const param = {
        template_no,
        shop_cate_no: $scope.data.shop_cate_no,
        default_yn: true,
        type: 'default',
        std_ol_yn: $scope.data.data.std_ol_yn
      };

      try {
        const re = await onlineProductModel.setInfoTemplateEdit(param);

        if (re.data.result === 'success') {
          $scope.searchData.default_template_no = template_no;
          commonSVC.showToaster('success', '성공', '기본양식이 변경되었습니다.');

          // 추가항목 간편양식 리스트 갱신
          updateSetInfoTemplateList();
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '기본양식 변경에 실패하였습니다.');
      }

      $scope.searchDo(true, false);
    };

    /**
     * 즐겨찾기 여부 변경
     */
    $scope.setBookmark = async row => {
      if ($scope.searchData.bookmarkTotal === 10 && row.bookmark_yn) {
        commonSVC.showConfirmCustom({
          title: '즐겨찾기 개수가 초과되었습니다.',
          text: '즐겨찾기는 최대 10개까지만 선택이 가능합니다.\n추가항목 양식 리스트에서 즐겨찾기를 다시 설정해 주시기 바랍니다.',
          showCancelButton: false
        });

        return false;
      }

      if (row.bookmark_yn) {
        $scope.searchData.bookmarkTotal++;
      } else {
        $scope.searchData.bookmarkTotal--;
      }

      try {
        const re = await onlineProductModel.setInfoTemplateEdit({
          template_no: row.template_no,
          bookmark_yn: row.bookmark_yn,
          type: 'bookmark',
          std_ol_yn: $scope.data.data.std_ol_yn
        });

        if (re.data.result === 'success') {
          commonSVC.showToaster('success', '성공', '즐겨찾기 수정에 성공하였습니다.');

          // 추가항목 간편양식 리스트 갱신
          updateSetInfoTemplateList();
        }
      } catch (error) {
        // 카운트 롤백
        if (row.bookmark_yn) {
          $scope.searchData.bookmarkTotal--;
        } else {
          $scope.searchData.bookmarkTotal++;
        }

        commonSVC.showToaster('error', '실패', '즐겨찾기 수정에 실패하였습니다.');

        return false;
      }
    };

    /**
     * 추가항목 템플릿 상세
     */
    $scope.setInfoTemplateDetail = (template_no, template_name, shop_id) => {
      const resolve = {
        data: {
          template_no: template_no || '',
          template_name: template_name,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no,
          default_template_no: $scope.searchData.default_template_no,
          shop_data: { ...$scope.data.shop_data, shop_id },
          openTemplate: $scope.data.openTemplate,
          systemList,
          madeinList: $scope.data.madeinList,
          data: $scope.data.data,
          detailType: $scope.data.detailType,
          templateList: { data: { result: $scope.data.templateList } }
        }
      };
      const modal = commonSVC.openModal('xxg', resolve, 'setInfoDetailCtrl', 'views/online/product/modals/set_info_template_detail.html');

      modal.result.then(re => {
        if (re === 'success') {
          $scope.searchDo();

          // 추가항목 간편양식 리스트 갱신
          updateSetInfoTemplateList();
        }
      });
    };

    /**
     * 추가항목 간편양식 리스트 갱신
     */
    const updateSetInfoTemplateList = async () => {
      try {
        $timeout(async () => {
          // 추가항목 모달의 간편양식 리스트 갱신을 위해 호출
          const result = await onlineProductModel.setInfoTemplateList({
            shortListYn: true,
            shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
            std_ol_yn: $scope.data.data.std_ol_yn,
            shop_cd: $scope.data.pa_shop_cd,
            pa_shop_cd: $scope.data.pa_shop_cd
          });

          // 추가항목 모달로 간편양식리스트 전달
          $rootScope.$emit('setInfoTemplateUpdate', result.data.results);
        }, 500);
      } catch (err) {}
    };

    /**
     * 추가항목 템플릿 삭제
     */
    $scope.setInfoTemplateDelete = async (template_no, default_yn) => {
      if (default_yn) {
        commonSVC.showConfirm('삭제하시겠습니까?', '\'기본\'으로 설정된 양식은 삭제가 불가합니다.');
        commonSVC.showConfirmCustom({
          title: '삭제하시겠습니까?',
          text: '\'기본\'으로 설정된 양식은 삭제가 불가합니다.',
          showCancelButton: false
        });

        return false;
      }

      const confirm = await commonSVC.showConfirm('삭제하시겠습니까?', '삭제시 해당 리스트에서 추가항목 양식을 관리할 수 없습니다.\n정말 삭제하시겠습니까?');

      if (confirm) {
        try {
          const re = await onlineProductModel.setInfoTemplateDelete(({ template_no }));

          if (re.data.result === 'success') {
            commonSVC.showToaster('success', '성공', '정상적으로 삭제되었습니다.');
            $scope.searchDo();

            // 추가항목 간편양식 리스트 갱신
            updateSetInfoTemplateList();
          }
        } catch (err) {
          commonSVC.showToaster('error', '실패', '양식 삭제에 실패했습니다.');
        }
      }
    };

    /**
     * 적용
     */
    $scope.apply = function() {
      if (hasDisabled) {
        commonSVC.showMessage('수정 불가능한 항목이 있는 경우 양식을 사용할 수 없습니다.\n값을 직접 수정해주십시오.');

        return false;
      }

      const selectedData = $scope.grid.methods.selectedData()[0];

      if (!selectedData) {
        commonSVC.showMessage('양식을 선택하세요.');

        return false;
      }

      $uibModalInstance.close(selectedData.template_data);
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  /**
   * 추가항목 템플릿 상세
   */
  .controller('setInfoDetailCtrl', function ($state, $controller, $scope, $timeout, data, $uibModalInstance, onlineProductModel, commonSVC) {
    $scope.row = angular.copy(data);

    // 컨트롤러 생성
    $scope.channelCtrl = $controller(`olProdShopDetail-${$scope.row.pa_shop_cd}-Ctrl`, {
      $scope: $scope,
      $uibModalInstance: $uibModalInstance,
      data: {
        shop_data: data.shop_data,
        openTemplate: data.openTemplate,
        madeinList: data.madeinList,
        data: $scope.row.data,
        detailType: $scope.row.detailType,
        from: 'templateList'
      },
      systemList: data.systemList || {},
      templateList: async () => {
        return await onlineProductModel.setInfoTemplateList({
          shortListYn: true,
          shop_cate_no: data.shop_cate_no || data.sol_shop_cate_no,
          shop_cd: data.pa_shop_cd
        });
      }
    }, true, false, true);

    $scope.setDefault = async () => {
      const title = $scope.row.saveAsDefaultYn ? '기본 양식으로 설정하시겠습니까?' : '기본 양식 설정을 해제 하시겠습니까?';
      const text = $scope.row.saveAsDefaultYn ? '아래 [확인] 버튼 클릭 후 양식 [저장] 시 현재 작성된 양식을 기본으로 설정합니다.\n 이 후 생성되는 모든 상품에 기본으로 설정된 정보가 상품에 자동으로 반영됩니다.' : '아래 [확인] 버튼 클릭 후 양식 [저장] 시 기본 설정이 해제됩니다.\n 기본값이 해제되어도 언제든 양식으로 설정된 정보는 불러올 수 있습니다.';

      const confirm = await commonSVC.showConfirmCustom({
        title,
        text,
        confirmButtonText: '설정'
      });

      if (!confirm) {
        $scope.row.saveAsDefaultYn = !$scope.row.saveAsDefaultYn;
        $timeout(() => {});

        return false;
      }
    };

    init();

    async function init() {
      try {
        const re = await onlineProductModel.setInfoTemplateDetail({ template_no: $scope.row.template_no });

        $scope.row = angular.merge({}, $scope.row, angular.copy(re.data.result));

        $scope.row.saveAsDefaultYn = re.data.result.default_yn === '1';

        if ($scope.row.pa_shop_cd === 'B378') {
          cpangOptConvert('init');

          // 인증서류 첨부파일 정리
          const fileKeys = ['safeInfoImg8', 'safeInfoImg4'];

          $scope.row.etc_files = [[], []];

          for (const idx in fileKeys) {
            const fileKey = fileKeys[idx];

            for (let i = 20; i > 1; i--) {
              const key = `key_${fileKey}_${i}_filename`;

              if ($scope.row.set_info[key] == undefined) {
                continue;
              }

              if ($scope.row.set_info[key]) {
                $scope.row.etc_files[idx].unshift({ files: { name: $scope.row.set_info[key] } });
              } else {
                $scope.row.etc_files[idx].unshift({});
              }

              if (fileKey === 'safeInfoImg4') {
                break;
              }
            }
            if ($scope.row.set_info[`key_${fileKey}_file`]) {
              $scope.row.etc_files[idx].unshift({ files: { name: $scope.row.set_info[`key_${fileKey}_filename`] } });
            } else {
              $scope.row.etc_files[idx].unshift({});
            }
          }
        }

        $timeout(() => {});
      } catch (err) {
        commonSVC.showToaster('error', '실패', '추가항목 양식 조회에 실패하였습니다.');
      }
    }

    /**
     * 쿠팡 옵션 치환
     */
    const cpangOptConvert = type => {
      if (type === 'init') {
        const siteOptNmData2 = $scope.row.set_info.key_siteOptNmData2?.split('|'),
              optNmData2 = $scope.row.set_info.key_optNmData2?.split('|');

        //카테고리별 검색옵션이 있을경우만 사용
        if (siteOptNmData2.length) {
          $scope.row.attributes = [];
          $scope.row.set_info.key_siteOptObject = {};

          siteOptNmData2.forEach((siteOpt, idx) => {
            if (siteOpt) {
              $scope.row.attributes.push({ attributeTypeName: siteOpt });
              $scope.row.set_info.key_siteOptObject[siteOpt] = optNmData2[idx];
            }
          });
        }
      } else {
        let key_siteOptNmData2 = '',
            key_optNmData2 = '';
        const searchOpt = $scope.row.set_info.key_siteOptObject || {};

        //카테고리별 검색옵션이 있을경우만 사용
        if ($scope.row.attributes) {
          $scope.row.attributes.forEach(function(opt) {
            key_siteOptNmData2 += `${opt.attributeTypeName}|`;
            if (opt.attributeTypeName in searchOpt) {
              key_optNmData2 += searchOpt[opt.attributeTypeName];
            }
            key_optNmData2 += '|';
          });
        }
        $scope.row.set_info.key_siteOptNmData2 = key_siteOptNmData2;
        $scope.row.set_info.key_optNmData2 = key_optNmData2;
      }
    };

    /**
     * 저장
     */
    $scope.apply = async () => {
      if (!!$scope.row.saveAsDefaultYn && $scope.row.default_template_no && $scope.row.default_template_no !== $scope.row.template_no) {
        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 양식을 기본으로 설정하시겠습니까?',
          text: '이미 등록된 추가항목 기본 양식이 존재합니다.\n\'설정\' 클릭 시 상품의 추가항목 등록 시 현재 입력한 양식의 내용을 기본으로 불러옵니다.',
          confirmButtonText: '설정'
        });

        if (!confirm) {
          return false;
        }
      }

      if ($scope.row.pa_shop_cd === 'B378') {
        cpangOptConvert('edit');
      }

      const param = {
        template_no: $scope.row.template_no,
        shop_cate_no: $scope.row.shop_cate_no,
        set_info: $scope.row.set_info,
        default_yn: $scope.row.saveAsDefaultYn,
        type: 'detail',
        std_ol_yn: $scope.row.std_ol_yn
      };

      try {
        const re = await onlineProductModel.setInfoTemplateEdit(param);

        if (re.data.result === 'success') {
          commonSVC.showToaster('success', '성공', '추가항목 양식이 수정되었습니다.');
          $uibModalInstance.close('success');
        }
      } catch (err) {
        commonSVC.showToaster('error', '실패', '추가항목 양식 수정에 실패하였습니다.');
      }
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  })

  .controller('ProdSimpleAddCtrl', function ($scope, data, $uibModalInstance, productSVC, madeinList, warehouseList, productModel, commonSVC, $state, localStorageService) {
    const regx = new RegExp(/^[a-zA-Z0-9,./_+=()-]*$/);
    $scope.nation = 'KR';
    $scope.prodCount = data.length;
    $scope.singleOptCustomCd = data.opt_custom_cd[0] ? (regx.test(data.opt_custom_cd[0]) ? data.opt_custom_cd[0] : null) : null;
    $scope.isNullOptCustomCdList = (data.opt_custom_cd.some(v => v) && $scope.prodCount > 1);   // 상품 옵션 중 옵션관리코드가 1개 이상이고, 기초상품 개수가 2개이상
    $scope.isAuto = true;
    $scope.prod_data = {
      skuAuto: $scope.isAuto,
      sku_cd: $scope.prodCount === 1 ? $scope.singleOptCustomCd : data.opt_custom_cd,
      save_type: 'prod',
      tax_type: '일반과세',
      madein_no: null,
      madein_etc: null,
      sale_price: 0,
      modify_real_stock: 0,
      delivery_vendor: null,
      depot_name: '',
      madein_type: '국내',
      madein_group: '',
      madein_name: '',
      contents: '',
      autoCreate: (!$scope.isNullOptCustomCdList && $scope.prodCount > 1) ? 'auto' : (localStorageService.get('autoCreate') || 'auto')
    };

    // 선택 row가 2개 이상이거나 전체인 경우 SKU 중복 될 수 없으므로 자동생성으로 강제 처리
    if (data?.length > 1 || $scope.isNullOptCustomCdList) {
      $scope.sku_disabled = true;
      $scope.prod_data.save_type = 'prod';
      $scope.save_type_disabled = true;
    }

    $scope.isSmileProd = data.isSmileProd;
    $scope.warehouseList = warehouseList.data.result || [];          // 배송처 리스트

    if ($scope.isSmileProd) {
      $scope.warehouseList = $scope.warehouseList.filter(o => o.ebaydepot_yn);
    }

    $scope.madeinList = madeinList.data.result;                  //원산지 리스트
    $scope.madeLocalList = [];                                     //국가
    $scope.madeLocalDetailList = [];                               //지역

    $scope.taxTypes = productSVC.taxTypes;                         //과세구분 리스트

    // 원산지 대분류 선택
    $scope.selectMadein1 = function (val) {
      $scope.madeinCountryList = $scope.madeinList[val];
      $scope.prod_data.madein_etc = '';
    };

    // 원산지 중분류 선택
    $scope.selectMadein2 = function (val) {
      $scope.madeinCityList = $scope.madeinCountryList[val];
    };

    // 원산지 값 세팅 ( encode : madeIn 에 값 넣어주기 , decode : madeIn에 값 prodData 에 넣어주기 )
    function settingMadein(mode) {
      if (mode === 'encode') {
        if ($scope.prod_data.madein_type != '기타') {
          const madein_list = $scope.madeinList[$scope.prod_data.madein_type][$scope.prod_data.madein_group];

          for (const i in madein_list) {
            if (madein_list[i].detail == $scope.prod_data.madein_name) {
              $scope.prod_data.madein_no = madein_list[i].number;
              $scope.prod_data.madein_etc = null;
              $scope.prod_data.madein_opt = null;
            }
          }
        } else {
          $scope.prod_data.madein_no = null;
        }
      } else {
        if ($scope.prod_data.madein_etc != 'null') {
          $scope.prod_data.madein_type = '기타';
        }
      }
    }

    // 옵션관리코드 빈값체크 및 SKU 중복체크
    $scope.overlabCheck = async function () {
      const sku = $scope.prod_data.sku_cd;
      const regx = new RegExp(/^[a-zA-Z0-9,./_+=()-]*$/);
      const checkDupArr = [];
      if (Array.isArray(sku)) {
        $scope.allMatch = sku.filter(item => !regx.test(item));
        for (let i = 0; i < sku.length; i++) {
          if (!sku[i]) {
            $scope.allMatch.push(sku[i]);
            checkDupArr.push(true);
          } else {
            const re = await productModel.skuOverlabCheck({ code: sku[i] });
            checkDupArr.push(re.data?.result);
            if (re.data?.result) {
              $scope.allMatch.push(sku[i]);
            }
          }

        }

        return !!checkDupArr.some(v => v) || sku.some(v => !v) || $scope.allMatch.length;
      } else {
        if (!regx.exec(sku)) {
          commonSVC.showMessage('', 'SKU 코드는 영문, 숫자, 특수문자 ( ) - _ = + . / , 입력이 가능합니다.');
        } else if (!sku) {
          commonSVC.showMessage('', 'SKU코드를 입력해주시기 바랍니다.');
        } else {
          productModel.skuOverlabCheck({ code: sku }, function (state, result) {
            if (result.data.result) {
              commonSVC.showMessage('', '이미 등록된 SKU코드입니다.');
              $scope.skuOverlabChecked = false;
            } else {
              commonSVC.showMessage('', '등록 가능한 SKU코드입니다.');
              $scope.skuOverlabChecked = true;
            }
          });
        }
      }

    };

    //배송처 관리
    $scope.setWarehouse = function() {
      $state.go('main.stock_warehouse_list');
      $scope.cancel();
    };

    init();
    function init() {
      settingMadein('decode');

    }

    $scope.save = async function () {
      $scope.prod_data.skuAuto = $scope.isAuto;
      // 선택한 row들의 옵션관리코드 중복 체크
      const getDupCheck = Object.values(data.opt_custom_cd
        .reduce((ac, v) => {
          if (!v) { return { ...ac }; }

          return ({ ...ac, [v]: (ac[v] || 0) + 1 });
        }, {}))
        .some(v => v > 1);

      let dupOrNullCheck;

      if ($scope.isNullOptCustomCdList) {
        dupOrNullCheck = await $scope.overlabCheck();
      }
      if (!$scope.prod_data.delivery_vendor) {
        commonSVC.showMessage('배송처 지정 후 저장해 주시기 바랍니다.');

        return;
      }

      if ($scope.prod_data.autoCreate === 'opt') {
        if (data?.length > 1 && getDupCheck) {
          return commonSVC.showMessageHtml('선택한 상품 중에 옵션관리코드가 동일한 상품이 있습니다.', '<ul><li>동일한 옵션관리코드 상품은 1건만 선택하거나 선택 해제 후 다시 시도해 주세요.</li></ul>');
        }
        if (dupOrNullCheck) {
          return commonSVC.showConfirmCustom({
            isHtml: true,
            title: '옵션관리코드가 없거나 SKU코드로 등록할 수 없는 상품이 있습니다.',
            text: `
            <ol>
              <li>옵션관리코드가 없는 상품이 있는지 확인해 주세요.</li>
              <li>SKU코드가 중복되는 상품이 있는지 확인해 주세요.</li>
              <li>SKU코드로 사용할 수 없는 문자(한글, ( ) - _ = + . / , 제외한 특수문자)가 있는지 확인해 주세요.</li>
            </ol>
            <ul>
              <li>위에 해당되는 상품은 SKU코드를 자동생성하여 등록할 수 있습니다.</li>
              <li>등록안함 선택 시 해당 상품 제외 후 나머지 상품은 정상 등록 됩니다.</li>
            </ul>
            `,
            confirmButtonText: 'SKU코드 자동생성하여 등록',
            cancelButtonText: '등록안함',
            width: 700
          }, function (confirm) {
            if (confirm) {
              $scope.prod_data.depot_name = $scope.warehouseList.find(v => v.code == $scope.prod_data.delivery_vendor).warehouse_name;
              if ($scope.form1.$valid) {
                if ($scope.prod_data.skuAuto == false) {
                  if ($scope.skuOverlabChecked == undefined || $scope.skuOverlabChecked == false) {
                    commonSVC.showMessage('SKU코드 중복확인을 하여 주시기 바랍니다.');

                    return;
                  }

                } else if ($scope.prodCount === 1 && $scope.prod_data.skuAuto) {
                  $scope.prod_data.sku_cd = '__AUTO__';
                }
              }
            } else {
              $scope.prod_data.sku_cd = $scope.prod_data.sku_cd.filter(sku => !$scope.allMatch.includes(sku));
            }

            settingMadein('encode'); // 원산지 셋팅
            $uibModalInstance.close($scope.prod_data);
          });
        }
      }
      $scope.prod_data.depot_name = $scope.warehouseList.find(v => v.code == $scope.prod_data.delivery_vendor).warehouse_name;

      if ($scope.form1.$valid) {
        if ($scope.prod_data.skuAuto == false) {
          if ($scope.skuOverlabChecked == undefined || $scope.skuOverlabChecked == false) {
            commonSVC.showMessage('SKU코드 중복확인을 하여 주시기 바랍니다.');

            return;
          }

        } else if ($scope.prodCount === 1 && $scope.prod_data.skuAuto) {
          $scope.prod_data.sku_cd = '__AUTO__';
        }

        settingMadein('encode'); // 원산지 셋팅
        $uibModalInstance.close($scope.prod_data);
      }
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.checkSkuAuto = function () {
      $scope.isAuto = !$scope.isAuto;
    };
  })

  .controller('OnlineProductPackageEditCtrl', function ($scope, $uibModalInstance, $q, commonSVC, onlineProductModel, productSVC, madeinList, warehouseList, data, systemModel, userInfo) {
    $scope.expirationMonths = _.range(1, productSVC.expirationMonth); //유통기한 개월수 리스트(1~50)
    $scope.madeinList = madeinList.data.result || [];                  // 원산지 리스트
    $scope.warehouseList = warehouseList.data.result;
    $scope.madein_group_list = [];                                         // 원산지 그룹
    $scope.madein_name_list = [];                                          // 원산지 지역
    $scope.shop_sale_name_yn = '';
    $scope.ol_products = data.ol_products;
    $scope.stock_use_yn = userInfo.permission.stock.use_yn;
    $scope.data = {
      ol_product_list: data.ol_products,
      std_ol_yn: data.std_ol_yn,
      sol_stock: data.sol_stock,
      is_master_prod: data.is_master_prod,
      prod_noti_data_new: [],

      // 판매가능재고로 수정
      apply_stock_cnt_yn: '0',
      // 추가구매옵션 상품 포함
      apply_add_stock_cnt_yn: true,

      // number타입으로 설정 시 ptgui-regex-mask 미적용되는 이슈 있어 string타입으로 설정
      cost_price: '0',
      supply_price: '0',
      street_price: '0',
      expire_add_month: '52',
      adminAuto: false,
      c_sale_cd: '',
      depot_info: '',
      depot_no: 0,
      depot_name: '',

      sale_modification: 'won',
      sale_operator: 'fix',
      sale_unit: '1',
      sale_unit_option: 'round',

      supply_modification: 'won',
      supply_operator: 'fix',
      supply_unit: '1',
      supply_unit_option: 'round',

      cost_modification: 'won',
      cost_operator: 'fix',
      cost_unit: '1',
      cost_unit_option: 'round',

      street_modification: 'won',
      street_operator: 'fix',
      street_unit: '1',
      street_unit_option: 'round'
    };

    $scope.overlapFlag = null;
    $scope.$watch('data.c_sale_cd', function () {
      $scope.overlapFlag = null;
    });
    /**
     * 판매가, 공급가, 원가, 시중가 '원', '%' 선택 시 기본옵션 값 설정
     */
    $scope.$watch('data.sale_modification', function () {
      $scope.data.sale_operator = $scope.data.sale_modification == 'won' ? 'fix' : '+';
    });

    $scope.$watch('data.supply_modification', function () {
      $scope.data.supply_operator = $scope.data.supply_modification == 'won' ? 'fix' : '+';
    });

    $scope.$watch('data.cost_modification', function () {
      $scope.data.cost_operator = $scope.data.cost_modification == 'won' ? 'fix' : '+';
    });

    $scope.$watch('data.street_modification', function () {
      $scope.data.street_operator = $scope.data.street_modification == 'won' ? 'fix' : '+';
    });

    // 원산지 국가 선택
    $scope.madein_type_change = function() {
      $scope.madein_group_list = $scope.madeinList[$scope.data.madein_type];
      $scope.data.madein_group = '';
      $scope.data.madein_name = '';
      $scope.data.madein_etc = '';
    };

    // 원산지 도시 선택
    $scope.madein_country_change = function() {
      $scope.madein_name_list = $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group];
      $scope.data.madein_etc = '';
    };

    // 배송처 선택시
    $scope.depotNoChange = function () {
      const depot_info = JSON.parse($scope.data.depot_info) || {};

      $scope.data.depot_no = depot_info.code;
      $scope.data.depot_name = depot_info.warehouse_name;
    };

    // 상품정보제공고시 모달 열기
    let temp_list = [];

    /**
     * 상품정보제공고시 선택 모달 오픈
     */
    $scope.openProdNotiInfoModal = function () {
      const resolve = {};

      resolve.data = {
        temp_list: temp_list,
        type: 'add'
      };
      const modal = commonSVC.openModal('lg', resolve, 'OnlineProductProdInfoCtrl', 'views/online/product/prod_info.html');

      modal.result.then(function (re) {
        temp_list = re;
        for (const i in re) {
          if (re[i].infoCode) {
            var array_result = {};

            $scope.data.prod_info_opt = re[i].infoName;

            //체인지 이벤트 발생 안하기 때문에 직접 처리
            $('#prod_info_opt-error').remove();

            array_result.infoCode = re[i].infoCode;
            array_result.infoName = re[i].infoName;
            array_result.infoDetail = {};

            re[i].infoDetail.forEach(list => {
              list.detail.map(v => {
                array_result.infoDetail[v.code] = v.value;
              });
            });
            $scope.data.prod_noti_data_new[i] = array_result;
          }
          else { $scope.data.prod_noti_data_new[i] = ''; }
        }
      });
    };

    $scope.open_desc = function() {
      const resolve = {};

      resolve.data = {
        detail_desc: $scope.data.detail_desc
      };

      const modal = commonSVC.openModal('lg', resolve, 'OnlineProductPkgContentCtrl', 'views/online/product/modals/pkg_content.html');

      modal.result.then(function (re) {
        if (re) { $scope.data.detail_desc = re; }
      });
    };

    /**
     * 판매자 관리 코드 수정시 동일한 쇼핑몰 계정이 있는지 확인
     */
    $scope.checkDupShopId = function () {
      if ($scope.data.c_sale_cd_yn === true) {
        // 동일한 쇼핑몰 계정의 상품을 선택한 경우 중복체크시 알림
        const shops = _(data.ol_products)
          .map(function (v) {
            return v.shop_cd + v.shop_id;
          })
          .uniq()
          .value();

        if (data.ol_products.length != shops.length) {
          commonSVC.showMessage('실패', '동일한 쇼핑몰계정 상품을 2개 이상 선택하여 수정하는 경우 판매자관리코드\n수정은 불가합니다.\n(판매자관리코드 내 동일 쇼핑몰 계정 상품 1개만 연동가능) ');
          $scope.data.c_sale_cd_yn = false;

          return;
        }
        commonSVC.showConfirmHtml('판매자관리코드 수정 시 주의사항!', `${'<div class="sweet-alert-body">' +
          '<ul class="textbox">'}${
          data.is_master_prod ?
            '<li>해당 마스터상품에 연동된 모든 쇼핑몰 상품의 판매자관리코드가 함께 수정됩니다.</li>' :
            '<li>판매자관리코드 수정 시 해당 쇼핑몰 상품의 코드만 개별 수정되며, 코드가 달라지는 경우 상품이 분리됩니다.</li>' +
          '<li>코드가 동일한 상품은 묶음처리 되며 동일한 *쇼핑몰 계정(ID)* 상품은 2개 이상 매칭이 불가합니다.</li>'
        }<li>주문데이터에 매핑된 판매자관리코드는 일괄 변경되지 않습니다. (주문데이터 내 과거 판매자관리코드 유지)</li>` +
          '<li>각 사이트마다 제공하는 내부 관리코드가 있을 경우, 본 코드 변경으로 추후 문제가 발생할 가능성이 매우 높습니다.</li>' +
          '<li>부득이한 경우가 아니라면 판매자관리코드 변경은 시스템에 많은 혼선을 줄 수 있으므로 자제하여 주십시오.</li>' +
          '<li>모든 사항을 숙지하셨으며, 그래도 변경하시겠습니까?</li>' +
          '</ul>' +
          '</div>')
          .then(function(confirm) {
            if (!confirm) {
              $scope.data.c_sale_cd_yn = false;
            }
          });
      }
    };

    /**
     * 판매가, 공급가, 원가, 시중가 수정 (판매가 매니저)
     *
     * @param {string} type 타입
     * @param {number} price 금액
     * @param {+|-} operator 더하기 or 빼기
     * @param {string} unit 단위
     * @param {string} unit_option 단위옵션
     * @param {string} modification 정수 or %
     * @param {Array} price_result 결과객체
     */
    function price_cal_change(type, price, operator, unit, unit_option, modification) {
      const apply_unit = unit * 10;
      $scope.data.ol_product_list.map((v) => {
        let calPrice;
        if (modification == 'won') {
          if (operator == '+') {
            calPrice = Math[unit_option]((v[type] + +price) / apply_unit) * apply_unit;
          } else if (operator == '-') {
            calPrice = Math[unit_option]((v[type] - +price) / apply_unit) * apply_unit;
          }
        } else {
          if (operator == '+') {
            calPrice = Math[unit_option]((v[type] * (1 + (+price / 100))) / apply_unit) * apply_unit;
          } else {
            calPrice = Math[unit_option]((v[type] * (1 - (+price / 100))) / apply_unit) * apply_unit;
          }
        }

        v.update_price.push({
          [type]: calPrice,
          ol_shop_no: v.ol_shop_no
        });
      });
    }

    $scope.edit = function () {
      // 상세설정 체크 해제 시 단위옵션 초기화
      const { sale_settings_yn, supply_settings_yn, cost_settings_yn, street_settings_yn } = $scope.data;
      if (sale_settings_yn === 0) {
        $scope.data.sale_unit_option = 'round';
        $scope.data.sale_unit = '1';
      }
      if (supply_settings_yn === 0) {
        $scope.data.supply_unit_option = 'round';
        $scope.data.supply_unit = '1';
      }
      if (cost_settings_yn === 0) {
        $scope.data.cost_unit_option = 'round';
        $scope.data.cost_unit = '1';
      }
      if (street_settings_yn === 0) {
        $scope.data.street_unit_option = 'round';
        $scope.data.street_unit = '1';
      }

      /**
       * 판매가, 공급가, 원가, 시중가 input값에 '0' 입력 시 체크
       *
       * @param {string} type 타입
       * @param {number} price 금액
       * @param {string} modification 원 or %
       */
      let flag = true;
      let emptyFlag = true;
      function check_val (type, price, modification) {
        if (price === '') {
          emptyFlag = false;
        } else if (Number(price) === 0) {
          if (type === '판매가') {
            commonSVC.showMessage('실패', '판매가가 0원으로 설정되었습니다. 쇼핑몰로 전송 하시기 전에 판매가가 0원인 상품을 한번 더 확인해 주세요.');
          } else if (modification === '%') {
            commonSVC.showMessage('실패', `${type}는 0%로 설정할 수 없습니다.`);
            flag = false;
          }
        }
      }

      const { sale_price_yn, supply_price_yn, cost_price_yn, street_price_yn, sale_price, sale_modification, supply_price, supply_modification, cost_price, cost_modification, street_price, street_modification } = $scope.data;
      if (sale_price_yn) { check_val('판매가', sale_price, sale_modification); }
      if (supply_price_yn) { check_val('공급가', supply_price, supply_modification); }
      if (cost_price_yn) { check_val('원가', cost_price, cost_modification); }
      if (street_price_yn) { check_val('시중가', street_price, street_modification); }

      if (!emptyFlag) {
        return false;
      }

      if (!flag) {
        return false;
      }

      if ($scope.data.detail_desc_yn && !$scope.data.detail_desc) {
        commonSVC.showMessage('실패', '상세설명 등록을 진행하여 주시기 바랍니다.');

        return false;
      }

      if ($scope.data.c_sale_cd_yn && !$scope.data.adminAuto && $scope.overlapFlag != 'y') {
        $scope.msg = '판매자관리코드 중복확인을 해주세요.';
        $scope.overlapFlag = 'n';

        return false;
      }

      if (!$scope.data?.sale_cnt_limit && $scope.data?.sale_cnt_limit_yn && $scope.data.apply_stock_cnt_yn != 1) {
        commonSVC.showMessage('실패', '쇼핑몰판매수량은 0 보다 커야 합니다.');

        return false;
      }

      if ($scope.form1.$valid && (($scope.data.detail_desc_yn && $scope.data.detail_desc) || !$scope.data.detail_desc_yn)) {
        const keys = Object.keys($scope.data);
        let invalid = false;

        keys.forEach(function(key) {
          if (['sale_price_yn', 'supply_price_yn', 'cost_price_yn', 'street_price_yn'].includes(key) && !$scope.data[key]) {
            $scope.data[key.split('_yn')[0]] = '';
          }
          if (!['std_ol_yn', 'apply_add_stock_cnt_yn', 'apply_stock_cnt_yn'].includes(key) && key.indexOf('_yn') != -1 && $scope.data[key] == true) {

            invalid = true;

            return;
          }
        });

        if (!invalid) {
          commonSVC.showMessage('실패', '수정 항목을 1개 이상 선택하여 주시기 바랍니다.');

          return;
        }

        // 등록될 원산지 번호 정리
        if ($scope.data.madein_type) {
          if ($scope.data.madein_type != '기타' && $scope.data.madein_type !== '원양산') {
            $scope.data.madein_etc = '';
            const madein_list = $scope.madeinList[$scope.data.madein_type][$scope.data.madein_group];

            angular.forEach(madein_list, function(row) { if (row.detail == $scope.data.madein_name) { $scope.data.madein_no = row.number; } });
          } else if ($scope.data.madein_type === '원양산') {
            $scope.data.madein_etc = `${$scope.data.madein_type}=${$scope.data.madein_group}`;
            const madein_list = $scope.madeinList[$scope.data.madein_type];

            angular.forEach(madein_list, function(row) {
              if (row.group == $scope.data.madein_group) {
                $scope.data.madein_no = row.number;
              }
            });
          } else { $scope.data.madein_no = ''; }
        } else { $scope.data.madein_no = ''; }

        let updateProm;

        if (data.is_master_prod) {
          const resolve = {
            slaveProdList: function () {
              return onlineProductModel.getSlaveLinkedMaster(_.map(data.ol_products, 'sale_no').join(','));
            },
            systemList: function () {
              return systemModel.load();
            },
            data: {
              edit_single_prod: false
            }
          };

          const modal = commonSVC.openModal('lg', resolve, 'EditMasterProdCtrl', 'views/online/product/modals/edit_master_prod.html');

          updateProm = modal.result;
        } else {
          updateProm = $q.resolve(true);
        }
        updateProm
          .then(function (re) {
            if (re !== true) {
              for (const i in re.add_ol_shop_no) {
                $scope.data.ol_product_list.push({
                  ol_shop_no: re.add_ol_shop_no[i],
                  pa_shop_cd: re.add_selected_shop[i].pa_shop_cd,
                  shop_cd: re.add_shop_cd[i],
                  shop_id: re.add_shop_id[i],
                  sale_no: re.add_sale_no[i],
                  sale_price: re.add_sale_price[i],
                  supply_price: re.add_supply_price[i] || '',
                  cost_price: re.add_cost_price[i] || '',
                  street_price: re.add_street_price[i] || '',
                  update_price: [],
                  ebay_shop_mas_no: re.add_selected_shop[i].ebay_shop_mas_no,
                  overwrite: true, // 마스터상품을 통한 연동상품 수정일시 true
                  std_ol_yn: re.add_std_ol_yn[i]
                });
              }
            }

            // 판매가 매니저 Calculator
            const { sale_price, supply_price, cost_price, street_price,
              sale_operator, sale_unit, sale_unit_option, sale_modification,
              supply_operator, supply_unit, supply_unit_option, supply_modification,
              cost_operator, cost_unit, cost_unit_option, cost_modification,
              street_operator, street_unit, street_unit_option, street_modification
            } = $scope.data;

            if (sale_price) { price_cal_change('sale_price', sale_price, sale_operator, sale_unit, sale_unit_option, sale_modification); }
            if (supply_price) { price_cal_change('supply_price', supply_price, supply_operator, supply_unit, supply_unit_option, supply_modification); }
            if (cost_price) { price_cal_change('cost_price', cost_price, cost_operator, cost_unit, cost_unit_option, cost_modification); }
            if (street_price) { price_cal_change('street_price', street_price, street_operator, street_unit, street_unit_option, street_modification); }

            onlineProductModel.editBatch($scope.data, function(status, data) {
              if (status == 'success' && data.data.results === 'success') {
                commonSVC.showToaster('success', '성공', '온라인 상품 정보 일괄 수정 성공');
                $uibModalInstance.close('success');
              } else {
                if (data.data.messages[0] === 'ER_DUP') {
                  commonSVC.showMessage('실패', ' 판매자관리코드 중복확인을 해주세요.');
                } else {
                  commonSVC.showMessage('실패', '온라인 상품 정보 일괄 수정 실패');
                }
              }
            });
          });
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.close('cancel');
    };
  })

  .controller('OnlineProductPkgContentCtrl', function ($scope, $uibModalInstance, data) {
    $scope.detail_desc = data.detail_desc;

    $scope.ok = function() {
      $uibModalInstance.close($scope.detail_desc);
    };

    $scope.cancel = function() {
      $uibModalInstance.close();
    };
  })

  .controller('NaverChangeColorCtrl', function ($scope, data, $uibModalInstance, commonSVC) {
    $scope.data = data.color;
    $scope.colorList = $scope.data.selected_attribute;
    $scope.changeColor = $scope.colorList.map(color => color.attributeValueName);
    $scope.submit = () => {
      if (!$scope.form1.$valid) {
        commonSVC.showMessage('전부 입력해주세요');
      } else {
        if ($scope.changeColor.length !== Array.from(new Set($scope.changeColor)).length) {
          commonSVC.showMessage('실패', '중복된 색상명을 사용할 수 없습니다.');

          return false;
        }
        let checkColorName = true;
        $scope.changeColor.forEach((changeColor, index) => {
          if (changeColor !== $scope.colorList[index].attributeValueName) {
            if ($scope.data.sub_group[0].attribute.map(attr => attr.attributeValueName).includes(changeColor)) {
              commonSVC.showMessage(`실패 : ${changeColor}`, '기존에 있는 색상명은 사용할 수 없습니다.');
              checkColorName = false;
            }
          }
        });
        if (checkColorName) {
          $scope.changeColor.forEach((color, index) => {
            $scope.colorList[index].attributeValueName = color;
          });
          $uibModalInstance.close();
        }
      }
    };
    $scope.cancel = () => {
      $uibModalInstance.close();
    };
  })

  /**
   * 연동상품 매칭설정 모달
   */
  .controller('OnlineSkuMapTypeCtrl', function ($scope, data, $uibModalInstance) {
    $scope.re = {
      ol_sku_map_type: data.ol_sku_map_type,
      not_show: data.not_show,
    };

    $scope.ok = function() {
      $uibModalInstance.close($scope.re);
    };

    $scope.close = function() {
      $uibModalInstance.close();
    };
  });