'use strict';

// SSF
angular.module('gmpApp')
  .controller('olProdShopDetail-P054-Ctrl', function ($scope, $rootScope, data, $timeout, $uibModalInstance, systemList, templateList, commonSVC, onlineProductModel, commonModel, shopAccountModel) {
    $scope.shop_id_view_type = systemList.shop_id_view_type;
    $scope.templateList = templateList.data?.results;

    $scope.row = _.cloneDeep(data.shop_data);
    $scope.data = _.cloneDeep(data.data);
    $scope.row.templateDupCheck = false; // 템플릿 양식 중복체크 여부

    $scope.pa5_job_data = {};

    // 인증정보 로드
    const loadQualityDcmtList = async () => {
      // 카테고리 정보가 변경되었을 때 초기화
      if ($scope.row.set_info.kcCrtfcInfo.kcCrtfcTgtCd !== $scope.category_data.kcCrtfcTgtCd) {
        $scope.row.set_info.kcCrtfcInfo = {
          kcCrtfcTgtCd: $scope.category_data.kcCrtfcTgtCd,
        };
      } else {
        // 기존 불러온 데이터가 있으면 
        await $scope.changekcCrtfcExmCd();
      }
    };

    // 컬러칩 로드
    const loadColorChips = async () => {
      await run_pa5_jobs('get_color_chips', '컬러칩 조회', {}, (data) => {
        $scope.colorCds = data;
      });
    };

    const setCategoryDetail = () => {
      // 수정
      // 현재 모달에 있는 '개별 상품 카테고리(직접 설정)'
      const shop_cate_no_data = $scope.data.shop_cate_no_add_info;
      if (shop_cate_no_data) {

        // 상품 수정할 때 최초에는 해당 값이 객체로 오지만 카테고리 수정시 JOSN 데이터로 바뀌어서 타입으로 분기 처리
        if (typeof shop_cate_no_data === 'object' && shop_cate_no_data?.detailData) {
          return shop_cate_no_data.detailData;
        }

        return JSON.parse(shop_cate_no_data).detailData;
      }

      // 쇼핑몰 상품 카테고리
      const sol_cate_shop_detail_data = $scope.data.sol_cate_shop_add_info?.detailData;
      if (sol_cate_shop_detail_data) {

        return sol_cate_shop_detail_data;
      }

      // 등록
      // 상품 최초 등록시 카테고리 값 들어있는 곳
      return JSON.parse($scope.row.shop_cate_no_add_info)?.detailData;
    };

    const init = async () => {
      // 카테고리 데이터 세팅
      $scope.category_data = setCategoryDetail();

      $scope.kcCrtfcTgtCd_table = {
        UTGT: '비대상',
        TGT_EXM_IMPS: '대상(면제 불가)',
        TGT_EXM_PSB: '대상(면제 가능)',
        TGT_SAFE_STDR: '대상(안전 기준)',
      };
      $scope.matrCds = [
        { code: 'M_CT', name: '면' },
        { code: 'M_LN', name: '린넨' },
        { code: 'M_SK', name: '실크' },
        { code: 'M_WW', name: '울' },
        { code: 'M_CM', name: '캐시미어' },
        { code: 'M_SX', name: '스판덱스' },
        { code: 'M_PL', name: '폴리에스터' },
        { code: 'M_NY', name: '나일론' },
        { code: 'M_RY', name: '레이온' },
        { code: 'M_LT', name: '가죽' },
        { code: 'M_SL', name: '합성피혁' },
        { code: 'M_FF', name: '기능성 섬유' },
        { code: 'M_1', name: '기타' },
        { code: 'M_DN', name: '데님' },
        { code: 'M_FU', name: 'fur' },
        { code: 'M_DW', name: '다운' },
        { code: 'M_AP', name: '알파카' },
      ];
      $scope.kcCrtfcSectCds = [
        { code: 'LVLH_SUPLI_SAFE_CRTFC', name: '생활용품-안전인증' },
        { code: 'LVLH_SUPLI_SAFE_CNFIRM', name: '생활용품-안전확인' },
        { code: 'LVLH_SUPLI_SPLR_STBLT', name: '생활용품-공급자적합성확인' },
        { code: 'CHLD_PRD_SAFE_CRTFC', name: '어린이제품-안전인증' },
        { code: 'CHLD_PRD_SAFE_CNFIRM', name: '어린이제품-안전확인' },
        { code: 'CHLD_PRD_SPLR_STBLT', name: '어린이제품-공급자적합성확인' },
        { code: 'ELCTY_SUPLI_SAFE_CRTFC', name: '전기용품-안전인증' },
        { code: 'ELCTY_SUPLI_SAFE_CNFIRM', name: '전기용품-안전확인' },
        { code: 'ELCTY_SUPLI_SPLR_STBLT', name: '전기용품-공급자적합성확인' },
        { code: 'RD_WV_PRD_CRTFC', name: '전파사용제품-적합인증' },
        { code: 'RD_WV_PRD_CNFIRM', name: '전파사용제품-적합확인' },
      ];

      await Promise.all([loadColorChips(), loadQualityDcmtList()]);
    };

    const save = () => {
      const kc = Object.entries($scope.row.set_info.kcCrtfcInfo?.qualityDcmtList || {});
      for (const [kcCrtfcSectCd, value] of kc) {
        const dcmtSns = Object.keys(value);
        for (const dcmtSn of dcmtSns) {
          if ($scope.qualityDcmtList && !$scope.qualityDcmtList.find(data => data.dcmtSn === dcmtSn && data.kcCrtfcSectCd === kcCrtfcSectCd)) {
            delete $scope.row.set_info.kcCrtfcInfo.qualityDcmtList[dcmtSn];
          }
          if ($scope.category_data.stdCtgryQltyDcmtList && !$scope.category_data.stdCtgryQltyDcmtList.find(data => data.dcmtSn === dcmtSn && data.dcmtKcCrtfcSectCd === kcCrtfcSectCd)) {
            delete $scope.row.set_info.kcCrtfcInfo.qualityDcmtList[dcmtSn];
          }
        }
      }

      // 유통기한 관리
      if ($scope.category_data.distbTmlmtManageYn === 'Y') {
        if (!$scope.row.set_info.distbTmlmtManageYn) {
          commonSVC.showMessage('유통기한 관리 여부를 확인하세요');

          return false;
        }

        if ($scope.row.set_info.distbTmlmtManageYn === 'Y' && !$scope.row.set_info.distbTmlmtDate) {
          commonSVC.showMessage('유통기한을 확인하세요');

          return false;
        }
      }

      // 컬러칩
      if ($scope.row.set_info.dsgnGrpNo && !$scope.row.set_info.colorCd) {
        commonSVC.showMessage('디자인그룹코드 입력시, "컬리칩"을 설정해야합니다.');

        return false;
      }
      if ($scope.row.set_info.colorCd === 'ETC' && !$scope.row.set_info.colorNm) {
        commonSVC.showMessage('컬리칩 "직접입력" 선택시, 값을 입력해주세요');

        return false;
      }

      // 안전인증
      if (!['UTGT', 'TGT_SAFE_STDR'].includes($scope.category_data.kcCrtfcTgtCd)) {
        if (!$scope.row.set_info.kcCrtfcInfo.kcCrtfcSectCd) {
          commonSVC.showMessage('인증유형1을 확인하세요');

          return false;
        }
        if ($scope.row.set_info.kcCrtfcInfo.kcCrtfcSectCd && (!$scope.row.set_info.kcCrtfcInfo.kcCrtfcNo && !$scope.row.set_info.kcCrtfcInfo.kcCrtfcSectCd.includes('SPLR_STBLT'))) {
          commonSVC.showMessage('인증번호1을 확인하세요');

          return false;
        }
        if ($scope.row.set_info.kcCrtfcInfo.kcCrtfcSect2Cd && (!$scope.row.set_info.kcCrtfcInfo.kcCrtfc2No && !$scope.row.set_info.kcCrtfcInfo.kcCrtfcSect2Cd.includes('SPLR_STBLT'))) {
          commonSVC.showMessage('인증번호2를 확인하세요');

          return false;
        }

        // KC인증서류 필수 확인
        const dcmtSns = $scope.qualityDcmtList.filter(data => data.essntlYn === 'Y').map(data => ([data.dcmtSn, data.kcCrtfcSectCd]));
        for (const _data of dcmtSns) {
          if (!$scope.row.set_info.kcCrtfcInfo.qualityDcmtList[_data[1]]?.[_data[0]]) {
            commonSVC.showMessage('KC인증서류를 확인하세요');

            return false;
          }
        }
        // 카테고리 인증 서류 확인
        const cate_dcmtSns = $scope.category_data.stdCtgryQltyDcmtList.filter(data => data.essntlYn === 'Y').map(data => ([data.dcmtSn, data.dcmtKcCrtfcSectCd]));
        for (const _data of cate_dcmtSns) {
          if (!$scope.row.set_info.kcCrtfcInfo.qualityDcmtList[_data[1]]?.[_data[0]]) {
            commonSVC.showMessage('카테고리 인증서류를 확인하세요');

            return false;
          }
        }
      }

      return true;
    };

    const run_pa5_jobs = async (job_name, job_kor_name, push_data, fn) => {
      try {
        $scope.pa5_job_data[job_name] = { load: false, data: [] };

        const re = await commonModel.pa5(`/channel_accounts/{uuid}/etc/${job_name}/sync`, push_data, data.shop_data.shop_cd, data.shop_data.shop_id);

        if (re.data.has_error == false) {
          $scope.pa5_job_data[job_name].data = re.data.results[0].data;
          $scope.pa5_job_data[job_name].load = true;

          if (fn) {
            fn($scope.pa5_job_data[job_name].data);
          }

          if (!$scope.$$phase && !$scope.$root.$$phase) {
            $scope.$apply();
          }

          return $scope.pa5_job_data[job_name].data;
        } else {
          commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.\n${re.data.exception_message}`);
        }
      } catch (e) {
        commonSVC.showMessage('실패', `${job_kor_name}정보 로딩에 실패하였습니다.`);
      }
    };

    /**
     * 소재 선택시 이벤트
     */
    $scope.changeMatrList = async (index) => {
      const temp = $scope.row.set_info.matrList.filter((_,i) => i !== index);
      if (temp.includes($scope.row.set_info.matrList[index])) {
        commonSVC.showMessage('같은 소재를 선택할 수 없습니다.');
        $scope.row.set_info.matrList[index] = null;
      }
    };

    /**
     * 인증 선택시 이벤트
     */
    $scope.changekcCrtfcTgtCd = async (type, kcCrtfcSectCd) => {
      const temp = $scope.kcCrtfcSectCds.find(data => data.code === kcCrtfcSectCd).name;
      // 대상(면제 가능)
      if ($scope.row.set_info.kcCrtfcInfo.kcCrtfcTgtCd === 'TGT_EXM_PSB') {
        if (!$scope.row.set_info.kcCrtfcInfo.kcCrtfcExmCd) {
          return;
        }
        await run_pa5_jobs('get_kc_certifications', '인증서류 조회', {
          kcCrtfcExmCd: $scope.row.set_info.kcCrtfcInfo.kcCrtfcExmCd,
          kcCrtfcSectCd,
        }, result => {
          $scope.row.set_info[`kcCrtfcTgt${type}Cd`] = result.map(data => ({ ...data, target: temp }));
        });
      } else {
        await run_pa5_jobs('get_kc_certifications', '인증서류 조회', {
          kcCrtfcSectCd,
        }, result => {
          $scope.row.set_info[`kcCrtfcTgt${type}Cd`] = result.map(data => ({ ...data, target: temp }));
        });
      }

      $scope.qualityDcmtList = [
        ...$scope.row.set_info.kcCrtfcTgt1Cd,
        ...($scope.row.set_info.kcCrtfcTgt2Cd ? $scope.row.set_info.kcCrtfcTgt2Cd : [])
      ];
    };

    /**
     * 인증 면제 유형 변경시 이벤트
     */
    $scope.changekcCrtfcExmCd = async () => {
      if ($scope.row.set_info.kcCrtfcInfo.kcCrtfcSectCd) {
        await $scope.changekcCrtfcTgtCd(1, $scope.row.set_info.kcCrtfcInfo.kcCrtfcSectCd);
      }
      if ($scope.row.set_info.kcCrtfcInfo.kcCrtfcSect2Cd) {
        await $scope.changekcCrtfcTgtCd(2, $scope.row.set_info.kcCrtfcInfo.kcCrtfcSect2Cd);
      }
      if (!$scope.$$phase && !$scope.$root.$$phase) {
        $scope.$apply();
      }
    };

    // 쇼핑몰 추가정보 파일 임시 업로드
    $scope.uploadFile = function (files, path) {
      // S3 temp 업로드 작업
      commonModel.tempUpload(files)
        .then(function (res) {
          const results = res.data.results;

          _.forEach(results, function (v) {
            if (v) {
              _.set($scope.row.set_info, [ ...path, 'file' ], v.url);
              _.set($scope.row.set_info, [ ...path, 'file_name'], v.origFileName);
            }
          });
        });
    };

    // 쇼핑몰 추가정보 파일 삭제
    $scope.deleteFile = function (path) {
      _.unset($scope.row.set_info, [ ...path ]);
    };

    // 파일 다운로드
    $scope.downloadFile = function(url) {
      window.open(url, '_blank');
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = async () => {
      if (!save()) {
        return false;
      }

      if ($scope.row.saveAsTemplateYn) {

        $scope.row.set_info.template_name = $scope.row.set_info.template_name?.trim() || '';
        // 템플릿명 필수값 검사
        if (!$scope.row.set_info.template_name) {
          $scope.noTemplateName = true;

          return false;
        }

        // 템플릿 양식 중복체크
        if (!$scope.row.templateDupCheck) {
          try {
            const re = await onlineProductModel.setInfoTemplateDupCheck(({
              shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
              shop_cd: $scope.data.shop_cd,
              std_ol_yn: data.data.std_ol_yn,
              template_name: $scope.row.set_info.template_name,
            }));

            if (re.data.result.isDup) {
              $scope.isTemplateDup = re.data.result.isDup;
              $scope.row.templateDupCheck = true;

              return false;
            } else {
              $scope.isTemplateDup = false;
            }
          } catch (err) {
            commonSVC.showToaster('error', '실패', '템플릿명 중복 조회에 실패했습니다.');

            return false;
          }
        }
        if ($scope.isTemplateDup) {
          return false;
        }

        const confirm = await commonSVC.showConfirmCustom({
          title: '해당 추가항목을 기본 양식으로 설정하시겠습니까?',
          text: `기본 양식으로 설정하시면, 동일한 쇼핑몰&카테고리의 상품을 추가항목 등록시
            현재 입력한 양식의 내용을 기본으로 불러옵니다.
            (이미 적용하신 기본 양식이 있는 경우, 현재 설정하는 추가항목 양식이 기본 양식으로 적용됩니다.)\n
            ※ 양식은 상품 ${$scope.data.detailType === 'add' ? '등록' : '수정'}시 저장됩니다.
          `,
          confirmButtonText: '기본 양식으로 설정 후 저장',
          cancelButtonText: '설정안하고 양식만 저장'
        });

        if (confirm) {
          $scope.row.default_yn = true;
        }
      }

      $uibModalInstance.close($scope.row);
    };

    /**
     * 양식 존재 여부 확인
     */
    $scope.checkEmpty = () => {
      if (!$scope.templateList.length) {
        commonSVC.showMessage('등록된 양식이 없습니다.', '하단 \'추가항목 양식 저장\'을 통해 등록하시면 추후 간편 양식 선택하기 또는 전체양식보기 버튼을 클릭하여 내용을 적용하실 수 있습니다.');
      }
    };

    /**
     * 추가항목 데이터 업데이트
     */
    function updateSetInfo(newSetInfo) {
      $scope.row.set_info = { ...$scope.data.setInfoDefault, ...newSetInfo };

      // max-bytes 디렉티브 들어있는 ng-model 요소들
      const maxByteElems = $('div[ng-include] input[ng-model][max-bytes]');

      // byte 계산 업데이트가 안되므로 이벤트 수동으로 트리거시켜줌
      for (let idx = 0; idx < maxByteElems.length; idx++) {
        $timeout(() => {
          maxByteElems.eq(idx).trigger('keydown');
        });
      }
    }

    /**
     * 추가항목 간편양식 적용
     */
    $scope.setTemplate = async () => {
      if ($scope.row.set_info_template_no) {
        const template = $scope.templateList.find(({ template_no }) => template_no === parseInt($scope.row.set_info_template_no));

        updateSetInfo(template.template_data);
      }
    };

    /**
     * 추가항목 불러오기 모달 오픈
     */
    $scope.setInfoTemplate = () => {
      const resolve = {
        data: {
          shop_cd: $scope.data.shop_cd,
          shop_id: $scope.data.shop_id,
          pa_shop_cd: $scope.data.pa_shop_cd,
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          seller_nick: $scope.data.seller_nick,
          shop_data: data.shop_data,
          openTemplate: data.openTemplate,
          madeinList: data.madeinList,
          templateList: $scope.templateList,
          data: $scope.data,
          detailType: $scope.data.detailType
        },
        systemList: systemList,
      };

      const modal = commonSVC.openModal('xg', resolve, 'setInfoTemplateCtrl', 'views/online/product/modals/set_info_template.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };

    // 추가항목 간편양식 리스트 업데이트
    $rootScope.$on('setInfoTemplateUpdate', function(event, templates) {
      $scope.templateList = templates;
      $scope.row.set_info_template_no = '';
    });

    /**
     * 기존 상품 항목 불러오기 모달
     */
    $scope.importSetInfo = () => {
      const resolve = {
        data: {
          shop_cate_no: $scope.data.shop_cate_no || $scope.data.sol_shop_cate_no,
          ol_shop_no: $scope.data.ol_shop_no,
          shop_cd: $scope.data.shop_cd,
          std_ol_yn: $scope.data.std_ol_yn,
        },
        systemList: systemList
      };

      const modal = commonSVC.openModal('xg', resolve, 'importSetInfoCtrl', 'views/online/product/modals/import_set_info.html');

      modal.result.then(function (re) {
        updateSetInfo(re);
      });
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    init();
  });