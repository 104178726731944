/**
 * Created by ally on 2017. 6. 28..
 */
'use strict';

angular.module('gmpApp')
  .controller('OrderShipmentMappingRuleCtrl',
    function (// common
      $rootScope, $scope, $compile, $timeout, gettextCatalog, $uibModalInstance,
      // info
      settings,
      // SVC
      commonSVC, shipmentSVC,
      // Model
      shipmentModel,
      // List
      systemList, warehouseList,
      $state, userInfo, localStorageService
    ) {
      $scope.excelFieldList = angular.copy(shipmentSVC.excelFieldList.matchingRule);
      // Description 칸
      $scope.showDesc = localStorageService.get('mappingRuleDescYn') || 'Y';
      $scope.showWarnDesc = localStorageService.get('mappingRuleWarnDescYn') || 'Y';

      const use_excel_upload = userInfo.user.sol_ser.includes('upload_matching_rule');

      const channelList = angular.copy($rootScope.use_channel_list);

      // 별칭정보 담기
      const seller_nick_info = [];

      let prodList = {};

      _.forEach(channelList, function(chRow) {
        seller_nick_info[chRow.shop_cd + chRow.shop_id] = chRow.seller_nick;
      });

      // 직접 입력 추가
      channelList.push({ shop_name: '직접입력', shop_cd: 'A000' });

      /* 검색 및 버튼 관련 변수 */
      const search = {
        searchForm: {
          search_key: 'all',
          search_word: '',
          search_type: 'partial',
          date_type: 'wdate',
          sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
          edate: moment().format('YYYY-MM-DD'),
          shop_id: '',
          shop_cd: '',
          map_type: '', //매칭구분
          multi_search_word: '', //멀티서치워드
          multi_type: 'shop_sale_no' //멀티서치 타입
        },
        searchData: {
          all_checked: false,  // 전체선택 여부
          selectCount: 0,       // 검색전체 건수
          totalCount: 0,       // 검색전체 건수
          search_key_items: [  // 검색영역 키값
            { label: gettextCatalog.getString('전체'), value: 'all' },
            // { label: gettextCatalog.getString('주문번호'), value: 'shop_ord_no' }, // 백엔드에 적용안되어 있어 제거
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('상품명'), value: 'shop_sale_name' },
            { label: gettextCatalog.getString('주문옵션명'), value: 'ord_opt_name' },
            { label: gettextCatalog.getString('SKU코드, 세트코드'), value: 'sku_cd,set_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
            { label: gettextCatalog.getString('SKU상품명, 세트상품명'), value: 'prod_name,set_name' },
            { label: gettextCatalog.getString('속성명'), value: 'attr' }
          ],
          search_date_type: [
            { label: gettextCatalog.getString('규칙생성일'), value: 'wdate' },
            { label: gettextCatalog.getString('규칙수정일'), value: 'mdate' }
          ],
          search_multi_items: [
            { label: gettextCatalog.getString('판매자관리코드'), value: 'c_sale_cd' },
            { label: gettextCatalog.getString('쇼핑몰 상품코드'), value: 'shop_sale_no' },
            { label: gettextCatalog.getString('매칭코드'), value: 'map_cd' },
            { label: gettextCatalog.getString('재고관리코드'), value: 'stock_cd' },
          ]
        },
        searchDetail: [
          {
            title: gettextCatalog.getString('배송처 선택'),
            search_name: 'delivery_vendor',
            item_list: warehouseList.data.result || [],
            item_key: 'warehouse_name',
            item_value: 'code',
            select_value: ''
          },
          {
            // 채널 선택
            title: gettextCatalog.getString('쇼핑몰 선택'),
            search_name: 'shop_cd',
            item_list: commonSVC.getSiteList(channelList),
            item_key: 'shop_name',
            item_sub_key: 'shop_name_kr',
            item_value: 'shop_cd',
            select_value: '',
            add_class: 'select-search'
          },
          {
            // 채널 계정 선택
            title: gettextCatalog.getString('계정 선택'),
            search_name: 'shop_id',
            item_list: commonSVC.getSiteIdList(channelList),
            item_key: 'shop_id',
            item_value: 'search_shop_id',
            select_value: '',
            add_class: 'select-search',
            filter: function(option) {
              return !option.pa_shop_cd?.startsWith('X') ? option.shop_cd == $scope.searchForm.shop_cd && option.shop_id : _.intersection([$scope.searchForm.shop_cd], option.shop_cds).length && option.shop_id;
            }
          },
          {
            // 매칭구분
            title: gettextCatalog.getString('매칭구분'),
            search_name: 'map_type',
            item_list: [{ value: '자동', key: '자동매칭' }, { value: '수동', key: '수동매칭' }],
            item_key: 'key',
            item_value: 'value',
            select_value: ''
          }
        ]
      };

      if ($rootScope.user_profile.auth_type === '배송처') {
        const searchKey = _.findIndex(search.searchDetail, { search_name: 'delivery_vendor' });

        if (searchKey > -1) {
          search.searchDetail.splice(searchKey, 1);
        }
      }
      $scope.searchData = angular.copy(search.searchData);
      $scope.searchForm = angular.copy(search.searchForm);
      $scope.searchDetail = angular.copy(search.searchDetail);
      $scope.searchBtn = {
        actions_right: [],
        table_actions: [
          // {
          //   label: '상태변경',
          //   btn_type: 'dropdown',
          //   add_class: 'btn-primary',
          //   item_list: []
          // },
          {
            label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
            test_id: 'drop-work',
            btn_type: 'dropdown',
            add_class: 'btn-default',
            item_list: [
              {
                label: gettextCatalog.getString('매칭수정'),
                test_id: 'btn-match-fix',
                action: function () {
                  $scope.ruleModify();
                },
                perm_only: ['order.mapping+write']
              }, {
                label: gettextCatalog.getString('규칙삭제'),
                test_id: 'btn-rule-del',
                action: function () {
                  $scope.ruleDelete();
                },
                perm_only: ['order.mapping+admin']
              }
            ]
          }
        ]
      };

      if (use_excel_upload) {
        $scope.searchBtn.actions_right.push({
          label: `<i class="icon-plus2" ng-if="use_excel_upload"></i><span> ${gettextCatalog.getString('매칭 규칙 일괄 등록')}</span>`,
          small_label: '일괄 등록',
          sub_label: '',
          btn_type: 'button',
          add_class: 'col-xs-12',
          perm_only: ['order.order+write'],
          action: async () => {
            if ($rootScope.user_profile.auto_map_yn !== 'Y') {
              const res = await commonSVC.showConfirmCustom({
                title: 'SKU상품 자동 매칭 설정 변경이 필요합니다.',
                text: 'SKU상품 자동 매칭 설정이 Off입니다.\nSKU상품 자동 매칭 설정을 수정해 주세요',
                confirmButtonText: '환경설정'
              });

              if (res) {
                $state.go('main.configs_system');
              }

              return false;
            }

            const modal = commonSVC.openModal('md', { data: null }, 'AddMatchingRuleCTRL', 'views/order/shipment/modals/add_matching_rule.html');

            modal.result.then(function (re) {
              if (re === 'success') {
                $scope.searchDo(true);
              }
            });
          }
        });
      }

      // 엑셀 다운로드는 전 고객 사용가능
      $scope.searchBtn.table_actions.push({
        label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀</span>' + '<span class="caret"></span>',
        test_id: 'btn-excel',
        btn_type: 'dropdown',
        add_class: 'btn-default',
        item_list: [
          {
            label: gettextCatalog.getString('전체 엑셀 다운로드'),
            test_id: 'btn-excel-all',
            action: function () {
              $scope.excelDown('all');
            },
            perm_only: ['order.mapping']
          }, {
            label: gettextCatalog.getString('선택 엑셀 다운로드'),
            test_id: 'btn-excel-select',
            action: function () {
              $scope.excelDown('select');
            },
            perm_only: ['order.mapping']
          }
        ]
      });

      /**
       * 엑셀 다운 모달창
       * */
      $scope.excelDown = function (type) {
        const visibleTable = $scope.grid.methods.getColumnsVisible(); // 활성화된 필드 데이터

        const resolve = {};

        resolve.data = {
          type: type,
          excelFieldList: $scope.excelFieldList,
          title: '매칭규칙',
          url: '/app/order/matching-rules/excel',
          searchForm: $scope.pageData,
          page: 'matchingRule',
          visibleTable: visibleTable,
          progress: true
        };

        if (type === 'all') {
          // 묶음이 아닌 전체 주문카운트 노출되도록 수정 2018-11-15 rony
          // resolve.data.count = $scope.searchData.totalCount;
          resolve.data.count = $scope.searchData.totalCount;
          resolve.data.isAll = true;

          if (resolve.data.count === 0) {
            commonSVC.showMessage(gettextCatalog.getString('다운로드 가능한 매칭규칙이 없습니다.'));

            return false;
          }
        } else {
          const mapNoList = $scope.grid.methods.selectedData('map_no');

          if (mapNoList.length === 0) {
            commonSVC.showMessage(gettextCatalog.getString('선택된 매칭규칙이 없습니다.'));

            return false;
          } else {
            resolve.data.uniq = mapNoList;
            resolve.data.count = mapNoList.length;
            resolve.data.isAll = false;
          }
        }

        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      };

      $scope.searchFn = {       // 데이터 테이블 관련기능 바인딩
        searchDo: function() {
          $scope.searchDo(true, true);
        },
        resetDo: function() {
          $scope.resetDo();
        },
        changeLen: function(count) {
          $scope.changeCount(count);
        },
        getMapList: function () {
          return $scope.orderList;
        }
      };

      /* 데이터 테이블 */
      $scope.grid = {};
      $scope.grid.methods = {};
      $scope.grid.options = {
        pinningColumns: ['widget'],
        alignCenterColumns: ['widget', 'map_type', 'opt_type'],
        alignRightColumns: ['map_cnt'],
        defaultSortingColumns: ['wdate'],
        notSortingColumns: ['widget', 'shop_sale_name', 'map_cd', 'map_name', 'attri', 'opt_type'],
        notResizingColumns: ['widget'],
        notMovingColumns: [],
        notVisibleColumns: ['notice_msg', 'stock_cd'],
        externalRequestOptions: {
          requestUrl: `${settings.pa20ApiUrl}/app/order/matching-rules`,
          requestWillAction: function(data) {
            data = angular.merge({}, data, $scope.searchForm);

            $scope.pageData = angular.copy(data);

            return data;
          },
          requestDidAction: function(result) {
            $scope.searchData.totalCount = result.recordsTotal;

            prodList = result.prodList;
            $scope.orderList = result.results;

            return result.results;
          }
        },
        columns: [
          {
            key: 'widget',
            title: '도구',
            width: 140,
            template: function(row) {
              return `${'' +
                '<button class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.ruleModify('}${row.map_no}, '${row.pa_shop_cd}')">매칭수정</button>` +
                `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.ruleDelete(${row.map_no})">규칙삭제</button>`;
            }
          },
          {
            key: 'wdate',
            title: '규칙 생성일',
            width: 90,
            filter: 'dateValid'
          },
          {
            key: 'shop_cd',
            title: '쇼핑몰',
            width: 150,
            template: function(row) {
              let img = '<div class="text-center">직접입력</div>';

              // 직접입력 쇼핑몰인 경우 쇼핑몰명 같이 출력
              if (row.shop_cd !== 'A000') {
                const shop_info = commonSVC.getShopIdViewText(systemList.data.shop_id_view_type, seller_nick_info, row.shop_cd, row.shop_id);

                img = `<span uib-tooltip="${row.shop_name}(${shop_info[0]})` + `" tooltip-append-to-body="true" tooltip-placement="right">
                  ${row.shop_cd.startsWith('U') ? `[${row.shop_name}]` : `<img src="/assets/images/sitelogo/${row.shop_cd === 'P059' ? row.shop_cd : row.pa_shop_cd}.png" style="width: 50px;">` }
                  ${shop_info[1]}
                </span>`;
              }

              return img;
            }
          },
          {
            key: 'c_sale_cd',
            title: '판매자관리코드',
            width: 120,
            filter: 'isNullHyphen'
          },
          {
            key: 'shop_sale_no',
            title: '쇼핑몰상품코드',
            width: 150
          },
          {
            key: 'shop_sale_name',
            title: '쇼핑몰 상품명',
            width: 200,
            filter: 'whiteSpace'
          },
          {
            key: 'ord_opt_name',
            title: '주문 옵션명',
            width: 250,
            notCompile: true
          },
          {
            key: 'opt_type',
            title: '옵션 구분',
            width: 80,
            notCompile: true,
            template: function(row) {
              return row.ord_opt_name === row.shop_sale_name ? '옵션없음' : row.ord_opt_type === '자동' ? '옵션' : '추가구매옵션';
            }
          },
          {
            key: 'map_type',
            title: '매칭 구분',
            width: 80,
            notCompile: true,
            template: function(row) {
              return row.map_modify_yn ? '수정' : row.map_type;
            }
          },
          {
            key: 'map_cd',
            title: '매칭코드',
            width: 180,
            template: function(row) {
              if (row.set_no) {
                return `<span class="label label-default bg-slate-400 mr-5">SET</span> <a ng-click="grid.appScope.showMultiSKUDetail('${row.map_no}', '${row.set_cd}', '${row.set_name}')">${row.set_cd}</a>`;
              } else {
                return prodList[row.map_no].length > 1 ?
                  `<span class="bg-white border-warning-400 text-warning-400 label label-default mr-5">다중</span> <a ng-click="grid.appScope.showMultiSKUDetail('${row.map_no}')">${prodList[row.map_no][0].sku_cd} 외 ${prodList[row.map_no].length - 1}건</a>`
                  : `<span class="label label-default bg-orange-800 mr-5">SKU</span>${prodList[row.map_no][0].sku_cd}`;
              }
            }
          },
          {
            key: 'stock_cd',
            title: '재고관리코드',
            width: 160,
            notCompile: true,
          },
          {
            key: 'map_name',
            title: '매칭상품명',
            width: 160,
            notCompile: true,
            template: function(row) {
              const name = row.set_name ? row.set_name :
                prodList[row.map_no].length > 1 ? `${prodList[row.map_no][0].prod_name} 외 ${prodList[row.map_no].length - 1}건` : row.prod_name;

              return name;
            }
          },
          {
            key: 'attri',
            title: '속성',
            width: 100,
            filter: 'isNullHyphen'
          },
          {
            key: 'map_cnt',
            title: '출고수량',
            tooltip: {
              text: '주문 1건당 출고되는 수량입니다.&#10;출고수량 수정 시 쇼핑몰 상품정보에 등록된 출고수량과 일치하지 않을 수 있습니다. 상품 > 쇼핑몰 상품 관리 > 상세 > 기본정보에서 출고수량을 확인하여 동일하게 수정해 주시기 바랍니다.',
              placement: 'left'
            },
            width: 80,
            template: function(row) {
              return (!row.set_name && prodList[row.map_no].length > 1) ? prodList[row.map_no].reduce((prev, curr) => prev + curr.pack_unit, 0) : (row.map_cnt || '');

            }
          },
          {
            key: 'depot_name',
            title: '배송처',
            width: 80
          },
          {
            key: 'mdate',
            title: '규칙수정일',
            width: 130,
            filter: 'dateValid'
          }

        ]
      };

      /**
       * 테이블에서 안보이던 컬럼 보이게 할시 datatable 재 컴파일
       */
      $scope.$on('OnColumnChange', function(event, data) {
        _.each(data, function(row) {
          $compile(row)($scope);
        });
      });

      /**
       * 검색
       */
      $scope.searchDo = function (refresh, noDelay, callback) {
        $scope.grid.methods.reloadData(function () {
          if (callback) {
            callback();
          }
        }, refresh, noDelay);
      };

      /**
       * 검색 초기화
       */
      $scope.resetDo = function() {
        $scope.searchForm = angular.copy(search.searchForm);
        $scope.searchData.search_key_name = '전체';
        $scope.searchDetail = angular.copy(search.searchDetail);
        $scope.searchData.search_key_name = '전체';
        $scope.selectCount = 'total';
        $scope.searchDo(true, true);
      };

      /**
       * 데이터테이블 pageLength 조절
       */
      $scope.changeCount = function () {
        $scope.grid.methods.length($scope.searchData.showCount);
      };
      /**
       * 매칭수정
       * */
      $scope.ruleModify = function(map_no, pa_shop_cd) {
        const mapList = map_no ? [map_no] : $scope.grid.methods.selectedData('map_no');

        if (!mapList.length) {
          commonSVC.showMessage('수정할 매칭을 선택해주세요');

          return false;
        }

        const resolve = {};

        resolve.data = {
          from: '규칙',
          pack_unit: true,
          warehouseList: warehouseList.data.result || [],
          isMulti: !$scope.user_profile.pa_sol_no,
          isMultiProd: true,
          systemList,
          selectedData: mapList,
          searchForm: $scope.pageData,
          pa_shop_cd
        };
        commonSVC.openModal('full', resolve, 'prodRuleSearch', 'views/prod/product/modals/mapping_rule.html');
      };

      /**
       * 매칭삭제
       * */
      $scope.ruleDelete = function(map_no) {
        let mapList = [];
        const keys = ['map_no', 'shop_name', 'shop_id', 'sku_cd', 'set_no', 'set_cd', 'ord_opt_name', 'c_sale_cd', 'shop_sale_no', 'shop_sale_name', 'set_name', 'prod_name', 'attri', 'map_cnt'];

        if (map_no) {
          const map = (_.filter($scope.orderList, function (map) {
            return map.map_no === map_no;
          }))[0];
          const obj = {};

          _.map(keys, function (key) {
            obj[key] = map[key];
          });

          obj.map_name = obj.set_name || obj.prod_name;

          mapList.push(obj);
        } else {
          mapList = $scope.grid.methods.selectedData(keys);
        }

        if (!mapList.length) {
          commonSVC.showMessage('삭제할 매칭규칙을 선택해주세요');

          return false;
        }

        const delete_message = '매칭규칙 삭제 후 수집되는 주문부터는 쇼핑몰 상품/옵션에</br> 매칭된 SKU 상품을 기준으로 매칭 및 신규 규칙이 생성됩니다.';

        commonSVC.showConfirmHtml('선택한 매칭규칙을 삭제하시겠습니까?', delete_message, function () {
          shipmentModel.delMappingRule({ mapList: mapList }, function(state, data) {
            if (data.results == 'success') {
              commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString('규칙삭제에 성공하였습니다'));
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', gettextCatalog.getString('실패'), gettextCatalog.getString('규칙삭제에 실패하였습니다'));
            }
          });
        });
      };

      // 다중 매칭된 SKU 조회 모달
      $scope.showMultiSKUDetail = function(map_no, set_cd, set_name) {
        const resolve = {
          data: { prodList: prodList[map_no], isMappingRule: true, set_cd, set_name }
        };

        commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
      };

      /**
     * 설명 Div 접기 변경
     */
      $scope.changeShowDesc = () => {
        $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
        localStorageService.set('mappingRuleDescYn', $scope.showDesc);
      };
      $scope.changeShowWanrDesc = () => {
        $scope.showWarnDesc = $scope.showWarnDesc === 'Y' ? 'N' : 'Y';
        localStorageService.set('mappingRuleWarnDescYn', $scope.showWarnDesc);
      };
      /**
       * 테이블 컬럼 사이즈 확인
       */
      $scope.$on('$stateChangeSuccessForJqueryGlobal', function(evt, originEvt, toState) {
        if (toState.name == 'main.order_shipment_order_list') {
          const $wrapper = $('#order_shipment_grid_wrapper');
          const $tableHeader = $wrapper.find('.dataTables_scrollHeadInner');

          if ($tableHeader.width() < 1) {
            $timeout(function () {
              $scope.grid.methods.calcLayout('set_width');
            }, 0);
          }
        }
      });

      /**
       * 2018-02-21 Daniel
       * 작업 완료시 새로고침
       * [주문동기화, 발주전 주문수집, 발주확인, 발주확인후주문수집, 배송지연]
       */
      // _.each(["SyncOrderState", "ScrapOrder", "ScrapOrderConfirmDo", "ScrapOrderAndConfirmDoit", "DelayedOrderDo", "DelayedOrderPaymentDo"], function (value) {
      //   $scope.$on(value, function () {
      //     $scope.searchDo(true);
      //   });
      // });

      // 2018-01-11 chris 등록 성공시 리스트 새로고침 추가
      $scope.$on('reloadShipmentOrderList', function() {
        $scope.searchDo(true);
      });
    });
