'use strict';

angular.module('gmpApp')
  .controller('EventPageCtrl', function ($rootScope, $scope, $location, commonSVC, userInfo, localStorageService, shopAccountModel, adminModel, payModel, $timeout, $window, $stateParams) {
    $scope.wakeupEventTab = 'tab1';
    $scope.wakeupEventFaq = 1;

    const dataLayer = $window.dataLayer = $window.dataLayer || [];

    let company, autoPayInfo, label;

    /**
     * 결제하기
     */
    $scope.eventPay = async () => {
      if (userInfo.user.sol_version == 'ZERO') {
        // 회사정보 로딩
        if (!company) {
          const res = await adminModel.load({ c_no: userInfo.user.c_no, onlyCompany: true });
          company = res.data.results.company;
        }

        // 자동 결제정보 및 환불정보 조회
        if (!autoPayInfo || !autoPayInfo.card_no) {
          const res = await payModel.getAutoPayInfo('');

          if (res.data?.autoPayInfo?.length && res.data?.autoPayInfo[0].card_no) {
            autoPayInfo = res.data?.autoPayInfo[0];
          } else {
            autoPayInfo = { card_no: null };
          }
        }

        const params = {
          card_no: autoPayInfo.card_no || '',
          card_effect_term: autoPayInfo.card_effect_term || '',
          card_type: autoPayInfo.card_type || 'company',
          card_biz_reg_no: autoPayInfo.biz_reg_no || company.biz_reg_no,
          birth_num: autoPayInfo.birth_num || '',
          executeAutoPay: { setAutoPay: false, sol_version: 'SLIM', autopay_term: 1, label }
        };

        const { re } = await commonSVC.openModal('md', { data: params }, 'AutoPayInfoCtrl', 'views/pay/modals/autopay_info.html').result;

        // 결제 성공시 페이지 새로고침 후 퀵수집 실행
        if (re == 'success') {
          localStorageService.set(`openQuickCollect_${userInfo.user.sol_no}`, true);

          location.reload();
        } else {
          $scope.openQuickCollect(true);
        }
      } else {
        commonSVC.showMessage('이미 유료 버전 사용중 입니다.');
      }
    };

    /**
     * 쇼핑몰 추가정보 입력 모달
     */
    async function openWakeUpAddInfo () {
      // 추가정보 입력이 필요한 쇼핑몰 (스마트스토어, 11번가, 쿠팡, 위메프2.0)
      const ADD_INFO_SHOP_CD = ['A077', 'A112', 'B378', 'B719'];

      const re = await shopAccountModel.shopAccountUseList();

      // 판매자센터 접속 채널 리스트에 공유하기 위해서는 rootScope 에 값을 넣어주어야 함. 2018-04-16 rony
      $rootScope.$broadcast('channel_update', { data: re.data });

      const addShops = re.data.results.filter(({ pa_shop_cd, shop_pwd }) =>
        ADD_INFO_SHOP_CD.includes(pa_shop_cd)
        && !shop_pwd
      );

      // 추가정보 받아야 하는 쇼핑몰이 있는 경우
      if (addShops.length) {
        const resolve = {
          data: { addShops }
        };

        const re = await commonSVC.openModal('xxg', resolve, 'wakeUpAddInfoCtrl', 'views/home/modals/wakeUpAddInfo.html').result;

        return re == 'success';
      } else {
        return true;
      }
    }

    /**
     * 퀵수집 모달 오픈
     */
    $scope.openQuickCollect = async (showNoti = false) => {
      localStorageService.remove(`openQuickCollect_${userInfo.user.sol_no}`);

      // 결제 취소 후 퀵수집 실행시에만 안내모달 띄우기
      if (showNoti) {
        const opt = {
          html: `<div class="no-padding-top pb-20" style="font-size: 17px !important;">
              <h2 class="mt-10">잠깐!</h2>
              내 쇼핑몰의 상품, 주문, 문의를 한꺼번에 수집할 수 있는 퀵플레이 기능을 체험해 보세요.<br />
              (연동된 쇼핑몰이 없을 경우 쇼핑몰 계정 연동이 먼저 진행됩니다.)
            </div>`,
          icon: 'info',
          confirmButtonText: '퀵플레이 체험하기',
          showCancelButton: false,
          width: 700,
          customClass: {
            actions: '',
            confirmButton: 'my-swal2-confirm2',
          },
        };

        await commonSVC.showConfirmCustomSwal2(opt);
      }

      const hasWakeUpAddInfo = await openWakeUpAddInfo();

      if (hasWakeUpAddInfo) {
        $rootScope.quickCollect(true);
      }
    };

    /**
     * 1:1 문의하기
     */
    $scope.openQuestion = () => {
      const sendInfo = localStorageService.get('sendInfo');
      const firstLogin = localStorageService.get('firstLogin');
      const info = atob(decodeURIComponent(sendInfo));

      let url = $location.$$host === 'localhost' ? 'http://localhost:8080' : 'https://www.plto.com';
      const ret_url = 'customer/Inquiry?ser_cd=gmp&from=wakeupEvent';
      url += `/member/AdminLogin/?info=${encodeURIComponent(info)}&firstLogin=${firstLogin}&ret_url=${encodeURIComponent(ret_url)}`;

      window.open(url);
    };

    $scope.$watch('$viewContentLoaded', function () {
      $timeout(function() {
        if (localStorageService.get(`openQuickCollect_${userInfo.user.sol_no}`)) {
          $scope.openQuickCollect();
        }
      }, 500);
    });

    $timeout(() => {
      $('.wakeup_button').on('click', function() {
        GA4_setting($(this).attr('label'));
      });
    });

    const GA4_setting = (GA4_label) => {
      label = GA4_label;
      dataLayer.push({
        event: 'Button Click',
        category: 'Button Click',
        label,
        action: 'click_wakeup_button'
      });
    };

	  // scroll 이벤트리스너로 처리시 모니터크기에따라서 최하단여부에 오차가 생겨 Intersection Observer 적용
    const io = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        GA4_setting('a-5');
        $scope.eventPay();
      }
    }, {
      root: null, // 기본 뷰포트
      rootMargin: '0px',
      threshold: 1 // 1=100%. 100% 보여야 callbackFn 실행
    });

    if ($stateParams.event_name == 'discountEvent') {
      // 대상요소 관찰시작
      io.observe(document.querySelector('.wakeup_button[label="a-4"]'));
    }

    $scope.$on('$destroy', function () {
      // 모든 관찰 중단
      io.disconnect();
    });

  });