/**
 * 환불요청 모달 컨트롤러
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayRefundRequestCtrl', function ($scope, data, $compile, commonSVC, $uibModalInstance, payModel, gettextCatalog, userInfo, commonModel, localStorageService) {

    // 리스트에서 넘어온 결제 정보.
    $scope.payInfo = data;

    // 1년전
    const lastYear = moment().add(-1, 'year').format('YYYY-MM-DD');

    // 환불계좌정보 필수여부
    $scope.required_bank = $scope.payInfo.ord_no.some(o => o.refund_status == '환불신청' && (o.pay_method != '카드' || o.cash_time < lastYear));

    // prod 테이블의 prod_name을 기준으로 payInfo.payName 만들어지는데, 알림톡의 경우 atalk으로 되어있어서
    // atalk -> 알림톡으로 변환처리 추가
    if ($scope.payInfo.payName.includes('atalk')) {
      $scope.payInfo.payName = $scope.payInfo.payName.replace('atalk', '알림톡');
    }

    // 환불요청 정보.
    $scope.refundData = {
      ord_no: JSON.stringify($scope.payInfo.ord_no),    // 주문번호
      bank_no: '',                      // 은행번호
      acct_holder_name: '',             // 예금주명
      acct_no: '',                      // 계좌번호
      // "title": $scope.payInfo.payName+" 결제"+$scope.payInfo.sales+" 원 환불요청",                 // 1:1 문의제목
      reason: '',                      // 환불사유리스트선택
      content: '',                      // 환불사유상세 ( 1:1 문의 등록 용 )
      // "cs_cate1": "1:1문의",               // 1:1 문의유형
      // "board_status": "접수대기",          // 1:1 문의상태
      // "board_type": "결제관련",
      m_no: userInfo.user.m_no,         // 가입자번호
      recycle: false,                   // 입력한 환불정보 재사용 여부
      file_array: []                    // 통장사본
    };

    $scope.refund_reasons = ['솔루션 기능 부족', '쇼핑몰 연동 불가', '오류', '사용법이 어려움', '요금제 부담', '결제방법 변경', 'PLTO 타 솔루션 사용(EMP/GLOBAL)', '폐업', '기타'];

    /**
     * 저장된 환불 계정정보 및 은행 정보 조회.
     */
    payModel.getRefundInfo('', function (status, data) {
      if (status == 'success') {

        // 은행정보
        $scope.bankInfo = data.bankInfo;

        // 저장된 환불 계좌 정보
        if (data.refundInfo.length) {
          $scope.refundData.bank_no = data.refundInfo[0].bank_no.toString();
          $scope.refundData.acct_holder_name = data.refundInfo[0].bank_holder_name;
          $scope.refundData.acct_no = data.refundInfo[0].bank_acct_no;
        }
      }
    });

    /**
     * 저장
     */
    $scope.onSubmit = function () {

      if ($scope.payInfo.sales <= 0 || !$scope.payInfo.ord_no.some(o => o.refund_status == '환불신청')) {
        commonSVC.showMessage('환불 실패', '환불 가능한 금액이 없어 환불 신청에 실패되었으나\n남은 기간 동안은 정상적인 이용이 가능합니다.');

        return false;
      }

      // 유효성 검사 추가해야 함.
      if ($scope.pay_refund_form.$valid) {
        if ($scope.required_bank && $scope.refundData.file_array.length < 1) {
          commonSVC.showMessage('통장사본을 첨부해 주십시오.');

          return false;
        }

        if (!$scope.refundData.reason) {
          commonSVC.showMessage('환불 사유를 선택해 주십시오.');

          return false;
        }

        commonSVC.showMessageHtml('',
          `환불 요청 시 결제수단에 따라 영업일 기준 5~7일 이내 카드 결제 취소 또는 환불 처리해 드리며,
          <span class="text-warning-400">환불 요청 즉시 해당 서비스 이용이 불가합니다.</span> <br><br>
          환불 요청을 계속 진행하시겠습니까?`,
          function (state) {
            if (state) {
              return false;
            }

            // 환불요청사유를 줄바꿈 하려면 태그를 넣어야 함.
            $scope.refundData.content = $scope.refundData.content.replace(/\n/g, '<br />');
            $scope.submitDisabled = true;
            payModel.payRefundRequest(_.cloneDeep($scope.refundData), function (state) {
              if (state == 'success') {
                // 환불요청 완료후 솔루션 완전새로고침
                commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
                  localStorageService.set('token', `${'Token' + ' '}${result}`);

                  commonSVC.showMessage('환불 요청에 성공하였습니다.', '페이지를 새로고침 합니다.', () => {
                    location.reload();
                  });
                });
              } else {
                $scope.submitDisabled = false;
                commonSVC.showToaster('error', '실패', gettextCatalog.getString('환불요청에 실패하였습니다.'));
              }
            });
          },
          { confirmButtonText: '취소', cancelButtonText: '확인', showCancelButton: true }
        );
      }
    };

    /**
     * 파일업로드
     */
    $scope.fileInfo = function (value) { // 파일 변화
      const max_fields = 1; // 인풋의 갯수 제한
      const wrapper = $('.input_fields_wrap'); //넣은 곳 찾기
      let x = wrapper[0].children.length;

      for (let i = 0; i < value.length; i++) {
        if (x < max_fields) {
          x++;
          const nRow =
            `<div class="input-group" id="file${x}">` +
            `<span class="mt-10 text-grey-400">${value[i].name}</span>` +
            '<span class="input-group-addon cursor-pointer" style="border:none !important;background-color:white">' +
            `<i class="icon-cross3" ng-click="subtractElement(${x},${value[i].size})"></i>` +
            '</span>' +
            '</div>';
          const finNRow = angular.element(nRow);

          $(wrapper).append(finNRow);
          $compile(finNRow[0])($scope); // 컴파일

          $scope.refundData.file_array.push(value[i]);
        }
      }
    };

    /**
     * 등록 파일 삭제
     */
    $scope.subtractElement = function (value, size) {
      $(`#file${value}`).remove();
      _.some($scope.refundData.file_array, function (v) {
        if (v.size == size) {
          const index = $scope.refundData.file_array.indexOf(v);

          if (index > -1) {
            $scope.refundData.file_array.splice(index, 1);
          }

          return true;
        }
      });
    };

    /**
     * 찯 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

  });
