'use strict';

angular.module('gmpApp')
  .controller('SkuExcelDownIntegratedCtrl', function ($scope, $rootScope, data, userInfo, shipmentModel, commonSVC, settings, gettextCatalog, $uibModalInstance, commonModel, $timeout, localStorageService, $state, securityModel, warehouseModel) {
    $scope.showDesc = localStorageService.get('excelDownIntegratedYn') || 'Y'; // Description 칸

    $scope.percent = '0%';
    $scope.isOurComp = $rootScope.user_profile.remote_ip === '127.0.0.1' || $rootScope.user_profile.sol_no == 496;
    $scope.modalData = data;
    $scope.templateList = [];
    $scope.editTemplateData = {};
    $scope.down_additional_sheet = false;
    let template;
    const searchForm = {
      search_word: '',
      search_key: 'ALL',
      isGlobal: data.isGlobal
    };
    $scope.searchForm = angular.copy(searchForm);
    $scope.searchData = {
      totalCount: 0,
      selectCount: 0,
      showCount: 10,
      selectType: false,
      all_checked: false,
      search_key_items: [],
      search_multi_items: []
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      modal: true,
      selectOptions: {
        multiSelect: false
      },
      pinningColumns: [],
      alignCenterColumns: ['cd', 'mdate', 'name', 'file_type', 'bookmark_yn'],
      alignRightColumns: [],
      defaultSortingColumns: ['bookmark_yn'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      gridHeight: 405,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/stock/skuIntegratedExcelTemplate/list`,
        requestWillAction: function (data) {
          return Object.assign(data, $scope.searchForm);
        },
        requestDidAction: function (result) {
          $scope.searchData.totalCount = result.totalCount;
          $scope.searchData.selectCount = result.rowsTotal;
          $scope.templateList = result.results;
          $scope.linkage_depot_list = [].concat(...$scope.templateList.map(tmp => tmp.linkage_depot_list?.split(',')).filter(Boolean));
          result.recordsTotal = result.totalCount;

          return result.results;
        }
      },
      columns: [
        {
          key: 'cd',
          width: 123,
          title: '분류',
          template: function (row) {
            return row.cd === 'T2' ? '기본양식' : row.cd_name;
          }
        },
        {
          key: 'mdate',
          title: '최종수정일',
          width: 185,
          template: function (row) {
            return row.cd === 'T2' ? '-' : row.mdate;
          }
        },
        {
          key: 'name',
          title: '양식명',
          width: 234
        },
        {
          key: 'file_type',
          title: '저장형식',
          width: 122
        },
        {
          key: 'bookmark_yn',
          title: '즐겨찾기',
          width: 73,
          template: function (row) {
            row.bookmark_yn = !!row.bookmark_yn;

            return '<pa-ui-switch ng-model="row.entity.bookmark_yn" fn="grid.appScope.changeDo(row.entity)"></pa-ui-switch>';
          }
        }
      ]
    };

    $scope.searchFn = {
      // 몇 건씩 볼지 결정
      changeLen: function (len) {
        $scope.searchData.showCount = len;
        $scope.grid.methods.length(len);
        $scope.grid.methods.reloadData(function () { }, true);
      },
      // 탭 변경
      // 검색
      searchDo: function () {
        $scope.grid.methods.reloadData(function () { }, true);
      },
      // 초기화
      resetDo: function () {
        $scope.searchForm = angular.copy(searchForm);
        $scope.searchFn.searchDo();
      }
    };

    $scope.search = function (e) {
      if ((e && (e.keyCode === 13 || e.which === 13))) {
        $scope.searchFn.searchDo();
      }
    };

    /**
     * 설명 Div 접기 변경
     */
    $scope.changeShowDesc = () => {
      $scope.showDesc = $scope.showDesc === 'Y' ? 'N' : 'Y';
      localStorageService.set('excelDownIntegratedYn', $scope.showDesc);
    };

    $scope.changeDo = async function ({ template_no, bookmark_yn }) {
      try {
        await shipmentModel.updateSkuIntegratedExcelTemplate({ template_no, template: { bookmark_yn } });

        commonSVC.showToaster('success', '성공', '즐겨찾기 수정 성공');
        $scope.searchFn.searchDo(); //즐겨 찾기 수정후 리스트재정렬 위해 새로고침
      } catch (error) {
        commonSVC.showToaster('error', '실패', '즐겨찾기 수정 실패');

        return false;
      }
    };

    /**
     * 양식 추가하기
     */
    $scope.addTemplate = function () {
      const resolve = {};

      resolve.data = {
        pageType: 'add',
        page: data.page,
        isGlobal: data.isGlobal,
        used_depot_list: $scope.linkage_depot_list // 이미 연동된 배송처 리스트
      };

      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true, sol_no: $rootScope.user_profile.pa_sol_no });
      };

      const modal = commonSVC.openModal('lg', resolve, 'SkuExcelEditIntegratedCtrl', 'views/prod/product/modals/sku_excel_edit_integrated.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchFn.resetDo();
        }
      });
    };

    /**
     * 양식 수정하기
     */
    $scope.editTemplate = function () {
      template = $scope.grid.methods.selectedData('template_no');

      if (!template || !template.length) {
        commonSVC.showMessage('수정할 양식을 선택하세요.');

        return false;
      } else if (template[0].cd === 'T2') {
        commonSVC.showMessage('기본양식은 수정할 수 없습니다.');

        return false;
      }

      const resolve = {
        data: {
          pageType: 'edit',
          template_no: template[0],
          page: data.page, // 초기화 작동하지 않는 문제로 페이지 정보 넘겨줌
          isGlobal: data.isGlobal,
          used_depot_list: $scope.linkage_depot_list // 이미 연동된 배송처 리스트
        },
        warehouseList: function() {
          return warehouseModel.ListAll({ use_yn: true, sol_no: $rootScope.user_profile.pa_sol_no });
        }
      };

      const modal = commonSVC.openModal('lg', resolve, 'SkuExcelEditIntegratedCtrl', 'views/prod/product/modals/sku_excel_edit_integrated.html');

      modal.result.then(function (result) {
        if (result === 'success') {
          $scope.searchFn.resetDo();
        }
      });
    };

    /**
     * 양식 삭제하기
     */
    $scope.deleteTemplate = function () {
      template = $scope.grid.methods.selectedData('all');
      if (template && template.length && template[0].template_no) {
        if (template[0].cd === 'T2') {
          commonSVC.showMessage('기본양식은 삭제할 수 없습니다.');

          return false;

        } else {
          let msg = '양식을 삭제 하시겠습니까?';

          if (template[0].bookmark_yn) {
            msg = `선택한 데이터는 즐겨찾는 EXCEL 양식입니다.\n${msg}`;
          }

          commonSVC.showConfirm(msg, '', function () {
            shipmentModel.deleteSkuIntegratedExcelTemplate({ template_no: template[0].template_no }, function (state) {
              if (state === 'success') {
                commonSVC.showToaster('success', '성공', 'Excel 양식 삭제 성공');
                $scope.searchFn.resetDo();

              } else {
                commonSVC.showToaster('error', '실패', 'Excel 양식 삭제 실패');

                return false;
              }
            });
          });
        }
      } else {
        commonSVC.showMessage('삭제할 양식을 선택하세요.');

        return false;
      }
    };

    /**
     * 양식 복사하기
     */

    $scope.copyTemplate = function () {
      template = $scope.grid.methods.selectedData('all');

      if (template && template.length && template[0].template_no) {
        if (template[0].cd === 'T2') {
          commonSVC.showMessage('기본양식은 복사할 수 없습니다.');

          return false;
        } else {
          commonSVC.showConfirm('양식을 복사 하시겠습니까?', '', function (flag) {
            if (flag) {
              shipmentModel.copySkuIntegratedExcelTemplate({ template_no: template[0].template_no }, function (state) {
                if (state === 'success') {
                  commonSVC.showToaster('success', '성공', 'Excel 양식 복사 성공');
                  $scope.searchFn.resetDo();

                  return true;
                } else {
                  commonSVC.showToaster('error', '실패', 'Excel 양식 복사 실패');

                  return false;
                }
              });
            }
          });
        }
      } else {
        commonSVC.showMessage('복사할 양식을 선택하세요.');
      }
    };

    /**
     * 다운로드 버튼 비활성화 여부 확인
     */
    $scope.checkBtnDisable = () => {
      return !$scope.modalData.count;
    };

    /**
     * 엑셀 다운로드
     * */
    $scope.download = async function () {
      $scope.alerts = {};

      const apiUrl = data.url;

      template = $scope.grid.methods.selectedData('all');

      if (!template.length) {
        commonSVC.showMessage('양식을 선택해 주십시오.');

        $('.progress-bar-dir').css({ display: 'none' });
        $('#downloadBtn').attr('disabled', false).html('다운로드');

        return false;
      }

      let params = {
        template_no: template[0].template_no,
        is_all: data.isAll,
        down_additional_sheet: $scope.down_additional_sheet,
        sku_code: data.sku_code,
        page: data.page
      };

      if (data.searchForm) {
        params = angular.merge(params, { ...data.searchForm, shopType: data.isGlobal ? 'global' : 'domestic' });
      }
      // 선택 다운로드
      if (!data.isAll) {
        params.sku_code = data.sku_code;
      }
      if ($scope.percent) {
        $('#down_add_sheet').css({ display: 'none' });
        $('.progress-bar-dir').css({ display: 'inline-block' });
      }

      params.timeout = Math.ceil(parseInt($scope.modalData.count) / 2000) * 60 * 1000; // 2000 개당 1분으로 타임아웃 보냄
      commonModel.excelDownload(apiUrl, params, async function (state, result) {
        if (state === 'success') {
          const url = result?.result?.url;
          // 팝업차단 설정 추가.
          if (url) {
            let newWin;
            let isFail = false;
            if (_.isArray(url)) {
              for (const url of url) {
                isFail = true;
                newWin = window.open(url);
                await new Promise(resolve => {
                  const intervalId = setInterval(() => {
                    if (newWin && newWin.closed) {
                      clearInterval(intervalId);
                      isFail = false;
                      resolve();
                    }
                  }, 500);
                });
              }
            } else {
              newWin = window.open(url);
              if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                isFail = true;
              }
            }
            if (isFail) {
              commonSVC.showToaster('error', '실패', '팝업 차단을 해제해주세요.');
              $('#downloadBtn').attr('disabled', false).html('다운로드');
              $('.progress-bar-dir').css({ display: 'none' });
              $('#down_add_sheet').css({ display: 'block' });
            } else {
              $scope.isFinish = true;
              $scope.percent = '100%';
              commonSVC.showToaster('success', '성공', '엑셀 다운로드에 성공하였습니다.');
              $uibModalInstance.close('success');
            }

          }
        } else {
          commonSVC.showToaster('error', '실패', result.data?.error?.includes('선택 항목') ? result.data?.error : '엑셀 다운로드에 실패하였습니다.');
          $('.progress-bar-dir').css({ display: 'none' });
          $('#downloadBtn').attr('disabled', false).html('다운로드');
        }
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.getBtnText = function() {
      return '다운로드';
    };
  });