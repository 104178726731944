'use strict';

/**
 * Created by ally on 2017. 6. 23..
 */
angular.module('gmpApp')
  .controller('OrderShipmentDetailCtrl', function ($rootScope, $scope, $filter, $compile, $uibModalInstance, $timeout,
    settings, userInfo,
    commonSVC, logHistorySVC, shipmentSVC, columnSVC,
    shipmentModel, deliveryModel, data, gettextCatalog,
    supplierModel, warehouseModel, productModel, systemModel, columnModel, deliveryInfoList, deliveryInfoListAll) {

    $scope.isDepot = $rootScope.user_profile.auth_type === '배송처';
    $scope.isManageStock = $rootScope.user_profile.sol_stock > 0;
    $scope.fromPage = data.fromPage;
    $scope.uniq = data.uniq;
    $scope.agency_name = data.agency_name || '';
    $scope.mapRuleIdx = [];
    $scope.viewModelNo = $rootScope.affName === 'LG전자' && !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));
    // 신규주문, 출고관리, 배송관리, 전체 주문 관리 수정가능
    $scope.orderEdit = (
      ($scope.fromPage === 'unstoring' && commonSVC.checkRole('order.unstoring+write', userInfo)) ||
      ($scope.fromPage === 'order' && commonSVC.checkRole('order.order+write', userInfo)) ||
      ($scope.fromPage === 'delivery' && commonSVC.checkRole('order.delivery+write', userInfo)) ||
      ($scope.fromPage === 'integrated' && commonSVC.checkRole('order.integrated+write', userInfo))
    );

    /** 수정버튼 노출 여부 */
    $scope.isViewEditButton = ['unstoring', 'order', 'delivery'].includes($scope.fromPage) && !$scope.isDepot && (data.fromPage !== 'suspicious');

    let isClaim = false;

    // 부모 솔루션 번호가 들어오는경우 해당 정보로 상세데이터 조회해야 함.
    const sub_sol_no = data.sub_sol_no || 0;

    // G마켓물류주문 수정가능여부
    $scope.ebaydepotOrderEdit = true;

    // 탭 인덱스
    $scope.tabActive = data.tab ? data.tab : 0;
    // 주문 데이터
    $scope.shipment = angular.copy(shipmentSVC.shipment);
    // 이전 주문데이터
    $scope.beforeShipment = [];
    // 이전 문의내역
    $scope.beforeCS = [];
    // 배송처 리스트
    $scope.warehouseList = data.warehouseList;
    // 나라 리스트
    $scope.countryList = $rootScope.countryList;

    // 택배사 리스트
    // 사용 택배사 미설정 시 전체 택배사 노출
    $scope.deliveryList = deliveryInfoList || deliveryInfoListAll;
    // 배송방법
    $scope.deliMethodList = angular.copy(shipmentSVC.deliMethodList);
    // 대리점 계정일 경우 내일배송 관련 값 추가
    if ($rootScope.user_profile.pa_sol_no) {
      $scope.deliMethodList.splice(-1, 0, ...['빠른 가전 배송', '내일 배송']);
    }

    // 배송서비스
    $scope.deliveryCarrList = [];

    // 매칭 한 상품들의 배송처
    const matchDepotNo = { prod: null, opt: null };

    // 사본여부 2018-05-08 rony
    $scope.copyedOrder = false;

    // 선택된 배송처
    $scope.selected_depot = {};

    // 로그용 데이터
    let oldData = {};
    const logField = {
      shop_ord_no: '쇼핑몰주문번호',

      gift_name: '사은품',
      order_name: '주문자명',
      order_id: '주문자 ID',
      order_tel: '주문자 전화번호',
      order_htel: '주문자 휴대폰번호',

      to_name: '수령자명',
      to_name_eng: '수령자 영문명',
      to_tel: '수령자 전화번호',
      to_htel: '수령자 핸드폰번호',
      to_zipcd: '수령자 우편번호',
      to_addr1: '수령자 주소',
      to_addr2: '수령자 상세주소',
      to_ctry_cd: '수령자 국가',

      ship_cost: '배송비',
      ship_method: '배송방법',
      ship_msg: '배송메세지',

      sku_pack: '주문옵션 SKU코드(세트코드)',
      prod_add_opt_sku_code: '추가구매옵션 SKU코드(세트코드)',
      shop_add_opt_name: '추가구매옵션 명칭',
      shop_cost_price: '원가',

      pack_unit: '건별 출고수량',
      opt_sale_cnt: '추가구매옵션 수량',

      invoice_no: '송장번호',
      carr_name: '택배사',

      depot_name: '배송처',
      depot_no: '배송처',

      shop_sale_name: '온라인상품명',
      shop_opt_name: '옵션명',
      sales: '금액',
      sale_cnt: '주문수량',

      bundle_width: '박스사이즈(가로)',
      bundle_height: '박스사이즈(세로)',
      bundle_depth: '박스사이즈(높이)',
      bundle_weight: '박스무게',

      gprivate_no: '개인통관번호',

      misc14: 'HS코드',
      misc15: '제조국',
      misc16: '개당 순 중량',

      c_sale_cd: '판매자관리코드',
      shop_supply_price: '공급가',

      pa_addcol_모델번호: '모델번호',

      pay_amt: '실결제금액',
      discount_amt: '할인금액'
    };

    const stockMatchState = ['결제완료', '신규주문', '주문보류', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중', '배송완료', '구매결정'];

    // 모달내 바로 업데이트 된경우 확인용
    let updateYn = false;

    $scope.memoUpdate = false;

    const serviceList = []; //배송서비스 리스트

    $scope.searchData = {};
    $scope.searchForm = {};

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      enablePaginationControls: false,
      modal: true,
      gridHeight: 400,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: 'ALL',
      notMovingColumns: 'ALL',
      notVisibleColumns: [],
      alignCenterColumns: ['ord_status', 'widget', 'shop_name', 'wdate', 'sale_cnt', 'carr_name'],
      alignRightColumns: ['sales'],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestMethod: 'GET',
        requestUrl: `${settings.pa20ApiUrl}/app/order/previous-orders`,
        requestWillAction: () => {
          return {
            uniq: $scope.uniq,
            sub_sol_no
          };
        },
        requestDidAction: (result) => {
          return result.results;
        }
      },
      columns: [
        {
          key: 'ord_status',
          title: '상태',
          width: 100,
          template: (row) => {
            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: (row) => {
            return `<button type="button" class="btn btn-default btn-xxs mr-5 summary-order" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>
            <button type="button" ng-class="{'bg-orange-300': row.entity.memo_complete_yn === 0 && row.entity.memo_yn === 1, 'btn-default': row.entity.memo_complete_yn === 0 && row.entity.memo_yn !== 1, 'btn-success': row.entity.memo_complete_yn === 1  && row.entity.memo_yn === 1}" class="btn btn-xxs mr-5 dt-select-do" ng-click="grid.appScope.openOrderCS('${row.uniq}')">메모</button>
          `;
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: (row) => {
            return `${row.shop_name}${row.shop_id ? '(' + row.shop_id + ')' : '' }`;
          }
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 150,
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호',
          width: 200,
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 150,
          template: (row) => {
            return $scope.shipment.shop_cd === 'B378' ? $scope.shipment.misc7 : $scope.shipment.shop_sale_no;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 400,
          filter: 'whiteSpace'
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 150,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'sale_cnt',
          title: '수량',
          width: 100,
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
        },
        {
          key: 'carr_name',
          title: '택배사',
          width: 100,
        },
        {
          key: 'invoice_no',
          title: '운송장번호',
          width: 150,
        },
      ]
    };

    // 모달 닫기
    $scope.cancel = function () {
      $scope.$emit('OnCloseDetail');
    };

    //속성 추가, 삭제
    $scope.calculateOPT = function (calMode, idx) {
      if (calMode === 'plus') {
        const maxCount = 10;

        if ($scope.shipment.prod_add_opt_list.length < maxCount) {
          $scope.shipment.prod_add_opt_list.push({
            ord_opt_type: '수동',
            opt_sale_cnt: 1
          });
        }
      } else {
        $scope.shipment.prod_add_opt_list.splice(idx, 1);
        $scope.priceUpdate();
      }
    };

    // SKU상품 매칭
    $scope.mappingProd = function(loca, idx) {
      const resolve = {
        data: {
          from: '선택한 주문',
          pack_unit: true,
          warehouseList: $scope.warehouseList,
          isAddOpt: loca === 'addOpt',
          isManual: $scope.shipment.shop_cd === 'A000',
          isMulti: !$scope.user_profile.pa_sol_no,
          isMultiProd: true,
        }
      };

      if (loca === 'addOpt') {
        // 추가구매옵션인 경우 기존에 사용하던 옵션명을 넘겨줌
        resolve.data.ord_opt_name = $scope.shipment.prod_add_opt_list[idx].ord_opt_name;
      }

      const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        function sm() {

          //매칭규칙생성(수정여부)
          if (loca === 'addOpt') {
            $scope.shipment.prod_add_opt_list[idx].newMappingRule = re.isSet ? re.data.set.newMappingRule : re[0].newMappingRule;
          } else {
            $scope.shipment.newMappingRule = re.isSet ? re.data.set.newMappingRule : re[0].newMappingRule;
          }

          if (loca === 'ord') {
            return stockMatch(re);
          } else {
            return addStockMatch(re, idx);
          }
        }

        // 다중 매칭 시 prod_no 오름차순 정렬이 기준이라 sort처리
        if (!re.isSet) {
          re = re.sort((p1, p2) => {
            if (p1.prod_no < p2.prod_no) {
              return -1;
            }
            if (p1.prod_no > p2.prod_no) {
              return 1;
            }

            return 0;
          });
        }

        if (re.length > 1 && re.some(re => !re.bundle_avail_yn)) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }

        if (_.indexOf(stockMatchState, $scope.shipment.ord_status) < 0) {
          if ($scope.shipment.depot_no && re.find(r => r.depot_no !== $scope.shipment.depot_no)) {
            commonSVC.showMessage('주문을 출고한 배송처와 매칭할 SKU상품에 설정된 배송처가 다릅니다.\n주문을 출고한 배송처의 SKU상품으로 매칭하시기 바랍니다.');
          }
        } else {
          if (sm() && $scope.shipment.bundle_cnt > 1) {
            if ($scope.shipment.ord_status !== '운송장출력' && re.bundle_avail_yn === 0) {
              $scope.shipment.divide_yn = true;
            } else {
              $scope.shipment.divide_yn = false;
            }
          }
          setDepotInfo();
        }
      });
    };

    // 저장
    $scope.submit = function() {
      if (!$scope.shipmentForm.$valid) {
        return false;
      }

      if (oldData.shop_ord_no) {
        if (oldData.shop_ord_no !== $scope.shipment.shop_ord_no) {
          if (!/^[-a-zA-Z0-9_\s]*$/.test($scope.shipment.shop_ord_no)) {
            commonSVC.showToaster('error', '주문수정에 실패하였습니다.', '주문 수정에 실패하였습니다.');

            return false;
          }
          $scope.shipment.isEditShopOrdNo = true;
        }
      }

      $scope.shipment.shop_add_opt_name = _.uniqBy(_.filter(_.map($scope.shipment.prod_add_opt_list, 'ord_opt_name'))).join(',');
      $scope.shipment.prod_add_opt_sku_code = _.uniq(_.flatMap($scope.shipment.prod_add_opt_list, opt => opt.set_no ? opt.sku_cd : _.map(opt.sku_list, 'sku_cd'))).filter(sku => sku).join(', ').replace(/,\s*$/, '');

      if (!oldData.set_no && oldData.sku_pack?.includes(' 외 ')) {
        oldData.sku_pack = oldData.sku_cd.split(';').join();

      }

      if ($scope.sku_list.length && !$scope.shipment.set_no && $scope.shipment.sku_pack.includes(' 외 ')) {
        $scope.shipment.sku_pack = $scope.shipment.sku_cd.split(';').join();

      }

      $scope.shipment.log_contents = logHistorySVC.compareReturnValue(logField, oldData, $scope.shipment);
      $scope.shipment.page = $scope.fromPage;

      // 재고변경 여부 (Y=변경, N=변경X)
      $scope.shipment.stockEdit = stockEditCheck() ? 'Y' : 'N';

      // 주문 옵션 수정 매칭 여부 (옵션에 매칭된 SKU상품 삭제 시에는 N)
      // SKU 다중 매칭된경우 N
      $scope.shipment.skuEdit = ((oldData.sku_cd !== $scope.shipment.sku_cd && $scope.shipment.sku_cd) || (oldData.set_cd !== $scope.shipment.set_cd && $scope.shipment.set_cd))
        && !($scope.sku_list.length > 1 && !$scope.shipment.set_cd)
        && !($scope.shipment.prod_add_opt_list?.find(opt => opt.sku_list && !opt.set_cd && opt.sku_list.length > 1)) ? 'Y' : 'N';

      if (!$scope.shipment.log_contents) {
        commonSVC.showMessage(gettextCatalog.getString('변경사항이 없습니다'));

        return false;
      }
      if ($scope.shipment.sale_cnt < 0) {
        commonSVC.showMessage(gettextCatalog.getString('주문수량은 0 이상으로 입력해주세요.'));

        return false;
      }

      const addr = $scope.shipment.to_addr1.trim().replace(/\s{2,}/g, ' '),
            isKorAddr = /[가-힣]/.test(addr[0]),
            regex = isKorAddr ? /^[가-힣]{2,8}(?:\s+[^ ]*){2,}$/ : /^[a-zA-Z-]{4,17}(?:\s+[^ ]*){2,}$/;
      // 주소 띄어쓰기 유효성 검사
      if (!regex.test(addr)) {
        commonSVC.showMessage(gettextCatalog.getString('주소 입력 시 행정구역 단위로 띄어쓰기를 해야합니다.'));

        return false;
      }

      // 주소2는 사용하지않으므로 값 비워줌.
      $scope.shipment.to_addr2 = '';

      toNameChk();
    };

    //택배사 변경
    $scope.changeCarr = function () {
      $timeout(function () {
        const carr = _.find($scope.deliveryList, { carr_no: parseInt($scope.shipment.carr_no) });

        $scope.shipment.carr_name = carr ? carr.carr_name : '';
      }, 0);
    };

    //메모 모달
    $scope.openOrderCS = function(uniq) {
      const resolve = {};

      resolve.data = {
        code: 'uniq',
        type: 'ord',
        uniq: uniq,
      };
      commonSVC.openModal('', resolve, 'memoCtrl', 'views/etc/memo.html');
    };

    //이전주문 상세보기
    $scope.showDetail = function(uniq) {
      $('.order-detail-summary').remove();
      const row = $('#order-preView');

      $scope.params = uniq;
      $scope.url = `${settings.pa20ApiUrl}/app/order/detail`;

      // $scope.detail_msg = $compile('<detail-preview params="params" type ="order_call"></detail-preview>')($scope);
      row.after($compile('<div class="order-detail-summary"><detail-preview params="params" type ="order_call"></detail-preview></div>')($scope));
    };

    //모달 끄기
    $scope.cancel = function () {
      // 상세안에서 바로수정되는건 있을경우 닫고 새로고침위해 추가
      if ($scope.memoUpdate) {
        $uibModalInstance.close('success');
      } else if (updateYn) {
        $uibModalInstance.close('update');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    init();
    function init() {
      shipmentModel.load({ uniq: [$scope.uniq], sub_sol_no }, function (state, data) {
        if (state == 'success') {
          $scope.shipment = data.order_data[0].set_no && data.order_data.find(ord => ord.prod_no === ord.main_prod_no) || data.order_data[0];
          $scope.shipment.misc15 = $scope.shipment.misc15 ? $scope.shipment.misc15 : 'KR';
          $scope.prodList = data.prod_list;
          isClaim = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf($scope.shipment.ord_status) >= 0;

          $scope.isSplitAddOpt = $rootScope.affName === 'LG전자' && ($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) && $scope.shipment.shop_ord_no.includes('+');

          // 세트상품일경우 set_sale_price로 판매가 넣어줌 2019-04-26 Alvin
          $scope.shipment.sale_price = data.order_data[0].set_no ? data.order_data[0].set_sale_price : _(data.ord_opt_list).filter({ ord_opt_seq: 1 }).map(function (opt) { return opt.sale_price * opt.pack_unit; }).sum() || 0;

          // 주문상세 2가 있는경우 주소 1에 합쳐서 노출 (주문2 노출 필드가 없음)
          if ($scope.shipment.to_addr2) {
            $scope.shipment.to_addr1 = `${$scope.shipment.to_addr1} ${$scope.shipment.to_addr2}`;
          }

          // 주문번호 null인 경우 체크
          if ($scope.shipment.shop_ord_no) {
            // 주문번호에 _copy 가 포함되어있는경우 사본주문으로 처리 2018-05-08 rony
            if ($scope.shipment.shop_ord_no.indexOf('_copy') > 0) {
              $scope.copyedOrder = true;
            }
          }

          //EBAY인보이스 내용 파싱
          if ($scope.shipment.bundle_invoice_ebay) {
            const curr_map = {
              USD: '$',
              EUR: '€',
              AUD: '$',
              GBP: '￡'
            };

            $scope.shipment.invoice = JSON.parse($scope.shipment.bundle_invoice_ebay);
            $scope.shipment.invoice.shippingService = _.map($scope.shipment.invoice.shippingList, function(e) {
              return `${e.shippingService} - ${curr_map[$scope.shipment.ord_curr_cd]}${e.shippingCost}`;
            }).join('<br>');
            $scope.shipment.invoice.adjustmentCost = ($scope.shipment.invoice.adjustment === 'DISCOUNT' ? '-' : '+') + curr_map[$scope.shipment.ord_curr_cd] + $scope.shipment.invoice.adjustmentAmount;
          }

          const sku_list = data.ord_opt_list.filter(opt => opt.ord_opt_seq === 1);

          // SKU코드에 수량 같이 나오는거 삭제
          $scope.shipment.sku_pack = $scope.shipment.set_no ? $scope.shipment.set_cd : $scope.shipment.sku_cd ? `${$scope.shipment.sku_cd.split(';')[0]}${sku_list.length > 1 ? ` 외 ${sku_list.length - 1}건` : ''}` : '';
          $scope.shipment.prod_pack = $scope.shipment.set_no ? $scope.shipment.set_name : $scope.shipment.prod_name ? $scope.shipment.prod_name : '';
          $scope.shipment.carr_no = $scope.shipment.carr_no ? $scope.shipment.carr_no.toString() : $scope.shipment.carr_no;
          $scope.shipment.stock_cd = $scope.shipment.stock_cd ? $scope.shipment.stock_cd.split(';')[0] : '';

          $scope.sku_list = angular.copy(data.order_data).map(prod => {
            prod.sku_cd = prod.prod_sku_cd;

            return prod;
          });

          // 건별출고수량은 세트상품전체를 하나의 단위로 보기때문에 세트내의 상품 출고수량을 더해주지 않음 (QA팀 확인) 2018-11-08 rony
          // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
          $scope.shipment.pack_unit = data.order_data.reduce((prev, curr) => prev + +curr.pack_unit, 0);

          // 옵션에 매칭되어있는 SKU상품 or 세트상품의 원가
          $scope.ord_opt_cost_price = _(data.ord_opt_list).filter({ ord_opt_seq: 1 }).map(function (opt) {
            return (opt.set_cost_price || opt.cost_price) * opt.pack_unit;
          }).sum();

          //$scope.shipment.total_cnt = pack_unit ? pack_unit * $scope.shipment.sale_cnt : '';

          // 추가구매옵션
          settingDetailData(data);

          setDepotInfo();

          $scope.orderEdit = $scope.orderEdit && data.status !== '운송장출력' && !_.includes(['발송요청중', '발송요청 완료', '발송 완료'], $scope.shipment.ebaydepot_link_status);

          $scope.ebaydepotOrderEdit = !_.includes(['발송요청중', '발송요청 완료', '발송 완료'], $scope.shipment.ebaydepot_link_status);
          $scope.ebaydepotInvoice = !_.includes(['발송요청 완료'], $scope.shipment.ebaydepot_link_status);

          // 기본옵션
          matchDepotNo.prod = $scope.shipment.prod_no ? $scope.shipment.depot_no : null;

          // 추가구매옵션
          const matchedAddOpts = _.filter($scope.shipment.prod_add_opt_list, function (opt) {
            return opt.sku_list[0].depot_no;
          });

          matchDepotNo.opt = matchedAddOpts.length ? matchedAddOpts[0].sku_list[0].depot_no : null;

          // 배송처수정 주문수정가능할때만 수정할 수 있게 조건추가
          $scope.depotChangeYn = _.indexOf(stockMatchState, $scope.shipment.ord_status) >= 0 && (!matchDepotNo.prod && !matchDepotNo.opt) && $scope.orderEdit;

          // G마켓물류 sku초과 여부
          $scope.shipment.skuOverYn = $scope.shipment.bundle_sku_cnt > 30;

          // 직접입력 주문과 복사주문은 주문정보 수정이 추가로 가능해야 하기때문에 변수 추가. 2018-11-27 rony
          $scope.orderEdit_etc = !!($scope.orderEdit === true && ($scope.shipment.ori_uniq !== null || $scope.shipment.shop_cd === 'A000'));

          // 기본 SKU상품이 매칭되어 있지 않고, 추가구매옵션만 매칭된 경우 배송처 공란 문제 및 배송처가 같지 않더라도 수정이 가능 한 문제 처리
          // 2018-12-03 Jacob
          let depots = [];

          // SKU상품 매칭 + 배송처 지정x
          if ($scope.prodList && $scope.prodList.length && (!$scope.selected_depot || !$scope.selected_depot.depot_no)) {
            _.each($scope.prodList, function (prod) {
              // prodList 에 depot_no가 있는 경우 처리
              if (prod.depot_no && $scope.depotChangeYn) {
                depots = _.filter($scope.warehouseList, function (w) {
                  return w.depot_no == prod.depot_no;
                });

                if (depots.length) {
                  $scope.depotChangeYn = false;
                  $scope.selected_depot = depots[0];
                  $scope.shipment.depot_name = depots[0].warehouse_name;
                  $scope.shipment.depot_no = depots[0].code;
                  $scope.shipment.prod_add_opt_list[0].sku_list[0].depot_no = depots[0].code;
                  matchDepotNo.opt = depots[0].code;
                }
              }
            });
            // 위에서 처리 되지 않는 경우 실행
            if ($scope.depotChangeYn) {
              const depotData = {};
              let pIndex;

              // 매칭된 상품 번호 가져오기
              _.each($scope.prodList, function (prod, index) {
                if (prod.prod_no) {
                  depotData.prod_no = prod.prod_no;
                  pIndex = index;

                  return;
                }
              });

              if (depotData.prod_no) {
                // 해당 상품번호로 배송처정보 가져오기
                productModel.getDepotNo(depotData.prod_no, function (state, data) {
                  if (state === 'success' && data.results.length) {
                    $scope.depotChangeYn = false;
                    depots = _.filter($scope.warehouseList, function (w) {
                      // 해당 번호에 맞는 배송처 정보 저장
                      return w.code == data.results[0].depot_no;
                    });
                    if (depots.length) {
                      $scope.selected_depot = depots[0];
                      $scope.shipment.depot_name = depots[0].warehouse_name;
                      $scope.shipment.depot_no = depots[0].code;
                      $scope.shipment.prod_add_opt_list[pIndex].sku_list[0].depot_no = depots[0].code;
                      matchDepotNo.opt = depots[0].code;
                    }
                  }
                });
              }
            }
          }

          // 클레임 주문시 0처리
          if (isClaim && !$rootScope.osse_sol) {
            $scope.shipment.sale_cnt = 0;
            $scope.shipment.sales = 0;
            $scope.shipment.shop_cost_price = 0;
            $scope.shipment.sale_price = 0;
            $scope.shipment.ord_opt_cost_price = 0;
            $scope.shipment.ship_cost = 0;
            $scope.shipment.shop_supply_price = 0;
            $scope.shipment.pay_amt = 0;
            $scope.shipment.discount_amt = 0;

            if ($scope.shipment.prod_add_opt_list) {
              _.forEach($scope.shipment.prod_add_opt_list, function (opt) {
                opt.opt_sale_cnt = 0;
              });
            }
          }

          const checkOnList = $scope.deliveryList.filter(o => o.carr_no == $scope.shipment.carr_no);

          if (!checkOnList.length) {
            $scope.unlisted = $rootScope.deliveryInfoListAll.find(o => o.carr_no == $scope.shipment.carr_no);
          }

          /// 롯데글로벌로지스틱스 제휴인경우 택배사와 송장번호가 입력되어있으면 수정버튼 비노출.
          if (userInfo.user.agency_c_no === 208224 && $scope.shipment.invoice_no && $scope.shipment.carr_no) {
            $scope.isViewEditButton = false;
            $('form[name="shipmentForm"]').find('input,select,button').prop('disabled', true);
          }
        } else {
          commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
        }

        getBeforeCS();

        //추가 컬럼 확목 조회
        columnModel.detailColumn({ uniq: $scope.uniq, type: 'ord' })
          .then((re) => {
            if (re.status === 200) {
              re.data.result.forEach((row) => {
                if (row.input_type === 'int' && row.col_val) {
                  row.col_val = parseFloat(row.col_val);
                }

                if (row.enum_data) {
                  row.enumList = columnSVC.enumDataSet(row.enum_data);
                  const findColVal = _.find(row.enumList, (o) => {
                    return o[0] === row.col_val;
                  });

                  if (findColVal) {
                    row.col_val = findColVal[1];
                  }
                }

              });

              $scope.shipment.addColData = _.groupBy(re.data.result, 'addcol_type');
            }
          }).then(() => {
            // 원가는 저장된 원가를 뿌려주므로 판매가만 계산
            salePriceUpdate();
          });
      });
    }

    // 모달 밖 세로 스크롤 늘어나는 문제 해결 2020-06-08 Tim
    $(document).ready(() => {
      $('div.modal').css({
        overflow: 'hidden',
        height: $(window).height(),
        width: $(window).width(),
      });
    });

    /**
     * 배송처 변경
     */
    $scope.changeDepot = function() {
      // SKU상품 매칭 되어있을 때만 선택한 배송처와 매칭된 SKU상품의 배송처 검사 2018-11-15 Amelia
      if ($scope.shipment.sku_pack || $scope.shipment.prod_add_opt_sku_code) {
        const sku_cd = $scope.shipment.sku_pack ? $scope.shipment.sku_pack.split(' 외 ')[0] : $scope.shipment.prod_add_opt_sku_code ? $scope.shipment.prod_add_opt_sku_code : null;

        productModel.detail({ sku_code: sku_cd }, function(state, re) {
          if (state == 'success') {
            // 일치하면 값 세팅
            if (re.results.depot_name == $scope.selected_depot.warehouse_name) {
              $scope.shipment.depot_no = $scope.selected_depot.code;
              $scope.shipment.depot_name = $scope.selected_depot.warehouse_name;
            } else {
              // 일치하지 않으면 값 초기화
              $scope.selected_depot = null;
              $scope.shipment.depot_no = null;
              $scope.shipment.depot_name = null;
              commonSVC.showMessage(gettextCatalog.getString('매칭된 SKU상품과 배송처가 다릅니다.'));
            }
          } else {
            commonSVC.showMessage(gettextCatalog.getString('배송처 조회에 실패하였습니다.'));

            return false;
          }
        });
      } else {
        $scope.shipment.depot_no = $scope.selected_depot.code;
        $scope.shipment.depot_name = $scope.selected_depot.warehouse_name;
      }
    };

    /**
     * 배송처 정보 세팅
     */
    function setDepotInfo() {
      _.each($scope.warehouseList, function(w) {
        if (w.code == $scope.shipment.depot_no) {
          $scope.shipment.depot_name = w.warehouse_name;
          $scope.selected_depot = w;

          return;
        }
      });
    }

    /**
     * hscode 검색
     **/
    $scope.searchHscode = function () {
      const resolve = {};

      resolve.data = {
        name: $scope.shipment.misc14
      };
      const redata = commonSVC.openModal('lg', resolve, 'CategorySearchHscodeCtrl', 'views/settings/category/searchHscode.html');

      redata.result.then(function (re) {
        $scope.shipment.misc14 = re;
      });
    };

    // 재고 변경 됐는지 확인
    // 2019-01-30 Alvin
    function stockEditCheck () {

      // 주문 수량이 바뀌었으면 재고변경
      if (oldData.sale_cnt !== $scope.shipment.sale_cnt) {
        return true;
      }

      // 옵션을 새로 매칭했으면 재고변경
      if ($scope.shipment.sku_list) {
        return true;
      }

      let returnValue = false;

      // 추가구매옵션 개수가 다르면 재고 변경
      if ($scope.shipment.prod_add_opt_list.length === oldData.prod_add_opt_list.length) {
        _.forEach($scope.shipment.prod_add_opt_list, function (opt, opt_key) {
          const oldOpt = oldData.prod_add_opt_list[opt_key];

          // 추가구매옵션 출고수량 다르면 재고 변경
          if (opt.opt_sale_cnt !== oldOpt.opt_sale_cnt) {
            returnValue = true;

            return false;
          }

          if (opt.hasOwnProperty('sku_list')) {
            _.forEach(opt.sku_list, function (prod, key) {
              // 추가구매옵션 매칭된 SKU상품 다르면 재고변경
              if (prod.prod_no !== oldOpt.sku_list[key].prod_no) {
                returnValue = true;

                return false;
              }
            });
          }
        });
      } else {
        returnValue = true;
      }

      return returnValue;
    }

    //추가구매옵션 포맷팅 및 oldData 셋팅
    function settingDetailData({ prod_list, ord_opt_list }) {
      $scope.shipment.prod_add_opt_list = [];
      const new_prod = angular.copy(shipmentSVC.shipment.prod_add_opt_list[0]);

      //[{ord_opt_name: '', ord_opt_type : '수동', opt_sale_cnt: 1 , sku_cd: '', sku_list: [{prod_no: null, depot_no: null, pal_no: null , pack_unit: 1}]}]

      // 추가 구매 옵션 없는 주문은 수량 공란으로 노출
      if (!new_prod.ord_opt_name) {
        new_prod.opt_sale_cnt = null;
      }

      let main_prod, idx;
      _.each(_.groupBy(prod_list, 'ord_opt_seq'), function(order) {
        main_prod = order[0];

        //세트상품의 대표상품을 0번 위치로 바꿔줌 2020-05-21 Tim
        if (main_prod.set_no) {
          idx = order.findIndex(ord => ord.prod_no === ord.main_prod_no);
          main_prod = order[idx];
          order = order.splice(idx, 1).concat(order);
        }

        const real_sku_list = ord_opt_list.filter(o => o.ord_opt_seq == main_prod.ord_opt_seq);

        const sku_list = [];
        let pack_unit = 0, cost_price = 0, set_cost_price = 0, sku;
        order.forEach(o => {
          sku = real_sku_list.length ? real_sku_list.find(s => s.prod_no == o.prod_no) : null;
          if (!matchDepotNo.opt || o.depot_no) {
            matchDepotNo.opt = o.depot_no;
          }
          pack_unit += o.pack_unit;
          cost_price += o.cost_price * o.pack_unit;
          set_cost_price += (sku?.set_cost_price || o.cost_price) * o.pack_unit;

          sku_list.push({
            prod_no: o.prod_no,
            depot_no: o.depot_no,
            pal_no: o.pal_no,
            pack_unit: o.pack_unit,
            sku_cd: o.sku_cd,
            prod_name: o.prod_name,
            sale_price: o.sale_price,
            cost_price: o.cost_price,
            set_cost_price: (sku?.set_cost_price || o.cost_price),
            attri: o.attri,
            stock_cd: o.stock_cd,
            opt_sale_cnt: o.opt_sale_cnt,
            depot_name: o.depot_name
          });
        });

        // SKU코드에 수량 같이 나오는거 삭제
        const cd = main_prod.set_no ? main_prod.set_cd : main_prod?.sku_cd || '';
        const cd_pack = main_prod.set_no ? main_prod.set_cd :
          main_prod.sku_cd && order.length > 1 ? `${main_prod.sku_cd}${` 외 ${order.length - 1}건`}` :
            main_prod?.sku_cd || '';
        const set_no = main_prod.set_no ? main_prod.set_no : null;
        const opt_prod_name = main_prod.set_no ? main_prod.set_name : main_prod.sku_cd ? main_prod.prod_name : '';
        const sale_price = main_prod.set_no ? main_prod.set_sale_price * main_prod.set_pack_unit
          : order.reduce((sum, ord) => sum + (ord.sale_price * ord.pack_unit), 0);

        // 건별출고수량은 세트상품전체를 하나의 단위로 보기때문에 세트내의 상품 출고수량을 더해주지 않음 (QA팀 확인) 2018-11-08 rony
        // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
        $scope.shipment.prod_add_opt_list.push({
          ord_opt_name: main_prod.ord_opt_name,
          ord_opt_type: '수동',
          opt_sale_cnt: main_prod.opt_sale_cnt,
          sku_cd: cd,
          sku_pack: cd_pack,
          sku_list: sku_list,
          ord_opt_seq: main_prod.ord_opt_seq,
          uniq: main_prod.uniq,
          set_no: set_no,
          set_cd: set_no ? cd : '',
          pack_unit: pack_unit,
          set_pack_unit: main_prod.set_pack_unit,
          total_cnt: pack_unit > 0 ? (main_prod.opt_sale_cnt * pack_unit) : '',
          bundle_avail_yn: main_prod.bundle_avail_yn,
          cost_price: cost_price,
          set_cost_price: set_cost_price,
          sale_price: sale_price,
          opt_sku_cd_list: order.map(o => o.sku_cd).join(', '),
          opt_prod_name: opt_prod_name,
          prod_attr: main_prod.attri_pack,
          stock_cd: main_prod.stock_cd,
          barcode: main_prod.barcode,
        });
      });

      if (!$scope.shipment.prod_add_opt_list.length) {
        $scope.shipment.prod_add_opt_list.push(new_prod);
      }

      $scope.shipment.shop_add_opt_name = _.uniqBy(_.filter(_.map($scope.shipment.prod_add_opt_list, 'ord_opt_name'))).join(',');
      $scope.shipment.prod_add_opt_sku_code = _.uniq(_.flatMap($scope.shipment.prod_add_opt_list, opt => opt.set_no ? opt.sku_cd : _.map(opt.sku_list, 'sku_cd'))).filter(sku => sku).join(', ').replace(/,\s*$/, '');
      $scope.status = $filter('statusColor')($scope.shipment.ord_status);  //주문상태 css
      oldData = angular.copy($scope.shipment); // 수정 로그용 원본 데이터
    }

    // 이전문의내역 불러오기
    function getBeforeCS() {
      shipmentModel.getBeforeCS({ uniq: $scope.uniq }, function (state, data) {
        if (state == 'success') {
          $scope.beforeCS = data.results;
        } else {
          commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
        }
      });
    }

    function toNameChk() {
      if ($scope.shipment.log_contents.join('').indexOf('수령자') > -1) {
        $timeout(function () {
          commonSVC.showConfirm('수정하시겠습니까?', ' 수령자정보 수정시 함께 묶인주문의 수령자정보도 변경됩니다. ', function (confirm2) {
            if (confirm2) {
              update();
            }
          });
        }, 300); // 스윗얼럿 버그로인해 0.3초 줌 수정 금지
      } else {
        update();
      }
    }

    function update() {
      prodAddOptSet();
      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $scope.viewModelNo) {
        // 로그필드에 설정된 추가컬럼
        const addColLogKeys = Object.keys(logField).filter(key => key.startsWith('pa_addcol'));

        // 로그필드에 설정된 추가컬럼이 아니면 데이터 제거
        Object.keys($scope.shipment).forEach(key => {
          if (key.startsWith('pa_addcol') && !addColLogKeys.includes(key)) {
            delete $scope.shipment[key];
          }
        });
      }

      $scope.shipment.updateInvoiceTime = !oldData.invoice_no;

      shipmentModel.update($scope.shipment, function (state, result) {

        if (state == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('주문수정에 성공하였습니다.'));
          $uibModalInstance.close('success');
          init();
        } else {
          commonSVC.showToaster('error', '주문수정에 실패하였습니다', result.data.error || '');
        }
      });
    }

    //SKU코드 매칭
    function stockMatch(re) {
      data = re.isSet ? re.data.prod : re;

      const depot_nos = _.uniqBy(_.map(data, 'depot_no'));

      if (depot_nos.length > 1 || (matchDepotNo.opt && matchDepotNo.opt != depot_nos[0])) {
        commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

        return false;
      }

      // 한주문에 합포장 불가인 상품 포함 금지
      // 기본옵션은 기본옵션 하나로 합포장불가인 주문으로 존재가능하기 때문에 혼자있을때 변경가능
      // G마켓물류주문은 패스
      if (!$scope.shipment.ebaydepot_yn) {
        const opts = $scope.shipment.prod_add_opt_list.filter(o => {
          // 세트상품에는 bundle_avail_yn 없으므로 세트상품인지 체크 (세트상품엔 합포장불가상품 추가안됨 & 합포장불가로 변경되면 세트상품 사용불가됨) 2018-11-13 Amelia
          if (!re.isSet && re.find(r => !r.bundle_avail_yn)) {
            // 매칭하려는 재고가 합포장 불가인 경우 추가구매옵션에서 매칭된 재고가 있는 경우 찾음
            return o.bundle_avail_yn !== null && o.sku_cd;
          } else {
            // 매칭하려는 재고가 합포장 가능인 경우 추가구매옵션에서 매칭된 합포장 불가 재고 찾음
            return o.bundle_avail_yn === 0 && o.sku_cd;
          }
        });

        if ((matchDepotNo.prod || matchDepotNo.opt) && opts.length) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }
      }

      if (ebaydepotNotSameDepotChk(data[0].depot_no)) {
        return false;
      }

      //선택한 상품들의 배송처가 같으면서 기존에 선택한 창고랑 같을때
      $scope.shipment.sku_pack = re.isSet ? re.data.set.set_cd : `${re[0].sku_cd}${re.length > 1 ? ` 외 ${re.length - 1}건` : ''}`;
      $scope.shipment.prod_pack = re.isSet ? re.data.set.set_name : re[0].prod_name;
      $scope.shipment.sku_list = data;
      $scope.sku_list = data;
      $scope.shipment.set_info = re.isSet ? { set_no: re.data.set.set_no, set_cd: re.data.set.set_cd, set_pack_unit: re.data.set.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.model_no = re.isSet ? re.data.set.model_no : re[0].model_no;

      let total_pack_unit = 0;
      let total_cost_price = 0;

      // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
      let re_pack_unit = data.reduce((sum, prod) => sum + +prod.pack_unit, 0);

      if (re.isSet) {
        _.each(re.data.prod, function (order) {
          total_pack_unit += order.pack_unit;
          order.pack_unit *= re.data.set.set_pack_unit; // 세트 출고수량 곱하기

          // 세트 원가가 있는경우 세트원가 사용
          total_cost_price += (order.set_cost_price || order.cost_price) * order.pack_unit;
        });

        re_pack_unit = total_pack_unit * re.data.set.set_pack_unit;
      } else {
        total_cost_price = data.reduce((prev, curr) => prev + (+curr.cost_price * curr.pack_unit || 0), 0);
      }

      // 주문 옵션에 매칭된 SKU상품 원가
      $scope.ord_opt_cost_price = total_cost_price;

      $scope.shipment.pack_unit = re_pack_unit;
      $scope.shipment.set_pack_unit = re.isSet ? re.data.set.set_pack_unit : null;
      $scope.shipment.prod_no = re.isSet ? null : re.prod_no;
      $scope.shipment.set_no = re.isSet ? re.data.set.set_no : null;
      $scope.shipment.set_cd = re.isSet ? re.data.set.set_cd : null;
      $scope.shipment.sku_cd = data.map((r) => r.sku_cd).join(';');
      $scope.shipment.bundle_avail_yn = data.find(r => !r.bundle_avail_yn) ? 0 : 1;
      $scope.shipment.sale_price = re.isSet ? re.data.set.sale_price : _(re).map(re => +re.sale_price * re.pack_unit || 0).sum();

      $scope.shipment.prod_sol_no = re.sol_no;

      // SKU상품매칭시 해당 SKU상품의 배송처도 매칭되도록 처리 2018-06-12 rony
      $scope.shipment.depot_no = data[0].depot_no;
      $scope.shipment.depot_name = data[0].depot_name;
      $scope.shipment.attri_pack = data[0].attri_pack ? data[0].attri_pack : '';
      $scope.shipment.stock_cd = data[0].stock_cd;
      $scope.shipment.barcode = data[0].barcode;
      matchDepotNo.prod = data[0].depot_no;

      $scope.depotChangeYn = false;

      // 판매가, 원가 업데이트
      $scope.priceUpdate();

      return true;
    }

    //추가구매 옵션 SKU코드 매칭
    function addStockMatch(re, idx) {
      data = re.isSet ? re.data.prod : re;

      const depot_nos = _.uniqBy(_.map(data, 'depot_no'));

      // 재고가 매칭된 옵션이 하나이고 그 옵션의 배송처를 바꾸는 경우 처리
      const depot_no = matchDepotNo.prod || matchDepotNo.opt;
      let depot_cnt = matchDepotNo.prod === depot_no ? 1 : 0;

      if (depot_no) {
        depot_cnt += $scope.shipment.prod_add_opt_list.filter((prod, i) => prod.sku_list && prod.sku_list[0]?.depot_no === depot_no && i !== idx).length;
      }

      if (depot_nos.length > 1 || (depot_no && (matchDepotNo.prod != depot_nos[0] && matchDepotNo.opt != depot_nos[0] && depot_cnt > 0))) {
        commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

        return false;
      }

      // 한주문에 합포장 불가인 상품 포함 금지
      // G마켓물류 주문은 매칭이 필수라 추가구매옵션혼자 합포장불가매칭으로 존재할수없어서 추가구매옵션에는 절대못들어가게 막음
      if (!$scope.shipment.ebaydepot_yn) {
        const opts = [{ bundle_avail_yn: $scope.shipment.bundle_avail_yn, sku_cd: $scope.shipment.sku_cd }]
          .concat($scope.shipment.prod_add_opt_list)
          .filter(function (o, k) {
            // 세트상품에는 bundle_avail_yn 없으므로 세트상품인지 체크 (세트상품엔 합포장불가상품 추가안됨 & 합포장불가로 변경되면 세트상품 사용불가됨) 2018-11-13 Amelia
            if (!re.isSet && re.find(r => !r.bundle_avail_yn)) {
              // 매칭하려는 재고가 합포장 불가인 경우 옵션 중에서 매칭된 재고가 있는 경우 찾음
              return o.bundle_avail_yn !== null && o.sku_cd && idx !== (k - 1);
            } else {
              // 매칭하려는 재고가 합포장 가능인 경우 옵션 중에서 매칭된 합포장 불가 재고 찾음
              return o.bundle_avail_yn === 0 && o.sku_cd && idx !== (k - 1);
            }
          });

        if ((matchDepotNo.prod || matchDepotNo.opt) && opts.length) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }
      }

      if (ebaydepotNotSameDepotChk(data[0].depot_no)) {
        return false;
      }

      $scope.shipment.prod_add_opt_list[idx].sku_cd = re.isSet ? re.data.set.set_cd : data.map(r => (r.sku_cd)).join(', ');
      $scope.shipment.prod_add_opt_list[idx].sku_pack = re.isSet ? re.data.set.set_cd : data.length > 1 ? `${data[0].sku_cd}${` 외 ${data.length - 1}건`}` : data[0].sku_cd;
      $scope.shipment.prod_add_opt_list[idx].ord_opt_name = re.isSet ? re.data.set.set_name : data[0].prod_name;
      $scope.shipment.prod_add_opt_list[idx].sku_list = data;
      $scope.shipment.prod_add_opt_list[idx].set_info = re.isSet ? { set_no: re.data.set.set_no, set_cd: re.data.set.set_cd, set_pack_unit: re.data.set.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.prod_add_opt_list[idx].set_pack_unit = re.isSet ? re.data.set.set_pack_unit : null;
      $scope.shipment.prod_add_opt_list[idx].cost_price = _(data).map(function (opt) { return opt.cost_price * opt.pack_unit; }).sum();

      let total_pack_unit = 0;

      if (re.isSet) {
        _.each(re.data.prod, function (order) {
          total_pack_unit += order.pack_unit;
          order.pack_unit *= re.data.set.set_pack_unit;
        });
      }

      // 건별출고수량은 세트상품전체를 하나의 단위로 보기때문에 세트내의 상품 출고수량을 더해주지 않음 (QA팀 확인) 2018-11-08 rony
      // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
      const pack_unit = re.isSet ? total_pack_unit * re.data.set.set_pack_unit : data.reduce((sum, ord) => sum + +ord.pack_unit, 0);
      //var pack_unit = re.isSet ? re.data.set.set_pack_unit : data[0].pack_unit;
      const opt_sale_cnt = $scope.shipment.prod_add_opt_list[idx].opt_sale_cnt;

      $scope.shipment.prod_add_opt_list[idx].sku_list[0].opt_sale_cnt = opt_sale_cnt;
      $scope.shipment.prod_add_opt_list[idx].pack_unit = pack_unit;
      $scope.shipment.prod_add_opt_list[idx].total_cnt = pack_unit * opt_sale_cnt;
      $scope.shipment.prod_add_opt_list[idx].prod_no = re.isSet ? null : data[0].prod_no;
      $scope.shipment.prod_add_opt_list[idx].set_no = re.isSet ? re.data.set.set_no : null;
      $scope.shipment.prod_add_opt_list[idx].set_cd = re.isSet ? re.data.set.set_cd : null;
      $scope.shipment.prod_add_opt_list[idx].bundle_avail_yn = data.find(r => !r.bundle_avail_yn) ? 0 : 1;
      $scope.shipment.prod_add_opt_list[idx].opt_prod_name = re.isSet ? re.data.set.opt_prod_name : data[0].opt_prod_name;
      $scope.shipment.prod_add_opt_list[idx].opt_sku_cd_list = re.isSet ? re.data.prod.map(p => p.sku_cd).join(', ') : '';
      $scope.shipment.prod_add_opt_list[idx].prod_attr = data[0].attri_pack ? data[0].attri_pack : '';
      $scope.shipment.prod_add_opt_list[idx].stock_cd = data[0].stock_cd ? data[0].stock_cd : '';
      $scope.shipment.prod_add_opt_list[idx].barcode = data[0].barcode ? data[0].barcode : '';
      $scope.shipment.prod_add_opt_list[idx].sale_price = re.isSet ? re.data.set.sale_price * re.data.set.set_pack_unit : data.reduce((sum, ord) => sum + (ord.sale_price * ord.pack_unit), 0);

      // SKU상품 매칭시 해당 SKU상품의 배송처도 매칭되도록 처리 2018-06-12 rony
      $scope.shipment.depot_no = data[0].depot_no;
      $scope.shipment.depot_name = data[0].depot_name;
      matchDepotNo.opt = data[0].depot_no;

      $scope.depotChangeYn = false;

      // 판매가, 원가 업데이트
      $scope.priceUpdate();

      return true;
    }

    /**
     * 2018-07-18 Daniel
     * G마켓물류주문 발송요청 대기, 발송요청 실패, 발송정보 수정대기 일때 다른배송처 막음
     */
    function ebaydepotNotSameDepotChk(changeDepotNo) {
      if ($scope.shipment.depot_no !== changeDepotNo) {
        const stockMatchEbaydepotState = ['발송요청 대기', '발송요청 실패', '발송정보 수정대기'];

        if (_.indexOf(stockMatchEbaydepotState, $scope.shipment.ebaydepot_link_status) >= 0) {
          commonSVC.showMessage('이미 발송정보가 전송되었으므로, 배송처 수정이 불가합니다.\n해당 주문을 발송정보 삭제 전송하신 후 SKU상품/배송처를 수정하시기 바랍니다.');

          return true;
        } else {
          return false;
        }
      }
    }

    /**
     * 2018-07-27 Daniel
     * G마켓물류주문 sku5개 초과일시 추가구매옵션 분리
     */
    $scope.divideEbaydepotOrderAddOpt = function (opt) {
      if (opt.ord_opt_seq === 1) {
        opt = {
          ord_opt_name: opt.shop_opt_name || '',
          ord_opt_seq: opt.ord_opt_seq,
          opt_sale_cnt: _(opt.sku_cd).split(';').map(function (sku) {
            return parseInt(sku.split('=')[1]);
          }).sum(),
          pack_unit: 1,
          bundle_avail_yn: opt.bundle_avail_yn
        };
      }

      commonSVC.showConfirm('주의', '선택한 옵션을 분리하여 직접입력 주문으로 생성합니다.\n분리하시겠습니까?', function (confirm) {
        if (confirm) {
          const params = {
            ord: $scope.shipment,
            opt: opt
          };

          shipmentModel.divideEbaydepotOrderAddOpt(params)
            .then(function () {
              updateYn = true;
              commonSVC.showToaster('success', '분리성공', '옵션분리에 성공했습니다.');

              init();
            })
            .catch(function (err) {
              commonSVC.showToaster('error', '분리실패', err);
            });
        }
      });
    };

    //미매칭 출고처리
    // function noneMapping() {
    //   $scope.shipment.sku_pack = '';
    //   $scope.shipment.prod_pack = '';
    //   $scope.shipment.sku_list = [];
    //   $scope.shipment.depot_name = '';
    //   matchDepotNo.prod = null;

    //   _.forEach($scope.shipment.prod_add_opt_list, function(opt) {
    //     opt.sku_cd = '';
    //     opt.sku_list = [];
    //   });

    //   $scope.shipment.use_map_yn = 0;
    // }

    $scope.countTotalUnit = function(idx) {
      const data = $scope.shipment.prod_add_opt_list[idx];

      data.total_cnt = data.pack_unit > 0 ? data.pack_unit * data.opt_sale_cnt : '';

      $scope.priceUpdate();
    };

    // 원가, 판매가 업데이트
    $scope.priceUpdate = () => {
      if ($scope.isManageStock) {
        costPriceUpdate();
        salePriceUpdate();
      }
    };

    // 원가 업데이트 (옵션의 원가  + 추가구매옵션들의 총 원가)
    const costPriceUpdate = () => {
      // 추가구매옵션의 총 원가
      const add_opt_cost_price = (_($scope.shipment.prod_add_opt_list).map(function (opt) {
        const sumCostPrice = opt.sku_list.reduce((acc, curr) => acc + ((curr.set_cost_price || curr.cost_price || 0) * curr.pack_unit), 0);

        return sumCostPrice * opt.opt_sale_cnt;
      }).sum()) || 0;
      const ord_opt_cost_price = ($scope.ord_opt_cost_price * $scope.shipment.sale_cnt) || 0; // 옵션 원가

      $scope.shipment.shop_cost_price = add_opt_cost_price + ord_opt_cost_price;
    };

    // 판매가 업데이트 (옵션의 판매가  + 추가구매옵션들의 총 판매가)
    const salePriceUpdate = () => {
      const pack_unit = $scope.shipment.set_no ? $scope.shipment.set_pack_unit : 1;
      const add_opt_sale_price = (_($scope.shipment.prod_add_opt_list).map(function (opt) { return opt.sale_price * opt.opt_sale_cnt; }).sum()) || 0; // 추가구매옵션의 총 판매가
      const ord_opt_sale_price = ($scope.shipment.sale_price * $scope.shipment.sale_cnt * pack_unit) || 0; // 옵션 판매가

      $scope.shipment.shop_sale_price = add_opt_sale_price + ord_opt_sale_price;
    };

    function prodAddOptSet() {
      _.each($scope.shipment.prod_add_opt_list, function(prod) {
        if (prod.sku_cd) {
          prod.set_info = prod.set_no ? { set_no: prod.set_no, set_cd: prod.sku_cd, set_pack_unit: prod.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
        }
      });
    }

    /**
     * SKU상품 상세페이지 열기
     */
    $scope.showProdDetail = (sku_cd, prod_number, prod_sol_no) => {

      // SKU상품 수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      if (!sku_cd) {
        commonSVC.showMessage('매칭된 SKU상품이 없습니다.');

        return;
      }

      sku_cd = sku_cd.replace(/=\d+/, '');

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          prodSolNo: prod_sol_no,
          detailType: 'edit'
        }
      };

      // 매칭된 SKU상품이 로그인계정 상품이 아닌 경우 상세에서 수정불가
      if (prod_sol_no && +prod_sol_no !== +$rootScope.user_profile.sol_no) {
        resolve.data.nonEdit = true;
      }

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');
    };

    /**
     * 세트 상세보기
     */
    $scope.setShowDetail = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };

      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };

    // 다중 매칭된 SKU 조회 모달
    $scope.showMultiSKUDetail = function (opt, index = null) {
      const prodList = opt === 'opt' ? $scope.sku_list : $scope.shipment.prod_add_opt_list[index].sku_list;

      const resolve = {
        data: {
          prodList: prodList.map(prod => {
            // 주문 수량 변수명이 달라 변경 처리
            if (opt === 'opt') {
              prod.opt_sale_cnt = $scope.shipment.sale_cnt;
            } else {
              // 주문 수량이 첫 항목에만 들어있어 모든 항목에 추가
              prod.opt_sale_cnt = $scope.shipment.prod_add_opt_list[index].opt_sale_cnt;
              // 재고 매칭 시 상품명을 opt_prod_name 변수에 저장하고 있어 변경 처리
              if (prod.opt_prod_name)
              { prod.prod_name = prod.opt_prod_name; }
            }

            return prod;
          })
        }

      };

      commonSVC.openModal('lm', resolve, 'multiSkuOrderProdList', 'views/prod/product/modals/multi_sku_order_prod_list.html');
    };

    /**
     * 2018-07-03 Daniel
     * 수정 버튼 누를시 삭제되도록 변경
     * 매칭 삭제
     */
    $scope.deleteMatching = function(ord_opt_seq, uniq, idx) {
      // shipment.set_info는 ord_opt_seq 1번에 대한 정보 이므로 1일때만 초기화 - 2019-07-16 Jackal
      if (ord_opt_seq === 1) {
        $scope.shipment.set_info = { set_no: null, set_cd: null, set_pack_unit: null };
      }
      let opt,
          optType;

      if (idx === undefined) {
        // 기본옵션
        opt = $scope.shipment;
        opt.sku_list = [
          { prod_no: null }
        ];
        optType = 'prod';

        matchDepotNo.prod = null;

        // 옵션 삭제 시 원가 0으로 변경
        $scope.ord_opt_cost_price = 0;
        // 옵션 삭제 시 판매가 0으로 변경
        $scope.shipment.sale_price = 0;
        // 옵션 삭제 시 기초상품 리스트 초기화
        $scope.sku_list = [];
      } else {
        // 추가구매옵션
        opt = $scope.shipment.prod_add_opt_list[idx];
        opt.sku_list = [];
        opt.sku_pack = '';
        optType = 'opt';
      }

      opt = _.merge(opt, shipmentSVC.initOpt[optType]);

      if (!_($scope.shipment.prod_add_opt_list).map('sku_list').flatten().filter('depot_no').value().length) {
        matchDepotNo.opt = null;
      }

      $scope.depotChangeYn = _.indexOf(stockMatchState, $scope.shipment.ord_status) >= 0 && (!matchDepotNo.prod && !matchDepotNo.opt);

      // 원가 업데이트
      $scope.priceUpdate();
    };
  });
