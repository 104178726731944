'use strict';

angular.module('gmpApp')
  .controller('MemoListCtrl', function ($rootScope, $scope, userInfo, systemList, commonSVC, $state, settings, memoModel, gettextCatalog, memoSVC) {
    // 이외 사용자는 본인 메모만 수정 삭제 가능
    $scope.user_m_no = userInfo.user.m_no;
    $scope.location = 'memoEtc'; // LeftControll 메모탭 제어용
    $scope.templateList = [];
    $scope.selectedTemplate = '0';
    $scope.use_ord_as = userInfo.user.sol_ser.includes('ord_as');
    $scope.originMemoContent = '';
    // 좌측 컨트롤패널과 비슷한 로직 구현을 위해 수정상태일때를 관리하는 변수
    $scope.edit_status = false;
    $scope.countList_permission = {
      mend: false,
      ord: true,
      ol_shop: true,
      prod: true,
      depot_set: true,
    };
    const countList = { total: 0, ord: 0, ol_shop: 0, prod: 0, depot_set: 0 };

    $scope.isMaster = userInfo.user.user_grade === '마스터';

    // userInfo.user.sol_ser 안에 ord_as 있으면 수선 사용자 현재 페라가모만 존재
    if ($scope.use_ord_as) {
      countList.mend = 0;
      $scope.countList_permission.mend = true;
    }

    if (userInfo.user.auth_type === '사용자') {
      $scope.countList_permission.mend = $scope.countList_permission.ord = userInfo.permission.order.use_yn;
      $scope.countList_permission.ord = $scope.countList_permission.ord || userInfo.permission.shipping.use_yn;
      $scope.countList_permission.ol_shop = userInfo.permission.online.use_yn;
      $scope.countList_permission.prod = $scope.countList_permission.depot_set = userInfo.permission.stock.use_yn;
    } else if (userInfo.user.auth_type === '배송처') {
      $scope.countList_permission.mend = $scope.countList_permission.ol_shop = false;
    }

    // 재고미사용버전 탭 안보이게
    if (userInfo.user.sol_stock == 0) {
      $scope.countList_permission.prod = $scope.countList_permission.depot_set = false;
    }

    if (Object.values($scope.countList_permission).every(i => !i)) {
      commonSVC.PermissionDenyAlert();
      $rootScope.$emit('$closeTab', 'main.memo_list');

      return;
    }

    $scope.countList = countList; // 우측 아이콘 카운트
    $scope.selectCount = 'total';
    $scope.selectedMemo = {};
    $scope.contentPlaceholder = '메모내용';

    const searchForm = {
      search_key: 'all',
      search_word: '',
      search_type: 'partial',
      date_type: 'wdate',
      sdate: moment().subtract(systemList.data.search_date, 'month').format('YYYY-MM-DD'),
      edate: commonSVC.getDate(new Date(), 'yyyy-MM-dd'),
      memo_type: 'total',
      multi_search_word: '', 		//멀티서치워드
      multi_type: 'c_sale_cd',  //멀티서치 타입
    };

    const searchData = {
      all_checked: false,  // 전체선택 여부
      totalCount: 0,       // 검색전체 건수
      selectCount: 0,      // 선택한 건수 <- 작업예정
      showCount: 500,
      search_key_items: [  // 검색영역 키값
        { label: '전체', value: 'all' },
        { label: '메모내용', value: 'content' },
        { label: '쇼핑몰주문번호', value: 'shop_ord_no' },
        { label: '주문자명', value: 'order_name' },
        { label: '쇼핑몰 상품명', value: 'shop_sale_name' },
        { label: '판매자관리코드', value: 'c_sale_cd' },
        { label: '쇼핑몰상품코드', value: 'shop_sale_no' },
        { label: 'SKU코드', value: 'sku_cd' },
        { label: '세트코드', value: 'set_cd' },
        { label: '재고관리코드', value: 'stock_cd' },
        { label: '묶음번호', value: 'bundle_no' },
        { label: '등록자', value: 'wname' },
        { label: '완료자', value: 'complete_m_name' },
        { label: '메모번호', value: 'memo_no' },
      ],
      search_date_type: [
        { label: '메모 등록일', value: 'wdate' },
        { label: '메모 완료일', value: 'mdate' },
        { label: '메모 수정일', value: 'content_mdate' }
      ],
      search_multi_items: [
        { label: '쇼핑몰주문번호', value: 'shop_ord_no' },
        { label: '주문자명', value: 'order_name' },
        { label: '쇼핑몰 상품명', value: 'shop_sale_name' },
        { label: '판매자관리코드', value: 'c_sale_cd' },
        { label: '쇼핑몰상품코드', value: 'shop_sale_no' },
        { label: 'SKU코드', value: 'sku_cd' },
        { label: '세트코드', value: 'set_cd' },
        { label: '재고관리코드', value: 'stock_cd' },
        { label: '묶음번호', value: 'bundle_no' },
        { label: '등록자', value: 'wname' },
        { label: '완료자', value: 'complete_m_name' },
      ],
    };

    /**
		 * 서치바 관련 변수
		 */
    $scope.searchData = angular.copy(searchData);
    $scope.searchForm = angular.copy(searchForm);

    const searchDetail = [
      {
        title: '메모 상태 선택',
        search_name: 'memo_status',
        item_list: [
          { memo_status: '등록' },
          { memo_status: '완료' },
        ],
        item_key: 'memo_status',
        item_value: 'memo_status',
        select_value: ''
      }
    ];

    $scope.searchDetail = angular.copy(searchDetail);

    // 데이터 테이블 관련기능 바인딩
    $scope.searchFn = {
      searchDo: function() {
        $scope.searchDo(true, true);
      },
      resetDo: function() {
        $scope.resetDo();
      },
      changeLen: function() {
        $scope.changeCount();
      }
    };

    // 검색영역 버튼 설정
    $scope.searchBtn = {
      table_actions: [
        {
          label: '<i class="fa fa-bolt">' + '<span class="pl-5">작업</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          test_id: 'dropdown-work',
          item_list: [{
            label: '완료 처리',
            test_id: 'btn-end',
            action: function () {
              $scope.endSelectedMemo('완료');
            },
          }, {
            label: '완료 해제',
            test_id: 'btn-end-rollback',
            action: function () {
              $scope.endSelectedMemo('등록');
            },
          }, {
            label: '메모 삭제',
            test_id: 'btn-del',
            action: function () {
              $scope.deleteSelectedMemo();
            }
          }]
        },
        {
          label: '<i class="fa fa-file-excel-o">' + '<span class="pl-5">엑셀 다운로드</span>' + '<span class="caret"></span>',
          btn_type: 'dropdown',
          add_class: 'btn-default',
          item_list: [
            {
              label: gettextCatalog.getString('전체 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('all', $scope.selectCount);
              },
            }, {
              label: gettextCatalog.getString('선택 엑셀 다운로드'),
              action: function () {
                $scope.excelDown('select', $scope.selectCount);
              },
            }
          ]
        }
      ],
    };

    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      pinningColumns: [],
      alignCenterColumns: ['memo_no', 'memo_type', 'memo_status', 'wdate', 'wname', 'mdate', 'complete_m_name'],
      alignRightColumns: [],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: [],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [
      ],
      initPagingSize: $scope.searchData.showCount,
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/memos/etc/list`,
        requestWillAction: function(data) {
          if ($rootScope.memo_noti_search.page === 'memo_list' && $rootScope.memo_noti_search.search) {
            const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_noti_search;

            $scope.searchForm = {
              ...$scope.searchForm,
              date_type,
              sdate,
              edate,
              search_key,
              search_word,
              search_type: 'exact'
            };
            $rootScope.memo_noti_search.search = false;
          }
          data = angular.merge({}, data, $scope.searchForm);

          return data;
        },
        requestDidAction: function(result) {
          $scope.searchData.totalCount = result.countList[0][`${$scope.selectCount}`];
          $scope.countList = result.countList[0];
          $scope.memoEtcList = result.results;

          return result.results;
        }
      },
      columns: [
        {
          key: 'memo_no',
          title: '메모 번호',
          width: 30
        },
        {
          key: 'memo_type',
          title: '메모 구분',
          width: 50,
          template: function({ memo_type }) {
            switch (memo_type) {
              case 'prod':
                return 'SKU상품';
              case 'ol_shop':
                return '쇼핑몰 상품';
              case 'ord':
                return '주문';
              case 'depot_set':
                return '세트상품';
              case 'mend':
                return 'AS(수선)';
              case 'depot_inout_manual':
                return '수동입출고';
              default:
                return '메모 구분';
            }
          }
        },
        {
          key: 'memo_status',
          title: '메모 상태',
          width: 20,
          template: function({ memo_status }) {
            switch (memo_status) {
              case '등록':
                return '<span class="label label-default bg-memo-status-blue">등록</span>';
              case '완료':
                return '<span class="label label-default bg-memo-status-green">완료</span>';
            }
          }
        },
        {
          key: 'wdate',
          title: '메모 등록일',
          width: 70
        },
        {
          key: 'content_mdate',
          title: '메모 수정일',
          width: 70
        },
        {
          key: 'content',
          title: '메모 내용',
          width: 300,
          template: function({ content }) {
            return content.replace(/<br>/gi, ' ');
          }
        },
        {
          key: 'wname',
          title: '등록자',
          width: 70,
        },
        {
          key: 'mdate',
          title: '메모 완료일',
          width: 70,
          template: function({ memo_status, mdate }) {
            if (memo_status === '완료') {
              return mdate;
            }

            return '-';
          }
        },
        {
          key: 'complete_m_name',
          title: '완료자',
          width: 70,
          template: function({ memo_status, complete_m_name }) {
            if (memo_status === '완료') {
              return complete_m_name;
            }

            return '-';
          }
        }
      ]
    };

    /**
		 * 조회
		 */
    $scope.searchDo = function (refresh, noDelay, callback) {
      $scope.selectCount = $scope.searchForm.memo_type || 'total';

      $scope.grid.methods.reloadData(callback, refresh, noDelay);
    };

    /**
     * 집계 조회
     */
    $scope.countSearch = function (type) {
      $scope.searchForm.memo_type = type || 'total';
      $scope.selectCount = type || 'total';

      // searchDo 에서 처리하고 있으므로 주석 2018-11-12 rony
      // $scope.selectCount = type;
      $scope.searchDo(true, true);
    };

    /**
		 * 검색 초기화
		 */
    $scope.resetDo = function () {
      $scope.searchForm = angular.copy(searchForm);
      $scope.searchData.search_key_name = '전체';
      $scope.searchDetail = angular.copy(searchDetail);
      $scope.selectCount = 'total';
      $scope.searchDo(true, true);
    };

    /**
		 * 데이터테이블 pageLength 조절
		 * @param number
		 */
    $scope.changeCount = function () {
      $scope.grid.methods.length($scope.searchData.showCount);
    };

    /**
		 * 선택 일괄 완료, 완료해제
		 */
    $scope.endSelectedMemo = function (status) {
      const selectedMemos = $scope.grid.methods.selectedData('all');

      if (selectedMemos.length == 0) {
        commonSVC.showMessage('선택된 메모가 없습니다.');

        return false;
      }

      const modal = commonSVC.openModal('', { data: {
        status: status === '완료' ? '완료' : '완료 해제',
        cnt: selectedMemos.length
      } }, 'MemoListConfirm', 'views/prod/product/modals/memo_list_confirm.html');

      modal.result.then(function () {
        let changeMemos = selectedMemos;

        if (userInfo.user.m_type !== '마스터') {
          // 사용자 계정인 경우 선택된 메모중 삭제 불가능한 메모가 있으면 삭제되지 않음
          changeMemos = selectedMemos.reduce((aur, { memo_no, memo_status }) => {
            if (memo_status !== status) {
              aur.push(memo_no);
            }

            return aur;
          }, []);

          if (changeMemos.length === 0) {
            commonSVC.showToaster('error', '실패', '변경사항이 없습니다.');

            return;
          }
        }
        memoModel.end({
          memo_no: changeMemos,
          memo_status: status
        }).then(re => {
          if (re && re.data.results === 'success') {
            commonSVC.showToaster('success', '', `메모가 ${status} 상태로 변경 되었습니다.`);
            $scope.selectedMemo = {};
            $scope.searchDo(false);
          } else {
            commonSVC.showToaster('error', '실패', '변경에 실패하였습니다.');
          }
        });
        $scope.searchDo(false);
      });
    };

    /**
		 * 선택 일괄 삭제
		 */
    $scope.deleteSelectedMemo = function () {
      const selectedMemos = $scope.grid.methods.selectedData('all');

      if (selectedMemos.length == 0) {
        commonSVC.showMessage('선택된 메모가 없습니다.');

        return false;
      }

      const delMemos = selectedMemos.reduce((aur, { memo_no, wm_no }) => {
        if (wm_no == userInfo.user.m_no || userInfo.user.user_grade === '마스터') {
          aur.push(memo_no);
        }

        return aur;
      }, []);

      if (delMemos.length !== selectedMemos.length) {
        if (!delMemos.length) {
          commonSVC.showMessageHtml('삭제가 가능한 메모가 없습니다.', '다른 사람이 등록한 메모는 삭제할 수 없습니다.');
        } else {
          commonSVC.showConfirmCustom({
            title: '메모 삭제',
            text: ('다른 사람이 등록한 메모가 아닌 현재 계정으로 등록한 메모만 삭제 가능합니다.'),
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#5c90d2',
            confirmButtonText: '삭제 가능한 메모만 선택해두기',
            cancelButtonText: '취소',
            animation: false,
          }, function () {
            const reselected_indexes = $scope.grid.methods.doSelectByFilter(function ({ wm_no }) {
              return wm_no == userInfo.user.m_no;
            }, true);

            if (reselected_indexes.length == 0) {
              commonSVC.showToaster('error', '자동 선택 불가', '메모삭제 가능한 건이 없습니다.');
            }
          });
        }
      } else {
        const modal = commonSVC.openModal('', { data: {
          status: '삭제',
          cnt: selectedMemos.length
        } }, 'MemoListConfirm', 'views/prod/product/modals/memo_list_confirm.html');

        modal.result.then(function () {
          memoModel.delete({
            memo_no: selectedMemos.map(({ memo_no }) => memo_no),
          }).then(re => {
            if (re && re.data.results === 'success') {
              commonSVC.showToaster('success', '', '메모가 삭제 되었습니다.');
              $scope.selectedMemo = {};
              $scope.searchDo(false);
            } else {
              commonSVC.showToaster('error', '실패', '삭제에 실패하였습니다.');
            }
          });
          $scope.searchDo(false);
        });
      }
    };

    // row 누르면 그 메모 선택되게
    $scope.$on('OnSelectChangeBefore', function(event, data) {
      $scope.selectedMemo = angular.copy(data.selectData);
      $scope.selectedMemo.content = $scope.selectedMemo.content.replace(/<br>/gi, '\n');
      if ($scope.selectedMemo.ol_shop_shop_name === '마스터 온라인상품') {
        $scope.selectedMemo.ol_shop_master_shop_sale_name = $scope.selectedMemo.ol_shop_shop_sale_name;
        $scope.selectedMemo.ol_shop_shop_sale_name = '';
      }

      $scope.leftTabAction(2);
    });

    $scope.excelDown = (type, tab) => {
      const resolve = {};

      resolve.data = {
        type: type,
        excelFieldList: angular.copy(memoSVC.excelFieldList[tab]),
        title: '메모 내역',
        url: '/app/memos/etc/excelDown',
        sheetName: tab,
        searchForm: $scope.searchForm,
        page: `memo_list_${tab}`
      };

      if (type === 'all') {
        resolve.data.count = $scope.searchData.totalCount;
        resolve.data.isAll = true;
      } else {
        const selectList = $scope.grid.methods.selectedData('memo_no');

        if (!selectList.length) {
          commonSVC.showMessage('선택된 내역이 없습니다.');

          return false;
        } else {
          resolve.data.uniq = selectList;
          resolve.data.count = selectList.length;
          resolve.data.isAll = false;
        }
      }

      if (tab === 'total') {
        // 수선(A/S) 권한 없을 시 필드에서 제거
        if (!($scope.use_ord_as && $scope.countList_permission.mend)) {
          resolve.data.excelFieldList = resolve.data.excelFieldList.filter(field => field.header !== 'A/S(수선)');
        }

        commonSVC.openModal('', resolve, 'IntegratedMemoDownCtrl', 'views/etc/integratedMemo_down.html');
      } else {
        commonSVC.openModal('', resolve, 'EtcExcelDownCtrl', 'views/etc/excel_down.html');
      }
    };

    $scope.editMemo = () => {
      const temp_data = {
        ...$scope.selectedMemo,
        content: $scope.selectedMemo.content.replace(/\n/gi, '<br>')
      };

      return memoModel.edit(temp_data).then((res) => {
        if (res) {
          $rootScope.$broadcast('etcMemoListInput_change', { status: 'edit', data: temp_data });
          $scope.selectedMemo.content_mdate = res.data.content_mdate;
          $scope.edit_status = false;
          commonSVC.showToaster('success', '', '메모가 수정 되었습니다.');
          $scope.searchDo(false);

          return true;// 로딩바 복구 처리
        } else {
          commonSVC.showToaster('error', '실패', res.data.error);

          return false;
        }
      })
        .catch((res) => {
          if (res.data.error) {
            commonSVC.showToaster('error', '실패', res.data.error);
          }

          return false;
        });
    };

    $scope.endMemo = (memo_status) => {
      return memoModel.end({
        memo_no: [$scope.selectedMemo.memo_no],
        memo_status,
      }).then((res) => {
        if (res) {
          $scope.selectedMemo.memo_status = memo_status;
          $rootScope.$broadcast('etcMemoListInput_change', { status: 'end', data: $scope.selectedMemo });
          if (memo_status === '완료') {
            commonSVC.showToaster('success', '', '메모가 완료처리 되었습니다.');
          } else if (memo_status === '등록') {
            commonSVC.showToaster('success', '', '메모가 완료 해제처리 되었습니다.');
          }
          $scope.searchDo(false);

          return true;// 로딩바 복구 처리
        } else {
          commonSVC.showToaster('error', '실패', res.data.error);

          return false;
        }
      })
        .catch((res) => {
          if (res.data.error) {
            commonSVC.showToaster('error', '실패', res.data.error);
          }

          return false;
        });
    };

    // 주문, 상품, 재고 탭 넘기는 바로가기
    $scope.memoHyperLink = function() {
      $rootScope.memo_etc_search = {};
      $rootScope.memo_etc_search.sdate = '2001-01-01';
      $rootScope.memo_etc_search.edate = moment().format('YYYY-MM-DD');
      $rootScope.memo_etc_search.date_type = 'wdate';
      $rootScope.memo_etc_search.search = true;
      switch ($scope.selectedMemo.memo_type) {
        case 'prod':
          $rootScope.memo_etc_search.page = 'product';
          $rootScope.memo_etc_search.key = 'sku_cd';
          $rootScope.memo_etc_search.value = $scope.selectedMemo.prod_sku_cd;
          $state.go('main.prod_product_list');
          $rootScope.$broadcast('memoEtcLink_prod');
          break;
        case 'ol_shop':
          // 쇼핑몰 상품코드가 없는 쇼핑몰 상품인 경우(판매대기상태) 판매자관리코드로 탭 넘기기
          if ($scope.selectedMemo.ol_shop_shop_name === '마스터 온라인상품' || !$scope.selectedMemo.ol_shop_shop_sale_no) {
            // 마스터상품은 마스터상품 탭으로 가야해서 따로 표시
            $rootScope.memo_etc_search.is_master = $scope.selectedMemo.ol_shop_shop_name === '마스터 온라인상품';
            $rootScope.memo_etc_search.key = 'c_sale_cd';
            $rootScope.memo_etc_search.value = $scope.selectedMemo.ol_shop_c_sale_cd;
          }
          // 쇼핑몰 상품코드가 있으면 그걸로 탭 넘기기
          else {
            $rootScope.memo_etc_search.is_master = false;
            $rootScope.memo_etc_search.key = 'shop_sale_no';
            $rootScope.memo_etc_search.value = $scope.selectedMemo.ol_shop_shop_sale_no;
          }
          // 단일상품인지 일반상품인지 판별
          $rootScope.memo_etc_search.is_single = $scope.selectedMemo.ol_shop_std_ol_yn == 1;

          $rootScope.memo_etc_search.page = 'online';
          $state.go('main.online_product_list');
          $rootScope.$broadcast('memoEtcLink_online');
          break;
        case 'ord':
          $rootScope.memo_etc_search.page = 'integrated';
          $rootScope.memo_etc_search.key = 'shop_ord_no';
          $rootScope.memo_etc_search.value = $scope.selectedMemo.ord_shop_ord_no;
          $state.go('main.order_shipment_integrated_list');
          $rootScope.$broadcast('memoEtcLink_integrated');
          break;
        case 'depot_set':
          $rootScope.memo_etc_search.page = 'prod_set';
          $rootScope.memo_etc_search.key = 'set_cd';
          $rootScope.memo_etc_search.value = $scope.selectedMemo.depot_set_set_cd;
          $state.go('main.prod_set_list');
          $rootScope.$broadcast('memoEtcLink_prod_set');
          break;
        case 'mend':
          $rootScope.memo_etc_search.page = 'as_list';
          $rootScope.memo_etc_search.key = 'bundle_no';
          $rootScope.memo_etc_search.value = $scope.selectedMemo.mend_bundle_no;
          $state.go('main.order_shipment_as_list');
          $rootScope.$broadcast('memoEtcLink_as_list');
          break;
      }
    };

    $scope.editOpen = () => {
      $scope.originMemoContent = `${$scope.selectedMemo.content}`;
      $scope.edit_status = true;
    };

    $scope.editClose = () => {
      $scope.edit_status = false;
    };

    // 상단 신규메모에서 왔을떄
    $scope.$on('memoList_new_memo_noti', function() {
      const { date_type, sdate, edate, key: search_key, value: search_word } = $rootScope.memo_noti_search;

      $scope.searchForm = {
        ...searchForm,
        date_type,
        sdate,
        edate,
        search_key,
        search_word,
        search_type: 'exact'
      };
      $rootScope.memo_noti_search.search = false;
      $scope.searchDo(true, true);
    });

    // 좌측 컨트롤패널에서 변경사항이 있을때
    $scope.$on('leftControllMemo_change', function(event, re) {
      if ($scope.selectedMemo && re.data.memo_no === $scope.selectedMemo.memo_no) {
        switch (re.status) {
          case 'edit':
            $scope.originMemoContent = `${re.data.content}`;
          // edit일때나 end일때나 공통처리가 있어서 이렇게 의도해서 만들었더니 eslint가 발동해서 일시해제
          // eslint-disable-next-line no-fallthrough
          case 'end':
            $scope.selectedMemo = { ...$scope.selectedMemo, ...re.data };
            break;
          case 'delete':
            $scope.selectedMemo = {};
        }
      }
    });
  });
