'use strict';

angular.module('gmpApp')
  .controller('ProdProductLinkageCtrl', function ($rootScope, $scope, data, $uibModalInstance, commonSVC, settings, siteInfo, onlineProductSVC, onlineProductModel, productModel, categoryModel, systemModel, warehouseList, $timeout) {
    const { code, prod_no, depot_no, depot_name, link_yn, link_set_yn, set_no } = data;

    $scope.code = code;
    $scope.type = !_.isUndefined(data.type) ? data.type : '';
    $scope.fromSet = !!data.fromSet;  // 2018-12-20 Jacob 세트상품관리에서 모달 로딩
    $scope.searchData = {};
    $scope.searchData.tab = $rootScope.user_profile.auth_type === '배송처' || (!link_yn && link_set_yn) ? 'set' : 'ol';

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    /**
       * 세트 상세보기
       */
    $scope.setDetails = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };

      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };
    $scope.onlineDetail = function (shop_cd, shop_id, ol_shop_no) {
      const resolve = {};
      const data = {};

      data.shop_cd = shop_cd;
      data.shop_id = shop_id;
      data.ol_shop_no = ol_shop_no;
      data.country = 'KR';
      data.number = `${shop_cd}|${shop_id}|${ol_shop_no}`;
      data.warehouseList = warehouseList.data.result || [];
      resolve.data = data;

      resolve.prodDetail = function() {
        return onlineProductModel.detail(shop_cd, shop_id, ol_shop_no);
      };
      // 원산지 리스트 조회
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      //환경설정 셋팃값
      resolve.systemList = function() {
        return systemModel.load();
      };

      const modal = commonSVC.openModal('full', resolve, 'OnlineProductEditCtrl', 'views/online/product/edit.html');

      modal.result.then(function (re) {
        if (re === 'success') {
          $scope.searchDo();
        }
      });

    };

    // 온라인 상품 탭
    $scope.ol = {};
    $scope.ol.methods = {};
    $scope.ol.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['widget', 'admin_code', 'std_ol_yn', 'shop_cd', 'shop_sale_no', 'prod_name', 'deal_type', 'sale_status', 'main_prod_yn', 'depot_name'],
      alignRightColumns: [],
      defaultSortingColumns: ['admin_code'],
      notSortingColumns: ['widget', 'shop_cd', 'deal_type', 'sale_status', 'main_prod_yn', 'depot_name'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      multiSelect: false,
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/common/get-linked-product`,
        requestWillAction: function (product) {
          let data;

          if ($scope.fromSet) {
            data = angular.merge({ set_no, tab: 'ol' }, product);
          } else if ($scope.type == 'template') {
            data = angular.merge({ template_no: code, tab: 'ol' }, product);
          } else {
            data = angular.merge({ sku_code: code, depot_no: depot_no, tab: 'ol' }, product);
          }
          data.searchType = $scope.type;

          return JSON.stringify(data);
        },
        requestDidAction: function(result) {
          return result.results;
        }
      },
      columns: [
        {
          key: 'widget',
          title: '상세보기',
          width: 10,
          className: 'tool text-center',
          template: function (row) { //도구
            return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.onlineDetail('${row.shop_cd}','${row.shop_id}',${row.ol_shop_no})">상세</button>`;
          }
        },
        {
          key: 'admin_code',
          title: '판매자관리코드',
          width: 150
        },
        {
          key: 'std_ol_yn',
          title: '상품유형',
          width: 80,
          notCompile: true,
          template: function (row) {
            return row.std_ol_yn === 2 ? '물류상품' : (row.std_ol_yn === 1 ? '단일상품' : '일반상품');
          }
        },
        {
          key: 'shop_cd',
          title: '쇼핑몰(계정)',
          width: 150,
          notCompile: true,
          template: function (row) {
            return data === 'Z000' ? row.shop_name : `${row.shop_name}(${row.shop_id})`;
          }
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 150,
          template: function (row) {
            const sale_no = row.shop_sale_no || '';
            const shop_cd = row.shop_cd;
            const shop_id = row.shop_id;
            let sale_no_link = '';

            if (shop_cd == 'B378') {
              sale_no_link = row.shop_sale_no_sub ? `${sale_no}?vendorItemId=${row.shop_sale_no_sub}` : sale_no;
            } else {
              sale_no_link = sale_no;
            }

            if (sale_no && !_.includes(onlineProductSVC.impossibeDetailView, shop_cd)) {
              return `<span>${sale_no}<i class="picon-link2 text-grey ml-5 cursor-pointer" ng-click="grid.appScope.shopDetailView('${shop_cd}','${shop_id}','${sale_no_link}')"></i></span>`;
            } else {
              return sale_no;
            }
          }
        },
        {
          key: 'prod_name',
          title: '상품명',
          width: 280,
          filter: 'whiteSpace'
        },
        {
          key: 'opt_name',
          title: '옵션명',
          width: 110,
        },
        {
          key: 'deal_type',
          title: '구분',
          width: 80,
        },
        {
          key: 'sale_status',
          title: '상품상태',
          width: 90
        },
        {
          key: 'main_prod_yn',
          title: '대표상품 설정 여부',
          width: 110,
          notCompile: true,
          template: (row) => {
            return row.main_prod_yn ? '대표상품' : '';
          }
        },
        {
          key: 'delivery_vendor_name',
          title: '배송처',
          width: 90,
          notCompile: true,
          template: (row) => {
            return row.delivery_vendor_name || depot_name;
          }
        },
      ]
    };

    // 세트 상품 탭
    $scope.set = {};
    $scope.set.methods = {};
    $scope.set.options = {
      modal: true,
      pinningColumns: [],
      alignCenterColumns: ['set_widget', 'set_cd', 'set_name', 'set_status', 'depot_name'],
      alignRightColumns: [],
      defaultSortingColumns: ['set_name'],
      notSortingColumns: ['set_widget', 'depot_name'],
      notResizingColumns: [],
      notMovingColumns: [],
      notVisibleColumns: [],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestUrl: `${settings.pa20ApiUrl}/app/common/get-linked-product`,
        requestWillAction: function (product) {
          const data = angular.merge({ prod_no: prod_no, depot_no: depot_no, tab: 'set' }, product);
          data.searchType = $scope.type;

          return JSON.stringify(data);
        },
        requestDidAction: function(result) {
          return result.results;
        }
      },
      columns: [
        {
          key: 'set_widget',
          title: '상세보기',
          width: 80,
          className: 'tool text-center',
          template: function (row) { //도구
            return `<button class="btn btn-default btn-xxs" ng-click="grid.appScope.setDetails('${row.set_no}')">상세</button>`;
          }
        },
        {
          key: 'set_cd',
          title: '세트코드',
          width: 150,
        },
        {
          key: 'set_name',
          title: '세트 상품명',
          width: 577,
        },
        {
          key: 'set_status',
          title: '상태',
          width: 150,
        },
        {
          key: 'depot_name',
          title: '배송처',
          width: 90,
          notCompile: true,
          template: (row) => {
            return row.depot_name || depot_name;
          }
        },
      ]
    };

    $scope.searchFn = {
      // 탭 변경
      changeTab: function (tab) {
        $scope.searchData.tab = tab;
      },
    };
  });