'use strict';

angular.module('gmpApp')
  .controller('OnlineProductProdInfoCtrl', function ($scope, $http, $uibModalInstance, commonSVC, onlineProductSVC, data, $log, $rootScope) {
    $scope.adminMode = $rootScope.adminMode;
    $scope.prod_noti_info = [{}, {}, {}];
    $scope.certifyList = onlineProductSVC.certifyList;
    $scope.tabActive = 0;
    const temp_list = angular.copy(data.temp_list);
    const submit_type = data.type || 'edit';

    /**
     * 상품분류 정의
     * **/
    const types = onlineProductSVC.newTypes;

    $scope.type_array = _.map(types, function(value) {
      return { key: value.code, name: value.name };
    });

    /**
     * 정보제공고시 정보 불러오기
     * **/
    $scope.changeType = function(idx, next) {
      angular.forEach($scope.type_array, function(v) {
        if (v.key == $scope.prod_noti_info[idx].infoCode) {
          $scope.prod_noti_info[idx].infoName = v.name;
        }
      });

      if (_.isUndefined($scope.prod_noti_info[idx].infoCode) || $scope.prod_noti_info[idx].infoCode == '') {
        return false;
      }

      commonSVC.sendUrl('get', `/views/online/product/json/${$scope.prod_noti_info[idx].infoCode}.json`, {}, function(state, data) {
        if (state == 'success') {
          $scope.prod_noti_info[idx].infoDetail = data;
          next();
        } else {
          $log.debug(data);
        }
      });
    };

    if (temp_list.length) {
      for (const i in temp_list) {
        if (!temp_list[i]) {
          temp_list[i] = {};
        }
        $scope.prod_noti_info[i].infoCode = temp_list[i].infoCode;
        if (submit_type == 'add') {
          $scope.prod_noti_info[i] = temp_list[i];
        }
      }
    }

    /**
     * 상세설명참조 전체적용
     * **/
    $scope.allRefer = function(idx) {
      const infoCode = $scope.prod_noti_info[idx].infoCode;

      if (infoCode === '' || infoCode === null || infoCode === undefined) {
        commonSVC.showMessage('상품 분류를 선택 해주세요', '');

        return false;
      }

      angular.forEach($scope.prod_noti_info[idx].infoDetail, function(v) {
        angular.forEach(v.detail, function(v2) {
          if (v2.value != 'Y' && v2.value != 'N' && v2.value != '해당없음') { //라디오버튼 제외
            v2.value = '[상세설명참조]';
          }
        });
      });
    };

    /**
     * 상품 고시 자동입력 버튼 (디버그)
     */
    $scope.setProdInfoName = (idx) => {
      const infoCode = $scope.prod_noti_info[idx].infoCode;

      if (infoCode === '' || infoCode === null || infoCode === undefined) {
        commonSVC.showMessage('상품 분류를 선택 해주세요', '');

        return false;
      }

      angular.forEach($scope.prod_noti_info[idx].infoDetail, function(v) {
        angular.forEach(v.detail, function(v2) {
          if (v2.value != 'Y' && v2.value != 'N' && v2.value != '해당없음') { //라디오버튼 제외
            v2.value = v2.title;
          }
        });
      });
    };

    /**
     * 등록
     * **/
    $scope.ok = function() {
      if ($scope.form1.$valid) {
        const flag = $scope.prod_noti_info.some(row =>
          row.infoCode && Object.values(row.infoDetail).some(infoDetail =>
            Object.values(infoDetail).some(detail => {
              if (typeof detail === 'object') {
                // 수입품 여부 true OR false
                let importYN = true;

                return Object.values(detail).some(detailRow => {
                  // 수입품 여부가 N 이면 수입자 표기 안해도 상관없음
                  if (detailRow.title == '수입품 여부') {
                    importYN = detailRow.value != 'N';
                  }
                  if (!detailRow.value && importYN) {
                    return true;
                  }
                });
              }
            }))
        );

        if (flag) {
          commonSVC.showMessage('세부항목을 모두 입력해주세요.', '');

          return false;
        } else {
          $uibModalInstance.close($scope.prod_noti_info);
        }
      }
    };

    //탭 선택
    $scope.clickTab = function(index) {
      $scope.tabActive = index;
    };

    /**
     * 취소
     * **/
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    // 2018-01-18 roy
    // 저장된 상품 정보제공고시 로드
    if (submit_type == 'edit') {
      _.each(temp_list, function(noti_info, i) {
        $scope.changeType(i, function() {
          const noti_info_list = _.map(noti_info.infoDetail, function(value) {
            return value;
          });

          $scope.prod_noti_info[i].infoDetail.forEach(list => {

            list.detail.map(v => {
              v.value = noti_info.infoDetail[v.code];
            });
          });
        });
      });
    }
  });
