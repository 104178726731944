'use strict';

/**
 * 주문 상세 컨트롤러(Glboal)
 */
angular.module('gmpApp')
  .controller('OrderShipmentDetailGlobalCtrl', function ($rootScope, $scope, $filter, $compile, $uibModalInstance, $timeout,
    settings, userInfo,
    commonSVC, logHistorySVC, shipmentSVC, columnSVC,
    shipmentModel, data, gettextCatalog,
    supplierModel, warehouseModel, productModel, systemModel, columnModel, deliveryModel) {

    // SKU매칭 가능 여부 (전체주문조회 탭에서 모달을 열면 매칭 불가)
    $scope.mapping_sku_yn = !['integrated', 'claim'].includes(data.fromPage);

    // 통화코드 리스트
    $scope.currList = _.uniqBy($scope.countryList, 'curr_cd');

    // 추가옵션 상품 정보 아코디언 펼침 여부
    $scope.accordion_expanded = true;

    // SKU상품 템플릿
    const emptySkuTemplate = {
      sku_cd: '',
      prod_name: '',
      pack_unit: 0,
      prod_weight: 0,
      attri: '',
      depot_no: '',
      ord_opt_seq: 1
    };

    // 통화 설정
    $scope.currency = {
      curr_cd: 'USD',
      set model(curr_cd) {
        commonSVC.showMessage('기준통화 변경 후 반드시 금액을 다시 확인해주세요.');
        this.curr_cd = curr_cd;
      },
      get model() {
        return this.curr_cd;
      },
    };

    // validation을 위한 element ref 배열 추가
    $scope.add_opt_ref = [];

    $scope.isDepot = $rootScope.user_profile.auth_type === '배송처';
    $scope.isManageStock = $rootScope.user_profile.sol_stock > 0;
    $scope.fromPage = data.fromPage;
    $scope.uniq = data.uniq;
    $scope.agency_name = data.agency_name || '';
    $scope.mapRuleIdx = [];
    $scope.viewModelNo = !!($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like'));
    // 신규주문, 출고관리, 배송관리, 전체 주문 관리 수정가능
    $scope.orderEdit = (
      ($scope.fromPage === 'unstoring' && commonSVC.checkRole('order.unstoring+write', userInfo)) ||
      ($scope.fromPage === 'order' && commonSVC.checkRole('order.order+write', userInfo)) ||
      ($scope.fromPage === 'delivery' && commonSVC.checkRole('order.delivery+write', userInfo)) ||
      ($scope.fromPage === 'integrated' && commonSVC.checkRole('order.integrated+write', userInfo))
    );

    $scope.isDepot = $rootScope.user_profile.auth_type === '배송처';

    let isClaim = false;

    // 부모 솔루션 번호가 들어오는경우 해당 정보로 상세데이터 조회해야 함.
    const sub_sol_no = data.sub_sol_no || 0;

    // 탭 인덱스
    $scope.tabActive = data.tab ? data.tab : 0;
    // 이전 주문데이터
    $scope.beforeShipment = [];
    // 이전 문의내역
    $scope.beforeCS = [];
    // 배송처 리스트
    $scope.warehouseList = data.warehouseList;
    // 나라 리스트
    $scope.countryList = $rootScope.countryList;

    // 배송방법
    $scope.deliMethodList = angular.copy(shipmentSVC.deliMethodList);
    // 대리점 계정일 경우 내일배송 관련 값 추가
    if ($rootScope.user_profile.pa_sol_no) {
      $scope.deliMethodList.splice(-1, 0, ...['빠른 가전 배송', '내일 배송']);
    }

    // 배송서비스
    $scope.deliveryCarrList = [];

    // 매칭 한 상품들의 배송처
    const matchDepotNo = { prod: null, opt: null };

    // 사본여부 2018-05-08 rony
    $scope.copyedOrder = false;

    // 선택된 배송처
    $scope.selected_depot = {};

    /* 발송정보 뷰 모델 */
    $scope.trackingInfo = {
      carr_no: null,
      invoice_no: '',
      global_carr_no: null,
      global_invoice_no: '',
      bundle_width: '',
      bundle_height: '',
      bundle_depth: '',
      bundle_box_weight: '',
    };

    /* 기타 정보 뷰 모델 */
    $scope.etcInfo = {
      bundle_export_data: [
        {
          exreportno: '',
          divide_yn: '',
          exreport_bundle_cnt: '',
        },
      ],
      ship_plan_date: '',
      ship_delay_type: '0',
      ship_delay_msg: '',
    };

    // 로그용 데이터
    let oldData = {};
    const logField = {
      gift_name: '사은품',
      order_name: '주문자명',
      order_id: '주문자 ID',
      order_tel: '주문자 전화번호',
      order_htel: '주문자 휴대폰번호',

      to_name: '수령자명',
      bundle_tin: '세금식별번호',
      to_addr1: '수령자 주소',
      to_addr2: '수령자 상세주소',
      to_city: '수령자 도시',
      to_state: '수령자 주/도',
      to_town: '수령자 타운',
      to_district: '수령자 현',
      to_zipcd: '수령자 우편번호',
      to_ctry_cd: '수령자 국가',
      to_tel: '수령자 전화번호',
      to_htel: '수령자 핸드폰번호',
      to_email: '수령자 이메일주소',

      ship_cost: '배송비',
      ship_method: '배송방법',
      ship_msg: '배송메세지',

      sku_pack: '주문옵션 SKU코드(세트코드)',
      prod_add_opt_sku_code: '추가구매옵션 SKU코드(세트코드)',
      shop_add_opt_name: '추가구매옵션 명칭',

      pack_unit: '건별 출고수량',
      opt_sale_cnt: '추가구매옵션 수량',

      depot_name: '배송처',
      depot_no: '배송처',

      shop_sale_name: '온라인상품명',
      shop_opt_name: '옵션명',
      sales: '금액',
      sale_cnt: '주문수량',

      bundle_width: '박스사이즈(가로)',
      bundle_height: '박스사이즈(세로)',
      bundle_depth: '박스사이즈(높이)',
      bundle_box_weight: '박스무게',

      gprivate_no: '개인통관번호',

      misc14: 'HS코드',
      misc15: '제조국',
      misc16: '총무게',

      c_sale_cd: '판매자관리코드',
      shop_supply_price: '공급가',
      sales_unit: '쇼핑몰 판매단가',

      pa_addcol_모델번호: '모델번호',

      global_carr_name: '해외택배사',
      global_invoice_no: '트래킹넘버',
      carr_name: '국내택배사',
      invoice_no: '운송장번호',

      ship_plan_date: '배송예정일',
      ship_delay_type: '배송지연 사유',
      ship_delay_msg: '배송지연 사유 직접입력',
    };

    // 수출이행 등록 정보 로그용 데이터 항목
    for (let i = 1; i <= 5; i++) {
      logField[`exreportno${i}`] = `수출신고번호 ${i}`;
      logField[`divide_yn${i}`] = `전량/분할 여부 ${i}`;
      logField[`exreport_bundle_cnt${i}`] = `선기적 포장개수 ${i}`;
    }

    const stockMatchState = ['결제완료', '신규주문', '출고대기', '출고보류', '운송장출력', '출고완료', '배송중', '배송완료', '구매결정', '주문보류'];

    // 모달내 바로 업데이트 된경우 확인용
    $scope.memoUpdate = false;
    $scope.searchData = {};
    $scope.searchForm = {};

    /* 데이터 테이블 */
    $scope.grid = {};
    $scope.grid.methods = {};
    $scope.grid.options = {
      enablePaginationControls: false,
      modal: true,
      gridHeight: 400,
      pinningColumns: ['widget'],
      defaultSortingColumns: ['wdate'],
      notSortingColumns: 'ALL',
      notMovingColumns: 'ALL',
      notVisibleColumns: [],
      alignCenterColumns: ['ord_status', 'widget', 'shop_name', 'wdate', 'sale_cnt', 'deli_corp'],
      alignRightColumns: ['sales'],
      selectOptions: {
        checkbox: false,
        multiSelect: false
      },
      externalRequestOptions: {
        requestMethod: 'GET',
        requestUrl: `${settings.pa20ApiUrl}/app/order/previous-orders`,
        requestWillAction: () => {
          return {
            uniq: $scope.uniq,
            sub_sol_no
          };
        },
        requestDidAction: (result) => {
          return result.results;
        }
      },
      columns: [
        {
          key: 'ord_status',
          title: '상태',
          width: 100,
          template: (row) => {
            return $filter('statusColor')(row.ord_status);
          }
        },
        {
          key: 'widget',
          title: '도구',
          width: 100,
          template: (row) => {
            return `<button type="button" class="btn btn-default btn-xxs mr-5 summary-order" ng-click="grid.appScope.showDetail('${row.uniq}')" >상세</button>
                 <button type="button" class="btn btn-default btn-xxs mr-5" ng-click="grid.appScope.openOrderCS('${row.uniq}')">메모</button>
                `;
          }
        },
        {
          key: 'shop_name',
          title: '쇼핑몰(계정)',
          width: 150,
          template: (row) => {
            return `${row.shop_name}${row.shop_id ? '(' + row.shop_id + ')' : '' }`;
          }
        },
        {
          key: 'wdate',
          title: '주문수집일',
          width: 150,
        },
        {
          key: 'shop_ord_no',
          title: '쇼핑몰 주문번호',
          width: 200,
        },
        {
          key: 'shop_sale_no',
          title: '쇼핑몰 상품코드',
          width: 150,
          template: () => {
            return $scope.shipment.shop_sale_no;
          }
        },
        {
          key: 'shop_sale_name',
          title: '상품명',
          width: 400,
        },
        {
          key: 'shop_opt_name',
          title: '옵션',
          width: 150,
          template: function(row) {
            return row.shop_opt_name ? row.shop_opt_name.replace(/</g, '&lt;') : '';
          }
        },
        {
          key: 'sale_cnt',
          title: '수량',
          width: 100,
        },
        {
          key: 'sales',
          title: '금액',
          width: 100,
        },
        {
          key: 'deli_corp',
          title: '택배사',
          width: 100,
        },
        {
          key: 'deli_invoice',
          title: '운송장번호',
          width: 150,
        },
      ]
    };

    // 모달 닫기
    $scope.cancel = function () {
      $scope.$emit('OnCloseDetail');
    };

    // SKU상품 매칭
    $scope.mappingProd = function(loca, idx) {
      const resolve = {
        data: {
          from: '선택한 주문',
          pack_unit: true,
          warehouseList: $scope.warehouseList,
          isAddOpt: loca === 'addOpt',
          isManual: $scope.shipment.shop_cd === 'A000',
          isMulti: !$scope.user_profile.pa_sol_no,
          isMultiProd: true,
        }
      };

      if (loca === 'addOpt') {
        // 추가구매옵션인 경우 기존에 사용하던 옵션명을 넘겨줌
        resolve.data.ord_opt_name = $scope.shipment.prod_add_opt_list[idx].ord_opt_name;
      }

      const redata = commonSVC.openModal('xxg', resolve, 'prodProductSearch', 'views/online/product/modals/prod_search.html');

      redata.result.then(function (re) {
        function sm() {

          //매칭규칙생성(수정여부)
          if (loca === 'addOpt') {
            $scope.shipment.prod_add_opt_list[idx].newMappingRule = re.isSet ? re.data.set.newMappingRule : re[0].newMappingRule;
          } else {
            $scope.shipment.newMappingRule = re.isSet ? re.data.set.newMappingRule : re[0].newMappingRule;
          }
          if (loca === 'ord') {
            return stockMatch(re, idx);
          } else {
            return addStockMatch(re, idx);
          }
        }

        // 해외 주문 g마켓 물류서비스 매칭 불가능
        if ((re.isSet && re.data.prod.some(re => re.ebaydepot_yn)) || (!re.isSet && re.some(re => re.ebaydepot_yn))) {
          commonSVC.showMessage('해외 주문은 G마켓 물류서비스 배송처 선택이 불가합니다.');

          return false;
        }

        // 다중 매칭 시 prod_no 오름차순 정렬이 기준이라 sort처리
        if (!re.isSet) {
          re = re.sort((p1, p2) => {
            if (p1.prod_no < p2.prod_no) {
              return -1;
            }
            if (p1.prod_no > p2.prod_no) {
              return 1;
            }

            return 0;
          });
        }

        if (re.length > 1 && re.some(re => !re.bundle_avail_yn)) {
          commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

          return false;
        }

        if (_.indexOf(stockMatchState, $scope.shipment.ord_status) < 0) {
          if ($scope.shipment.depot_no && re.find(r => r.depot_no !== $scope.shipment.depot_no)) {
            commonSVC.showMessage('주문을 출고한 배송처와 매칭할 SKU상품에 설정된 배송처가 다릅니다.\n주문을 출고한 배송처의 SKU상품으로 매칭하시기 바랍니다.');
          }
        } else {
          if (sm() && $scope.shipment.bundle_cnt > 1) {
            if ($scope.shipment.ord_status !== '운송장출력' && re.bundle_avail_yn === 0) {
              $scope.shipment.divide_yn = true;
            } else {
              $scope.shipment.divide_yn = false;
            }
          }
          setDepotInfo();
        }
      });
    };

    /**
     * hscode 검색
     **/
    $scope.mappingHSCD = function(hscd) {
      const resolve = {};
      resolve.data = {
        name: hscd
      };
      const redata = commonSVC.openModal('lg', resolve, 'CategorySearchHscodeCtrl', 'views/settings/category/searchHscode.html');

      redata.result.then(function (hscd) {
        $scope.shipment.misc14 = hscd;
      });
    };

    // 저장
    $scope.submit = function() {
      if (!$scope.shipmentForm.$valid) {
        return false;
      }
      const add_opt_list = $scope.shipment.prod_add_opt_list;

      const addOptProds = [];

      // 추가구매옵션 row 사이즈가 1이고 row가 완전히 비었을 때는 유효성 검사 통과
      if (!(add_opt_list.length === 1 && !(add_opt_list[0].sku_list?.length && add_opt_list[0].isCustom))) {
        let valid = true;
        $scope.shipment.prod_add_opt_list.forEach((add_opt, index) => {
          // 추가옵션상품명이 없는 경우
          if (!add_opt.ord_opt_name) {
            valid = false;
            $scope.add_opt_ref[index].ord_opt_name.emitInputError();
          }
          // 주문수량이 없는 경우
          if (!add_opt.opt_sale_cnt) {
            valid = false;
            $scope.add_opt_ref[index].opt_sale_cnt.emitInputError();
          }
        });

        if (!valid) {
          return false;
        }
      }

      // 합포장 불가 상품이 있는 경우에는 SKU 직접입력 상품이 입력되어있는 경우 모달을 통해 수정 실패처리
      const bundle_not_avail_exist = $scope.shipment.sku_list
        .concat($scope.shipment.prod_add_opt_list)
        .some(item => item.bundle_avail_yn === false || item.bundle_avail_yn === 0);
      if (bundle_not_avail_exist && ($scope.shipment.sku_list.some(sku => sku.isCustom) || $scope.shipment.prod_add_opt_list.some(row => row.isCustom))) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      // 기타 정보 > 수출이행 등록 정보 > 전량 분할 값 미선택시 에러 출력
      if ($scope.etcInfo.bundle_export_data.some(row => (row.exreportno || row.exreport_bundle_cnt) && !row.divide_yn)) {
        commonSVC.showMessage(gettextCatalog.getString('수출이행 등록정보 전량/분할 여부를 선택해주세요.'));

        return false;
      }

      $scope.shipment.shop_add_opt_name = _.uniqBy(_.filter(_.map($scope.shipment.prod_add_opt_list, 'ord_opt_name'))).join(',');
      $scope.shipment.prod_add_opt_sku_code = _.uniq(_.flatMap($scope.shipment.prod_add_opt_list, opt => opt.set_no ? opt.sku_cd : _.map(opt.sku_list, 'sku_cd'))).filter(sku => sku).join(', ').replace(/,\s*$/, '');

      if (!oldData.set_no) {
        // SKU 매칭 직접 비교 시에는 기존 데이터 비교 위해 sku_list가 아닌 shop_sku_cds와 비교를 해야됨
        oldData.sku_pack = oldData.shop_sku_cds?.map(sku => {
          if (!sku.ord_opt_seq || sku.ord_opt_seq === 1) {
            return sku.shop_sku_cd;
          }
        }).filter(Boolean).join(',');
      }

      // 직접 입력 sku ord_opt_prod 에 넣지 않기 위해 제거
      // 직접입력 SKU & 매칭 SKU, shop_sku_cds 에 넣어줌
      $scope.shipment.prod_add_opt_list.forEach((opt, index) => {
        if (opt.isCustom) {
          if (opt.sku_cd) {
            addOptProds.push({
              ord_opt_seq: index + 2,
              shop_sku_cd: opt.sku_cd,
              prod_weight: null,
              total_prod_weight: opt.total_prod_weight || 0
            });
          }
        } else {
          opt.sku_list?.forEach(sku => {
            if (sku.sku_cd) {
              addOptProds.push({
                ord_opt_seq: index + 2,
                shop_sku_cd: sku.sku_cd,
                prod_weight: sku.prod_weight || null,
                total_prod_weight: opt.total_prod_weight || 0
              });
            }
          });
        }

        if (opt.sku_list) {
          opt.sku_list = opt.sku_list.map(sku => {
            if (sku.prod_no && sku.sku_cd) { return sku; }
          }).filter(Boolean);
        }
      });

      if (!$scope.shipment.set_no) {
        $scope.shipment.sku_pack = $scope.shipment.sku_list.map(sku => {
          if (!sku.ord_opt_seq || sku.ord_opt_seq === 1) {
            return sku.sku_cd;
          }
        }).filter(Boolean).join(',');
      }

      // 세트일 경우 세트 총 무게 저장
      $scope.etcInfo.shop_sku_cds = $scope.shipment.sku_list.filter(sku => sku.sku_cd).map(prod => ({ shop_sku_cd: prod.sku_cd, ord_opt_seq: prod.ord_opt_seq, prod_weight: prod.prod_weight, ...($scope.shipment.set_no && { total_prod_weight: $scope.shipment.total_prod_weight }) }));
      $scope.etcInfo.shop_sku_cds = [
        ...$scope.etcInfo.shop_sku_cds,
        ...(addOptProds.length && addOptProds || [])
      ];

      // 총 무게에 추가구매옵션 무게 더 해줘야됨
      if ($scope.shipment.set_cd) {
        $scope.etcInfo.misc16 = $scope.shipment.total_prod_weight * $scope.shipment.sale_cnt + add_opt_list.map(opt => parseInt(opt.total_prod_weight) * opt.opt_sale_cnt).reduce((prev, curr) => prev + curr, 0) || $scope.shipment.ori_misc16;
      } else {
        $scope.etcInfo.misc16 = +($scope.shipment.sku_list.reduce((sum, sku) => sum + (sku.prod_weight || $scope.getProdWeight(sku.prod_no)) * $scope.shipment.sale_cnt, 0))
          + add_opt_list.map(opt => parseInt(opt.total_prod_weight) * opt.opt_sale_cnt).reduce((prev, curr) => prev + curr, 0) || $scope.shipment.ori_misc16;
      }

      // 트래킹 정보 및 기타 정보 정제
      $scope.shipment = {
        ...$scope.shipment,
        ...$scope.trackingInfo,
        ...$scope.etcInfo,
        isGlobal: true,
        pa_bundle_no: $scope.shipment.bundle_no,
        sku_list: $scope.shipment.sku_list.filter(sku => sku.prod_no && sku.sku_cd) // 직접입력 주문 건 제외 (ord_opt_prod 테이블 입력)
      };

      oldData = {
        ...oldData,
        ...oldData.trackingInfo,
        ...oldData.etcInfo
      };

      // 로그데이터 가공 후 처리
      const formatLogData = (input) => {
        const data = angular.copy(input);
        data.global_carr_name = $scope.globalCarrierList.find(carr => carr.carr_no == data.global_carr_no)?.carr_name || '';
        data.carr_name = $scope.carrierList.find(carr => carr.carr_no == data.carr_no)?.carr_name || '';
        switch (data.ship_delay_type) {
          case '1':
            data.ship_delay_type = '상품준비중';
            break;
          case '2':
            data.ship_delay_type = '주문제작';
            break;
          case '3':
            data.ship_delay_type = '고객요청';
            break;
          case '4':
            data.ship_delay_type = '기타';
            break;
          default:
            data.ship_delay_type = '';
        }
        for (let i = 0; i < 5; i++) {
          if (data.bundle_export_data[i]) {
            data[`exreportno${i + 1}`] = data.bundle_export_data[i].exreportno;
            data[`exreport_bundle_cnt${i + 1}`] = data.bundle_export_data[i].exreport_bundle_cnt;
            data[`divide_yn${i + 1}`] = +data.bundle_export_data[i].divide_yn ? '분할' : '전량';
          } else {
            data[`exreportno${i + 1}`] = data[`exreport_bundle_cnt${i + 1}`] = data[`divide_yn${i + 1}`] = '';
          }
        }

        return data;
      };

      $scope.shipment.log_contents = logHistorySVC.compareReturnValue(logField, formatLogData(oldData), formatLogData($scope.shipment));
      $scope.shipment.page = $scope.fromPage;

      // 재고변경 여부 (Y=변경, N=변경X)
      $scope.shipment.stockEdit = stockEditCheck() ? 'Y' : 'N';

      // 주문 옵션 수정 매칭 여부 (옵션에 매칭된 SKU상품 삭제 시에는 N)
      // SKU 다중 매칭된경우 N
      $scope.shipment.skuEdit = ((oldData.sku_cd !== $scope.shipment.sku_cd && $scope.shipment.sku_cd) || (oldData.set_cd !== $scope.shipment.set_cd && $scope.shipment.set_cd))
        && !($scope.shipment.sku_list.length > 1 && !$scope.shipment.set_cd)
        && !($scope.shipment.prod_add_opt_list?.find(opt => opt.sku_list && !opt.set_cd && opt.sku_list.length > 1)) ? 'Y' : 'N';

      if (!$scope.shipment.log_contents) {
        commonSVC.showMessage(gettextCatalog.getString('변경사항이 없습니다'));

        return false;
      }
      if ($scope.shipment.sale_cnt < 0) {
        commonSVC.showMessage(gettextCatalog.getString('주문수량은 0 이상으로 입력해주세요.'));

        return false;
      }

      toNameChk();
    };

    //메모 모달
    $scope.openOrderCS = function(uniq) {
      const resolve = {};

      resolve.data = {
        code: 'uniq',
        type: 'ord',
        uniq: uniq,
      };
      commonSVC.openModal('', resolve, 'memoCtrl', 'views/etc/memo.html');
    };

    //이전주문 상세보기
    $scope.showDetail = function(uniq) {
      $('.order-detail-summary').remove();
      const row = $('#order-preView');

      $scope.params = uniq;
      $scope.url = `${settings.pa20ApiUrl}/app/order/detail`;

      row.after($compile('<div class="order-detail-summary"><detail-preview params="params" type ="order_call"></detail-preview></div>')($scope));
    };

    //모달 끄기
    $scope.cancel = function () {
      // 상세안에서 바로수정되는건 있을경우 닫고 새로고침위해 추가
      if ($scope.memoUpdate) {
        $uibModalInstance.close('success');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    init();
    function init() {
      deliveryModel.bookMarkdeliveryInfoList({ domestic: true }).then(({ data: { carr_list, domestic_list } }) => {
        $scope.globalCarrierList = carr_list;
        $scope.carrierList = domestic_list;
      }).catch(() => {
        commonSVC.showMessage(gettextCatalog.getString('해외택배사 리스트를 불러오는데 실패했습니다.'));
      });

      shipmentModel.load({ uniq: [$scope.uniq], sub_sol_no }, function (state, data) {
        if (state == 'success') {
          $scope.shipment = data.order_data[0].set_no && data.order_data.find(ord => ord.prod_no === ord.main_prod_no) || data.order_data[0];
          if ($scope.shipment.sku_cd) {
            $scope.shipment.sku_cd = $scope.shipment.sku_cd.replace(/;/g, ', ');
          }

          // 샤피인 경우는 하이픈 고정값으로 설정. 타 쇼핑몰은 misc17 값으로 사용
          $scope.shipment.shop_custom_cd = $scope.shipment.pa_shop_cd === 'X099' ? '-' : $scope.shipment.misc17;

          $scope.prodList = data.prod_list;
          isClaim = ['취소완료', '반품완료', '교환완료', '맞교환완료'].indexOf($scope.shipment.ord_status) >= 0;

          $scope.isSplitAddOpt = ($rootScope.user_profile.pa_sol_no || $rootScope.userProfileCheck('sol_ser', 'super_user', 'like')) && $scope.shipment.shop_ord_no.includes('+');

          // 세트상품일경우 set_sale_price로 판매가 넣어줌 2019-04-26 Alvin

          $scope.shipment.sale_price = data.order_data[0].set_no ? data.order_data[0].set_sale_price : _(data.ord_opt_list).filter({ ord_opt_seq: 1 }).map(function (opt) { return opt.sale_price * opt.pack_unit; }).sum() || 0;

          // 주문번호 null인 경우 체크
          if ($scope.shipment.shop_ord_no) {
            // 주문번호에 _copy 가 포함되어있는경우 사본주문으로 처리 2018-05-08 rony
            if ($scope.shipment.shop_ord_no.indexOf('_copy') > 0) {
              $scope.copyedOrder = true;
            }
          }

          // 기준통화 설정
          $scope.currency.curr_cd = $scope.shipment.ord_curr_cd;

          const sku_list = data.ord_opt_list.filter(opt => opt.ord_opt_seq === 1);

          /* 배송방법(배송유형) */
          (() => {
            const { misc12, misc4, ori_to_addr2, ori_to_addr1, ori_to_city, ori_shop_opt_name, ori_to_ctry_cd, ori_to_state, ori_to_zipcd, originShipToCountryName, shop_sku_cd } = $scope.shipment;
            $scope.shipment.shippingMethod = `${misc12 || '-'}${misc4 ? ` (${misc4})` : ''}`;
            $scope.shipment.originShipToCountryName = $scope.countryList.find(({ ctry_cd }) => ori_to_ctry_cd === ctry_cd) || '';
            $scope.shipment.originOptionName = `${ori_shop_opt_name || '-'}${shop_sku_cd ? ` (${shop_sku_cd})` : ''}`;
            $scope.shipment.originShipToFullAddress = '' +
            (ori_to_addr2 ? `${ori_to_addr2}, ` : '') +
            (ori_to_addr1 ? `${ori_to_addr1}, ` : '') +
            (ori_to_city ? `${ori_to_city}, ` : '') +
            (ori_to_state ? `${ori_to_state}, ` : '') +
            (ori_to_zipcd ? `${ori_to_zipcd}, ` : '') +
            (originShipToCountryName || '');
          })();

          // 금액관련 항목들에는 소숫점 2자리까지 표기하는 처리 추가
          ['ori_sales', 'ori_sales_unit', 'ship_cost', 'bundle_ship_cost', 'sales_unit', 'sales', 'shop_supply_price'].forEach(key => {
            $scope.shipment[key] = Number($scope.shipment[key]).toFixed(2);
          });

          /**
           * 초기화 시 매칭상품 무게 가져오는 처리 추가
           */
          $scope.getProdWeight = function (prod_no) {
            const prod = data.ord_opt_list.find(opt => opt.prod_no == prod_no);
            if (prod) {
              return prod.prod_weight * prod.pack_unit * 1000;
            }

            return 0;
          };
          // SKU코드에 수량 같이 나오는거 삭제
          $scope.shipment.sku_pack = $scope.shipment.set_no ? $scope.shipment.set_cd : $scope.shipment.sku_cd ? `${$scope.shipment.sku_cd.split(';')[0]}${sku_list.length > 1 ? ` 외 ${sku_list.length - 1}건` : ''}` : '';
          $scope.shipment.prod_pack = $scope.shipment.set_no ? $scope.shipment.set_name : $scope.shipment.prod_name ? $scope.shipment.prod_name : '';
          $scope.shipment.carr_no = $scope.shipment.carr_no ? $scope.shipment.carr_no.toString() : $scope.shipment.carr_no;
          $scope.shipment.stock_cd = $scope.shipment.stock_cd ? $scope.shipment.stock_cd.split(';')[0] : '';

          if ($scope.shipment.shop_sku_cds?.length) {

            if ($scope.shipment.set_no) {
              $scope.shipment.total_prod_weight = 0;
            }

            $scope.shipment.sku_list = angular.copy($scope.shipment.shop_sku_cds).map(sku => {

              const matchOpt = data.order_data.find(ord => ord.prod_sku_cd === sku.shop_sku_cd);

              if (matchOpt && (!sku.ord_opt_seq || sku.ord_opt_seq < 2)) {
                const matchingProd = data.ord_opt_list.find(opt => matchOpt.prod_no === opt.prod_no);

                if ($scope.shipment.set_no) {
                  $scope.shipment.total_prod_weight = sku.total_prod_weight || $scope.shipment.total_prod_weight + matchingProd.prod_weight * 1000;
                }

                return {
                  ...matchingProd,
                  sku_cd: matchOpt.prod_sku_cd,
                  prod_name: matchOpt.prod_name,
                  prod_weight: sku.prod_weight,
                  attri: matchOpt.attri,
                  isCustom: false, // 매칭된 sku 아닌지 여부
                  bundle_avail_yn: matchOpt.bundle_avail_yn
                };
              } else if (!matchOpt && (!sku.ord_opt_seq || sku.ord_opt_seq === 1)) {
                return {
                  sku_cd: sku.shop_sku_cd, // SKU 코드
                  prod_no: null, // 매칭No
                  pack_unit: 1, // 개당출고수량
                  prod_weight: sku.prod_weight, // 개당무게
                  depot_no: null, // 배송처
                  total_cnt: $scope.shipment.sale_cnt || 0, // 총출고수량
                  isCustom: true // 매칭된 sku 아닌지 여부
                };
              }

            }).filter(Boolean) || [];

          } else {
            $scope.shipment.sku_list = [];
          }

          // 주문 상세모달 로드시 매칭된 SKU상품이 없는 경우에도 기본값으로 빈 SKU매칭 row를 출력
          if (!$scope.shipment.sku_list.length) {
            $scope.addSku();
          }

          // 건별출고수량은 세트상품전체를 하나의 단위로 보기때문에 세트내의 상품 출고수량을 더해주지 않음 (QA팀 확인) 2018-11-08 rony
          // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
          $scope.shipment.pack_unit = data.order_data.reduce((prev, curr) => prev + +curr.pack_unit, 0);

          // 옵션에 매칭되어있는 SKU상품 or 세트상품의 원가
          $scope.ord_opt_cost_price = _(data.ord_opt_list).filter({ ord_opt_seq: 1 }).map(function (opt) { return opt.cost_price * opt.pack_unit; }).sum();

          // 추가구매옵션
          settingDetailData(data);

          setDepotInfo();

          $scope.orderEdit = $scope.orderEdit && data.status !== '운송장출력';

          // 기본옵션
          matchDepotNo.prod = $scope.shipment.prod_no ? $scope.shipment.depot_no : null;

          // 추가구매옵션
          const matchedAddOpts = _.filter($scope.shipment.prod_add_opt_list, function (opt) {
            return opt.sku_list?.[0]?.depot_no;
          });

          matchDepotNo.opt = matchedAddOpts.length ? matchedAddOpts[0].sku_list[0].depot_no : null;

          // 배송처수정 주문수정가능할때만 수정할 수 있게 조건추가
          $scope.depotChangeYn = _.indexOf(stockMatchState, $scope.shipment.ord_status) >= 0 && (!matchDepotNo.prod && !matchDepotNo.opt) && $scope.orderEdit;

          // 기본 SKU상품이 매칭되어 있지 않고, 추가구매옵션만 매칭된 경우 배송처 공란 문제 및 배송처가 같지 않더라도 수정이 가능 한 문제 처리
          // 2018-12-03 Jacob
          let depots = [];

          // SKU상품 매칭 + 배송처 지정x
          if ($scope.prodList && $scope.prodList.length && (!$scope.selected_depot || !$scope.selected_depot.depot_no)) {
            _.each($scope.prodList, function (prod) {
              // prodList 에 depot_no가 있는 경우 처리
              if (prod.depot_no && $scope.depotChangeYn) {
                depots = _.filter($scope.warehouseList, function (w) {
                  return w.depot_no == prod.depot_no;
                });

                if (depots.length) {
                  $scope.depotChangeYn = false;
                  $scope.selected_depot = depots[0];
                  $scope.shipment.depot_name = depots[0].warehouse_name;
                  $scope.shipment.depot_no = depots[0].code;
                  $scope.shipment.prod_add_opt_list[0].sku_list[0].depot_no = depots[0].code;
                  matchDepotNo.opt = depots[0].code;
                }
              }
            });
            // 위에서 처리 되지 않는 경우 실행
            if ($scope.depotChangeYn) {
              const depotData = {};
              let pIndex;

              // 매칭된 상품 번호 가져오기
              _.each($scope.prodList, function (prod, index) {
                if (prod.prod_no) {
                  depotData.prod_no = prod.prod_no;
                  pIndex = index;

                  return;
                }
              });

              if (depotData.prod_no) {
                // 해당 상품번호로 배송처정보 가져오기
                productModel.getDepotNo(depotData.prod_no, function (state, data) {
                  if (state === 'success' && data.results.length) {
                    $scope.depotChangeYn = false;
                    depots = _.filter($scope.warehouseList, function (w) {
                      // 해당 번호에 맞는 배송처 정보 저장
                      return w.code == data.results[0].depot_no;
                    });
                    if (depots.length) {
                      $scope.selected_depot = depots[0];
                      $scope.shipment.depot_name = depots[0].warehouse_name;
                      $scope.shipment.depot_no = depots[0].code;
                      $scope.shipment.prod_add_opt_list[pIndex].sku_list[0].depot_no = depots[0].code;
                      matchDepotNo.opt = depots[0].code;
                    }
                  }
                });
              }
            }
          }

          // 클레임 주문시 0처리
          if (isClaim) {
            $scope.shipment.sale_cnt = 0;
            $scope.shipment.sales = 0;
            $scope.shipment.sale_price = 0;
            $scope.shipment.ord_opt_cost_price = 0;
            $scope.shipment.ship_cost = 0;
            $scope.shipment.shop_supply_price = 0;

            if ($scope.shipment.prod_add_opt_list) {
              _.forEach($scope.shipment.prod_add_opt_list, function (opt) {
                opt.opt_sale_cnt = 0;
              });
            }
          }
        } else {
          commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
        }

        getBeforeCS();

        //추가 컬럼 확목 조회
        columnModel.detailColumn({ uniq: $scope.uniq, type: 'ord' })
          .then((re) => {
            if (re.status === 200) {
              re.data.result.forEach((row) => {
                if (row.input_type === 'int' && row.col_val) {
                  row.col_val = parseFloat(row.col_val);
                }
                if (row.enum_data) {
                  row.enumList = columnSVC.enumDataSet(row.enum_data);
                  const findColVal = _.find(row.enumList, (o) => {
                    return o[0] === row.col_val;
                  });
                  if (findColVal) {
                    row.col_val = findColVal[1];
                  }
                }
              });
              $scope.shipment.addColData = _.groupBy(re.data.result, 'addcol_type');
            }
          }).then(() => {
            salePriceUpdate();
          });

        /* 첫번째 주문 데이터 */
        const [shipment] = data.order_data;
        const { exReportData: [{ exreportno, divide_yn, exreport_bundle_cnt } = {}, ...restExportData] = [] } = data;

        /* 해외 트래킹 정보 수정 모델에 반영 */
        $scope.trackingInfo = {
          carr_no: shipment.carr_no ? String(shipment.carr_no) : null,
          invoice_no: shipment.invoice_no || '',
          global_carr_no: shipment.global_carr_no ? String(shipment.global_carr_no) : null,
          global_invoice_no: shipment.global_invoice_no || ''
        };

        /* 박스 정보 수정 모델에 반영 */
        Object.assign($scope.trackingInfo, {
          bundle_width: shipment.bundle_width,
          bundle_height: shipment.bundle_height,
          bundle_depth: shipment.bundle_depth,
          bundle_box_weight: shipment.bundle_box_weight,
        });

        /* 수출이행등록 정보 수정 모델에 반영 */
        $scope.etcInfo.bundle_export_data = [
          {
            exreportno: exreportno || '',
            divide_yn: typeof divide_yn === 'number' ? String(divide_yn) : '',
            exreport_bundle_cnt: exreport_bundle_cnt || '',
          },
          ...restExportData.map(({ exreportno, divide_yn, exreport_bundle_cnt }) => ({
            exreportno: exreportno || '',
            divide_yn: typeof divide_yn === 'number' ? String(divide_yn) : '',
            exreport_bundle_cnt: exreport_bundle_cnt || '',
          })),
        ];

        /* 배송예정일 정보 수정 모델에 반영 */
        Object.assign($scope.etcInfo, {
          ship_plan_date:
            !shipment.ship_plan_date || shipment.ship_plan_date === '0000-00-00' ? '' : shipment.ship_plan_date,
          ship_delay_type: typeof shipment.ship_delay_type === 'number' ? String(shipment.ship_delay_type) : '0',
          ship_delay_msg: shipment.ship_delay_msg || '',
        });

        /* 통화 코드 반영 */
        Object.assign($scope.currency, {
          curr_cd: shipment.ord_curr_cd,
        });

        $scope.add_opt_ref = _.cloneDeep($scope.shipment.prod_add_opt_list);
      });
    }

    /**
     * 제조국 select 검색
     */
    $timeout(function () {
      const select2_id = 'madein_ctry';
      const $searchDetailElem = $(`#${select2_id}`);
      $searchDetailElem.attr('data-container-css-class', 'select2-custom2 input-xs');
      $searchDetailElem.select2({
        placeholder: '선택하세요',
        width: 153
      });
      const $selectSearchElemBox = $(`[aria-labelledby='select2-${select2_id}-container']`);
      $selectSearchElemBox.css({
        'height': '36px',
        'display': 'flex',
        'align-items': 'center'
      });
    });

    // 모달 밖 세로 스크롤 늘어나는 문제 해결 2020-06-08 Tim
    $(document).ready(() => {
      $('div.modal').css({
        overflow: 'hidden',
        height: $(window).height(),
        width: $(window).width(),
      });
    });

    /**
     * 배송처 변경
     */
    $scope.changeDepot = function() {
      // SKU상품 매칭 되어있을 때만 선택한 배송처와 매칭된 SKU상품의 배송처 검사 2018-11-15 Amelia
      if ($scope.shipment.sku_pack || $scope.shipment.prod_add_opt_sku_code) {
        const sku_cd = $scope.shipment.sku_pack ? $scope.shipment.sku_pack.split(' 외 ')[0] : $scope.shipment.prod_add_opt_sku_code ? $scope.shipment.prod_add_opt_sku_code : null;

        productModel.detail({ sku_code: sku_cd }, function(state, re) {
          if (state == 'success') {
            // 일치하면 값 세팅
            if (re.results.depot_name == $scope.selected_depot.warehouse_name) {
              $scope.shipment.depot_no = $scope.selected_depot.code;
              $scope.shipment.depot_name = $scope.selected_depot.warehouse_name;
            } else {
              // 일치하지 않으면 값 초기화
              $scope.selected_depot = null;
              $scope.shipment.depot_no = null;
              $scope.shipment.depot_name = null;
              commonSVC.showMessage(gettextCatalog.getString('매칭된 SKU상품과 배송처가 다릅니다.'));
            }
          } else {
            commonSVC.showMessage(gettextCatalog.getString('배송처 조회에 실패하였습니다.'));

            return false;
          }
        });
      } else {
        $scope.shipment.depot_no = $scope.selected_depot.code;
        $scope.shipment.depot_name = $scope.selected_depot.warehouse_name;
      }
    };

    /**
     * 배송처 정보 세팅
     */
    function setDepotInfo() {
      _.each($scope.warehouseList, function(w) {
        if (w.code == $scope.shipment.depot_no) {
          $scope.shipment.depot_name = w.warehouse_name;
          $scope.selected_depot = w;

          return;
        }
      });
    }

    /**
     * hscode 검색
     **/
    $scope.searchHscode = function () {
      const resolve = {};

      resolve.data = {
        name: $scope.shipment.misc14
      };
      const redata = commonSVC.openModal('lg', resolve, 'CategorySearchHscodeCtrl', 'views/settings/category/searchHscode.html');

      redata.result.then(function (re) {
        $scope.shipment.misc14 = re;
      });
    };

    /*직접입력 체크박스 클릭 시 매칭된 정보 날려줌*/
    $scope.setSkuCustom = (row, isAddOpt) => {
      $timeout(() => {
        if (!isAddOpt) {
          if (row.isCustom) {

            row.sku_cd = '';
            row.prod_no = null;
            row.pack_unit = 1;
            row.prod_weight = '';
            row.total_pack_unit = row.sale_cnt || 0;
            row.prod_name = '';
            row.attri = '';
            row.ord_opt_seq = 1;
            row.total_prod_weight = 0;
            row.bundle_avail_yn = 1;
          }
        } else {
          row.set_cd = null;
          row.set_no = null;
          row.sku_cd = '';
          row.sku_pack = row.sku_cd;
          row.pack_unit = 1;
          row.opt_prod_name = '';
          row.prod_pack = '';
          row.prod_attr = '';
          row.set_info = { set_no: null, set_cd: null, set_pack_unit: null };
          row.set_pack_unit = null;
          row.opt_sku_cd_list = '';
          row.total_prod_weight = '';
          row.sku_list = [{
            prod_no: null,
            pack_unit: 1,
            prod_weight: '',
            total_pack_unit: row.sale_cnt || 0,
            prod_name: '',
            attri: ''
          }];
          row.bundle_avail_yn = 1;
        }
      }, 0);
    };

    $scope.changeSetProd = (sku_cd) => {
      $scope.shipment.sku_list = [{
        sku_cd: '',
        prod_no: null,
        pack_unit: 1,
        prod_weight: '',
        total_pack_unit: $scope.shipment.sale_cnt || 0,
        total_prod_weight: 0,
        prod_name: '',
        attri: '',
        isCustom: true,
        bundle_avail_yn: 1
      }];
      $scope.shipment.set_cd = '';
      $scope.shipment.set_no = '';
      $scope.shipment.total_prod_weight = 0;
      $scope.shipment.set_info = { set_no: null, set_cd: null, set_pack_unit: null };
    };

    // 재고 변경 됐는지 확인
    // 2019-01-30 Alvin
    function stockEditCheck () {

      // 주문 수량이 바뀌었으면 재고변경
      if (oldData.sale_cnt !== $scope.shipment.sale_cnt) {
        return true;
      }

      // 옵션을 새로 매칭했으면 재고변경
      if ($scope.shipment.sku_list) {
        return true;
      }

      let returnValue = false;

      // 추가구매옵션 개수가 다르면 재고 변경
      if ($scope.shipment.prod_add_opt_list.length === oldData.prod_add_opt_list.length) {
        _.forEach($scope.shipment.prod_add_opt_list, function (opt, opt_key) {
          const oldOpt = oldData.prod_add_opt_list[opt_key];

          // 추가구매옵션 출고수량 다르면 재고 변경
          if (opt.opt_sale_cnt !== oldOpt.opt_sale_cnt) {
            returnValue = true;

            return false;
          }

          if (opt.hasOwnProperty('sku_list')) {
            _.forEach(opt.sku_list, function (prod, key) {
              // 추가구매옵션 매칭된 SKU상품 다르면 재고변경
              if (prod.prod_no !== oldOpt.sku_list[key].prod_no) {
                returnValue = true;

                return false;
              }
            });
          }
        });
      } else {
        returnValue = true;
      }

      return returnValue;
    }

    //추가구매옵션 포맷팅 및 oldData 셋팅
    function settingDetailData({ prod_list }) {
      $scope.shipment.prod_add_opt_list = [];
      const new_prod = angular.copy(shipmentSVC.shipment.prod_add_opt_list[0]);
      delete new_prod.sku_list;

      // 추가 구매 옵션 없는 주문은 수량 공란으로 노출
      if (!new_prod.ord_opt_name) {
        new_prod.opt_sale_cnt = null;
        new_prod.pack_unit = '';
      }

      let main_prod, idx;
      _.each(_.groupBy(prod_list, 'ord_opt_seq'), function(order) {
        main_prod = order[0];

        //세트상품의 대표상품을 0번 위치로 바꿔줌 2020-05-21 Tim
        if (main_prod.set_no) {
          idx = order.findIndex(ord => ord.prod_no === ord.main_prod_no);
          main_prod = order[idx];
          order = order.splice(idx, 1).concat(order);
        }

        const sku_list = [];
        let pack_unit = 0, cost_price = 0;
        order.forEach(o => {
          if (!matchDepotNo.opt || o.depot_no) {
            matchDepotNo.opt = o.depot_no;
          }
          pack_unit += o.pack_unit;
          cost_price += o.cost_price * o.pack_unit;

          // 매칭된 SKU 전체
          const matchProd = $scope.shipment.shop_sku_cds.filter(sku => o.ord_opt_seq === sku.ord_opt_seq && sku.shop_sku_cd === o.sku_cd);

          matchProd.forEach(prod => {
            sku_list.push({
              prod_no: o.prod_no,
              depot_no: o.depot_no,
              pal_no: o.pal_no,
              pack_unit: o.pack_unit,
              sku_cd: prod.shop_sku_cd,
              prod_name: o.prod_name,
              sale_price: o.sale_price,
              attri: o.attri,
              stock_cd: o.stock_cd,
              opt_sale_cnt: o.opt_sale_cnt,
              depot_name: o.depot_name,
              ord_opt_name: o.ord_opt_name,
              total_prod_weight: prod.total_prod_weight,
            });
          });

          // 매칭된 SKU가 없을 경우 직접 입력 SKU 데이터에서 찾아서 삽입해줘야함
          if (!o.prod_no) {
            const customSku = $scope.shipment.shop_sku_cds.find(sku => o.ord_opt_seq === sku.ord_opt_seq && !order.map(o => o.sku_cd).includes(sku.shop_sku_cd));

            if (customSku) {
              order[0].sku_cd = customSku.shop_sku_cd;

              sku_list.push({
                prod_no: null,
                depot_no: o.depot_no,
                pal_no: o.pal_no,
                pack_unit: 1,
                sku_cd: customSku.shop_sku_cd,
                prod_name: o.prod_name,
                sale_price: o.sale_price,
                attri: o.attri,
                stock_cd: o.stock_cd,
                opt_sale_cnt: o.opt_sale_cnt,
                depot_name: o.depot_name,
                ord_opt_name: o.ord_opt_name,
                total_prod_weight: customSku.total_prod_weight,
              });
            }
          }
        });

        // SKU코드에 수량 같이 나오는거 삭제
        const cd = main_prod.set_no ? main_prod.set_cd : main_prod?.sku_cd || '';
        const cd_pack = main_prod.set_no ? main_prod.set_cd :
          main_prod?.sku_cd && order.length > 1 ? `${main_prod.sku_cd}${` 외 ${order.length - 1}건`}` :
            main_prod?.sku_cd || '';
        const set_no = main_prod.set_no ? main_prod.set_no : null;
        const opt_prod_name = main_prod.set_no ? main_prod.set_name : main_prod.sku_cd ? main_prod.prod_name : '';
        const sale_price = main_prod.set_no ? main_prod.set_sale_price * main_prod.set_pack_unit
          : order.reduce((sum, ord) => sum + (ord.sale_price * ord.pack_unit), 0);

        // 건별출고수량은 세트상품전체를 하나의 단위로 보기때문에 세트내의 상품 출고수량을 더해주지 않음 (QA팀 확인) 2018-11-08 rony
        // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
        $scope.shipment.prod_add_opt_list.push({
          ord_opt_name: main_prod.ord_opt_name,
          ord_opt_type: '수동',
          opt_sale_cnt: main_prod.opt_sale_cnt,
          sku_cd: cd,
          sku_pack: cd_pack,
          sku_list: sku_list,
          ord_opt_seq: main_prod.ord_opt_seq,
          uniq: main_prod.uniq,
          set_no: set_no,
          set_cd: set_no ? cd : '',
          pack_unit: pack_unit,
          set_pack_unit: main_prod.set_pack_unit,
          total_cnt: pack_unit > 0 ? (main_prod.opt_sale_cnt * pack_unit) : '',
          bundle_avail_yn: main_prod.bundle_avail_yn,
          total_prod_weight: sku_list.length ? sku_list[0].total_prod_weight : 0,
          cost_price: cost_price,
          sale_price: sale_price,
          opt_sku_cd_list: sku_list.map(o => o.sku_cd).join(', '),
          opt_prod_name: opt_prod_name,
          prod_attr: main_prod.attri_pack,
          stock_cd: main_prod.stock_cd,
          barcode: main_prod.barcode,
          isCustom: sku_list.length ? !!(!sku_list[0].prod_no && sku_list[0].sku_cd) : false
        });
      });

      if (!$scope.shipment.prod_add_opt_list.length) {
        $scope.shipment.prod_add_opt_list.push(new_prod);
      }

      if (!$scope.shipment.prod_add_opt_list[0].ord_opt_name) {
        $scope.accordion_expanded = false;
      }

      $scope.shipment.shop_add_opt_name = _.uniqBy(_.filter(_.map($scope.shipment.prod_add_opt_list, 'ord_opt_name'))).join(',');
      $scope.shipment.prod_add_opt_sku_code = _.uniq(_.flatMap($scope.shipment.prod_add_opt_list, opt => opt.set_no ? opt.sku_cd : _.map(opt.sku_list, 'sku_cd'))).filter(sku => sku).join(', ').replace(/,\s*$/, '');
      $scope.status = $filter('statusColor')($scope.shipment.ord_status);  //주문상태 css

      // 각 추구옵에 매칭된 옵션의 개당 총합 계산(1 set)
      $scope.shipment.prod_add_opt_list.forEach(opt => {
        opt.total_prod_weight = $scope.calcAddOptTotalWeight(opt);
      });

      oldData = angular.copy($scope.shipment); // 수정 로그용 원본 데이터
      oldData.trackingInfo = angular.copy($scope.trackingInfo);
      oldData.etcInfo = angular.copy($scope.etcInfo);

    }

    // 이전문의내역 불러오기
    function getBeforeCS() {
      shipmentModel.getBeforeCS({ uniq: $scope.uniq }, function (state, data) {
        if (state == 'success') {
          $scope.beforeCS = data.results;
        } else {
          commonSVC.showMessage(gettextCatalog.getString('데이터 로드 실패'));
        }
      });
    }

    function toNameChk() {
      if ($scope.shipment.log_contents.join('').indexOf('수령자') > -1) {
        $timeout(function () {
          commonSVC.showConfirm('수정하시겠습니까?', ' 수령자정보 수정시 함께 묶인주문의 수령자정보도 변경됩니다. ', function (confirm2) {
            if (confirm2) {
              update();
            }
          });
        }, 300); // 스윗얼럿 버그로인해 0.3초 줌 수정 금지
      } else {
        update();
      }
    }

    function update() {
      prodAddOptSet();
      if ($rootScope.userProfileCheck('sol_ser', 'addcol', 'like') || $scope.viewModelNo) {
        // 로그필드에 설정된 추가컬럼
        const addColLogKeys = Object.keys(logField).filter(key => key.startsWith('pa_addcol'));

        // 로그필드에 설정된 추가컬럼이 아니면 데이터 제거
        Object.keys($scope.shipment).forEach(key => {
          if (key.startsWith('pa_addcol') && !addColLogKeys.includes(key)) {
            delete $scope.shipment[key];
          }
        });
      }

      shipmentModel.update($scope.shipment, function (state, result) {

        if (state == 'success') {
          commonSVC.showToaster('success', '', gettextCatalog.getString('주문수정에 성공하였습니다.'));
          $uibModalInstance.close('success');
          init();
        } else {
          commonSVC.showToaster('error', '', result.data.error || '주문수정에 실패하였습니다.');
        }
      });
    }

    //SKU코드 매칭
    function stockMatch(re, idx) {
      let setWeight = 0;
      if (re.isSet) {
        re.data.prod.forEach(prod => {
          prod.prod_weight = prod.prod_weight * 1000;
          prod.ord_opt_seq = 1;
          setWeight += prod.prod_weight * prod.pack_unit;
        });
        data = re.data.prod;
      } else {
        re.forEach(prod => {
          prod.prod_weight = prod.prod_weight * 1000 * prod.pack_unit;
          prod.ord_opt_seq = 1;
        });
        if ($scope.shipment.set_cd) {
          data = re;
        } else {
          data = _.uniqBy([...$scope.shipment.sku_list.slice(0, idx), ...re, ...$scope.shipment.sku_list.slice(idx + 1)], 'sku_cd');
        }
      }

      const depot_nos = _.uniqBy(_.map(data, 'depot_no')).filter(depot_no => depot_no);

      if (depot_nos.length > 1 || (matchDepotNo.opt && matchDepotNo.opt != depot_nos[0])) {
        commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

        return false;
      }

      // 매칭하려는 상품이 2개 이상 있고 이중에 합포장 불가 상품이 있는 경우 매칭 실패 처리
      if (data.filter(prod => prod.prod_no || prod.sku_cd).length > 1 && data.filter(prod => !prod.isCustom).some(prod => !prod.bundle_avail_yn)) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      // 매칭 상품/추구옵 매칭 상품 중에 SKU 직접입력한 상품이 있고 매칭하려는 상품 중 합포장 불가 상품이 있는 경우 매칭 실패 처리
      else if (data.filter(prod => !prod.isCustom).some(prod => !prod.bundle_avail_yn) && ($scope.shipment.sku_list.some((sku, i) => sku.isCustom && idx !== i) || $scope.shipment.prod_add_opt_list.some(row => row.isCustom))) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      const opts = $scope.shipment.prod_add_opt_list.filter(o => {
        // 세트상품에는 bundle_avail_yn 없으므로 세트상품인지 체크 (세트상품엔 합포장불가상품 추가안됨 & 합포장불가로 변경되면 세트상품 사용불가됨) 2018-11-13 Amelia
        if (!re.isSet && re.find(r => !r.bundle_avail_yn)) {
          // 매칭하려는 재고가 합포장 불가인 경우 추가구매옵션에서 매칭된 재고가 있는 경우 찾음
          return o.bundle_avail_yn !== null && o.sku_cd;
        } else {
          // 매칭하려는 재고가 합포장 가능인 경우 추가구매옵션에서 매칭된 합포장 불가 재고 찾음
          return o.bundle_avail_yn === 0 && o.sku_cd;
        }
      });

      if ((matchDepotNo.prod || matchDepotNo.opt) && opts.length) {

        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      const valid_prod_list = data.filter(prod => prod.prod_no);

      //선택한 상품들의 배송처가 같으면서 기존에 선택한 창고랑 같을때
      $scope.shipment.sku_pack = re.isSet ? re.data.set.set_cd : `${valid_prod_list[0].sku_cd}${valid_prod_list.length > 1 ? ` 외 ${valid_prod_list.length - 1}건` : ''}`;
      $scope.shipment.prod_pack = re.isSet ? re.data.set.set_name : valid_prod_list[0].prod_name;
      $scope.shipment.sku_list = data;
      $scope.shipment.set_info = re.isSet ? { set_no: re.data.set.set_no, set_cd: re.data.set.set_cd, set_pack_unit: re.data.set.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.model_no = re.isSet ? re.data.set.model_no : valid_prod_list[0].model_no;

      let total_pack_unit = 0;
      let re_pack_unit = valid_prod_list.reduce((sum, prod) => sum + +prod.pack_unit, 0);
      let total_cost_price = valid_prod_list.reduce((prev, curr) => prev + (+curr.cost_price * curr.pack_unit || 0), 0);

      if (re.isSet) {
        _.each(re.data.prod, function (order) {
          total_pack_unit += order.pack_unit;
          order.pack_unit *= re.data.set.set_pack_unit;

          total_cost_price += order.cost_price * order.pack_unit;
        });

        re_pack_unit = re.data.set.set_pack_unit;
      }

      // 주문 옵션에 매칭된 SKU상품 원가
      $scope.ord_opt_cost_price = total_cost_price * (re.isSet ? re_pack_unit : 1);
      const pack_unit = re.isSet ? total_pack_unit * re.data.set.set_pack_unit : re_pack_unit;
      $scope.shipment.pack_unit = pack_unit;
      $scope.shipment.set_pack_unit = re.isSet ? re.data.set.set_pack_unit : null;
      $scope.shipment.prod_no = re.isSet ? null : re.prod_no;
      $scope.shipment.set_no = re.isSet ? re.data.set.set_no : null;
      $scope.shipment.set_cd = re.isSet ? re.data.set.set_cd : null;
      $scope.shipment.sku_cd = valid_prod_list.map((r) => r.sku_cd).join(', ');
      $scope.shipment.bundle_avail_yn = valid_prod_list.find(r => !r.bundle_avail_yn) ? 0 : 1;
      $scope.shipment.sale_price = re.isSet ? re.data.set.sale_price : _(re).map(re => +re.sale_price * re.pack_unit || 0).sum();
      $scope.shipment.prod_sol_no = re.sol_no;
      $scope.shipment.total_prod_weight = setWeight * $scope.shipment.set_pack_unit;

      // SKU상품매칭시 해당 SKU상품의 배송처도 매칭되도록 처리 2018-06-12 rony
      $scope.shipment.depot_no = valid_prod_list[0].depot_no;
      $scope.shipment.depot_name = valid_prod_list[0].depot_name;
      $scope.shipment.attri_pack = valid_prod_list[0].attri_pack ? valid_prod_list[0].attri_pack : '';
      $scope.shipment.stock_cd = valid_prod_list[0].stock_cd;
      $scope.shipment.barcode = valid_prod_list[0].barcode;
      matchDepotNo.prod = valid_prod_list[0].depot_no;
      $scope.depotChangeYn = false;

      // 판매가, 원가 업데이트
      $scope.priceUpdate();

      // 주문 제조국(원산지), HS코드 업데이트
      if (idx === 0) {
        $scope.shipment.misc15 = re[0].ctry_cd;
        $scope.shipment.misc14 = re[0].hscd;
        // 제조국(원산지) 선택값은 드롭다운 클릭 시 바뀌어 있는데 출력되는 부분은 업데이트가 정상적으로 안되는 문제 수정
        if (re[0].ctry_cd) {
          if (re[0].ctry_cd === 'KR') {
            $('#select2-madein_ctry-container').text('대한민국');
          } else {
            $('#select2-madein_ctry-container').text(re[0].madein_name);
          }
        }
      }

      return true;
    }

    //속성 추가, 삭제
    $scope.calculateOPT = function (calMode, idx) {
      if (calMode === 'plus') {
        const maxCount = 10;

        if ($scope.shipment.prod_add_opt_list.length < maxCount) {
          $scope.shipment.prod_add_opt_list.push({
            ord_opt_type: '수동',
            opt_sale_cnt: 1,
            total_prod_weight: 0
          });
        }
        $scope.add_opt_ref.push({
          ord_opt_name: null,
          opt_sale_cnt: null,
        });
      } else {
        $scope.shipment.prod_add_opt_list.splice(idx, 1);
        $scope.priceUpdate();
        $scope.add_opt_ref.pop();
      }
    };

    //추가구매 옵션 SKU코드 매칭
    function addStockMatch(re, idx) {
      data = re.isSet ? re.data.prod : re;

      const depot_nos = _.uniqBy(_.map(data, 'depot_no'));

      // 재고가 매칭된 옵션이 하나이고 그 옵션의 배송처를 바꾸는 경우 처리
      const depot_no = matchDepotNo.prod || matchDepotNo.opt;
      let depot_cnt = matchDepotNo.prod === depot_no ? 1 : 0;

      if (depot_no) {
        depot_cnt += $scope.shipment.prod_add_opt_list.filter((prod, i) => prod.sku_list && prod.sku_list[0]?.depot_no && prod.sku_list[0]?.depot_no === depot_no && i !== idx).length;
      }

      if (depot_nos.length > 1 || (depot_no && (matchDepotNo.prod != depot_nos[0] && matchDepotNo.opt != depot_nos[0] && depot_cnt > 0))) {
        commonSVC.showMessage('매칭실패', '묶음주문(합배송) 상품은 배송처가 동일한 SKU상품만 매칭이 가능합니다.\n매칭할 SKU상품의 배송처를 확인해주세요.');

        return false;
      }

      let bundle_not_avail_exist = false;
      // 한주문에 합포장 불가인 상품 포함 금지
      const opts = [{ bundle_avail_yn: $scope.shipment.bundle_avail_yn, sku_cd: $scope.shipment.sku_cd }]
        .concat($scope.shipment.prod_add_opt_list)
        .filter(function (o, k) {
          // 세트상품에는 bundle_avail_yn 없으므로 세트상품인지 체크 (세트상품엔 합포장불가상품 추가안됨 & 합포장불가로 변경되면 세트상품 사용불가됨) 2018-11-13 Amelia
          if (!re.isSet && re.find(r => !r.bundle_avail_yn)) {
            bundle_not_avail_exist = bundle_not_avail_exist || o.bundle_avail_yn !== null;

            // 매칭하려는 재고가 합포장 불가인 경우 옵션 중에서 매칭된 재고가 있는 경우 찾음
            return o.bundle_avail_yn !== null && o.sku_cd && idx !== (k - 1);
          } else {
            bundle_not_avail_exist = bundle_not_avail_exist || o.bundle_avail_yn === 0;

            // 매칭하려는 재고가 합포장 가능인 경우 옵션 중에서 매칭된 합포장 불가 재고 찾음
            return o.bundle_avail_yn === 0 && o.sku_cd && idx !== (k - 1);
          }
        });
      if ((matchDepotNo.prod || matchDepotNo.opt) && opts.length) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      // 매칭 상품/추구옵 매칭 상품 중에 SKU 직접입력한 상품이 있고 매칭하려는 상품 중 합포장 불가 상품이 있는 경우 매칭 실패 처리
      if (bundle_not_avail_exist && ($scope.shipment.sku_list.some(sku => sku.isCustom) || $scope.shipment.prod_add_opt_list.some((row, i) => row.isCustom && i !== idx))) {
        commonSVC.showMessage('매칭실패', '1개 주문에 합포장 가능/불가 SKU상품을 함께 매칭할 수 없습니다.\n해당 상품은 주문을 분리하여 생성하시기 바랍니다.');

        return false;
      }

      data.forEach(sku => {
        sku.prod_weight = sku.prod_weight * 1000 * sku.pack_unit;
      });

      $scope.shipment.prod_add_opt_list[idx].sku_cd = re.isSet ? re.data.set.set_cd : data.map(r => (r.sku_cd)).join(', ');
      $scope.shipment.prod_add_opt_list[idx].sku_pack = re.isSet ? re.data.set.set_cd : data.length > 1 ? `${data[0].sku_cd}${` 외 ${data.length - 1}건`}` : data[0].sku_cd;
      $scope.shipment.prod_add_opt_list[idx].ord_opt_name = re.isSet ? re.data.set.set_name : data[0].prod_name;
      $scope.shipment.prod_add_opt_list[idx].sku_list = data;
      $scope.shipment.prod_add_opt_list[idx].set_info = re.isSet ? { set_no: re.data.set.set_no, set_cd: re.data.set.set_cd, set_pack_unit: re.data.set.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
      $scope.shipment.prod_add_opt_list[idx].set_pack_unit = re.isSet ? re.data.set.set_pack_unit : null;
      $scope.shipment.prod_add_opt_list[idx].cost_price = _(data).map(function (opt) { return opt.cost_price * opt.pack_unit; }).sum();
      $scope.shipment.prod_add_opt_list[idx].total_prod_weight = data.reduce((prev, curr) => prev + +curr.prod_weight, 0) * (re.isSet ? re.data.set.set_pack_unit : 1);
      $scope.shipment.prod_add_opt_list[idx].isCustom = false;

      let total_pack_unit = 0;

      if (re.isSet) {
        _.each(re.data.prod, function (order) {
          total_pack_unit += order.pack_unit;
          order.pack_unit *= re.data.set.set_pack_unit;
        });
      }

      // 건별출고수량은 세트상품전체를 하나의 단위로 보기때문에 세트내의 상품 출고수량을 더해주지 않음 (QA팀 확인) 2018-11-08 rony
      // 건별출고수량은 해당 주문건에 나가는 모든 SKU상품의 출고수량을 더한 수 (애슐리 확인) 2019-01-24 Alvin
      const pack_unit = re.isSet ? total_pack_unit * re.data.set.set_pack_unit : data.reduce((sum, ord) => sum + +ord.pack_unit, 0);
      //var pack_unit = re.isSet ? re.data.set.set_pack_unit : data[0].pack_unit;
      const opt_sale_cnt = $scope.shipment.prod_add_opt_list[idx].opt_sale_cnt;

      $scope.shipment.prod_add_opt_list[idx].sku_list[0].opt_sale_cnt = opt_sale_cnt;
      $scope.shipment.prod_add_opt_list[idx].pack_unit = pack_unit;
      $scope.shipment.prod_add_opt_list[idx].total_cnt = pack_unit * opt_sale_cnt;
      $scope.shipment.prod_add_opt_list[idx].prod_no = re.isSet ? null : data[0].prod_no;
      $scope.shipment.prod_add_opt_list[idx].set_no = re.isSet ? re.data.set.set_no : null;
      $scope.shipment.prod_add_opt_list[idx].set_cd = re.isSet ? re.data.set.set_cd : null;
      $scope.shipment.prod_add_opt_list[idx].bundle_avail_yn = data.find(r => !r.bundle_avail_yn) ? 0 : 1;
      $scope.shipment.prod_add_opt_list[idx].opt_prod_name = re.isSet ? re.data.set.opt_prod_name : data[0].opt_prod_name;
      $scope.shipment.prod_add_opt_list[idx].opt_sku_cd_list = re.isSet ? re.data.prod.map(p => p.sku_cd).join(', ') : '';
      $scope.shipment.prod_add_opt_list[idx].prod_attr = data[0].attri_pack ? data[0].attri_pack : '';
      $scope.shipment.prod_add_opt_list[idx].stock_cd = data[0].stock_cd ? data[0].stock_cd : '';
      $scope.shipment.prod_add_opt_list[idx].barcode = data[0].barcode ? data[0].barcode : '';
      $scope.shipment.prod_add_opt_list[idx].sale_price = re.isSet ? re.data.set.sale_price * re.data.set.set_pack_unit : data.reduce((sum, ord) => sum + (ord.sale_price * ord.pack_unit), 0);

      // SKU상품 매칭시 해당 SKU상품의 배송처도 매칭되도록 처리 2018-06-12 rony
      $scope.shipment.depot_no = data[0].depot_no;
      $scope.shipment.depot_name = data[0].depot_name;
      matchDepotNo.opt = data[0].depot_no;

      $scope.depotChangeYn = false;

      // 판매가, 원가 업데이트
      $scope.priceUpdate();

      return true;
    }

    // 판매가 업데이트
    $scope.priceUpdate = () => {
      if ($scope.isManageStock) {
        salePriceUpdate();
      }
    };

    // 판매가 업데이트 (옵션의 판매가  + 추가구매옵션들의 총 판매가)
    const salePriceUpdate = () => {
      const pack_unit = $scope.shipment.set_no ? $scope.shipment.set_pack_unit : 1;
      const add_opt_sale_price = (_($scope.shipment.prod_add_opt_list).map(function (opt) { return opt.sale_price * opt.opt_sale_cnt; }).sum()) || 0; // 추가구매옵션의 총 원가
      const ord_opt_sale_price = ($scope.shipment.sale_price * $scope.shipment.sale_cnt * pack_unit) || 0; // 옵션 원가

      $scope.shipment.shop_sale_price = add_opt_sale_price + ord_opt_sale_price;
    };

    function prodAddOptSet() {
      _.each($scope.shipment.prod_add_opt_list, function(prod) {
        if (prod.sku_cd) {
          prod.set_info = prod.set_no ? { set_no: prod.set_no, set_cd: prod.sku_cd, set_pack_unit: prod.set_pack_unit } : { set_no: null, set_cd: null, set_pack_unit: null };
        }
      });
    }

    /**
     * SKU상품 상세페이지 열기
     */
    $scope.showProdDetail = (sku_cd, prod_number, prod_sol_no) => {

      // SKU상품 수정 권한 확인. 2019-01-03 rony
      if (commonSVC.checkPermission('stock.roles.editStock', userInfo.permission) === false) {
        return false;
      }

      if (!sku_cd) {
        commonSVC.showMessage('매칭된 SKU상품이 없습니다.');

        return;
      }

      sku_cd = sku_cd.replace(/=\d+/, '');

      const resolve = {
        data: {
          sku: sku_cd,
          number: prod_number,
          prodSolNo: prod_sol_no,
          detailType: 'edit'
        }
      };

      // 매칭된 SKU상품이 로그인계정 상품이 아닌 경우 상세에서 수정불가
      if (prod_sol_no && +prod_sol_no !== +$rootScope.user_profile.sol_no) {
        resolve.data.nonEdit = true;
      }

      resolve.supplierList = function() {
        return supplierModel.ListAll({ use_yn: true });
      };
      resolve.warehouseList = function() {
        return warehouseModel.ListAll({ use_yn: true });
      };
      resolve.madeinList = function() {
        return productModel.getMadein();
      };
      resolve.systemList = function() {
        return systemModel.load();
      };
      commonSVC.openModal('xg', resolve, 'ProdProductDetailCtrl', 'views/prod/product/detail.html');
    };

    /**
     * 세트 상세보기
     */
    $scope.setShowDetail = function(set_no) {
      const resolve = {
        data: { from: '수정', set_no: set_no }
      };

      commonSVC.openModal('full', resolve, 'addSetProdCtrl', 'views/prod/set/modals/edit_set_prod.html');
    };

    /**
     * 수령자 정보 수정 모달
     */
    $scope.onEditShipToInfoClicked = function () {
      const resolve = {
        data: {
          model: {
            to_name: $scope.shipment.to_name,
            bundle_tin: $scope.shipment.bundle_tin,
            to_addr1: $scope.shipment.to_addr1,
            to_addr2: $scope.shipment.to_addr2,
            to_city: $scope.shipment.to_city,
            to_state: $scope.shipment.to_state,
            to_town: $scope.shipment.to_town,
            to_district: $scope.shipment.to_district,
            to_zipcd: $scope.shipment.to_zipcd,
            to_ctry_cd: $scope.shipment.to_ctry_cd,
            to_tel: $scope.shipment.to_tel,
            to_htel: $scope.shipment.to_htel,
            to_email: $scope.shipment.to_email
          }
        }
      };
      commonSVC.openModal('lg', resolve, 'EditShipmentInfoCtrl', 'views/order/shipment/modals/editShipmentInfo.html').result.then(result => {
        Object.assign($scope.shipment, result);
      });
    };

    /**
     * 매개변수로 받은 리스트에 SKU상품 추가
     */
    $scope.addSku = function() {
      $scope.shipment.sku_list.push({ ...emptySkuTemplate });
    };

    /**
     * 특정 인덱스 위치에 있는 매칭된 SKU상품 삭제
     */
    $scope.removeSkuAtIndex = function(index) {
      $scope.shipment.sku_list.splice(index, 1);
    };

    $scope.deleteMatching = function(ord_opt_seq) {
      let opt,
          optType;

      if (ord_opt_seq === undefined) {
        // 기본옵션
        opt = $scope.shipment;
        opt.sku_list = [
          { prod_no: null }
        ];
        optType = 'prod';

        matchDepotNo.prod = null;

        // 옵션 삭제 시 원가 0으로 변경
        $scope.ord_opt_cost_price = 0;
        // 옵션 삭제 시 판매가 0으로 변경
        $scope.shipment.sale_price = 0;
        $scope.shipment.set_info = { set_no: null, set_cd: null, set_pack_unit: null };
      } else {
        // 추가구매옵션
        opt = $scope.shipment.prod_add_opt_list[ord_opt_seq];
        opt.sku_list = [];
        opt.sku_pack = '';
        optType = 'opt';
        opt.opt_sale_cnt = null;
        opt.ord_opt_name = '';
      }

      opt = _.merge(opt, shipmentSVC.initOpt[optType]);

      if (!_($scope.shipment.prod_add_opt_list).map('sku_list').flatten().filter('depot_no').value().length) {
        matchDepotNo.opt = null;
      }

      $scope.depotChangeYn = _.indexOf(stockMatchState, $scope.shipment.ord_status) >= 0 && (!matchDepotNo.prod && !matchDepotNo.opt);

      // 원가 업데이트
      $scope.priceUpdate();
    };

    $scope.calcPackUnit = function (prod_add_opt) {
      if (prod_add_opt) {
        return prod_add_opt.sku_list?.reduce((sum, curr) => sum + +curr.pack_unit, 0);
      }

      return $scope.shipment.sku_list?.reduce((sum, curr) => sum + +curr.pack_unit, 0);
    };

    $scope.calcTotalWeight = function(sku) {
      let output = 0;
      if ($scope.shipment.set_no) {
        output = $scope.shipment.total_prod_weight * $scope.shipment.sale_cnt;
      } else if (sku) {
        output = (sku.prod_weight || $scope.getProdWeight(sku.prod_no)) * $scope.shipment.sale_cnt;
      } else {
        return '';
      }

      return output || '';
    };

    $scope.calcAddOptTotalWeight = function(prod_add_opt) {
      if (!prod_add_opt?.sku_list?.length) {
        return 0;
      }

      if (prod_add_opt.total_prod_weight) {
        return prod_add_opt.total_prod_weight;
      } else {
        return prod_add_opt.sku_list.reduce((sum, sku) => sum + (sku.prod_weight || $scope.getProdWeight(sku.prod_no)) * sku.pack_unit, 0) * 1000;
      }
    };

    $scope.formatAddOptTotalPackUnit = function(prod_add_opt, value) {
      if (!prod_add_opt.sku_list) {
        return '';
      }

      return value;
    };

    $scope.formatSkuCode = function(sku_list) {
      if (!sku_list?.length) {
        return '';
      } else if (sku_list.length === 1) {
        return sku_list[0].sku_cd;
      }

      return `${sku_list[0].sku_cd} 외 ${sku_list.length - 1}건`;
    };

    $scope.formatToFixed2Digits = function(object, key) {
      object[key] = Number(object[key]).toFixed(2);
    };

    $scope.exreportRoundDownTo255 = function (exreport) {
      if (+exreport.exreport_bundle_cnt) {
        exreport.exreport_bundle_cnt = Math.min(+exreport.exreport_bundle_cnt, 255);
      }
    };

    // 기타 정보 > 수출이행 등록정보 추가 함수
    $scope.addBundleExportData = function() {
      $scope.etcInfo.bundle_export_data.push({
        exreportno: '',
        divide_yn: '',
        exreport_bundle_cnt: '',
      });
    };

  })

  /**
   * 수령자 정보 수정 컨트롤러
   */
  .controller('EditShipmentInfoCtrl', function ($rootScope, $scope, data, $uibModalInstance) {
    const required_input_list = [
      'to_name', 'to_addr1', 'to_zipcd', 'to_ctry_cd'
    ];

    $scope.countryList = $rootScope.countryList;
    $scope.model = data.model;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.onSubmit = () => {
      if (!validateInput()) {
        return;
      }
      $uibModalInstance.close($scope.model);
    };

    function validateInput() {
      for (const key of required_input_list) {
        if (!$scope.model[key]) {
          return false;
        }
      }

      return true;
    }

  });