/**
 * 자동결제정보 또는 환불정보 입력 모달.
 * */

'use strict';

angular.module('gmpApp')
  .controller('AutoPayInfoCtrl', function ($scope, data, $uibModalInstance, commonSVC, userInfo, payModel, commonModel, localStorageService, $window) {

    const dataLayer = $window.dataLayer = $window.dataLayer || [];

    // 호출타입 ( card:자동결제카드정보, refund:환불결제계좌정보 )
    $scope.type = data.type || 'card';
    $scope.title = $scope.type === 'card' ? '결제정보 입력' : '환불 은행/계좌정보 입력';
    const cardNos = data.card_no ? data.card_no.split('-') : [];
    const cardTerms = data.card_effect_term ? data.card_effect_term.split('-') : [];
    const card_biz_reg_no = data.card_biz_reg_no?.replace(/-/g, '') || userInfo.user.biz_reg_no?.replace(/-/g, '') || '';
    $scope.bankInfo = data.bank_info || [];
    $scope.executeAutoPay = data.executeAutoPay || null;

    const setAutoPayInfo = !!data.setAutoPayInfo; // 모달내 저장여부

    // 저장정보
    $scope.setData = {
      type: $scope.type,                                  // 호출타입
      bank_no: data.bank_no || '',                        // 은행번호
      bank_name: '',                                      // 은행명
      bank_holder_name: data.bank_holder_name || '',      // 예금주명
      bank_acct_no: data.bank_acct_no || '',              // 계좌번호
      card_no1: cardNos.length ? cardNos[0] : '',         // 카드번호
      card_no2: cardNos.length ? cardNos[1] : '',
      card_no3: cardNos.length ? cardNos[2] : '',
      card_no4: cardNos.length ? cardNos[3] : '',
      card_effect_term1: cardTerms.length ? cardTerms[0] : '',  // 카드유효기간
      card_effect_term2: cardTerms.length ? cardTerms[1] : '',
      card_type: data.card_type || 'company',             // 카드종류 (법인, 개인)
      birth_num: data.birth_num || '',                    // 주민등록번호 앞 6자리 ( 개인카드인경우 필수 )
      biz_reg_no: (data.card_type || 'company') === 'company' ? card_biz_reg_no : '',
      cardPw: '',
    };

    // 입력하는 input에 최대 문자 도달 시 다음 input으로 커서 이동
    $scope.moveToNextInput = function(event, nextInputName) {
      if (event.target.value.length === event.target.maxLength) {
        const nextInput = document.getElementsByName(nextInputName)[0];

        nextInput.focus();
      }
    };

    /**
     * pay_management 창에 결제 정보 넘기기.
     */
    $scope.onSubmit = async function () {
      if ($scope.setData.type === 'card') {
        if ($scope.setData.card_no1 === '' || $scope.setData.card_no2 === '' || $scope.setData.card_no3 === '' || $scope.setData.card_no4 === '') {
          commonSVC.showMessage('카드번호를 빠짐없이 입력해주십시오.');

          return false;
        }
        else if ($scope.setData.card_effect_term1 === '' || $scope.setData.card_effect_term2 === '') {
          commonSVC.showMessage('카드 유효년월을 입력해주십시오.');

          return false;
        } else if ($scope.setData.card_type === 'personal' && $scope.setData.birth_num === '') {
          commonSVC.showMessage('개인카드인경우 생년월일은 필수입니다.');

          return false;
        } else if ($scope.setData.card_type === 'company' && $scope.setData.biz_reg_no === '') {
          commonSVC.showMessage('법인카드인경우 사업자등록번호는 필수입니다.');

          return false;
        } else if ($scope.setData.cardPw === '') {
          commonSVC.showMessage('카드 비밀번호 앞 두자리를 입력해주십시오.');

          return false;
        }
      }

      if ($scope.autopayForm.$valid) {
        const autoPayData = {
          type: 'autoPayCardInfo',
          card_type: $scope.setData.card_type,
          card_no: `${$scope.setData.card_no1}-${$scope.setData.card_no2}-${$scope.setData.card_no3}-${$scope.setData.card_no4}`,
          card_effect_term: `${$scope.setData.card_effect_term1}-${$scope.setData.card_effect_term2}`,
          birth_num: $scope.setData.birth_num,
          cardPw: $scope.setData.cardPw,
          biz_reg_no: $scope.setData.biz_reg_no,
        };

        if ($scope.executeAutoPay) {
          if (!$scope.executeAutoPay.setAutoPay) {
            commonSVC.showMessage('정기결제를 동의해 주세요.');

            return false;
          }

          autoPayData.use_yn = 'Y';
          autoPayData.executeAutoPay = $scope.executeAutoPay;
          if ($scope.executeAutoPay.autopay_term) {
            autoPayData.autopay_term = $scope.executeAutoPay.autopay_term;
          }

          try {
            const res = await payModel.setAutoPayInfo(autoPayData);

            // GA4 dataLayer에 결제 정보 전달 스크립트 추가
            if (res?.data?.GA4_data) {
              dataLayer.push({
                event: 'purchase',
                user_id: String(userInfo.user.user_m_no),
                label: $scope.executeAutoPay.label,
                ecommerce: res.data.GA4_data.ecommerce
              });
            }

            commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
              localStorageService.set('token', `${'Token' + ' '}${result}`);
            });

            const opt = {
              html: `<div class="no-padding-top pb-20" style="font-size: 18px !important;">
                <h2 class="mt-10">결제완료</h2>
                결제정보 등록이 완료되었어요!<br />
                지금 바로 플레이오토의 다양한 기능을 사용해 보세요.
              </div>`,
              icon: 'success',
              confirmButtonText: '퀵플레이 체험하기',
              showCancelButton: false,
              customClass: {
                actions: '',
                confirmButton: 'my-swal2-confirm2',
              },
            };

            commonSVC.showConfirmCustomSwal2(opt, function () {
              $uibModalInstance.close({ re: 'success' });
            });
          } catch (err) {
            commonSVC.showMessage('결제에 실패하였습니다', err.data.error);

            return false;
          }
        } else {
          if ($scope.setData.bank_no !== '') {
            $scope.setData.bank_name = _.find($scope.bankInfo, { bank_no: parseInt($scope.setData.bank_no) }).bank_name;
          }

          if (setAutoPayInfo) {
            try {
              await payModel.setAutoPayInfo(autoPayData);
            } catch (err) {
              commonSVC.showMessage('저장에 실패하였습니다.', err.data.error);

              return false;
            }
          }

          $uibModalInstance.close({ re: 'success', setData: $scope.setData });
        }
      }
    };

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.close({ re: 'cancel' });
    };

  });