'use strict';

(() => {
  angular.module('gmpApp').component('multiSelect2', {
    transclude: true,

    bindings: {
      nonSelectedText: '@',
      numberDisplayed: '@',
      useFilteredText: '@',
      onChange: '&',
    },

    require: {
      ngModel: 'ngModel',
    },

    template() {
      return /* html */ `
        <select
          class="multi-select2"
          multiple="multiple"
          ng-transclude>
        </select>
      `;
    },

    controller($scope, $element) {
      this.$postLink = function () {
        this.$select = $($element.find('select'));
        this.$select.multiselect({
          nSelectedText: this.nonSelectedText === '그룹 선택',
          includeSelectAllOption: true,
          nonSelectedText: this.nonSelectedText === '그룹 선택' ? '그룹 선택' : (this.nonSelectedText == '상품 상태' ? '상품 상태' : '선택하세요.'),
          allSelectedText: ['계정 선택', '그룹 선택'].includes(this.nonSelectedText) ? '전체 선택' : '상품상태 전체',
          includeFilterClearBtn: true,
          delimiterText: '/',
          maxHeight: this.maxHeight,
          numberDisplayed: this.numberDisplayed,
          buttonWidth: '100%',
          ...(this.ngModel.$$attr.maxHeight && { maxHeight: this.ngModel.$$attr.maxHeight }),   // multi-select2태그 속성으로 max-height추가 시 ul 높이 기준으로 overflow처리 위해 추가
          onChange: (option, checked) => {
            this.ngModel.$setViewValue(this.$select.val());
            this.onChange({ $option: option, $checked: checked });
          },

          // 전체선택 or 해제 시 CSS 적용
          onSelectAll: function (options) {
            options ? $('.multi-select').addClass('select-on') : $('.multi-select').removeClass('select-on');
            // 상품집계 탭 내에서는 전체상태 값 넣지 않도록 조건 추가 (tabIndex = 검색 0, 상품집계 4)
            if ($scope.$parent.row.search_name !== 'multi_group_no') {
              options && $scope.$parent.tabIndex === 4 ? $scope.$parent.searchForm.sale_status : $scope.$parent.searchForm.sale_status = ['판매대기', '승인대기', '반려', '판매중', '종료대기', '수정대기', '판매중지', '일시품절'];
            }
          },
          // 검색 기능 사용하는 경우
          ...(this.useFilteredText === 'true' && {
            enableFiltering: false, // 검색 기능 사용 여부
            enableCaseInsensitiveFiltering: true // 부분 일치 여부
          })
        });
      };

      this.$doCheck = function () {
        if (!this.$select) { return; }
        const lastCount = this.optionCount || 0;
        const currentCount = this.$select.find('option').length;
        if (lastCount !== currentCount) {
          this.$select.multiselect('rebuild');
          this.optionCount = currentCount;
        }
      };

      $scope.$watch(
        () => this.ngModel.$viewValue,
        value => {
          this.$select.val(value);
          // 상품집계에서 선택한 값으로 로드
          this.$select.multiselect('select', value);
          // 상품상태 전체선택 후 변경했을 때 초기화 처리
          this.$select.multiselect('refresh');
        }
      );
    },
  });
})();
