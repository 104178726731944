/**
 * 결제 관리 컨틀홀러
 * */

'use strict';

angular.module('gmpApp')
  .controller('PayManagementCtrl', function (paySVC, $scope, data, commonSVC, $uibModalInstance, gettextCatalog, payModel, userInfo, commonModel, adminModel, localStorageService, cardSVC, $rootScope, $timeout, $document, $window) {

    $scope.solutionInfo = data.solutionInfo;    // 사용솔루션 정보
    $scope.solSerInfo = data.solSerInfo;        // 사용부가서비스 정보 ( SMS, Email, 알림톡 )
    const payInfosWithToday = data.payInfosWithToday; // 현재 결제정보

    $scope.editCard = false; // 버튼텍스트 '등록' > '변경'
    $scope.editRefund = false;

    let origin_card_no = '';
    let bankInfo = [];

    const dataLayer = $window.dataLayer = $window.dataLayer || [];
    // 무료버전
    $scope.freeVersion = paySVC.freeVersion.includes($scope.solutionInfo.sol_version);

    // 서비스 제한버전
    $scope.limitedVersion = paySVC.limitedVersion.includes($scope.solutionInfo.sol_version);

    // 자동결제, 환불 저장 정보
    $scope.setAutoDatas = {
      card_no: '',                                  // 카드번호
      card_info: '등록된 카드가 없습니다.',           // 카드번호
      card_effect_term: '',                         // 카드 유효기간
      card_type: 'company',                         // 카드종류 (개인, 법인)
      birth_num: '',                                // 개인카드인경우 주민번호 앞 6자리
      cardPw: '',                                   // 카드 비밀번호 앞 2자리
      refund_bank_no: '',                           // 환불은행번호
      refund_bank_info: '등록된 계좌가 없습니다.',    // 환불은행명
      refund_bank_holder_name: '',                  // 환불예금주명
      refund_bank_acct_no: '',                      // 환불계좌번호
      biz_reg_no: userInfo.user.biz_reg_no,         // 사업자 등록번호 넘김
      use_yn: 'N'                                   // 자동결제 적용여부
    };

    // 사용부가서비스 이용료 계산
    $scope.serPrice = $scope.solSerInfo.reduce(function(acc, cur) {
      return acc + cur.price;
    }, 0);

    const indv_pay_amt = $rootScope.indvPayInfo.indv_pay_amt; // 개별설정 월사용요금
    const saleCnt = payInfosWithToday.sale_cnt || 1;

    $scope.solPayUseInfo = {
      term: saleCnt,
      solPrice: saleCnt * $scope.solutionInfo.monthPrice,
      percent: '',
      longTermDc: 0,
      autoPayDc: 0,
      dcAmount: 0,
    };

    // 전체쿠폰 리스트
    let couponList = [];

    // 적용중인 쿠폰 리스트
    let runningCouponList = [];

    // 장기결제 할인율
    const percentMap = {
      3: 0.05,
      6: 0.1,
      12: 0.2,
      24: 0.3
    };

    // 할인금액 계산
    function executeCalAfterIndvPayAmt() {
      if (indv_pay_amt || paySVC.freeVersion.includes($scope.solutionInfo.sol_version)) {
        $scope.solPayUseInfo.term = 1;
        $scope.solPayUseInfo.solPrice = indv_pay_amt || $scope.solutionInfo.monthPrice;

        return;
      }

      let totalPrice = $scope.solPayUseInfo.solPrice;

      // 장기결제 시 할인 계산
      if (saleCnt > 1 && saleCnt in percentMap) {
        const percent = percentMap[saleCnt];
        $scope.solPayUseInfo.percent = `${percent * 100}%`;
        $scope.solPayUseInfo.longTermDc = $scope.solPayUseInfo.solPrice * percent;
        $scope.solPayUseInfo.dcAmount += $scope.solPayUseInfo.longTermDc;

        totalPrice -= $scope.solPayUseInfo.longTermDc;
      }

      // 정기결제 시 할인 계산
      if (payInfosWithToday.autopay_yn) {
        $scope.solPayUseInfo.autoPayDc = totalPrice * 0.03;
        $scope.solPayUseInfo.dcAmount += $scope.solPayUseInfo.autoPayDc;

        totalPrice -= $scope.solPayUseInfo.autoPayDc;
      }

      // 적용된 정기결제 쿠폰이 있을경우
      if (payInfosWithToday.dc_tar_list.length) {
        // 쿠폰 할인가 계산 함수
        const calculateCouponDiscount = function(price, coupon) {
          return coupon.ben_sub_type === '정률' ? Math.floor(price * coupon.dc_qty / 100) : coupon.dc_qty;
        };

        $scope.genCouponDc = $scope.duplCouponDc = 0;
        if ($scope.genCoupon) {
          $scope.genCouponDc = calculateCouponDiscount(totalPrice, $scope.genCoupon);
          $scope.solPayUseInfo.dcAmount += $scope.genCouponDc;
        }

        if ($scope.duplCoupon) {
          $scope.duplCouponDc = calculateCouponDiscount(totalPrice, $scope.duplCoupon);
          $scope.solPayUseInfo.dcAmount += $scope.duplCouponDc;
        }
        totalPrice = totalPrice - $scope.genCouponDc - $scope.duplCouponDc;
      }
    }

    /**
     * 자동 결제정보 입력 창 열기.
     */
    let selectedType = '';
    let refundBankInfo = '';

    $scope.autoPayInfo = async function (type) {
      selectedType = type;
      type = type.includes('card') ? 'card' : 'refund';
      const res = await adminModel.load({ c_no: userInfo.user.c_no, onlyCompany: true });
      const company = res.data.results.company;

      if (selectedType === 'refund_add') {
        const format_invalid = ['name', 'ceo_name', 'addr1', 'uptae', 'upjong'].some(key => commonSVC.isEmailFormat(company[key]));

        if (!company.biz_reg_url || !company.addr1
          || (company.c_type !== '해외사업자' && (!company.zipcd || !company.uptae || !company.upjong)) || format_invalid) {
          if (!userInfo.user.auth_type.includes('관리자') && (!userInfo.permission.setting?.use_yn || !userInfo.permission.setting?.roles.company)) {
            commonSVC.showMessage('환불 정보 등록 시 사업자정보는 필수로 입력되어 있어야 합니다.', '회사 정보 관리 메뉴에 접근 권한이 없습니다.\n사내 관리자에게 요청하여 사업자정보 저장 후 다시 진행해 주세요.');

            return false;
          } else {
            commonSVC.showConfirm('환불 정보 등록 시 사업자정보는 필수로 입력되어 있어야 합니다.', '사업자 정보를 등록하시겠습니까?')
              .then((isConfirm) => {
                if (isConfirm) {
                  const modal = commonSVC.openModal('xg', { data: company }, 'EditUserInfoModalCtrl', 'views/pay/modals/editUserInfo.html');

                  modal.result.then(function (re) {
                    if (re === 'success') {
                      commonSVC.showToaster('success', '성공', '사업자정보 저장 성공');
                      $scope.autoPayInfo('refund_add');

                      return false;
                    }
                  });

                  return false;
                }
              });
          }

          return false;
        }
      }

      $scope.setAutoDatas.type = type === 'card' ? 'autoPayCardInfo' : 'autoPayRefundInfo';

      const params = {
        type: type,
        card_biz_reg_no: $scope.setAutoDatas.biz_reg_no || company.biz_reg_no,
        card_no: $scope.setAutoDatas.card_no,
        card_effect_term: $scope.setAutoDatas.card_effect_term,
        card_type: $scope.setAutoDatas.card_type,
        birth_num: $scope.setAutoDatas.birth_num,
        bank_no: $scope.setAutoDatas.refund_bank_no,
        bank_holder_name: $scope.setAutoDatas.refund_bank_holder_name,
        bank_acct_no: $scope.setAutoDatas.refund_bank_acct_no,
        bank_info: bankInfo
      };
      const { re, setData } = await commonSVC.openModal('md', { data: params }, 'AutoPayInfoCtrl', 'views/pay/modals/autopay_info.html').result;

      if (re == 'success') {
        refundBankInfo = `${setData.bank_name}(${maskingByPosiotion(setData.bank_acct_no, 6)})`;
        const saveParams = {
          card_type: setData.card_type,
          type: type,
          use_yn: $scope.setAutoDatas.use_yn || 'N',
        };
        // 자동결제 신용카드정보
        if (setData.type === 'card') {
          saveParams.card_no = `${setData.card_no1}-${setData.card_no2}-${setData.card_no3}-${setData.card_no4}`;
          saveParams.card_effect_term = `${setData.card_effect_term1}-${setData.card_effect_term2}`;
          saveParams.birth_num = setData.birth_num;
          saveParams.cardPw = setData.cardPw;
          saveParams.editCardNo = origin_card_no != setData.card_no;
          saveParams.biz_reg_no = setData.biz_reg_no || company.biz_reg_no;
          if (saveParams.use_yn == 'Y' && userInfo.user.sol_expired) {
            saveParams.autopay_term = $scope.setAutoDatas.autopay_term || $scope.solutionInfo.last_sale_cnt;
          }
          $scope.clearButtonStyle = { top: '10px' };
        }
        // 환불계좌정보
        else if (setData.type === 'refund') {
          saveParams.refund_bank_no = setData.bank_no;
          saveParams.refund_bank_info = `${setData.bank_name}(${maskingByPosiotion(setData.bank_acct_no, 6)})`;
          saveParams.refund_bank_holder_name = setData.bank_holder_name;
          saveParams.refund_bank_acct_no = setData.bank_acct_no;
        }
        savePaymentInfo(saveParams);
      }
    };

    // 이탈방지 모달 esc이벤트 발생 시 ui-switch가 초기값으로 돌아와야하는데 돌아오지 않아서 해당처리 추가
    $document.on('keydown', (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        $scope.$apply(function() {
          init();
        });
      }

      return;
    });
    /**
     * 정기결제 사용 설정.
     */
    $scope.setPayAuto = async function () {
      $scope.setAutoDatas.type = 'card';

      const saveParams = {
        type: 'card',
        card_no: $scope.setAutoDatas.card_no,
        card_effect_term: $scope.setAutoDatas.card_effect_term,
        card_type: $scope.setAutoDatas.card_type,
        birth_num: $scope.setAutoDatas.birth_num,
        biz_reg_no: $scope.setAutoDatas.biz_reg_no,
        cardPw: $scope.setAutoDatas.cardPw,
        editCardNo: $scope.setAutoDatas.editCardNo,
      };

      let isMaintain = false;

      if (!$scope.setAutoDatas.card_no && $scope.setAutoPay) {
        commonSVC.showMessage('자동결제정보가 입력되어있지 않습니다.', '자동결제를 원하시는경우 결제정보를 먼저 입력해 주셔야 합니다.');
        $scope.setAutoPay = false;

        return;
      }
      if (!$scope.setAutoPay) {
        const modal = commonSVC.openModal('w560', { data: {} }, 'PayAutoInduceMentCtrl', 'views/pay/modals/regular_payment_b.html');
        await modal.result.then(async (re) => {
          if (re) {
            isMaintain = true;
            $scope.setAutoPay = true; // "유지하겠습니다" 선택 시 현재 상태 유지

            return;
          } else {
            $scope.setAutoPay = false; // 진짜 정기결제 해지
            // 정기결제 미적용 시
            if (runningCouponList.length && $scope.setAutoDatas.use_yn == 'Y') {
              if (runningCouponList.find(o => o.cpn_status == '사용중')) {
                const confirm = await commonSVC.showConfirm('잔여 할인이 존재합니다.', '자동결제를 중단하면 남은 할인회수도 소멸되며, 다시 복구할수 없습니다.');
                if (!confirm) {
                  $scope.setAutoPay = true;

                  return;
                }
              }

              await payModel.useCoupon({ couponList: runningCouponList, disappear_yn: true });
              await getCouponList();
            }
            $scope.setAutoDatas.use_yn = 'N';
          }
        });
      } else {
        const confirmDescribe = userInfo.user.sol_expired ? '• [확인]클릭 시 등록된 카드로 즉시 결제가 실행되며, 다음 결제부터 정기적으로 결제가 진행됩니다.' : '• [확인]클릭 시 다음 결제부터 정기적으로 결제가 진행됩니다.';

        const confirm = await commonSVC.showConfirm('등록된 카드로 결제를 진행 하시겠습니까?', confirmDescribe);
        if (!confirm) {
          $scope.setAutoPay = false;

          return;
        }

        // 자동결제 주기
        saveParams.autopay_term = $scope.setAutoDatas.autopay_term || $scope.solutionInfo.last_sale_cnt;

        // 사용가능한 정기결제 쿠폰 있을 시 모달 열어줌
        const availCpnList = couponList.filter(cpn => {
          return ['미사용', '사용대기'].includes(cpn.cpn_status)
            // 사용조건-버전 확인
            && (!cpn.prod_group_nos || cpn.prod_group_nos.includes($scope.solutionInfo.prod_group_no))
            // 사용조건-결제개월 확인
            && (!cpn.pay_month || cpn.pay_month == saveParams.autopay_term)
            // 사용조건-첫결제여부 확인
            && (!cpn.pay_sol_type || ((cpn.pay_sol_type == '무료') == !$scope.solutionInfo.first_pay_no))
            // 서비스 제한버전
            && (!$scope.limitedVersion);
        });

        if (availCpnList.length > 0) {
          const modalResult = await commonSVC.openModal('mg', {
            data: {
              autopay_coupon_list: availCpnList,
            }
          }, 'SetCouponCtrl', 'views/pay/modals/set_coupon.html').result;

          if (modalResult === 'fail') {
            commonSVC.showMessage('저장에 실패하였습니다.');
          }
        }
        $scope.setAutoDatas.use_yn = 'Y';
      }

      saveParams.use_yn = $scope.setAutoDatas.use_yn;

      selectedType = 'card_edit';
      // 정기결제 유지 시에는 호출할 필요없음.
      if (!isMaintain) {
        savePaymentInfo(saveParams);
      }
    };

    /**
     * 결제정보 초기화.
     */
    $scope.clearData = async function(type) {
      let saveParams;

      $scope.setAutoDatas.type = type;
      if (type === 'refund') {
        if (!$scope.setAutoDatas.refund_bank_acct_no) { return; }

        const confirm = await commonSVC.showConfirm('환불계좌를 삭제하시겠습니까?', '');
        if (!confirm) { return; }

        selectedType = 'bankInfoClear';
        $scope.setAutoDatas.refund_bank_no = '';
        $scope.setAutoDatas.refund_bank_info = '등록된 계좌가 없습니다.';
        $scope.setAutoDatas.refund_bank_holder_name = '';
        $scope.setAutoDatas.refund_bank_acct_no = '';

        saveParams = angular.copy($scope.setAutoDatas);
      } else {
        if (!$scope.setAutoDatas.card_no) {
          commonSVC.showMessage('카드정보가 입력되어있지 않습니다.', '카드정보를 먼저 입력해 주세요.');

          return false;
        }

        if (runningCouponList.length && $scope.setAutoDatas.use_yn == 'Y') {
          if (runningCouponList.find(o => o.cpn_status == '사용중')) {
            const confirm = await commonSVC.showConfirm('잔여 할인이 존재합니다.', '자동결제를 중단하면 남은 할인회수도 소멸되며, 다시 복구할수 없습니다.');
            if (!confirm) {
              return;
            }
          }

          await payModel.useCoupon({ couponList: runningCouponList, disappear_yn: true });
          await getCouponList();
        } else {
          const confirm = await commonSVC.showConfirm('카드정보를 삭제하시겠습니까?', '');
          if (!confirm) {
            return;
          }
        }

        selectedType = 'cardInfoClear';
        $scope.setAutoDatas.card_no = '';
        $scope.setAutoDatas.companyName = '';
        $scope.setAutoDatas.card_effect_term = '';
        $scope.setAutoDatas.card_info = '등록된 카드가 없습니다.';
        $scope.setAutoDatas.use_yn = 'N';
        $scope.setAutoDatas.card_type = 'company';
        $scope.clearButtonStyle = { top: '0px' };
        $scope.setAutoPay = false;

        saveParams = angular.copy($scope.setAutoDatas);
        delete saveParams.type;
        delete saveParams.editCardNo;
        delete saveParams.autopay_term;
      }

      savePaymentInfo(saveParams);
    };

    /**
     * 마스킹 처리.
     */
    function maskingByPosiotion (str, start) {
      str = $.trim(str);      // 대상문자열
      const end = str.length;
      let temp = '';

      temp = str.substring(0, start);
      for (let i = start; i < end; i++) {
        temp = `${temp}*`;
      }

      return temp;
    }

    /**
     * 저장하기
     */
    async function savePaymentInfo(autoPayData) {
      try {
        await payModel.setAutoPayInfo(autoPayData);

        origin_card_no = $scope.setAutoDatas.card_no;

        if (autoPayData.type === 'card') {
          const cardInfo = autoPayData.card_no.split('-');

          // 카드사 조회
          const cardNumber = autoPayData.card_no.replace(/-/g, '');
          let companyName = cardSVC.getCompanyName(cardNumber.substring(0, 6));

          // 카드사가 조회되지 않을 시 개인카드 or 법인카드로 표기
          switch (companyName) {
            case '카드':
              companyName = (autoPayData.card_type === 'personal') ? '개인카드' : '법인카드';
              break;
            default:
              break;
          }
          // 등록된 카드가 없을경우 카드사 공란처리
          $scope.setAutoDatas.companyName = !cardNumber ? '' : companyName;

          $scope.setAutoDatas.card_no = autoPayData.card_no;
          $scope.setAutoDatas.card_info = autoPayData.card_info || `${cardInfo[0]}-${cardInfo[1]}-${maskingByPosiotion(cardInfo[2], 0)}-${maskingByPosiotion(cardInfo[3], 0)}`;
          $scope.setAutoDatas.card_effect_term = autoPayData.card_effect_term || null;
          $scope.setAutoDatas.card_type = autoPayData.card_type;
          $scope.setAutoDatas.birth_num = autoPayData.birth_num;
          $scope.setAutoDatas.biz_reg_no = autoPayData.biz_reg_no;
          $scope.setAutoDatas.cardPw = autoPayData.cardPw;
          $scope.setAutoDatas.editCardNo = origin_card_no != autoPayData.card_no;

          if (autoPayData.autopay_term) {
            $scope.setAutoDatas.autopay_term = autoPayData.autopay_term;
          }
        } else if (autoPayData.type === 'refund') {
          $scope.setAutoDatas.refund_bank_info = refundBankInfo ? refundBankInfo : '등록된 카드가 없습니다.';
          $scope.setAutoDatas.refund_bank_no = autoPayData.refund_bank_no;
          $scope.setAutoDatas.refund_bank_holder_name = autoPayData.refund_bank_holder_name;
          $scope.setAutoDatas.refund_bank_acct_no = autoPayData.refund_bank_acct_no;
        }

        let message = '';

        switch (selectedType) {
          case 'refund_add':
            $scope.editRefund = true;
            message = '환불계좌 등록 성공';
            break;
          case 'card_add':
            $scope.editCard = true;
            if (!$scope.freeVersion) {
              // 정기결제 사용
              $scope.setAutoPay = true;
              await $scope.setPayAuto();
            }
            message = '결제정보 등록 성공';
            break;
          case 'refund_edit':
            message = '환불계좌정보 수정 성공';
            break;
          case 'card_edit':
            message = '결제정보 수정 성공';
            break;
          case 'cardInfoClear':
            $scope.editCard = false;
            message = '결제정보 삭제 성공';
            break;
          default:
            $scope.editRefund = false;
            message = '계좌정보 삭제 성공';
            break;
        }

        commonSVC.showToaster('success', gettextCatalog.getString('성공'), gettextCatalog.getString(message));

        // 토큰 갱신
        if ($scope.setAutoDatas.use_yn === 'Y' && userInfo.user.sol_expired) {
          commonModel.resetToken({ sol_no: userInfo.user.sol_no, email: userInfo.user.email }, function (state, result) {
            localStorageService.set('token', `${'Token' + ' '}${result}`);

            commonSVC.showMessage('저장에 성공하였습니다.', '페이지를 새로고침 합니다.');
            location.replace('/');
          });
        }

        if (!$scope.$$phase && !$scope.$root.$$phase) {
          $scope.$apply();
        }
      } catch (err) {
        commonSVC.showMessage('저장에 실패하였습니다.', err.data.error);

        return false;
      }
    }

    /**
     * 쿠폰리스트 갱신
     */
    async function getCouponList() {
      couponList = (await payModel.getUserCouponList({})).data.results.filter(o => o.use_type === '정기결제할인');

      // 적용중인 쿠폰
      runningCouponList = couponList.filter(o => ['사용중', '사용대기'].includes(o.cpn_status));

      // 정기결제 일반쿠폰: genCoupon, 중복쿠폰: duplCoupon
      $scope.genCoupon = couponList.find(cpn => cpn.dupl_dc_yn === 0 && payInfosWithToday.dc_tar_list.some(o => o.dc_tar_no == cpn.dc_tar_no));
      $scope.duplCoupon = couponList.find(cpn => cpn.dupl_dc_yn === 1 && payInfosWithToday.dc_tar_list.some(o => o.dc_tar_no == cpn.dc_tar_no));
    }

    async function init() {
      // 은행정보 조회
      payModel.getBankInfo('', function (status, data) {
        bankInfo = data.results;
      });

      // 정기결제정보 및 환불정보 조회
      payModel.getAutoPayInfo('', function (status, data) {
        if (status == 'success') {
          // 저장된 카드정보가 있는경우
          if (data.autoPayInfo.length && data.autoPayInfo[0].card_no) {
            $scope.editCard = true;
            origin_card_no = data.autoPayInfo[0].card_no;
            const cardNos = data.autoPayInfo[0].card_no.split('-');
            $scope.setAutoPay = !!data.autoPayInfo[0].use_yn;

            $scope.setAutoDatas.biz_reg_no = data.autoPayInfo[0].card_biz_reg_no || userInfo.user.biz_reg_no;
            $scope.setAutoDatas.card_no = data.autoPayInfo[0].card_no;
            $scope.setAutoDatas.card_info = `${cardNos[0]}-${cardNos[1]}-${maskingByPosiotion(cardNos[2], 0)}-${maskingByPosiotion(cardNos[3], 0)}`;
            $scope.setAutoDatas.card_effect_term = data.autoPayInfo[0].card_effect_term;
            $scope.setAutoDatas.card_type = data.autoPayInfo[0].card_type;
            $scope.setAutoDatas.birth_num = data.autoPayInfo[0].birth_num || '';
            $scope.setAutoDatas.use_yn = data.autoPayInfo[0].use_yn ? 'Y' : 'N';
            $scope.setAutoDatas.autopay_term = data.autoPayInfo[0].autopay_term || '';
            $scope.clearButtonStyle = { top: '10px' };

            // 카드사 조회
            const cardNumber = $scope.setAutoDatas.card_no.replace(/-/g, '');
            let companyName = cardSVC.getCompanyName(cardNumber.substring(0, 6));

            // 카드사가 조회되지 않을 시 개인카드 or 법인카드로 표기
            switch (companyName) {
              case '카드':
                companyName = ($scope.setAutoDatas.card_type === 'personal') ? '개인카드' : '법인카드';
                break;
              default:
                break;
            }

            // 등록된 카드가 없을경우 카드사 공란처리
            $scope.setAutoDatas.companyName = !cardNumber ? '' : companyName;
          }

          // 저장된 환불정보가 있는경우
          if (data.refundInfo.length > 0) {
            $scope.editRefund = true;
            $scope.setAutoDatas.refund_bank_no = data.refundInfo[0].bank_no;
            $scope.setAutoDatas.refund_bank_info = `${data.refundInfo[0].bank_name}(${maskingByPosiotion(data.refundInfo[0].bank_acct_no, 6)})`;
            $scope.setAutoDatas.refund_bank_holder_name = data.refundInfo[0].bank_holder_name;
            $scope.setAutoDatas.refund_bank_acct_no = data.refundInfo[0].bank_acct_no;
          }
        }
      });

      // 쿠폰조회
      await getCouponList();

      // 현재 사용중인 결제건 노출
      $timeout(() => {
        executeCalAfterIndvPayAmt();
      });
    }
    init();

    /**
     * 창 닫기
     */
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };

    // 정기결제 QA테스트
    $scope.autoPayTest = async () => {
      try {
        const { data: { results } } = await payModel.autoPayTest({ test_sol_no: userInfo.user.sol_no });
        if (results[0].state == '실패') {
          commonSVC.showMessage('정기결제 실패', '실패사유는 백오피스 CS로그를 확인해주세요');
        } else {
          commonSVC.showMessage('정기결제 성공');
        }
      } catch (error) {
        commonSVC.showMessage('정기결제 실패', error.data.error);
      }
    };

    /**
     * 정기결제 혜택제공 QA테스트
     */
    $scope.autoPayBenefitApplyTest = async () => {
      try {
        const re = await payModel.autoPayBenefitApplyTest({ test_sol_no: userInfo.user.sol_no });
        if (re.data.result.results == 'success') {
          commonSVC.showMessage('정기결제 혜택 제공성공');
        }
      } catch (error) {
        commonSVC.showMessage('정기결제 혜택 제공실패', error.data.messages[0]);
      }
    };

    /**
     * 정기결제 유도 모달 오픈
     */
    $scope.openModal = () => {
      // GA4 dataLayer에 정기결제 유도 정보 전달 스크립트 추가
      dataLayer.push({
        event: 'Button Click',
        category: 'Button Click',
        label: '2',
        action: 'click_autopay_info_button'
      });

      commonSVC.openModal('w560', { data: {} }, 'PayAutoInduceMentCtrl', 'views/pay/modals/regular_payment_a.html');
    };
  });